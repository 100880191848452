import { SyncFollowStreamManager } from "./syncFollowStreamManager";

export class ConfigDStreamManager extends SyncFollowStreamManager {
  init(fabricId: string | undefined) {
    if (fabricId) {
      // the fabric id is required for the ConfigD service to route sockets
      super.init(`wss://${location.host}/ws?svc=configD&fabricId=${fabricId}`);
    }
  }
}
