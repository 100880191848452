import { useEffect, useRef, useState, useMemo } from "react";
import { logger } from "../core/globals";
import { useStatus, StatusType } from "@magnetic/icons";
import "./icons.scss";

export type IconSize = "xl" | "lg" | "mdl" | "md" | "sm";

interface IconProps {
  readonly name: string;
  readonly classNames?: string;
  readonly size?: IconSize;
}

export const Icon = (iconProps: IconProps): JSX.Element | null => {
  const { name, classNames, size } = iconProps;
  const ImportedIconRef = useRef(null);
  const [, setRenderedAt] = useState<number>(0);

  const classes = useMemo<string>((): string => {
    const names = ["cnc-icon"];
    if (size) {
      names.push(size);
    }
    names.push(name);
    if (classNames) {
      // it's already a space delimited string
      names.push(classNames);
    }
    return names.join(" ");
  }, [name, classNames, size]);

  useEffect(() => {
    const importIcon = async () => {
      try {
        const styledIcon = await import(`../assets/icons/${name}.svg?react`);
        ImportedIconRef.current = styledIcon?.default;
        setRenderedAt(Date.now());
      } catch (err) {
        logger.warn("icon not loaded", err);
      }
    };
    importIcon();
    return;
  }, [name]);

  if (ImportedIconRef.current) {
    const ImportedIcon = ImportedIconRef.current as React.ElementType;
    return <ImportedIcon className={classes} />;
  }

  return null;
};

interface StatusIconProps {
  readonly status: StatusType;
  readonly hasBackground?: boolean;
  readonly classNames?: string;
  readonly size?: IconSize;
}

/*
  Wrapper over magnetic's StatusIcon
*/
export const StatusIcon = (props: StatusIconProps): JSX.Element | null => {
  const { status, size, hasBackground, classNames } = props;

  const IconComponent = useStatus(status);

  const classes = useMemo<string>((): string => {
    const classArr = ["cnc-icon"];
    if (size) {
      classArr.push(size);
    }
    if (classNames) {
      classArr.push(classNames);
    }

    return classArr.join(" ");
  }, [classNames, size]);

  return <IconComponent hasBackground={hasBackground} className={classes} />;
};
