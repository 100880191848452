// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/fabric.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "models";

/** AdjacencyProtocol defines an enumeration of port adjacency protocols. */
export enum AdjacencyProtocol {
  /** ADJACENCY_UNSPECIFIED - Unspecified adjacency protocol type. */
  ADJACENCY_UNSPECIFIED = 0,
  /** LLDP - Link layer discovery protocol. */
  LLDP = 1,
  /** CDP - Cisco discovery protocol. */
  CDP = 2,
  /** LACP - Link aggregation control protocol (aka PortChannel). */
  LACP = 3,
  /** STP - Spanning tree protocol. */
  STP = 4,
  UNRECOGNIZED = -1,
}

export function adjacencyProtocolFromJSON(object: any): AdjacencyProtocol {
  switch (object) {
    case 0:
    case "ADJACENCY_UNSPECIFIED":
      return AdjacencyProtocol.ADJACENCY_UNSPECIFIED;
    case 1:
    case "LLDP":
      return AdjacencyProtocol.LLDP;
    case 2:
    case "CDP":
      return AdjacencyProtocol.CDP;
    case 3:
    case "LACP":
      return AdjacencyProtocol.LACP;
    case 4:
    case "STP":
      return AdjacencyProtocol.STP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AdjacencyProtocol.UNRECOGNIZED;
  }
}

export function adjacencyProtocolToJSON(object: AdjacencyProtocol): string {
  switch (object) {
    case AdjacencyProtocol.ADJACENCY_UNSPECIFIED:
      return "ADJACENCY_UNSPECIFIED";
    case AdjacencyProtocol.LLDP:
      return "LLDP";
    case AdjacencyProtocol.CDP:
      return "CDP";
    case AdjacencyProtocol.LACP:
      return "LACP";
    case AdjacencyProtocol.STP:
      return "STP";
    case AdjacencyProtocol.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Airflow enumerates various types of Airflow. */
export enum AirflowType {
  /** AIRFLOW_TYPE_UNSPECIFIED - Unspecified airflow type. */
  AIRFLOW_TYPE_UNSPECIFIED = 0,
  /** AIRFLOW_TYPE_PORT_SIDE_INTAKE - The Airflow type is Port side Intake or front-to-back. */
  AIRFLOW_TYPE_PORT_SIDE_INTAKE = 1,
  /** AIRFLOW_TYPE_PORT_SIDE_EXHAUST - The Airflow type is Port side Exhaust or back-to-front. */
  AIRFLOW_TYPE_PORT_SIDE_EXHAUST = 2,
  UNRECOGNIZED = -1,
}

export function airflowTypeFromJSON(object: any): AirflowType {
  switch (object) {
    case 0:
    case "AIRFLOW_TYPE_UNSPECIFIED":
      return AirflowType.AIRFLOW_TYPE_UNSPECIFIED;
    case 1:
    case "AIRFLOW_TYPE_PORT_SIDE_INTAKE":
      return AirflowType.AIRFLOW_TYPE_PORT_SIDE_INTAKE;
    case 2:
    case "AIRFLOW_TYPE_PORT_SIDE_EXHAUST":
      return AirflowType.AIRFLOW_TYPE_PORT_SIDE_EXHAUST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AirflowType.UNRECOGNIZED;
  }
}

export function airflowTypeToJSON(object: AirflowType): string {
  switch (object) {
    case AirflowType.AIRFLOW_TYPE_UNSPECIFIED:
      return "AIRFLOW_TYPE_UNSPECIFIED";
    case AirflowType.AIRFLOW_TYPE_PORT_SIDE_INTAKE:
      return "AIRFLOW_TYPE_PORT_SIDE_INTAKE";
    case AirflowType.AIRFLOW_TYPE_PORT_SIDE_EXHAUST:
      return "AIRFLOW_TYPE_PORT_SIDE_EXHAUST";
    case AirflowType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** CableType defines an enumeration of cable types. */
export enum CableType {
  /** CABLE_TYPE_UNSPECIFIED - Unspecified cable type. */
  CABLE_TYPE_UNSPECIFIED = 0,
  /** DAC - Directly attached copper cable. */
  DAC = 1,
  /** FIBER - Fiber or optics cable type. */
  FIBER = 2,
  /** AOC - Active Optical Cable. */
  AOC = 3,
  /** SMF - Single-Mode Fiber. */
  SMF = 4,
  /** MMF - Multi-Mode Fiber. */
  MMF = 5,
  /** CAT6AOR7 - Copper cable of type Cat6a or Cat7. */
  CAT6AOR7 = 6,
  UNRECOGNIZED = -1,
}

export function cableTypeFromJSON(object: any): CableType {
  switch (object) {
    case 0:
    case "CABLE_TYPE_UNSPECIFIED":
      return CableType.CABLE_TYPE_UNSPECIFIED;
    case 1:
    case "DAC":
      return CableType.DAC;
    case 2:
    case "FIBER":
      return CableType.FIBER;
    case 3:
    case "AOC":
      return CableType.AOC;
    case 4:
    case "SMF":
      return CableType.SMF;
    case 5:
    case "MMF":
      return CableType.MMF;
    case 6:
    case "CAT6AOR7":
      return CableType.CAT6AOR7;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CableType.UNRECOGNIZED;
  }
}

export function cableTypeToJSON(object: CableType): string {
  switch (object) {
    case CableType.CABLE_TYPE_UNSPECIFIED:
      return "CABLE_TYPE_UNSPECIFIED";
    case CableType.DAC:
      return "DAC";
    case CableType.FIBER:
      return "FIBER";
    case CableType.AOC:
      return "AOC";
    case CableType.SMF:
      return "SMF";
    case CableType.MMF:
      return "MMF";
    case CableType.CAT6AOR7:
      return "CAT6AOR7";
    case CableType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** CandidateType defines an enumeration of candidate types. */
export enum CandidateType {
  /** CANDIDATE_UNSPECIFIED - Unspecified candidate type. */
  CANDIDATE_UNSPECIFIED = 0,
  /** CANDIDATE_CONFIGS - The candidate contains config changes. A config candidate will not contain reboot requests. */
  CANDIDATE_CONFIGS = 1,
  /** CANDIDATE_REBOOTS - The candidate contains reboot requests. A reboot candidate will not contain config changes. */
  CANDIDATE_REBOOTS = 2,
  /**
   * CANDIDATE_DOWNLOADS - The candidate contains software package downloads. A download candidate may also contain either
   * config changes or reboot requests, but not both.
   */
  CANDIDATE_DOWNLOADS = 3,
  UNRECOGNIZED = -1,
}

export function candidateTypeFromJSON(object: any): CandidateType {
  switch (object) {
    case 0:
    case "CANDIDATE_UNSPECIFIED":
      return CandidateType.CANDIDATE_UNSPECIFIED;
    case 1:
    case "CANDIDATE_CONFIGS":
      return CandidateType.CANDIDATE_CONFIGS;
    case 2:
    case "CANDIDATE_REBOOTS":
      return CandidateType.CANDIDATE_REBOOTS;
    case 3:
    case "CANDIDATE_DOWNLOADS":
      return CandidateType.CANDIDATE_DOWNLOADS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CandidateType.UNRECOGNIZED;
  }
}

export function candidateTypeToJSON(object: CandidateType): string {
  switch (object) {
    case CandidateType.CANDIDATE_UNSPECIFIED:
      return "CANDIDATE_UNSPECIFIED";
    case CandidateType.CANDIDATE_CONFIGS:
      return "CANDIDATE_CONFIGS";
    case CandidateType.CANDIDATE_REBOOTS:
      return "CANDIDATE_REBOOTS";
    case CandidateType.CANDIDATE_DOWNLOADS:
      return "CANDIDATE_DOWNLOADS";
    case CandidateType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * ConfigOrigin is used by a management port to indicate if the configuration
 * was provided by the cloud or the device.
 */
export enum ConfigOrigin {
  /** CONFIG_ORIGIN_UNSPECIFIED - Unspecified config origin. */
  CONFIG_ORIGIN_UNSPECIFIED = 0,
  /** CONFIG_ORIGIN_CLOUD - The management port configuration has been provided by the user. */
  CONFIG_ORIGIN_CLOUD = 1,
  /** CONFIG_ORIGIN_DEVICE - The management port configuration was determined by the device. */
  CONFIG_ORIGIN_DEVICE = 2,
  UNRECOGNIZED = -1,
}

export function configOriginFromJSON(object: any): ConfigOrigin {
  switch (object) {
    case 0:
    case "CONFIG_ORIGIN_UNSPECIFIED":
      return ConfigOrigin.CONFIG_ORIGIN_UNSPECIFIED;
    case 1:
    case "CONFIG_ORIGIN_CLOUD":
      return ConfigOrigin.CONFIG_ORIGIN_CLOUD;
    case 2:
    case "CONFIG_ORIGIN_DEVICE":
      return ConfigOrigin.CONFIG_ORIGIN_DEVICE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConfigOrigin.UNRECOGNIZED;
  }
}

export function configOriginToJSON(object: ConfigOrigin): string {
  switch (object) {
    case ConfigOrigin.CONFIG_ORIGIN_UNSPECIFIED:
      return "CONFIG_ORIGIN_UNSPECIFIED";
    case ConfigOrigin.CONFIG_ORIGIN_CLOUD:
      return "CONFIG_ORIGIN_CLOUD";
    case ConfigOrigin.CONFIG_ORIGIN_DEVICE:
      return "CONFIG_ORIGIN_DEVICE";
    case ConfigOrigin.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * ConfigType is used by a management port to indicate if the IP
 * configuration is static or DHCP.
 */
export enum ConfigType {
  /** CONFIG_TYPE_UNSPECIFIED - Unspecified config origin. */
  CONFIG_TYPE_UNSPECIFIED = 0,
  /** CONFIG_TYPE_STATIC - The management port configuration has been provided by the user. */
  CONFIG_TYPE_STATIC = 1,
  /** CONFIG_TYPE_DHCP - The management port configuration has been set through DHCP. */
  CONFIG_TYPE_DHCP = 2,
  UNRECOGNIZED = -1,
}

export function configTypeFromJSON(object: any): ConfigType {
  switch (object) {
    case 0:
    case "CONFIG_TYPE_UNSPECIFIED":
      return ConfigType.CONFIG_TYPE_UNSPECIFIED;
    case 1:
    case "CONFIG_TYPE_STATIC":
      return ConfigType.CONFIG_TYPE_STATIC;
    case 2:
    case "CONFIG_TYPE_DHCP":
      return ConfigType.CONFIG_TYPE_DHCP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConfigType.UNRECOGNIZED;
  }
}

export function configTypeToJSON(object: ConfigType): string {
  switch (object) {
    case ConfigType.CONFIG_TYPE_UNSPECIFIED:
      return "CONFIG_TYPE_UNSPECIFIED";
    case ConfigType.CONFIG_TYPE_STATIC:
      return "CONFIG_TYPE_STATIC";
    case ConfigType.CONFIG_TYPE_DHCP:
      return "CONFIG_TYPE_DHCP";
    case ConfigType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * ConnectedState is used by a management port to indicate if the port
 * is successfully connected to the Hyperfabric service. It is
 * distinct from the AdminState.
 */
export enum ConnectedState {
  /** CONNECTED_STATE_UNSPECIFIED - Unspecified connected state. */
  CONNECTED_STATE_UNSPECIFIED = 0,
  /** CONNECTED_STATE_NOT_CONNECTED - The management port has never connected. */
  CONNECTED_STATE_NOT_CONNECTED = 1,
  /** CONNECTED_STATE_CONNECTED - The management port is connected or has connected at some point. */
  CONNECTED_STATE_CONNECTED = 2,
  UNRECOGNIZED = -1,
}

export function connectedStateFromJSON(object: any): ConnectedState {
  switch (object) {
    case 0:
    case "CONNECTED_STATE_UNSPECIFIED":
      return ConnectedState.CONNECTED_STATE_UNSPECIFIED;
    case 1:
    case "CONNECTED_STATE_NOT_CONNECTED":
      return ConnectedState.CONNECTED_STATE_NOT_CONNECTED;
    case 2:
    case "CONNECTED_STATE_CONNECTED":
      return ConnectedState.CONNECTED_STATE_CONNECTED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConnectedState.UNRECOGNIZED;
  }
}

export function connectedStateToJSON(object: ConnectedState): string {
  switch (object) {
    case ConnectedState.CONNECTED_STATE_UNSPECIFIED:
      return "CONNECTED_STATE_UNSPECIFIED";
    case ConnectedState.CONNECTED_STATE_NOT_CONNECTED:
      return "CONNECTED_STATE_NOT_CONNECTED";
    case ConnectedState.CONNECTED_STATE_CONNECTED:
      return "CONNECTED_STATE_CONNECTED";
    case ConnectedState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** EstimateStatus enumerates various status of estimate creation. */
export enum EstimateStatus {
  /** ESTIMATE_STATUS_UNSPECIFIED - Unspecified Estimate Status. */
  ESTIMATE_STATUS_UNSPECIFIED = 0,
  /** ESTIMATE_STATUS_COMPLETE - Estimate creation complete Status. */
  ESTIMATE_STATUS_COMPLETE = 2,
  /** ESTIMATE_STATUS_INCOMPLETE - Estimate creation Incomplete Status. */
  ESTIMATE_STATUS_INCOMPLETE = 3,
  UNRECOGNIZED = -1,
}

export function estimateStatusFromJSON(object: any): EstimateStatus {
  switch (object) {
    case 0:
    case "ESTIMATE_STATUS_UNSPECIFIED":
      return EstimateStatus.ESTIMATE_STATUS_UNSPECIFIED;
    case 2:
    case "ESTIMATE_STATUS_COMPLETE":
      return EstimateStatus.ESTIMATE_STATUS_COMPLETE;
    case 3:
    case "ESTIMATE_STATUS_INCOMPLETE":
      return EstimateStatus.ESTIMATE_STATUS_INCOMPLETE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EstimateStatus.UNRECOGNIZED;
  }
}

export function estimateStatusToJSON(object: EstimateStatus): string {
  switch (object) {
    case EstimateStatus.ESTIMATE_STATUS_UNSPECIFIED:
      return "ESTIMATE_STATUS_UNSPECIFIED";
    case EstimateStatus.ESTIMATE_STATUS_COMPLETE:
      return "ESTIMATE_STATUS_COMPLETE";
    case EstimateStatus.ESTIMATE_STATUS_INCOMPLETE:
      return "ESTIMATE_STATUS_INCOMPLETE";
    case EstimateStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** FabricTopology defines an enumeration of types of fabric topologies. */
export enum FabricTopology {
  /** TOPOLOGY_UNSPECIFIED - Unspecified fabric topology. */
  TOPOLOGY_UNSPECIFIED = 0,
  /** MESH - A mesh of two or more nodes. */
  MESH = 1,
  /** SPINE_LEAF - A spine-leaf fabric topology. */
  SPINE_LEAF = 2,
  UNRECOGNIZED = -1,
}

export function fabricTopologyFromJSON(object: any): FabricTopology {
  switch (object) {
    case 0:
    case "TOPOLOGY_UNSPECIFIED":
      return FabricTopology.TOPOLOGY_UNSPECIFIED;
    case 1:
    case "MESH":
      return FabricTopology.MESH;
    case 2:
    case "SPINE_LEAF":
      return FabricTopology.SPINE_LEAF;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FabricTopology.UNRECOGNIZED;
  }
}

export function fabricTopologyToJSON(object: FabricTopology): string {
  switch (object) {
    case FabricTopology.TOPOLOGY_UNSPECIFIED:
      return "TOPOLOGY_UNSPECIFIED";
    case FabricTopology.MESH:
      return "MESH";
    case FabricTopology.SPINE_LEAF:
      return "SPINE_LEAF";
    case FabricTopology.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** InterfaceType defines an enumeration of network interface types. */
export enum InterfaceType {
  /** INTERFACE_UNSPECIFIED - Unspecified interface type. */
  INTERFACE_UNSPECIFIED = 0,
  /** NETWORK_PORT_INTERFACE - Physical network port. */
  NETWORK_PORT_INTERFACE = 1,
  /** LOOPBACK_INTERFACE - Loopback interface. */
  LOOPBACK_INTERFACE = 2,
  /** VLAN_INTERFACE - VLAN interface or SVI. */
  VLAN_INTERFACE = 3,
  /** PORT_CHANNEL_INTERFACE - Port channel interface. */
  PORT_CHANNEL_INTERFACE = 4,
  /** SUB_PORT_INTERFACE - Sub-port of a sub-interface interface. */
  SUB_PORT_INTERFACE = 5,
  UNRECOGNIZED = -1,
}

export function interfaceTypeFromJSON(object: any): InterfaceType {
  switch (object) {
    case 0:
    case "INTERFACE_UNSPECIFIED":
      return InterfaceType.INTERFACE_UNSPECIFIED;
    case 1:
    case "NETWORK_PORT_INTERFACE":
      return InterfaceType.NETWORK_PORT_INTERFACE;
    case 2:
    case "LOOPBACK_INTERFACE":
      return InterfaceType.LOOPBACK_INTERFACE;
    case 3:
    case "VLAN_INTERFACE":
      return InterfaceType.VLAN_INTERFACE;
    case 4:
    case "PORT_CHANNEL_INTERFACE":
      return InterfaceType.PORT_CHANNEL_INTERFACE;
    case 5:
    case "SUB_PORT_INTERFACE":
      return InterfaceType.SUB_PORT_INTERFACE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return InterfaceType.UNRECOGNIZED;
  }
}

export function interfaceTypeToJSON(object: InterfaceType): string {
  switch (object) {
    case InterfaceType.INTERFACE_UNSPECIFIED:
      return "INTERFACE_UNSPECIFIED";
    case InterfaceType.NETWORK_PORT_INTERFACE:
      return "NETWORK_PORT_INTERFACE";
    case InterfaceType.LOOPBACK_INTERFACE:
      return "LOOPBACK_INTERFACE";
    case InterfaceType.VLAN_INTERFACE:
      return "VLAN_INTERFACE";
    case InterfaceType.PORT_CHANNEL_INTERFACE:
      return "PORT_CHANNEL_INTERFACE";
    case InterfaceType.SUB_PORT_INTERFACE:
      return "SUB_PORT_INTERFACE";
    case InterfaceType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** IpAddrType defines an enumeration of internet address types. */
export enum IPAddrType {
  /** IP_ADDR_UNSPECIFIED - Unspecified IP address type. */
  IP_ADDR_UNSPECIFIED = 0,
  /** IPV4 - IPv4 address type. */
  IPV4 = 1,
  /** IPV6 - IPv6 address type. */
  IPV6 = 2,
  UNRECOGNIZED = -1,
}

export function iPAddrTypeFromJSON(object: any): IPAddrType {
  switch (object) {
    case 0:
    case "IP_ADDR_UNSPECIFIED":
      return IPAddrType.IP_ADDR_UNSPECIFIED;
    case 1:
    case "IPV4":
      return IPAddrType.IPV4;
    case 2:
    case "IPV6":
      return IPAddrType.IPV6;
    case -1:
    case "UNRECOGNIZED":
    default:
      return IPAddrType.UNRECOGNIZED;
  }
}

export function iPAddrTypeToJSON(object: IPAddrType): string {
  switch (object) {
    case IPAddrType.IP_ADDR_UNSPECIFIED:
      return "IP_ADDR_UNSPECIFIED";
    case IPAddrType.IPV4:
      return "IPV4";
    case IPAddrType.IPV6:
      return "IPV6";
    case IPAddrType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** LacpMode defines an enumeration of LACP modes. */
export enum LacpMode {
  /** LACP_UNSPECIFIED - Unspecified LACP mode. */
  LACP_UNSPECIFIED = 0,
  /** LACP_ACTIVE - LACP active mode. */
  LACP_ACTIVE = 1,
  /** LACP_PASSIVE - LACP passive mode. */
  LACP_PASSIVE = 2,
  /** LACP_OFF - LACP is disabled. */
  LACP_OFF = 3,
  UNRECOGNIZED = -1,
}

export function lacpModeFromJSON(object: any): LacpMode {
  switch (object) {
    case 0:
    case "LACP_UNSPECIFIED":
      return LacpMode.LACP_UNSPECIFIED;
    case 1:
    case "LACP_ACTIVE":
      return LacpMode.LACP_ACTIVE;
    case 2:
    case "LACP_PASSIVE":
      return LacpMode.LACP_PASSIVE;
    case 3:
    case "LACP_OFF":
      return LacpMode.LACP_OFF;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LacpMode.UNRECOGNIZED;
  }
}

export function lacpModeToJSON(object: LacpMode): string {
  switch (object) {
    case LacpMode.LACP_UNSPECIFIED:
      return "LACP_UNSPECIFIED";
    case LacpMode.LACP_ACTIVE:
      return "LACP_ACTIVE";
    case LacpMode.LACP_PASSIVE:
      return "LACP_PASSIVE";
    case LacpMode.LACP_OFF:
      return "LACP_OFF";
    case LacpMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** LicenseType enumerates various types or levels of license. */
export enum LicenseType {
  /** LICENSE_TYPE_UNSPECIFIED - Unspecified license type. */
  LICENSE_TYPE_UNSPECIFIED = 0,
  /** LICENSE_TYPE_ESSENTIALS - The license type or level is Essentials. */
  LICENSE_TYPE_ESSENTIALS = 1,
  /** LICENSE_TYPE_ADVANTAGE - The license type or level is Advantage. */
  LICENSE_TYPE_ADVANTAGE = 2,
  /** LICENSE_TYPE_PREMIER - The license type or level is Premier. */
  LICENSE_TYPE_PREMIER = 3,
  UNRECOGNIZED = -1,
}

export function licenseTypeFromJSON(object: any): LicenseType {
  switch (object) {
    case 0:
    case "LICENSE_TYPE_UNSPECIFIED":
      return LicenseType.LICENSE_TYPE_UNSPECIFIED;
    case 1:
    case "LICENSE_TYPE_ESSENTIALS":
      return LicenseType.LICENSE_TYPE_ESSENTIALS;
    case 2:
    case "LICENSE_TYPE_ADVANTAGE":
      return LicenseType.LICENSE_TYPE_ADVANTAGE;
    case 3:
    case "LICENSE_TYPE_PREMIER":
      return LicenseType.LICENSE_TYPE_PREMIER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LicenseType.UNRECOGNIZED;
  }
}

export function licenseTypeToJSON(object: LicenseType): string {
  switch (object) {
    case LicenseType.LICENSE_TYPE_UNSPECIFIED:
      return "LICENSE_TYPE_UNSPECIFIED";
    case LicenseType.LICENSE_TYPE_ESSENTIALS:
      return "LICENSE_TYPE_ESSENTIALS";
    case LicenseType.LICENSE_TYPE_ADVANTAGE:
      return "LICENSE_TYPE_ADVANTAGE";
    case LicenseType.LICENSE_TYPE_PREMIER:
      return "LICENSE_TYPE_PREMIER";
    case LicenseType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** MatchType defines an enumeration of conditional matching operators. */
export enum MatchType {
  /** MATCH_TYPE_UNSPECIFIED - Unspecified match operator. */
  MATCH_TYPE_UNSPECIFIED = 0,
  /** MATCH_EQ - Exact match or equality operator. */
  MATCH_EQ = 1,
  /** MATCH_GE - Greater than or equal operator. */
  MATCH_GE = 2,
  /** MATCH_LE - Less than or equal operator. */
  MATCH_LE = 3,
  /** MATCH_ANY - Match at least one of the items in a list. */
  MATCH_ANY = 4,
  UNRECOGNIZED = -1,
}

export function matchTypeFromJSON(object: any): MatchType {
  switch (object) {
    case 0:
    case "MATCH_TYPE_UNSPECIFIED":
      return MatchType.MATCH_TYPE_UNSPECIFIED;
    case 1:
    case "MATCH_EQ":
      return MatchType.MATCH_EQ;
    case 2:
    case "MATCH_GE":
      return MatchType.MATCH_GE;
    case 3:
    case "MATCH_LE":
      return MatchType.MATCH_LE;
    case 4:
    case "MATCH_ANY":
      return MatchType.MATCH_ANY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MatchType.UNRECOGNIZED;
  }
}

export function matchTypeToJSON(object: MatchType): string {
  switch (object) {
    case MatchType.MATCH_TYPE_UNSPECIFIED:
      return "MATCH_TYPE_UNSPECIFIED";
    case MatchType.MATCH_EQ:
      return "MATCH_EQ";
    case MatchType.MATCH_GE:
      return "MATCH_GE";
    case MatchType.MATCH_LE:
      return "MATCH_LE";
    case MatchType.MATCH_ANY:
      return "MATCH_ANY";
    case MatchType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** NodeRole defines an enumeration of roles that can be associated with a node. */
export enum NodeRole {
  /** NODE_ROLE_UNSPECIFIED - Unspecified node role. */
  NODE_ROLE_UNSPECIFIED = 0,
  /** LEAF - The node is a leaf node or mesh node. */
  LEAF = 1,
  /** SPINE - The node is a spine node. */
  SPINE = 2,
  /** SERVER - The node is a managed ucs server. */
  SERVER = 3,
  UNRECOGNIZED = -1,
}

export function nodeRoleFromJSON(object: any): NodeRole {
  switch (object) {
    case 0:
    case "NODE_ROLE_UNSPECIFIED":
      return NodeRole.NODE_ROLE_UNSPECIFIED;
    case 1:
    case "LEAF":
      return NodeRole.LEAF;
    case 2:
    case "SPINE":
      return NodeRole.SPINE;
    case 3:
    case "SERVER":
      return NodeRole.SERVER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NodeRole.UNRECOGNIZED;
  }
}

export function nodeRoleToJSON(object: NodeRole): string {
  switch (object) {
    case NodeRole.NODE_ROLE_UNSPECIFIED:
      return "NODE_ROLE_UNSPECIFIED";
    case NodeRole.LEAF:
      return "LEAF";
    case NodeRole.SPINE:
      return "SPINE";
    case NodeRole.SERVER:
      return "SERVER";
    case NodeRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** NodeType defines an enumeration of node types. */
export enum NodeType {
  /** NODE_TYPE_UNSPECIFIED - Unspecified node type. */
  NODE_TYPE_UNSPECIFIED = 0,
  /** NODE_BLUEPRINT - The node is a blueprint or design mode node. */
  NODE_BLUEPRINT = 1,
  /** NODE_SWITCH - The node is a network switch. */
  NODE_SWITCH = 2,
  UNRECOGNIZED = -1,
}

export function nodeTypeFromJSON(object: any): NodeType {
  switch (object) {
    case 0:
    case "NODE_TYPE_UNSPECIFIED":
      return NodeType.NODE_TYPE_UNSPECIFIED;
    case 1:
    case "NODE_BLUEPRINT":
      return NodeType.NODE_BLUEPRINT;
    case 2:
    case "NODE_SWITCH":
      return NodeType.NODE_SWITCH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NodeType.UNRECOGNIZED;
  }
}

export function nodeTypeToJSON(object: NodeType): string {
  switch (object) {
    case NodeType.NODE_TYPE_UNSPECIFIED:
      return "NODE_TYPE_UNSPECIFIED";
    case NodeType.NODE_BLUEPRINT:
      return "NODE_BLUEPRINT";
    case NodeType.NODE_SWITCH:
      return "NODE_SWITCH";
    case NodeType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** OsType defines an enumeration of various device operating system types. */
export enum OsType {
  /** OS_UNSPECIFIED - Unspecified OS type. */
  OS_UNSPECIFIED = 0,
  /** HYPER_FABRIC - Cisco Nexus Hyperfabric OS. */
  HYPER_FABRIC = 1,
  /** NEXUS - Cisco Nexus OS. */
  NEXUS = 2,
  /** IOS_XE - Cisco IOS-XE OS. */
  IOS_XE = 3,
  /** IOS_XR - Cisco IOS-XR OS. */
  IOS_XR = 4,
  /** LINUX - Linux OS (host OS). */
  LINUX = 5,
  /** WINDOWS - Windows OS (host OS). */
  WINDOWS = 6,
  UNRECOGNIZED = -1,
}

export function osTypeFromJSON(object: any): OsType {
  switch (object) {
    case 0:
    case "OS_UNSPECIFIED":
      return OsType.OS_UNSPECIFIED;
    case 1:
    case "HYPER_FABRIC":
      return OsType.HYPER_FABRIC;
    case 2:
    case "NEXUS":
      return OsType.NEXUS;
    case 3:
    case "IOS_XE":
      return OsType.IOS_XE;
    case 4:
    case "IOS_XR":
      return OsType.IOS_XR;
    case 5:
    case "LINUX":
      return OsType.LINUX;
    case 6:
    case "WINDOWS":
      return OsType.WINDOWS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OsType.UNRECOGNIZED;
  }
}

export function osTypeToJSON(object: OsType): string {
  switch (object) {
    case OsType.OS_UNSPECIFIED:
      return "OS_UNSPECIFIED";
    case OsType.HYPER_FABRIC:
      return "HYPER_FABRIC";
    case OsType.NEXUS:
      return "NEXUS";
    case OsType.IOS_XE:
      return "IOS_XE";
    case OsType.IOS_XR:
      return "IOS_XR";
    case OsType.LINUX:
      return "LINUX";
    case OsType.WINDOWS:
      return "WINDOWS";
    case OsType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** PluggableType defines an enumeration of pluggable and optic options. */
export enum PluggableType {
  /** PLUGGABLE_TYPE_UNSPECIFIED - Unspecified pluggable type. */
  PLUGGABLE_TYPE_UNSPECIFIED = 0,
  /** QSFP - QSFP pluggable type. */
  QSFP = 1,
  UNRECOGNIZED = -1,
}

export function pluggableTypeFromJSON(object: any): PluggableType {
  switch (object) {
    case 0:
    case "PLUGGABLE_TYPE_UNSPECIFIED":
      return PluggableType.PLUGGABLE_TYPE_UNSPECIFIED;
    case 1:
    case "QSFP":
      return PluggableType.QSFP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PluggableType.UNRECOGNIZED;
  }
}

export function pluggableTypeToJSON(object: PluggableType): string {
  switch (object) {
    case PluggableType.PLUGGABLE_TYPE_UNSPECIFIED:
      return "PLUGGABLE_TYPE_UNSPECIFIED";
    case PluggableType.QSFP:
      return "QSFP";
    case PluggableType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** PortRole defines an enumeration of roles of ports or port channels. */
export enum PortRole {
  /** PORT_ROLE_UNSPECIFIED - Unspecified port role. */
  PORT_ROLE_UNSPECIFIED = 0,
  /** UNUSED_PORT - The port is not used. */
  UNUSED_PORT = 1,
  /** FABRIC_PORT - The port is a fabric port. */
  FABRIC_PORT = 2,
  /** HOST_PORT - The port is a host port. */
  HOST_PORT = 3,
  /** ROUTED_PORT - The port is a routed port or a port with IP addresses. */
  ROUTED_PORT = 4,
  /** LAG_PORT - The port is attached to a port channel (LAG/MLAG). */
  LAG_PORT = 5,
  UNRECOGNIZED = -1,
}

export function portRoleFromJSON(object: any): PortRole {
  switch (object) {
    case 0:
    case "PORT_ROLE_UNSPECIFIED":
      return PortRole.PORT_ROLE_UNSPECIFIED;
    case 1:
    case "UNUSED_PORT":
      return PortRole.UNUSED_PORT;
    case 2:
    case "FABRIC_PORT":
      return PortRole.FABRIC_PORT;
    case 3:
    case "HOST_PORT":
      return PortRole.HOST_PORT;
    case 4:
    case "ROUTED_PORT":
      return PortRole.ROUTED_PORT;
    case 5:
    case "LAG_PORT":
      return PortRole.LAG_PORT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PortRole.UNRECOGNIZED;
  }
}

export function portRoleToJSON(object: PortRole): string {
  switch (object) {
    case PortRole.PORT_ROLE_UNSPECIFIED:
      return "PORT_ROLE_UNSPECIFIED";
    case PortRole.UNUSED_PORT:
      return "UNUSED_PORT";
    case PortRole.FABRIC_PORT:
      return "FABRIC_PORT";
    case PortRole.HOST_PORT:
      return "HOST_PORT";
    case PortRole.ROUTED_PORT:
      return "ROUTED_PORT";
    case PortRole.LAG_PORT:
      return "LAG_PORT";
    case PortRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** RefPointJobState defines an enumeration of reference point job states. */
export enum RefPointJobState {
  /** JOB_STATE_UNSPECIFIED - Unspecified reference point job state. */
  JOB_STATE_UNSPECIFIED = 0,
  /** JOB_STATE_NOT_REQUESTED - The reference point job is pending. */
  JOB_STATE_NOT_REQUESTED = 1,
  /** JOB_STATE_QUEUED - The reference point job is running. */
  JOB_STATE_QUEUED = 2,
  /** JOB_STATE_PROCESSING - The reference point job has completed. */
  JOB_STATE_PROCESSING = 3,
  /** JOB_STATE_READY - The reference point job has failed. */
  JOB_STATE_READY = 4,
  /** JOB_STATE_FAILED - The reference point job has been canceled. */
  JOB_STATE_FAILED = 5,
  UNRECOGNIZED = -1,
}

export function refPointJobStateFromJSON(object: any): RefPointJobState {
  switch (object) {
    case 0:
    case "JOB_STATE_UNSPECIFIED":
      return RefPointJobState.JOB_STATE_UNSPECIFIED;
    case 1:
    case "JOB_STATE_NOT_REQUESTED":
      return RefPointJobState.JOB_STATE_NOT_REQUESTED;
    case 2:
    case "JOB_STATE_QUEUED":
      return RefPointJobState.JOB_STATE_QUEUED;
    case 3:
    case "JOB_STATE_PROCESSING":
      return RefPointJobState.JOB_STATE_PROCESSING;
    case 4:
    case "JOB_STATE_READY":
      return RefPointJobState.JOB_STATE_READY;
    case 5:
    case "JOB_STATE_FAILED":
      return RefPointJobState.JOB_STATE_FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RefPointJobState.UNRECOGNIZED;
  }
}

export function refPointJobStateToJSON(object: RefPointJobState): string {
  switch (object) {
    case RefPointJobState.JOB_STATE_UNSPECIFIED:
      return "JOB_STATE_UNSPECIFIED";
    case RefPointJobState.JOB_STATE_NOT_REQUESTED:
      return "JOB_STATE_NOT_REQUESTED";
    case RefPointJobState.JOB_STATE_QUEUED:
      return "JOB_STATE_QUEUED";
    case RefPointJobState.JOB_STATE_PROCESSING:
      return "JOB_STATE_PROCESSING";
    case RefPointJobState.JOB_STATE_READY:
      return "JOB_STATE_READY";
    case RefPointJobState.JOB_STATE_FAILED:
      return "JOB_STATE_FAILED";
    case RefPointJobState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** RouteOriginType defines an enumeration of BGP route origins. */
export enum RouteOriginType {
  /** ROUTE_ORIGIN_UNSPECIFIED - Unspecified route origin. */
  ROUTE_ORIGIN_UNSPECIFIED = 0,
  /** ROUTE_ORIGIN_EBGP - EBGP route origin. */
  ROUTE_ORIGIN_EBGP = 1,
  /** ROUTE_ORIGIN_IBGP - IBGP route origin. */
  ROUTE_ORIGIN_IBGP = 2,
  UNRECOGNIZED = -1,
}

export function routeOriginTypeFromJSON(object: any): RouteOriginType {
  switch (object) {
    case 0:
    case "ROUTE_ORIGIN_UNSPECIFIED":
      return RouteOriginType.ROUTE_ORIGIN_UNSPECIFIED;
    case 1:
    case "ROUTE_ORIGIN_EBGP":
      return RouteOriginType.ROUTE_ORIGIN_EBGP;
    case 2:
    case "ROUTE_ORIGIN_IBGP":
      return RouteOriginType.ROUTE_ORIGIN_IBGP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RouteOriginType.UNRECOGNIZED;
  }
}

export function routeOriginTypeToJSON(object: RouteOriginType): string {
  switch (object) {
    case RouteOriginType.ROUTE_ORIGIN_UNSPECIFIED:
      return "ROUTE_ORIGIN_UNSPECIFIED";
    case RouteOriginType.ROUTE_ORIGIN_EBGP:
      return "ROUTE_ORIGIN_EBGP";
    case RouteOriginType.ROUTE_ORIGIN_IBGP:
      return "ROUTE_ORIGIN_IBGP";
    case RouteOriginType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** RouteType defines an enumeration of BGP EVPN route types from 1 to 5. */
export enum RouteType {
  /** ROUTE_TYPE_UNSPECIFIED - Unspecified route type. */
  ROUTE_TYPE_UNSPECIFIED = 0,
  /** ROUTE_EAD - Ethernet auto-discovery route type. */
  ROUTE_EAD = 1,
  /** ROUTE_MAC_IP - MAC/IP route type. */
  ROUTE_MAC_IP = 2,
  /** ROUTE_MULTICAST - Multicast route type. */
  ROUTE_MULTICAST = 3,
  /** ROUTE_ES - Ethernet segment route type */
  ROUTE_ES = 4,
  /** ROUTE_PREFIX - Prefix route type. */
  ROUTE_PREFIX = 5,
  UNRECOGNIZED = -1,
}

export function routeTypeFromJSON(object: any): RouteType {
  switch (object) {
    case 0:
    case "ROUTE_TYPE_UNSPECIFIED":
      return RouteType.ROUTE_TYPE_UNSPECIFIED;
    case 1:
    case "ROUTE_EAD":
      return RouteType.ROUTE_EAD;
    case 2:
    case "ROUTE_MAC_IP":
      return RouteType.ROUTE_MAC_IP;
    case 3:
    case "ROUTE_MULTICAST":
      return RouteType.ROUTE_MULTICAST;
    case 4:
    case "ROUTE_ES":
      return RouteType.ROUTE_ES;
    case 5:
    case "ROUTE_PREFIX":
      return RouteType.ROUTE_PREFIX;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RouteType.UNRECOGNIZED;
  }
}

export function routeTypeToJSON(object: RouteType): string {
  switch (object) {
    case RouteType.ROUTE_TYPE_UNSPECIFIED:
      return "ROUTE_TYPE_UNSPECIFIED";
    case RouteType.ROUTE_EAD:
      return "ROUTE_EAD";
    case RouteType.ROUTE_MAC_IP:
      return "ROUTE_MAC_IP";
    case RouteType.ROUTE_MULTICAST:
      return "ROUTE_MULTICAST";
    case RouteType.ROUTE_ES:
      return "ROUTE_ES";
    case RouteType.ROUTE_PREFIX:
      return "ROUTE_PREFIX";
    case RouteType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** BreakoutMode encapsulates properties of a port breakout mode. */
export interface BreakoutMode {
  /** Mode defines breakout mode. E.g. 2x200G(2). */
  mode: string;
  /** Short description of the breakout mode. E.g. Two 200G ports using 2 lanes each. */
  description: string;
  /** IsDefault indicates if the mode is the default mode of the port. */
  isDefault: boolean;
  /** FecModes defines supported FEC modes. */
  fecModes: string[];
  /** Ports defines the names of base network ports. */
  ports: string[];
  /** NumPorts defines the number of breakout ports. */
  numPorts: number;
  /** Speed defines the speed of each breakout port. */
  speed: string;
}

function createBaseBreakoutMode(): BreakoutMode {
  return { mode: "", description: "", isDefault: false, fecModes: [], ports: [], numPorts: 0, speed: "" };
}

export const BreakoutMode = {
  encode(message: BreakoutMode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mode !== "") {
      writer.uint32(10).string(message.mode);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.isDefault !== false) {
      writer.uint32(24).bool(message.isDefault);
    }
    for (const v of message.fecModes) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.ports) {
      writer.uint32(66).string(v!);
    }
    if (message.numPorts !== 0) {
      writer.uint32(72).uint32(message.numPorts);
    }
    if (message.speed !== "") {
      writer.uint32(82).string(message.speed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BreakoutMode {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBreakoutMode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isDefault = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fecModes.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.ports.push(reader.string());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.numPorts = reader.uint32();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.speed = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BreakoutMode {
    return {
      mode: isSet(object.mode) ? globalThis.String(object.mode) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      isDefault: isSet(object.isDefault) ? globalThis.Boolean(object.isDefault) : false,
      fecModes: globalThis.Array.isArray(object?.fecModes) ? object.fecModes.map((e: any) => globalThis.String(e)) : [],
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => globalThis.String(e)) : [],
      numPorts: isSet(object.numPorts) ? globalThis.Number(object.numPorts) : 0,
      speed: isSet(object.speed) ? globalThis.String(object.speed) : "",
    };
  },

  toJSON(message: BreakoutMode): unknown {
    const obj: any = {};
    if (message.mode !== "") {
      obj.mode = message.mode;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.isDefault !== false) {
      obj.isDefault = message.isDefault;
    }
    if (message.fecModes?.length) {
      obj.fecModes = message.fecModes;
    }
    if (message.ports?.length) {
      obj.ports = message.ports;
    }
    if (message.numPorts !== 0) {
      obj.numPorts = Math.round(message.numPorts);
    }
    if (message.speed !== "") {
      obj.speed = message.speed;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BreakoutMode>, I>>(base?: I): BreakoutMode {
    return BreakoutMode.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BreakoutMode>, I>>(object: I): BreakoutMode {
    const message = createBaseBreakoutMode();
    message.mode = object.mode ?? "";
    message.description = object.description ?? "";
    message.isDefault = object.isDefault ?? false;
    message.fecModes = object.fecModes?.map((e) => e) || [];
    message.ports = object.ports?.map((e) => e) || [];
    message.numPorts = object.numPorts ?? 0;
    message.speed = object.speed ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
