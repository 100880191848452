// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: configd/image.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";
import { FabricImageUpdate, ImagePackageInfo, NodeImageUpdate } from "../models/image";

export const protobufPackage = "configd";

export enum FabricImageUpdateStage {
  FABRIC_IMAGE_STAGE_UNSPECIFIED = 0,
  CONFIGURED = 1,
  STAGED = 2,
  RUNNING = 3,
  CANDIDATE = 4,
  EXPIRED = 5,
  UNRECOGNIZED = -1,
}

export function fabricImageUpdateStageFromJSON(object: any): FabricImageUpdateStage {
  switch (object) {
    case 0:
    case "FABRIC_IMAGE_STAGE_UNSPECIFIED":
      return FabricImageUpdateStage.FABRIC_IMAGE_STAGE_UNSPECIFIED;
    case 1:
    case "CONFIGURED":
      return FabricImageUpdateStage.CONFIGURED;
    case 2:
    case "STAGED":
      return FabricImageUpdateStage.STAGED;
    case 3:
    case "RUNNING":
      return FabricImageUpdateStage.RUNNING;
    case 4:
    case "CANDIDATE":
      return FabricImageUpdateStage.CANDIDATE;
    case 5:
    case "EXPIRED":
      return FabricImageUpdateStage.EXPIRED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FabricImageUpdateStage.UNRECOGNIZED;
  }
}

export function fabricImageUpdateStageToJSON(object: FabricImageUpdateStage): string {
  switch (object) {
    case FabricImageUpdateStage.FABRIC_IMAGE_STAGE_UNSPECIFIED:
      return "FABRIC_IMAGE_STAGE_UNSPECIFIED";
    case FabricImageUpdateStage.CONFIGURED:
      return "CONFIGURED";
    case FabricImageUpdateStage.STAGED:
      return "STAGED";
    case FabricImageUpdateStage.RUNNING:
      return "RUNNING";
    case FabricImageUpdateStage.CANDIDATE:
      return "CANDIDATE";
    case FabricImageUpdateStage.EXPIRED:
      return "EXPIRED";
    case FabricImageUpdateStage.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Add an Image update configuration to a fabric. */
export interface AddFabricImageUpdateRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The update tag of the image packages recorded in the global.image_package table */
  tag: string;
  /** The optional planed deploy time (to be implemented) */
  deployAt:
    | Date
    | undefined;
  /** The optional fabric specific image expire time (to be implemented) */
  expireAt:
    | Date
    | undefined;
  /** amd64(default) or arm64 */
  arch: string;
}

/** Get the image update configuration from a fabric */
export interface GetFabricImageUpdatesRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
}

/** Get the committed image update configuration from a fabric */
export interface GetCommittedFabricImageUpdatesRequest {
  /** The org uuid */
  orgId: string;
  /** The fabric uuid */
  fabricIds: string[];
  /** The lookback window in days */
  lookbackDays: number;
}

/** GetFabricImageUpdateResponse encapsulates the fabric image update objects. */
export interface GetFabricImageUpdatesResponse {
  fabricImages: FabricImageUpdate[];
}

/** Get the node image update configuration from a fabric */
export interface GetNodeImageUpdatesRequest {
  /** The candidate name. */
  candidate: string;
  /** The fabric identifiers. */
  fabricId: string;
  /** A set of node identifiers. */
  nodeIds: string[];
  /** A set of The image update tags. */
  tags: string[];
}

/** GetNodeImageUpdatesResponse gets and returns the node image update configuration in a fabric. */
export interface GetNodeImageUpdatesResponse {
  nodeImages: NodeImageUpdate[];
}

/** UpdateNodeImageUpdatesRequest encapsulate the node image upgrade configurations */
export interface UpdateNodeImageUpdatesRequest {
  /** The fabric identifiers. */
  fabricId: string;
  nodeImages: NodeImageUpdate[];
}

/** UpdateNodeImageUpdateNodesResponse provides the node image update configurations */
export interface UpdateNodeImageUpdatesResponse {
  nodeImages: NodeImageUpdate[];
}

/** AddNodeImagePackage adds a NodeImagePackage entry for testing */
export interface AddNodeImagePackagesRequest {
  /** The fabric id or name. */
  fabricId: string;
  packages: NodeImagePackageInfo[];
}

export interface NodeImagePackageInfo {
  /** The node external id */
  nodeId: string;
  /** The device mac */
  deviceId: string;
  /** The partition that the package reside */
  partition: string;
  /** The target image tag */
  tag: string;
  /** The package name */
  name: string;
  /** partition is active */
  active: boolean;
  /** partition is intended to be active after next reboot */
  nextActive: boolean;
  /** The SHA512/224 hash of the package file */
  sha512: string;
  /** modification time */
  updatedAt: Date | undefined;
}

/** AddNodeImagePackagesResponse specifies the lists of nodes that have the ImagePackages updated */
export interface AddNodeImagePackagesResponse {
  /** The fabric id or name. */
  fabricId: string;
  /** list of the external node_ids */
  nodeIds: string[];
}

/** Get the NodeImagePackage data */
export interface GetNodeImagePackagesRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** list of the node Ids or names */
  nodeId: string;
}

/** GetNodeImagePackageResponse contains a list of NodeImagePackage data */
export interface GetNodeImagePackagesResponse {
  /** The fabric id or name. */
  fabricId: string;
  packages: NodeImagePackageInfo[];
}

/** Delete the device image meta data */
export interface DeleteNodeImagePackagesRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** list of the node Ids or names */
  nodeId: string;
}

/** AddImagePackagesRequest requests to add a set of device image packages to the global.image_package table */
export interface AddImagePackagesRequest {
  packages: ImagePackageInfo[];
}

export interface ImagePackagesResponse {
  /** The list of added packages */
  addedPackages: ImagePackageInfo[];
  /** The list of updated packages */
  updatedPackages: ImagePackageInfo[];
}

/** GetImagePackagesRequest retrieves the image packages that match the given parameters */
export interface GetImagePackagesRequest {
  /** The release tag */
  tag: string;
  /** The arch type amd64(default) or arm64 */
  arch: string;
  /** The image package name */
  name: string;
  /** The image release type */
  releaseType: string;
  /** The image platform */
  platform: string;
  /** The search lookback time in days */
  lookbackDays: number;
}

/** GetImagePackagesResponse returns the requested the image package information */
export interface GetImagePackagesResponse {
  packages: ImagePackageInfo[];
}

/** UpdateImagePackagesRequest requests to update the set of the image packages under a release tag */
export interface UpdateImagePackagesRequest {
  tag: string;
  packages: ImagePackageInfo[];
}

/** DeleteImagePackagesRequest requests to remove the image packages associated with a tag */
export interface DeleteImagePackagesRequest {
  /** The package release tag */
  tag: string;
}

/** AddImagePackagesServiceRequest requests to add a set of device image packages to the global.image_package table */
export interface AddImagePackagesServiceRequest {
  packages: ImagePackageInfo[];
}

/** GetImagePackagesServiceRequest retrieves the image packages that match the given parameters */
export interface GetImagePackagesServiceRequest {
  /** The release tag */
  tag: string;
  /** The arch type amd64(default) or arm64 */
  arch: string;
  /** The image package name */
  name: string;
  /** The image release type */
  releaseType: string;
  /** The image platform */
  platform: string;
  /** The search lookback time in days */
  lookbackDays: number;
}

/** DeleteImagePackagesRequestInternal requests to remove the image packages associated with a tag */
export interface DeleteImagePackagesServiceRequest {
  /** The package release tag */
  tag: string;
}

function createBaseAddFabricImageUpdateRequest(): AddFabricImageUpdateRequest {
  return { fabricId: "", tag: "", deployAt: undefined, expireAt: undefined, arch: "" };
}

export const AddFabricImageUpdateRequest = {
  encode(message: AddFabricImageUpdateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.tag !== "") {
      writer.uint32(18).string(message.tag);
    }
    if (message.deployAt !== undefined) {
      Timestamp.encode(toTimestamp(message.deployAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.expireAt !== undefined) {
      Timestamp.encode(toTimestamp(message.expireAt), writer.uint32(34).fork()).ldelim();
    }
    if (message.arch !== "") {
      writer.uint32(42).string(message.arch);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddFabricImageUpdateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFabricImageUpdateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tag = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deployAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.expireAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.arch = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddFabricImageUpdateRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
      deployAt: isSet(object.deployAt) ? fromJsonTimestamp(object.deployAt) : undefined,
      expireAt: isSet(object.expireAt) ? fromJsonTimestamp(object.expireAt) : undefined,
      arch: isSet(object.arch) ? globalThis.String(object.arch) : "",
    };
  },

  toJSON(message: AddFabricImageUpdateRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    if (message.deployAt !== undefined) {
      obj.deployAt = message.deployAt.toISOString();
    }
    if (message.expireAt !== undefined) {
      obj.expireAt = message.expireAt.toISOString();
    }
    if (message.arch !== "") {
      obj.arch = message.arch;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddFabricImageUpdateRequest>, I>>(base?: I): AddFabricImageUpdateRequest {
    return AddFabricImageUpdateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddFabricImageUpdateRequest>, I>>(object: I): AddFabricImageUpdateRequest {
    const message = createBaseAddFabricImageUpdateRequest();
    message.fabricId = object.fabricId ?? "";
    message.tag = object.tag ?? "";
    message.deployAt = object.deployAt ?? undefined;
    message.expireAt = object.expireAt ?? undefined;
    message.arch = object.arch ?? "";
    return message;
  },
};

function createBaseGetFabricImageUpdatesRequest(): GetFabricImageUpdatesRequest {
  return { fabricId: "", candidate: "" };
}

export const GetFabricImageUpdatesRequest = {
  encode(message: GetFabricImageUpdatesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.candidate !== "") {
      writer.uint32(18).string(message.candidate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricImageUpdatesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricImageUpdatesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.candidate = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricImageUpdatesRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
    };
  },

  toJSON(message: GetFabricImageUpdatesRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricImageUpdatesRequest>, I>>(base?: I): GetFabricImageUpdatesRequest {
    return GetFabricImageUpdatesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricImageUpdatesRequest>, I>>(object: I): GetFabricImageUpdatesRequest {
    const message = createBaseGetFabricImageUpdatesRequest();
    message.fabricId = object.fabricId ?? "";
    message.candidate = object.candidate ?? "";
    return message;
  },
};

function createBaseGetCommittedFabricImageUpdatesRequest(): GetCommittedFabricImageUpdatesRequest {
  return { orgId: "", fabricIds: [], lookbackDays: 0 };
}

export const GetCommittedFabricImageUpdatesRequest = {
  encode(message: GetCommittedFabricImageUpdatesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    for (const v of message.fabricIds) {
      writer.uint32(18).string(v!);
    }
    if (message.lookbackDays !== 0) {
      writer.uint32(24).uint32(message.lookbackDays);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCommittedFabricImageUpdatesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCommittedFabricImageUpdatesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricIds.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.lookbackDays = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCommittedFabricImageUpdatesRequest {
    return {
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricIds: globalThis.Array.isArray(object?.fabricIds)
        ? object.fabricIds.map((e: any) => globalThis.String(e))
        : [],
      lookbackDays: isSet(object.lookbackDays) ? globalThis.Number(object.lookbackDays) : 0,
    };
  },

  toJSON(message: GetCommittedFabricImageUpdatesRequest): unknown {
    const obj: any = {};
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricIds?.length) {
      obj.fabricIds = message.fabricIds;
    }
    if (message.lookbackDays !== 0) {
      obj.lookbackDays = Math.round(message.lookbackDays);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCommittedFabricImageUpdatesRequest>, I>>(
    base?: I,
  ): GetCommittedFabricImageUpdatesRequest {
    return GetCommittedFabricImageUpdatesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCommittedFabricImageUpdatesRequest>, I>>(
    object: I,
  ): GetCommittedFabricImageUpdatesRequest {
    const message = createBaseGetCommittedFabricImageUpdatesRequest();
    message.orgId = object.orgId ?? "";
    message.fabricIds = object.fabricIds?.map((e) => e) || [];
    message.lookbackDays = object.lookbackDays ?? 0;
    return message;
  },
};

function createBaseGetFabricImageUpdatesResponse(): GetFabricImageUpdatesResponse {
  return { fabricImages: [] };
}

export const GetFabricImageUpdatesResponse = {
  encode(message: GetFabricImageUpdatesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.fabricImages) {
      FabricImageUpdate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricImageUpdatesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricImageUpdatesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricImages.push(FabricImageUpdate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricImageUpdatesResponse {
    return {
      fabricImages: globalThis.Array.isArray(object?.fabricImages)
        ? object.fabricImages.map((e: any) => FabricImageUpdate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetFabricImageUpdatesResponse): unknown {
    const obj: any = {};
    if (message.fabricImages?.length) {
      obj.fabricImages = message.fabricImages.map((e) => FabricImageUpdate.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricImageUpdatesResponse>, I>>(base?: I): GetFabricImageUpdatesResponse {
    return GetFabricImageUpdatesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricImageUpdatesResponse>, I>>(
    object: I,
  ): GetFabricImageUpdatesResponse {
    const message = createBaseGetFabricImageUpdatesResponse();
    message.fabricImages = object.fabricImages?.map((e) => FabricImageUpdate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetNodeImageUpdatesRequest(): GetNodeImageUpdatesRequest {
  return { candidate: "", fabricId: "", nodeIds: [], tags: [] };
}

export const GetNodeImageUpdatesRequest = {
  encode(message: GetNodeImageUpdatesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    for (const v of message.nodeIds) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.tags) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodeImageUpdatesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodeImageUpdatesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nodeIds.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tags.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodeImageUpdatesRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeIds: globalThis.Array.isArray(object?.nodeIds) ? object.nodeIds.map((e: any) => globalThis.String(e)) : [],
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetNodeImageUpdatesRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeIds?.length) {
      obj.nodeIds = message.nodeIds;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodeImageUpdatesRequest>, I>>(base?: I): GetNodeImageUpdatesRequest {
    return GetNodeImageUpdatesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodeImageUpdatesRequest>, I>>(object: I): GetNodeImageUpdatesRequest {
    const message = createBaseGetNodeImageUpdatesRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    message.nodeIds = object.nodeIds?.map((e) => e) || [];
    message.tags = object.tags?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetNodeImageUpdatesResponse(): GetNodeImageUpdatesResponse {
  return { nodeImages: [] };
}

export const GetNodeImageUpdatesResponse = {
  encode(message: GetNodeImageUpdatesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.nodeImages) {
      NodeImageUpdate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodeImageUpdatesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodeImageUpdatesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeImages.push(NodeImageUpdate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodeImageUpdatesResponse {
    return {
      nodeImages: globalThis.Array.isArray(object?.nodeImages)
        ? object.nodeImages.map((e: any) => NodeImageUpdate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetNodeImageUpdatesResponse): unknown {
    const obj: any = {};
    if (message.nodeImages?.length) {
      obj.nodeImages = message.nodeImages.map((e) => NodeImageUpdate.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodeImageUpdatesResponse>, I>>(base?: I): GetNodeImageUpdatesResponse {
    return GetNodeImageUpdatesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodeImageUpdatesResponse>, I>>(object: I): GetNodeImageUpdatesResponse {
    const message = createBaseGetNodeImageUpdatesResponse();
    message.nodeImages = object.nodeImages?.map((e) => NodeImageUpdate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateNodeImageUpdatesRequest(): UpdateNodeImageUpdatesRequest {
  return { fabricId: "", nodeImages: [] };
}

export const UpdateNodeImageUpdatesRequest = {
  encode(message: UpdateNodeImageUpdatesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.nodeImages) {
      NodeImageUpdate.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateNodeImageUpdatesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateNodeImageUpdatesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeImages.push(NodeImageUpdate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateNodeImageUpdatesRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeImages: globalThis.Array.isArray(object?.nodeImages)
        ? object.nodeImages.map((e: any) => NodeImageUpdate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateNodeImageUpdatesRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeImages?.length) {
      obj.nodeImages = message.nodeImages.map((e) => NodeImageUpdate.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateNodeImageUpdatesRequest>, I>>(base?: I): UpdateNodeImageUpdatesRequest {
    return UpdateNodeImageUpdatesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateNodeImageUpdatesRequest>, I>>(
    object: I,
  ): UpdateNodeImageUpdatesRequest {
    const message = createBaseUpdateNodeImageUpdatesRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeImages = object.nodeImages?.map((e) => NodeImageUpdate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateNodeImageUpdatesResponse(): UpdateNodeImageUpdatesResponse {
  return { nodeImages: [] };
}

export const UpdateNodeImageUpdatesResponse = {
  encode(message: UpdateNodeImageUpdatesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.nodeImages) {
      NodeImageUpdate.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateNodeImageUpdatesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateNodeImageUpdatesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeImages.push(NodeImageUpdate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateNodeImageUpdatesResponse {
    return {
      nodeImages: globalThis.Array.isArray(object?.nodeImages)
        ? object.nodeImages.map((e: any) => NodeImageUpdate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateNodeImageUpdatesResponse): unknown {
    const obj: any = {};
    if (message.nodeImages?.length) {
      obj.nodeImages = message.nodeImages.map((e) => NodeImageUpdate.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateNodeImageUpdatesResponse>, I>>(base?: I): UpdateNodeImageUpdatesResponse {
    return UpdateNodeImageUpdatesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateNodeImageUpdatesResponse>, I>>(
    object: I,
  ): UpdateNodeImageUpdatesResponse {
    const message = createBaseUpdateNodeImageUpdatesResponse();
    message.nodeImages = object.nodeImages?.map((e) => NodeImageUpdate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAddNodeImagePackagesRequest(): AddNodeImagePackagesRequest {
  return { fabricId: "", packages: [] };
}

export const AddNodeImagePackagesRequest = {
  encode(message: AddNodeImagePackagesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.packages) {
      NodeImagePackageInfo.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddNodeImagePackagesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddNodeImagePackagesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.packages.push(NodeImagePackageInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddNodeImagePackagesRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      packages: globalThis.Array.isArray(object?.packages)
        ? object.packages.map((e: any) => NodeImagePackageInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AddNodeImagePackagesRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.packages?.length) {
      obj.packages = message.packages.map((e) => NodeImagePackageInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddNodeImagePackagesRequest>, I>>(base?: I): AddNodeImagePackagesRequest {
    return AddNodeImagePackagesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddNodeImagePackagesRequest>, I>>(object: I): AddNodeImagePackagesRequest {
    const message = createBaseAddNodeImagePackagesRequest();
    message.fabricId = object.fabricId ?? "";
    message.packages = object.packages?.map((e) => NodeImagePackageInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseNodeImagePackageInfo(): NodeImagePackageInfo {
  return {
    nodeId: "",
    deviceId: "",
    partition: "",
    tag: "",
    name: "",
    active: false,
    nextActive: false,
    sha512: "",
    updatedAt: undefined,
  };
}

export const NodeImagePackageInfo = {
  encode(message: NodeImagePackageInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeId !== "") {
      writer.uint32(10).string(message.nodeId);
    }
    if (message.deviceId !== "") {
      writer.uint32(18).string(message.deviceId);
    }
    if (message.partition !== "") {
      writer.uint32(26).string(message.partition);
    }
    if (message.tag !== "") {
      writer.uint32(34).string(message.tag);
    }
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    if (message.active !== false) {
      writer.uint32(48).bool(message.active);
    }
    if (message.nextActive !== false) {
      writer.uint32(56).bool(message.nextActive);
    }
    if (message.sha512 !== "") {
      writer.uint32(66).string(message.sha512);
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NodeImagePackageInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodeImagePackageInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.partition = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tag = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.nextActive = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.sha512 = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodeImagePackageInfo {
    return {
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      partition: isSet(object.partition) ? globalThis.String(object.partition) : "",
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      active: isSet(object.active) ? globalThis.Boolean(object.active) : false,
      nextActive: isSet(object.nextActive) ? globalThis.Boolean(object.nextActive) : false,
      sha512: isSet(object.sha512) ? globalThis.String(object.sha512) : "",
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
    };
  },

  toJSON(message: NodeImagePackageInfo): unknown {
    const obj: any = {};
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.partition !== "") {
      obj.partition = message.partition;
    }
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.active !== false) {
      obj.active = message.active;
    }
    if (message.nextActive !== false) {
      obj.nextActive = message.nextActive;
    }
    if (message.sha512 !== "") {
      obj.sha512 = message.sha512;
    }
    if (message.updatedAt !== undefined) {
      obj.updatedAt = message.updatedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodeImagePackageInfo>, I>>(base?: I): NodeImagePackageInfo {
    return NodeImagePackageInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodeImagePackageInfo>, I>>(object: I): NodeImagePackageInfo {
    const message = createBaseNodeImagePackageInfo();
    message.nodeId = object.nodeId ?? "";
    message.deviceId = object.deviceId ?? "";
    message.partition = object.partition ?? "";
    message.tag = object.tag ?? "";
    message.name = object.name ?? "";
    message.active = object.active ?? false;
    message.nextActive = object.nextActive ?? false;
    message.sha512 = object.sha512 ?? "";
    message.updatedAt = object.updatedAt ?? undefined;
    return message;
  },
};

function createBaseAddNodeImagePackagesResponse(): AddNodeImagePackagesResponse {
  return { fabricId: "", nodeIds: [] };
}

export const AddNodeImagePackagesResponse = {
  encode(message: AddNodeImagePackagesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.nodeIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddNodeImagePackagesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddNodeImagePackagesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddNodeImagePackagesResponse {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeIds: globalThis.Array.isArray(object?.nodeIds) ? object.nodeIds.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: AddNodeImagePackagesResponse): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeIds?.length) {
      obj.nodeIds = message.nodeIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddNodeImagePackagesResponse>, I>>(base?: I): AddNodeImagePackagesResponse {
    return AddNodeImagePackagesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddNodeImagePackagesResponse>, I>>(object: I): AddNodeImagePackagesResponse {
    const message = createBaseAddNodeImagePackagesResponse();
    message.fabricId = object.fabricId ?? "";
    message.nodeIds = object.nodeIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetNodeImagePackagesRequest(): GetNodeImagePackagesRequest {
  return { fabricId: "", nodeId: "" };
}

export const GetNodeImagePackagesRequest = {
  encode(message: GetNodeImagePackagesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodeImagePackagesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodeImagePackagesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodeImagePackagesRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
    };
  },

  toJSON(message: GetNodeImagePackagesRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodeImagePackagesRequest>, I>>(base?: I): GetNodeImagePackagesRequest {
    return GetNodeImagePackagesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodeImagePackagesRequest>, I>>(object: I): GetNodeImagePackagesRequest {
    const message = createBaseGetNodeImagePackagesRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    return message;
  },
};

function createBaseGetNodeImagePackagesResponse(): GetNodeImagePackagesResponse {
  return { fabricId: "", packages: [] };
}

export const GetNodeImagePackagesResponse = {
  encode(message: GetNodeImagePackagesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.packages) {
      NodeImagePackageInfo.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodeImagePackagesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodeImagePackagesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.packages.push(NodeImagePackageInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodeImagePackagesResponse {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      packages: globalThis.Array.isArray(object?.packages)
        ? object.packages.map((e: any) => NodeImagePackageInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetNodeImagePackagesResponse): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.packages?.length) {
      obj.packages = message.packages.map((e) => NodeImagePackageInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodeImagePackagesResponse>, I>>(base?: I): GetNodeImagePackagesResponse {
    return GetNodeImagePackagesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodeImagePackagesResponse>, I>>(object: I): GetNodeImagePackagesResponse {
    const message = createBaseGetNodeImagePackagesResponse();
    message.fabricId = object.fabricId ?? "";
    message.packages = object.packages?.map((e) => NodeImagePackageInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteNodeImagePackagesRequest(): DeleteNodeImagePackagesRequest {
  return { fabricId: "", nodeId: "" };
}

export const DeleteNodeImagePackagesRequest = {
  encode(message: DeleteNodeImagePackagesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteNodeImagePackagesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteNodeImagePackagesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteNodeImagePackagesRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
    };
  },

  toJSON(message: DeleteNodeImagePackagesRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteNodeImagePackagesRequest>, I>>(base?: I): DeleteNodeImagePackagesRequest {
    return DeleteNodeImagePackagesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteNodeImagePackagesRequest>, I>>(
    object: I,
  ): DeleteNodeImagePackagesRequest {
    const message = createBaseDeleteNodeImagePackagesRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    return message;
  },
};

function createBaseAddImagePackagesRequest(): AddImagePackagesRequest {
  return { packages: [] };
}

export const AddImagePackagesRequest = {
  encode(message: AddImagePackagesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.packages) {
      ImagePackageInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddImagePackagesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddImagePackagesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.packages.push(ImagePackageInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddImagePackagesRequest {
    return {
      packages: globalThis.Array.isArray(object?.packages)
        ? object.packages.map((e: any) => ImagePackageInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AddImagePackagesRequest): unknown {
    const obj: any = {};
    if (message.packages?.length) {
      obj.packages = message.packages.map((e) => ImagePackageInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddImagePackagesRequest>, I>>(base?: I): AddImagePackagesRequest {
    return AddImagePackagesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddImagePackagesRequest>, I>>(object: I): AddImagePackagesRequest {
    const message = createBaseAddImagePackagesRequest();
    message.packages = object.packages?.map((e) => ImagePackageInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseImagePackagesResponse(): ImagePackagesResponse {
  return { addedPackages: [], updatedPackages: [] };
}

export const ImagePackagesResponse = {
  encode(message: ImagePackagesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.addedPackages) {
      ImagePackageInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.updatedPackages) {
      ImagePackageInfo.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImagePackagesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImagePackagesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.addedPackages.push(ImagePackageInfo.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.updatedPackages.push(ImagePackageInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImagePackagesResponse {
    return {
      addedPackages: globalThis.Array.isArray(object?.addedPackages)
        ? object.addedPackages.map((e: any) => ImagePackageInfo.fromJSON(e))
        : [],
      updatedPackages: globalThis.Array.isArray(object?.updatedPackages)
        ? object.updatedPackages.map((e: any) => ImagePackageInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ImagePackagesResponse): unknown {
    const obj: any = {};
    if (message.addedPackages?.length) {
      obj.addedPackages = message.addedPackages.map((e) => ImagePackageInfo.toJSON(e));
    }
    if (message.updatedPackages?.length) {
      obj.updatedPackages = message.updatedPackages.map((e) => ImagePackageInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImagePackagesResponse>, I>>(base?: I): ImagePackagesResponse {
    return ImagePackagesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImagePackagesResponse>, I>>(object: I): ImagePackagesResponse {
    const message = createBaseImagePackagesResponse();
    message.addedPackages = object.addedPackages?.map((e) => ImagePackageInfo.fromPartial(e)) || [];
    message.updatedPackages = object.updatedPackages?.map((e) => ImagePackageInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetImagePackagesRequest(): GetImagePackagesRequest {
  return { tag: "", arch: "", name: "", releaseType: "", platform: "", lookbackDays: 0 };
}

export const GetImagePackagesRequest = {
  encode(message: GetImagePackagesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== "") {
      writer.uint32(10).string(message.tag);
    }
    if (message.arch !== "") {
      writer.uint32(18).string(message.arch);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.releaseType !== "") {
      writer.uint32(34).string(message.releaseType);
    }
    if (message.platform !== "") {
      writer.uint32(42).string(message.platform);
    }
    if (message.lookbackDays !== 0) {
      writer.uint32(48).uint32(message.lookbackDays);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetImagePackagesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetImagePackagesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tag = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.arch = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.releaseType = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.platform = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.lookbackDays = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetImagePackagesRequest {
    return {
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
      arch: isSet(object.arch) ? globalThis.String(object.arch) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      releaseType: isSet(object.releaseType) ? globalThis.String(object.releaseType) : "",
      platform: isSet(object.platform) ? globalThis.String(object.platform) : "",
      lookbackDays: isSet(object.lookbackDays) ? globalThis.Number(object.lookbackDays) : 0,
    };
  },

  toJSON(message: GetImagePackagesRequest): unknown {
    const obj: any = {};
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    if (message.arch !== "") {
      obj.arch = message.arch;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.releaseType !== "") {
      obj.releaseType = message.releaseType;
    }
    if (message.platform !== "") {
      obj.platform = message.platform;
    }
    if (message.lookbackDays !== 0) {
      obj.lookbackDays = Math.round(message.lookbackDays);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetImagePackagesRequest>, I>>(base?: I): GetImagePackagesRequest {
    return GetImagePackagesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetImagePackagesRequest>, I>>(object: I): GetImagePackagesRequest {
    const message = createBaseGetImagePackagesRequest();
    message.tag = object.tag ?? "";
    message.arch = object.arch ?? "";
    message.name = object.name ?? "";
    message.releaseType = object.releaseType ?? "";
    message.platform = object.platform ?? "";
    message.lookbackDays = object.lookbackDays ?? 0;
    return message;
  },
};

function createBaseGetImagePackagesResponse(): GetImagePackagesResponse {
  return { packages: [] };
}

export const GetImagePackagesResponse = {
  encode(message: GetImagePackagesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.packages) {
      ImagePackageInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetImagePackagesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetImagePackagesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.packages.push(ImagePackageInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetImagePackagesResponse {
    return {
      packages: globalThis.Array.isArray(object?.packages)
        ? object.packages.map((e: any) => ImagePackageInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetImagePackagesResponse): unknown {
    const obj: any = {};
    if (message.packages?.length) {
      obj.packages = message.packages.map((e) => ImagePackageInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetImagePackagesResponse>, I>>(base?: I): GetImagePackagesResponse {
    return GetImagePackagesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetImagePackagesResponse>, I>>(object: I): GetImagePackagesResponse {
    const message = createBaseGetImagePackagesResponse();
    message.packages = object.packages?.map((e) => ImagePackageInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateImagePackagesRequest(): UpdateImagePackagesRequest {
  return { tag: "", packages: [] };
}

export const UpdateImagePackagesRequest = {
  encode(message: UpdateImagePackagesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== "") {
      writer.uint32(10).string(message.tag);
    }
    for (const v of message.packages) {
      ImagePackageInfo.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateImagePackagesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateImagePackagesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tag = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.packages.push(ImagePackageInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateImagePackagesRequest {
    return {
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
      packages: globalThis.Array.isArray(object?.packages)
        ? object.packages.map((e: any) => ImagePackageInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateImagePackagesRequest): unknown {
    const obj: any = {};
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    if (message.packages?.length) {
      obj.packages = message.packages.map((e) => ImagePackageInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateImagePackagesRequest>, I>>(base?: I): UpdateImagePackagesRequest {
    return UpdateImagePackagesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateImagePackagesRequest>, I>>(object: I): UpdateImagePackagesRequest {
    const message = createBaseUpdateImagePackagesRequest();
    message.tag = object.tag ?? "";
    message.packages = object.packages?.map((e) => ImagePackageInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteImagePackagesRequest(): DeleteImagePackagesRequest {
  return { tag: "" };
}

export const DeleteImagePackagesRequest = {
  encode(message: DeleteImagePackagesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== "") {
      writer.uint32(10).string(message.tag);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteImagePackagesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteImagePackagesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tag = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteImagePackagesRequest {
    return { tag: isSet(object.tag) ? globalThis.String(object.tag) : "" };
  },

  toJSON(message: DeleteImagePackagesRequest): unknown {
    const obj: any = {};
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteImagePackagesRequest>, I>>(base?: I): DeleteImagePackagesRequest {
    return DeleteImagePackagesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteImagePackagesRequest>, I>>(object: I): DeleteImagePackagesRequest {
    const message = createBaseDeleteImagePackagesRequest();
    message.tag = object.tag ?? "";
    return message;
  },
};

function createBaseAddImagePackagesServiceRequest(): AddImagePackagesServiceRequest {
  return { packages: [] };
}

export const AddImagePackagesServiceRequest = {
  encode(message: AddImagePackagesServiceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.packages) {
      ImagePackageInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddImagePackagesServiceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddImagePackagesServiceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.packages.push(ImagePackageInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddImagePackagesServiceRequest {
    return {
      packages: globalThis.Array.isArray(object?.packages)
        ? object.packages.map((e: any) => ImagePackageInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AddImagePackagesServiceRequest): unknown {
    const obj: any = {};
    if (message.packages?.length) {
      obj.packages = message.packages.map((e) => ImagePackageInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddImagePackagesServiceRequest>, I>>(base?: I): AddImagePackagesServiceRequest {
    return AddImagePackagesServiceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddImagePackagesServiceRequest>, I>>(
    object: I,
  ): AddImagePackagesServiceRequest {
    const message = createBaseAddImagePackagesServiceRequest();
    message.packages = object.packages?.map((e) => ImagePackageInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetImagePackagesServiceRequest(): GetImagePackagesServiceRequest {
  return { tag: "", arch: "", name: "", releaseType: "", platform: "", lookbackDays: 0 };
}

export const GetImagePackagesServiceRequest = {
  encode(message: GetImagePackagesServiceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== "") {
      writer.uint32(10).string(message.tag);
    }
    if (message.arch !== "") {
      writer.uint32(18).string(message.arch);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.releaseType !== "") {
      writer.uint32(34).string(message.releaseType);
    }
    if (message.platform !== "") {
      writer.uint32(42).string(message.platform);
    }
    if (message.lookbackDays !== 0) {
      writer.uint32(48).uint32(message.lookbackDays);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetImagePackagesServiceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetImagePackagesServiceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tag = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.arch = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.releaseType = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.platform = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.lookbackDays = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetImagePackagesServiceRequest {
    return {
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
      arch: isSet(object.arch) ? globalThis.String(object.arch) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      releaseType: isSet(object.releaseType) ? globalThis.String(object.releaseType) : "",
      platform: isSet(object.platform) ? globalThis.String(object.platform) : "",
      lookbackDays: isSet(object.lookbackDays) ? globalThis.Number(object.lookbackDays) : 0,
    };
  },

  toJSON(message: GetImagePackagesServiceRequest): unknown {
    const obj: any = {};
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    if (message.arch !== "") {
      obj.arch = message.arch;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.releaseType !== "") {
      obj.releaseType = message.releaseType;
    }
    if (message.platform !== "") {
      obj.platform = message.platform;
    }
    if (message.lookbackDays !== 0) {
      obj.lookbackDays = Math.round(message.lookbackDays);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetImagePackagesServiceRequest>, I>>(base?: I): GetImagePackagesServiceRequest {
    return GetImagePackagesServiceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetImagePackagesServiceRequest>, I>>(
    object: I,
  ): GetImagePackagesServiceRequest {
    const message = createBaseGetImagePackagesServiceRequest();
    message.tag = object.tag ?? "";
    message.arch = object.arch ?? "";
    message.name = object.name ?? "";
    message.releaseType = object.releaseType ?? "";
    message.platform = object.platform ?? "";
    message.lookbackDays = object.lookbackDays ?? 0;
    return message;
  },
};

function createBaseDeleteImagePackagesServiceRequest(): DeleteImagePackagesServiceRequest {
  return { tag: "" };
}

export const DeleteImagePackagesServiceRequest = {
  encode(message: DeleteImagePackagesServiceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== "") {
      writer.uint32(10).string(message.tag);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteImagePackagesServiceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteImagePackagesServiceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tag = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteImagePackagesServiceRequest {
    return { tag: isSet(object.tag) ? globalThis.String(object.tag) : "" };
  },

  toJSON(message: DeleteImagePackagesServiceRequest): unknown {
    const obj: any = {};
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteImagePackagesServiceRequest>, I>>(
    base?: I,
  ): DeleteImagePackagesServiceRequest {
    return DeleteImagePackagesServiceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteImagePackagesServiceRequest>, I>>(
    object: I,
  ): DeleteImagePackagesServiceRequest {
    const message = createBaseDeleteImagePackagesServiceRequest();
    message.tag = object.tag ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
