import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { Heading } from "@magnetic/heading";
import { Button } from "@magnetic/button";
import { Text } from "@magnetic/text";
import { Input } from "@magnetic/input";
import { Link } from "@magnetic/link";
import { PageLoader } from "components/pageLoader";
import { CiscoLogo } from "src/common/ciscoLogo";
import { GlobalFooter } from "src/layouts/globalFooter";

import "./login.scss";
import { ApiError } from "./apiError";
import { Apis } from "src/utils/api";
import { Constants } from "src/utils/constants";
import { LoginContext } from "./loginContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Flex } from "@magnetic/flex";
import { ErrorNotification } from "components/errorNotification";
import { PATHS } from "src/core/routes";

export const DEV_SIGN_UP = "https://int-id.cisco.com/signin/register";
export const PROD_SIGN_UP = "https://id.cisco.com/signin/register";

export const DEV_NEED_HELP = "https://int-id.cisco.com";
export const PROD_NEED_HELP = "https://id.cisco.com";

import { setLocalTheme, getLocalTheme } from "src/utils/theme";
import { VFlex } from "src/components";

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

/**
 * Email validation function
 * @param value
 */
export function isEmail(value: FormDataEntryValue | null): boolean {
  if (value) {
    if (!(value instanceof File)) {
      return EMAIL_REGEX.test(value);
    }
  }
  return false;
}

/**
 * Using the serialized Location object passed from the shell, rebuild the destination as relative URL
 */
function buildDestinationLocation(loc: Location) {
  return [loc.pathname, loc.search, loc.hash].join("");
}

export const Login = () => {
  const isDevMode: boolean = window.cnc?.settings?.get("dev");
  const isAuthBypass: boolean = window.cnc?.settings?.get("authBypass");
  const { getUserSession } = useContext(LoginContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const theme = getLocalTheme();
    setLocalTheme(theme);
  }, []);

  /**
   * Initially, due to asynchronous timing of waiting for the `state` property to resolve, we only have a standard `location`
   * to read from the browser's URL. In a few event turns, the additional `NavigationOptions` will resolve
   */
  const getNavigationLocation = useMemo(() => {
    return location.state ? location.state.location : location;
  }, [location]);

  /**
   * Moved to using form submit to handle
   * the case where the user hits enter instead
   * of clicking the "sign in" button. Using
   * e.preventDefault(); should keep this
   * from causing problems for QA automation
   */
  const handleLogin = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      /**
       * Connect with third-party OKTA IdP for user authentication flow
       */
      function dispatchIdentityProvider(form: HTMLFormElement) {
        form.action = "/ui/v1/auth/login/okta";
        form.method = "post";
        form.submit();
      }

      /**
       * Connect with local dev database for simplified user authentication flow
       * Serializes the form data for passing to the Auth login API
       */
      function dispatchAuthBypass(formData: FormData) {
        setLoading(true);
        const payload: string[] = [];
        for (const [key, val] of formData.entries()) {
          payload.push([key, encodeURIComponent(val.toString())].join("="));
        }
        Apis.Auth.login(payload).then(
          (response) => {
            if (response.status === 401) {
              response?.text().then((err) => {
                setError(err);
                setLoading(false);
              });
            } else {
              const defaultLandingPath = PATHS.FABRICS;

              /*
               * If login is successful, the next step is go get the user
               * session to continue to onwards to user's intended URL, or resort to an app default route.
               */
              getUserSession().then(() => {
                setLoading(false);

                if (window.location.pathname !== defaultLandingPath) {
                  const stateLocation = location.state?.location;
                  const from = stateLocation
                    ? stateLocation
                    : defaultLandingPath;
                  navigate(from, { replace: true });
                }
              });
            }
          },
          (err: ApiError) => {
            setError(err.getMessage());
            setLoading(false);
          }
        );
      }

      e.preventDefault();
      const data = new FormData(e.currentTarget);
      const email = data.get("email");
      if (email === "") {
        setError("Enter email address to continue");
        return;
      }
      if (!isEmail(email)) {
        setError("Enter a valid email address to continue");
        return;
      }

      if (!isAuthBypass) {
        dispatchIdentityProvider(e.target as HTMLFormElement);
      } else {
        dispatchAuthBypass(data);
      }
    },
    [isAuthBypass, location, navigate, getUserSession]
  );

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className="login-page">
      <div className="login">
        <CiscoLogo />
        <form onSubmit={handleLogin}>
          <VFlex gap="md">
            <Flex align="center" justify="center">
              <Heading size="section" className="brand">
                {Constants.APP_NAME} Sign In
              </Heading>
            </Flex>
            <Text size="p3" className="text">
              {Constants.APP_NAME} uses cisco.com to authenticate users. Sign in
              using your cisco.com credentials.
            </Text>
          </VFlex>
          <Input
            label="Email"
            name="email"
            type="email"
            size="md"
            required={true}
            fixedWidth={true}
            autoComplete="off"
          />
          {isDevMode ? (
            /*
             * this dev mode condition is an improvement over visible `auth_bypass` logic.
             */
            <input
              type="hidden"
              name="auth_bypass"
              value={Boolean(isAuthBypass).toString()}
            />
          ) : (
            /*
             * [TORT-4855] In production mode: UI to supply Identity Provider with `start_url` form parameter with user's destination URL.
             * Upon successful authentication, redirect to that URL or where the page last seen if due to a session timeout.
             */
            <input
              type="hidden"
              name="start_url"
              value={buildDestinationLocation(getNavigationLocation)}
            />
          )}
          <ErrorNotification msg={error} />
          <Button type="submit" size="md" name="signin">
            Continue to cisco.com
          </Button>
          <VFlex gap="xs" className="help-text">
            <Text size="p3">
              Don&apos;t have a cisco.com login?&nbsp;
              <Link
                href={isDevMode ? DEV_SIGN_UP : PROD_SIGN_UP}
                className="login-link"
              >
                Sign up here.
              </Link>
            </Text>
            <Text size="p3">
              <Link
                href={isDevMode ? DEV_NEED_HELP : PROD_NEED_HELP}
                className="login-link"
              >
                Need help?
              </Link>
            </Text>
          </VFlex>
        </form>
        <GlobalFooter showCopyright={false} textSize="p3" />
      </div>
    </div>
  );
};
