// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: fred/fapi.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { UserRole, userRoleFromJSON, userRoleToJSON } from "../models/auth";

export const protobufPackage = "fred";

export enum ClaimState {
  CLAIM_STATE_UNSPECIFIED = 0,
  CLAIMED = 1,
  UNCLAIMED = 2,
  UNRECOGNIZED = -1,
}

export function claimStateFromJSON(object: any): ClaimState {
  switch (object) {
    case 0:
    case "CLAIM_STATE_UNSPECIFIED":
      return ClaimState.CLAIM_STATE_UNSPECIFIED;
    case 1:
    case "CLAIMED":
      return ClaimState.CLAIMED;
    case 2:
    case "UNCLAIMED":
      return ClaimState.UNCLAIMED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ClaimState.UNRECOGNIZED;
  }
}

export function claimStateToJSON(object: ClaimState): string {
  switch (object) {
    case ClaimState.CLAIM_STATE_UNSPECIFIED:
      return "CLAIM_STATE_UNSPECIFIED";
    case ClaimState.CLAIMED:
      return "CLAIMED";
    case ClaimState.UNCLAIMED:
      return "UNCLAIMED";
    case ClaimState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum BindState {
  BIND_STATE_UNSPECIFIED = 0,
  BOUND = 1,
  UNBOUND = 2,
  UNRECOGNIZED = -1,
}

export function bindStateFromJSON(object: any): BindState {
  switch (object) {
    case 0:
    case "BIND_STATE_UNSPECIFIED":
      return BindState.BIND_STATE_UNSPECIFIED;
    case 1:
    case "BOUND":
      return BindState.BOUND;
    case 2:
    case "UNBOUND":
      return BindState.UNBOUND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BindState.UNRECOGNIZED;
  }
}

export function bindStateToJSON(object: BindState): string {
  switch (object) {
    case BindState.BIND_STATE_UNSPECIFIED:
      return "BIND_STATE_UNSPECIFIED";
    case BindState.BOUND:
      return "BOUND";
    case BindState.UNBOUND:
      return "UNBOUND";
    case BindState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface GetUserContextsRequest {
}

export interface GetDeviceDetailsRequest {
  /** The device serial number */
  serialNumber: string;
}

export interface UserContext {
  orgId: string;
  orgName: string;
  tenantId: string;
  tenantName: string;
  current: boolean;
  role: UserRole;
}

export interface GetUserContextsResponse {
  email: string;
  contexts: UserContext[];
  eulaVersion: string;
}

/** GetUserPreferencesRequest allows a user to get their current user preferences */
export interface GetUserPreferencesRequest {
}

export interface GetDeviceDetailsResponse {
  serialNumber: string;
  deviceId: string;
  hostname: string;
  fabricId: string;
  nodeId: string;
  orgId: string;
  claimState: ClaimState;
  bindState: BindState;
}

function createBaseGetUserContextsRequest(): GetUserContextsRequest {
  return {};
}

export const GetUserContextsRequest = {
  encode(_: GetUserContextsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserContextsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserContextsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetUserContextsRequest {
    return {};
  },

  toJSON(_: GetUserContextsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserContextsRequest>, I>>(base?: I): GetUserContextsRequest {
    return GetUserContextsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserContextsRequest>, I>>(_: I): GetUserContextsRequest {
    const message = createBaseGetUserContextsRequest();
    return message;
  },
};

function createBaseGetDeviceDetailsRequest(): GetDeviceDetailsRequest {
  return { serialNumber: "" };
}

export const GetDeviceDetailsRequest = {
  encode(message: GetDeviceDetailsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serialNumber !== "") {
      writer.uint32(10).string(message.serialNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDeviceDetailsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceDetailsRequest {
    return { serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "" };
  },

  toJSON(message: GetDeviceDetailsRequest): unknown {
    const obj: any = {};
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeviceDetailsRequest>, I>>(base?: I): GetDeviceDetailsRequest {
    return GetDeviceDetailsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeviceDetailsRequest>, I>>(object: I): GetDeviceDetailsRequest {
    const message = createBaseGetDeviceDetailsRequest();
    message.serialNumber = object.serialNumber ?? "";
    return message;
  },
};

function createBaseUserContext(): UserContext {
  return { orgId: "", orgName: "", tenantId: "", tenantName: "", current: false, role: 0 };
}

export const UserContext = {
  encode(message: UserContext, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    if (message.orgName !== "") {
      writer.uint32(18).string(message.orgName);
    }
    if (message.tenantId !== "") {
      writer.uint32(26).string(message.tenantId);
    }
    if (message.tenantName !== "") {
      writer.uint32(34).string(message.tenantName);
    }
    if (message.current !== false) {
      writer.uint32(40).bool(message.current);
    }
    if (message.role !== 0) {
      writer.uint32(48).int32(message.role);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserContext {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserContext();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tenantName = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.current = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserContext {
    return {
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      orgName: isSet(object.orgName) ? globalThis.String(object.orgName) : "",
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      tenantName: isSet(object.tenantName) ? globalThis.String(object.tenantName) : "",
      current: isSet(object.current) ? globalThis.Boolean(object.current) : false,
      role: isSet(object.role) ? userRoleFromJSON(object.role) : 0,
    };
  },

  toJSON(message: UserContext): unknown {
    const obj: any = {};
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.orgName !== "") {
      obj.orgName = message.orgName;
    }
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.tenantName !== "") {
      obj.tenantName = message.tenantName;
    }
    if (message.current !== false) {
      obj.current = message.current;
    }
    if (message.role !== 0) {
      obj.role = userRoleToJSON(message.role);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserContext>, I>>(base?: I): UserContext {
    return UserContext.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserContext>, I>>(object: I): UserContext {
    const message = createBaseUserContext();
    message.orgId = object.orgId ?? "";
    message.orgName = object.orgName ?? "";
    message.tenantId = object.tenantId ?? "";
    message.tenantName = object.tenantName ?? "";
    message.current = object.current ?? false;
    message.role = object.role ?? 0;
    return message;
  },
};

function createBaseGetUserContextsResponse(): GetUserContextsResponse {
  return { email: "", contexts: [], eulaVersion: "" };
}

export const GetUserContextsResponse = {
  encode(message: GetUserContextsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    for (const v of message.contexts) {
      UserContext.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.eulaVersion !== "") {
      writer.uint32(26).string(message.eulaVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserContextsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserContextsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.contexts.push(UserContext.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.eulaVersion = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserContextsResponse {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      contexts: globalThis.Array.isArray(object?.contexts)
        ? object.contexts.map((e: any) => UserContext.fromJSON(e))
        : [],
      eulaVersion: isSet(object.eulaVersion) ? globalThis.String(object.eulaVersion) : "",
    };
  },

  toJSON(message: GetUserContextsResponse): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.contexts?.length) {
      obj.contexts = message.contexts.map((e) => UserContext.toJSON(e));
    }
    if (message.eulaVersion !== "") {
      obj.eulaVersion = message.eulaVersion;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserContextsResponse>, I>>(base?: I): GetUserContextsResponse {
    return GetUserContextsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserContextsResponse>, I>>(object: I): GetUserContextsResponse {
    const message = createBaseGetUserContextsResponse();
    message.email = object.email ?? "";
    message.contexts = object.contexts?.map((e) => UserContext.fromPartial(e)) || [];
    message.eulaVersion = object.eulaVersion ?? "";
    return message;
  },
};

function createBaseGetUserPreferencesRequest(): GetUserPreferencesRequest {
  return {};
}

export const GetUserPreferencesRequest = {
  encode(_: GetUserPreferencesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserPreferencesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserPreferencesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetUserPreferencesRequest {
    return {};
  },

  toJSON(_: GetUserPreferencesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserPreferencesRequest>, I>>(base?: I): GetUserPreferencesRequest {
    return GetUserPreferencesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserPreferencesRequest>, I>>(_: I): GetUserPreferencesRequest {
    const message = createBaseGetUserPreferencesRequest();
    return message;
  },
};

function createBaseGetDeviceDetailsResponse(): GetDeviceDetailsResponse {
  return {
    serialNumber: "",
    deviceId: "",
    hostname: "",
    fabricId: "",
    nodeId: "",
    orgId: "",
    claimState: 0,
    bindState: 0,
  };
}

export const GetDeviceDetailsResponse = {
  encode(message: GetDeviceDetailsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serialNumber !== "") {
      writer.uint32(10).string(message.serialNumber);
    }
    if (message.deviceId !== "") {
      writer.uint32(18).string(message.deviceId);
    }
    if (message.hostname !== "") {
      writer.uint32(26).string(message.hostname);
    }
    if (message.fabricId !== "") {
      writer.uint32(34).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(42).string(message.nodeId);
    }
    if (message.orgId !== "") {
      writer.uint32(50).string(message.orgId);
    }
    if (message.claimState !== 0) {
      writer.uint32(56).int32(message.claimState);
    }
    if (message.bindState !== 0) {
      writer.uint32(64).int32(message.bindState);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDeviceDetailsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceDetailsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.hostname = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.claimState = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.bindState = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceDetailsResponse {
    return {
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      claimState: isSet(object.claimState) ? claimStateFromJSON(object.claimState) : 0,
      bindState: isSet(object.bindState) ? bindStateFromJSON(object.bindState) : 0,
    };
  },

  toJSON(message: GetDeviceDetailsResponse): unknown {
    const obj: any = {};
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.claimState !== 0) {
      obj.claimState = claimStateToJSON(message.claimState);
    }
    if (message.bindState !== 0) {
      obj.bindState = bindStateToJSON(message.bindState);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeviceDetailsResponse>, I>>(base?: I): GetDeviceDetailsResponse {
    return GetDeviceDetailsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeviceDetailsResponse>, I>>(object: I): GetDeviceDetailsResponse {
    const message = createBaseGetDeviceDetailsResponse();
    message.serialNumber = object.serialNumber ?? "";
    message.deviceId = object.deviceId ?? "";
    message.hostname = object.hostname ?? "";
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.orgId = object.orgId ?? "";
    message.claimState = object.claimState ?? 0;
    message.bindState = object.bindState ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
