// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: sextant/service.proto

/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { EchoRequest, EchoResponse } from "../common/echo";
import {
  AssertCounterReportResponse,
  AssertHistoryApiRecordResponse,
  AssertRestCounterReportRequest,
  AssertRestRecordRequest,
  HistoryRequest,
  HistoryResponse,
} from "../reader/reader_api";
import {
  AssertionsResponse,
  GetDeviceAssertionsRequest,
  GetDevicePortAssertionsRequest,
  GetNodeAssertionsRequest,
  GetNodePortAssertionsRequest,
} from "./rest_assertions";
import { CountersResponse, GetDeviceCountersRequest, GetDevicePortCountersRequest } from "./rest_counters";
import { DeviceManagementPortsResponse, GetDeviceManagementPortsRequest } from "./rest_devices";
import { GetDeviceSensorsRequest, SensorsResponse } from "./rest_sensors";

export const protobufPackage = "sextant";

export interface Sextant {
  Echo(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Promise<EchoResponse>;
  StreamEcho(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Observable<EchoResponse>;
  /** Get history data on port statistics. */
  DruidTelemetryHistoryReader(request: DeepPartial<HistoryRequest>, metadata?: grpc.Metadata): Promise<HistoryResponse>;
  /**
   * Get the device management port configuration.
   *
   * Get the management port configuration reported by an unbound device.
   */
  GetDeviceManagementPorts(
    request: DeepPartial<GetDeviceManagementPortsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeviceManagementPortsResponse>;
  /**
   * Get the list of device assertions.
   *
   * Get the list of assertions of a specific device in a fabric.
   */
  GetDeviceAssertions(
    request: DeepPartial<GetDeviceAssertionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertionsResponse>;
  /**
   * Get the list of port assertions.
   *
   * Get the list of assertions for a specific port of a device in a fabric.
   */
  GetDevicePortAssertions(
    request: DeepPartial<GetDevicePortAssertionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertionsResponse>;
  /**
   * Get node assertions.
   *
   * Get the list of assertions of a specific device in a fabric.
   */
  GetNodeAssertions(
    request: DeepPartial<GetNodeAssertionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertionsResponse>;
  /**
   * Get port assertions for a node.
   *
   * Get the list of assertions for a specific port of a node in a fabric.
   */
  GetNodePortAssertions(
    request: DeepPartial<GetNodePortAssertionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertionsResponse>;
  /**
   * Get the list of device counters.
   *
   * Get the list of counters information of a specific device.
   */
  GetDeviceCounters(
    request: DeepPartial<GetDeviceCountersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CountersResponse>;
  /**
   * Get the list of device port counters.
   *
   * Get the list of port counters information on a specific port of a device.
   */
  GetDevicePortCounters(
    request: DeepPartial<GetDevicePortCountersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CountersResponse>;
  /**
   * Get the list of device sensor information.
   *
   * Get the list of sensor information of a specific device.
   */
  GetDeviceSensors(request: DeepPartial<GetDeviceSensorsRequest>, metadata?: grpc.Metadata): Promise<SensorsResponse>;
  /** Query chassis assertion history messages, internal low-level API for UI */
  QueryAssertionHistoryMessage(
    request: DeepPartial<HistoryRequest>,
    metadata?: grpc.Metadata,
  ): Promise<HistoryResponse>;
  /** Get the list of assertion counters and type history */
  GetAssertionHistoryCounterReport(
    request: DeepPartial<AssertRestCounterReportRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertCounterReportResponse>;
  /** Get the list of assertion history records */
  GetAssertionHistoryRecord(
    request: DeepPartial<AssertRestRecordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertHistoryApiRecordResponse>;
}

export class SextantClientImpl implements Sextant {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Echo = this.Echo.bind(this);
    this.StreamEcho = this.StreamEcho.bind(this);
    this.DruidTelemetryHistoryReader = this.DruidTelemetryHistoryReader.bind(this);
    this.GetDeviceManagementPorts = this.GetDeviceManagementPorts.bind(this);
    this.GetDeviceAssertions = this.GetDeviceAssertions.bind(this);
    this.GetDevicePortAssertions = this.GetDevicePortAssertions.bind(this);
    this.GetNodeAssertions = this.GetNodeAssertions.bind(this);
    this.GetNodePortAssertions = this.GetNodePortAssertions.bind(this);
    this.GetDeviceCounters = this.GetDeviceCounters.bind(this);
    this.GetDevicePortCounters = this.GetDevicePortCounters.bind(this);
    this.GetDeviceSensors = this.GetDeviceSensors.bind(this);
    this.QueryAssertionHistoryMessage = this.QueryAssertionHistoryMessage.bind(this);
    this.GetAssertionHistoryCounterReport = this.GetAssertionHistoryCounterReport.bind(this);
    this.GetAssertionHistoryRecord = this.GetAssertionHistoryRecord.bind(this);
  }

  Echo(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Promise<EchoResponse> {
    return this.rpc.unary(SextantEchoDesc, EchoRequest.fromPartial(request), metadata);
  }

  StreamEcho(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Observable<EchoResponse> {
    return this.rpc.invoke(SextantStreamEchoDesc, EchoRequest.fromPartial(request), metadata);
  }

  DruidTelemetryHistoryReader(
    request: DeepPartial<HistoryRequest>,
    metadata?: grpc.Metadata,
  ): Promise<HistoryResponse> {
    return this.rpc.unary(SextantDruidTelemetryHistoryReaderDesc, HistoryRequest.fromPartial(request), metadata);
  }

  GetDeviceManagementPorts(
    request: DeepPartial<GetDeviceManagementPortsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeviceManagementPortsResponse> {
    return this.rpc.unary(
      SextantGetDeviceManagementPortsDesc,
      GetDeviceManagementPortsRequest.fromPartial(request),
      metadata,
    );
  }

  GetDeviceAssertions(
    request: DeepPartial<GetDeviceAssertionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertionsResponse> {
    return this.rpc.unary(SextantGetDeviceAssertionsDesc, GetDeviceAssertionsRequest.fromPartial(request), metadata);
  }

  GetDevicePortAssertions(
    request: DeepPartial<GetDevicePortAssertionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertionsResponse> {
    return this.rpc.unary(
      SextantGetDevicePortAssertionsDesc,
      GetDevicePortAssertionsRequest.fromPartial(request),
      metadata,
    );
  }

  GetNodeAssertions(
    request: DeepPartial<GetNodeAssertionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertionsResponse> {
    return this.rpc.unary(SextantGetNodeAssertionsDesc, GetNodeAssertionsRequest.fromPartial(request), metadata);
  }

  GetNodePortAssertions(
    request: DeepPartial<GetNodePortAssertionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertionsResponse> {
    return this.rpc.unary(
      SextantGetNodePortAssertionsDesc,
      GetNodePortAssertionsRequest.fromPartial(request),
      metadata,
    );
  }

  GetDeviceCounters(
    request: DeepPartial<GetDeviceCountersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CountersResponse> {
    return this.rpc.unary(SextantGetDeviceCountersDesc, GetDeviceCountersRequest.fromPartial(request), metadata);
  }

  GetDevicePortCounters(
    request: DeepPartial<GetDevicePortCountersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CountersResponse> {
    return this.rpc.unary(
      SextantGetDevicePortCountersDesc,
      GetDevicePortCountersRequest.fromPartial(request),
      metadata,
    );
  }

  GetDeviceSensors(request: DeepPartial<GetDeviceSensorsRequest>, metadata?: grpc.Metadata): Promise<SensorsResponse> {
    return this.rpc.unary(SextantGetDeviceSensorsDesc, GetDeviceSensorsRequest.fromPartial(request), metadata);
  }

  QueryAssertionHistoryMessage(
    request: DeepPartial<HistoryRequest>,
    metadata?: grpc.Metadata,
  ): Promise<HistoryResponse> {
    return this.rpc.unary(SextantQueryAssertionHistoryMessageDesc, HistoryRequest.fromPartial(request), metadata);
  }

  GetAssertionHistoryCounterReport(
    request: DeepPartial<AssertRestCounterReportRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertCounterReportResponse> {
    return this.rpc.unary(
      SextantGetAssertionHistoryCounterReportDesc,
      AssertRestCounterReportRequest.fromPartial(request),
      metadata,
    );
  }

  GetAssertionHistoryRecord(
    request: DeepPartial<AssertRestRecordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertHistoryApiRecordResponse> {
    return this.rpc.unary(SextantGetAssertionHistoryRecordDesc, AssertRestRecordRequest.fromPartial(request), metadata);
  }
}

export const SextantDesc = { serviceName: "sextant.Sextant" };

export const SextantEchoDesc: UnaryMethodDefinitionish = {
  methodName: "Echo",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return EchoRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = EchoResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantStreamEchoDesc: UnaryMethodDefinitionish = {
  methodName: "StreamEcho",
  service: SextantDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return EchoRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = EchoResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantDruidTelemetryHistoryReaderDesc: UnaryMethodDefinitionish = {
  methodName: "DruidTelemetryHistoryReader",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return HistoryRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HistoryResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetDeviceManagementPortsDesc: UnaryMethodDefinitionish = {
  methodName: "GetDeviceManagementPorts",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDeviceManagementPortsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = DeviceManagementPortsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetDeviceAssertionsDesc: UnaryMethodDefinitionish = {
  methodName: "GetDeviceAssertions",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDeviceAssertionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AssertionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetDevicePortAssertionsDesc: UnaryMethodDefinitionish = {
  methodName: "GetDevicePortAssertions",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDevicePortAssertionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AssertionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetNodeAssertionsDesc: UnaryMethodDefinitionish = {
  methodName: "GetNodeAssertions",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNodeAssertionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AssertionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetNodePortAssertionsDesc: UnaryMethodDefinitionish = {
  methodName: "GetNodePortAssertions",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNodePortAssertionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AssertionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetDeviceCountersDesc: UnaryMethodDefinitionish = {
  methodName: "GetDeviceCounters",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDeviceCountersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CountersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetDevicePortCountersDesc: UnaryMethodDefinitionish = {
  methodName: "GetDevicePortCounters",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDevicePortCountersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CountersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetDeviceSensorsDesc: UnaryMethodDefinitionish = {
  methodName: "GetDeviceSensors",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDeviceSensorsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SensorsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantQueryAssertionHistoryMessageDesc: UnaryMethodDefinitionish = {
  methodName: "QueryAssertionHistoryMessage",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return HistoryRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HistoryResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetAssertionHistoryCounterReportDesc: UnaryMethodDefinitionish = {
  methodName: "GetAssertionHistoryCounterReport",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AssertRestCounterReportRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AssertCounterReportResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetAssertionHistoryRecordDesc: UnaryMethodDefinitionish = {
  methodName: "GetAssertionHistoryRecord",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AssertRestRecordRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AssertHistoryApiRecordResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    const upStreamCodes = this.options.upStreamRetryCodes ?? [];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const transport = this.options.streamingTransport ?? this.options.transport;
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          ...(transport !== undefined ? { transport } : {}),
          metadata: maybeCombinedMetadata ?? {},
          debug: this.options.debug ?? false,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string, trailers: grpc.Metadata) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              const err = new Error(message) as any;
              err.code = code;
              err.metadata = trailers;
              observer.error(err);
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
