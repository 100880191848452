import React, { SetStateAction, useCallback, useState } from "react";
import { logger } from "../core/globals";

// for this to work the input needs to have both name and value defined, then it'll autobind that to the state object
// directly set form state by using the setForm return function
export const useForm = <T extends object>(
  initialState: T
): {
  form: T;
  handleInputChange: (
    e: React.FormEvent<HTMLInputElement>,
    isValueNumber?: boolean
  ) => void;
  handleTagInputChange: (key: string, values: string[]) => void;
  handleFormValueChange: (t: T) => void;
  setForm: (o: SetStateAction<T>) => void;
} => {
  const [state, setState] = useState(initialState);

  const handleInputChange = useCallback(
    (e?: React.FormEvent<HTMLInputElement>, isValueNumber?: boolean) => {
      setState((prev) => {
        /* given the unknown nature of the form values seems to be no other way */
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        const o: any = {};
        const target = e?.target as HTMLInputElement;
        if (target === undefined) {
          logger.error("Input event target undefined");
          return prev;
        }
        const index = target.name as keyof T;
        if (index === undefined) {
          logger.error(
            "Input 'name' is undefined; Make JSX property 'name' is being set."
          );
          return prev;
        }
        if (isValueNumber) {
          o[index] = Number(target.value);
        } else {
          o[index] = target.value;
        }
        if (target === undefined) {
          logger.error(
            "Input 'value' is undefined; Make JSX property 'value' is being set."
          );
          return prev;
        }
        return { ...prev, ...o };
      });
    },
    []
  );

  const handleTagInputChange = useCallback(
    (key: string, tagValues: string[]) => {
      setState((prev: T) => {
        const o: { [key: string]: string[] } = {};
        if (tagValues) {
          o[key] = tagValues;
        }
        return { ...prev, ...o };
      });
    },
    []
  );

  const handleFormValueChange = useCallback((t: T) => {
    setState((prev: T) => {
      return { ...prev, ...t };
    });
  }, []);

  return {
    form: state,
    handleInputChange,
    handleTagInputChange,
    handleFormValueChange,
    setForm: setState
  };
};
