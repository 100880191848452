import { grpc } from "@improbable-eng/grpc-web";
import { Error, Status } from "../gen/schema/common/grpc";
import { logger } from "./globals";
import { BrowserHeaders } from "browser-headers";
import { ErrorCode } from "../gen/schema/syncfollow/syncfollow";

const UNKNOWN = "Unknown Error";

const stringToUint8Array = (str: string): Uint8Array => {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0; i < str.length; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return bufView;
};

const HEADER = "grpc-status-details-bin";
const parseMetadata = (metadata: BrowserHeaders): Error[][] => {
  const details = metadata.headersMap?.[HEADER];
  if (details) {
    return details.map((encoded: string) => {
      const input = stringToUint8Array(atob(encoded));
      const status = Status.decode(input);
      return status.details.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (detail: any): Error => {
          return Error.decode(detail.value);
        }
      );
    });
  }
  return [[]];
};

const errorsToDetails = (errors: Error[][]): Map<string, Error> => {
  return errors.reduce((errorMap: Map<string, Error>, error: Error[]) => {
    error?.forEach((detail) => {
      errorMap.set(detail?.field, detail);
    });
    return errorMap;
  }, new Map<string, Error>());
};

type BackendErrors = Partial<{
  /* Properties we care about from GrpcWebError */
  metadata?: BrowserHeaders;
  code?: grpc.Code | undefined;
  message?: string;
  /* Properties we care about from syncfollow Errors */
  errorMessage?: string;
  errorCode?: ErrorCode | undefined;
}>;

// wrapper to make sure we deal with well-defined values
export class ApiError {
  private errorCode: ErrorCode | undefined;
  private code: grpc.Code | undefined;
  private errors: Error[][] = [[]];
  private details: Map<string, Error> = new Map<string, Error>();
  private message: string = UNKNOWN;

  constructor(e: BackendErrors) {
    /**
     * Backend sync follow errors are inconsistent
     * There seem to be at least 2 sync-follow
     * response error(s) that are wrapped in another
     * object, The Grpc error are just as bad
     * as the Class is lost when thrown and
     * the error is just a javascript object
     *
     * errorMessage: SyncFollow error message.
     * message: GrpcWebError message
     * metadata: GrpcWevError set of BrowserHeaders,
     *  we care about the grpc-status-details-bin
     *  header.
     */
    const {
      metadata,
      code = grpc.Code.Unknown,
      errorCode = ErrorCode.ERR_UNSPECIFIED,
      errorMessage = this.message,
      message = errorMessage
    } = e;
    if (metadata) {
      this.code = code;
      this.errors = parseMetadata(metadata);
      this.details = errorsToDetails(this.errors);
      this.message = Array.from(this.details.values())
        .map((err: Error) => `${err.field}: ${err.message}`)
        .join(" ");
    } else if (message) {
      this.message = message || "Unknown Error";
      this.errorCode = errorCode;
    }
    logger.error("Error message", this.details);
  }

  getMessage(): string {
    return this.message || UNKNOWN;
  }

  getCode(): ErrorCode | grpc.Code | undefined {
    return this.code || this.errorCode;
  }

  getDetails(): Error[][] {
    // Flatten it to a <field, Error> Map
    return this.errors;
  }

  getFieldErrors(): Map<string, Error> {
    // Flatten it to a <field, Error> Map
    return this.details;
  }
}
