import { Parser, Json, getParser } from "./parser";
/**
 * Sends an HTTP request using the Fetch API.
 *
 * @param {string} url - The URL to which the request is sent.
 * @param {string} method - The HTTP method to use (e.g., GET, POST, PUT, DELETE).
 * @param {string} [body] - The body of the request, if applicable.
 * @param {RequestInit} [options] - Additional options to customize the request.
 * @returns {Promise<Response>} A promise that resolves to the response of the request.
 */
const request = (
  url: string,
  method: string,
  body?: string,
  options?: RequestInit | undefined
): Promise<Response> => {
  const headers = new Headers({
    "Content-Type": "application/json",
    ...options?.headers
  });
  if (!!window.cnc && !!window.cnc.xCsrfToken) {
    headers.append("X-Csrf-Token", window.cnc.xCsrfToken);
  }
  const opts: RequestInit | undefined = {
    method: method,
    mode: "same-origin",
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body,
    ...options,
    headers
  };
  return fetch(url, opts);
};

export const ReST = {
  get: (url: string, options?: RequestInit | undefined): Promise<Response> => {
    return request(url, "GET", undefined, options);
  },
  post: (
    url: string,
    body: string = "",
    options?: RequestInit | undefined
  ): Promise<Response> => {
    return request(url, "POST", body, options);
  },
  put: (
    url: string,
    body: string = "",
    options?: RequestInit | undefined
  ): Promise<Response> => {
    return request(url, "PUT", body, options);
  },
  delete: (
    url: string,
    body: string = "",
    options?: RequestInit | undefined
  ): Promise<Response> => {
    return request(url, "DELETE", body, options);
  },
  /**
   * Initial stab at a ReST Infra that
   * will be typesafe. It will consume and
   * return the existing protobuf objects
   * the UI already uses.
   *
   * TODO: make shure we can generate post data properly
   */
  TypeSafe: {
    get: <TResponse>(
      url: string,
      transform: (json: Json) => TResponse,
      options?: RequestInit | undefined
    ): Promise<TResponse> => {
      const parser = new Parser(transform);
      return ReST.get(url, options).then(
        (resp: Response) => parser.parse(resp),
        (err) => Promise.reject(err)
      );
    },
    post: <TResponse>(
      url: string,
      body: string = "",
      transform: (json: Json) => TResponse,
      options?: RequestInit | undefined
    ): Promise<TResponse> => {
      const parse = getParser(transform);
      return ReST.post(url, body, options).then(parse, (err) =>
        Promise.reject(err)
      );
    },
    put: <TResponse>(
      url: string,
      body: string = "",
      transform: (json: Json) => TResponse,
      options?: RequestInit | undefined
    ): Promise<TResponse> => {
      const parse = getParser(transform);
      return ReST.put(url, body, options).then(parse, (err) =>
        Promise.reject(err)
      );
    },
    delete: <TResponse>(
      url: string,
      body: string = "",
      transform: (json: Json) => TResponse,
      options?: RequestInit | undefined
    ): Promise<TResponse> => {
      const parse = getParser(transform);
      return ReST.delete(url, body, options).then(parse, (err) =>
        Promise.reject(err)
      );
    }
  }
};
