// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: brig/bapi.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Token } from "../common/token";
import { Timestamp } from "../google/protobuf/timestamp";
import {
  SbpModel,
  sbpModelFromJSON,
  sbpModelToJSON,
  SbpPid,
  sbpPidFromJSON,
  sbpPidToJSON,
  UserRole,
  userRoleFromJSON,
  userRoleToJSON,
} from "../models/auth";
import { EntitlementLevel, entitlementLevelFromJSON, entitlementLevelToJSON } from "../models/global";
import { Tenant } from "../models/tenant";
import { SignUp, User, UserContext, UserPreferences } from "../models/user";

export const protobufPackage = "brig";

/** StartUserSessionRequest requests the start of a session for the user identified by email. */
export interface StartUserSessionRequest {
  /**
   * Identifies the user, mandatory.  If email identifies a new user, the UserSessionToken returned
   * may only be used with NewOrg.  If disabled user (user, tenant or organization is DISABLED),
   * permission denied is returned.  If enabled user (user, tenant and organization are ENABLED),
   * and no specific tenant is identified, preference is given to tenant the user last logged into.
   */
  email: string;
  /** The single sign on (SSO) identity provider (IDP), optional (default: CISCO). */
  provider: string;
  /** Skip checking token from IdP. */
  authBypass: boolean;
  /**
   * Identify a specific organization by name, optional.  If specified, the user identified by
   * email must be a member of a tenant that is a child of the organization identified by this name.
   * May not be specfied with tenant ID.  Must be specified with tenant name.
   */
  orgName: string;
  /**
   * Identify a specific tenant by name, optional.  If specified, the user identified by email must
   * be member of this tenant that must be a child of the organization identified by organization name.
   * May not be specfied with tenant ID. Must be specified with organization name.
   * To specify the default-tenant, set tenant name to the value of organization name.
   */
  tenantName: string;
  /**
   * Identify a specific tenant by external ID, optional.  If specified, the user identified by email
   * must be an enabled member of this tenant or an error is returned.  May not be specified with
   * organization name or tenant name.
   */
  tenantId: string;
  /**
   * Expiry, if specified, overrides the value the service creating the UserSessionToken would normally provide.
   * An example value of "now+2m" means "2 minutes past the time the UserSessionToken is created".  If empty,
   * the system value is used.  If set, any value that happens after the system value is rejected.
   */
  expiry: string;
  /**
   * A JWT named IdToken from OAUTH2 authentication sequence.  If 'auth_bypass' is false, mandatory and
   * must be considered soley (other user identifying parameters such as 'email' must be ignored).
   */
  idToken: string;
  /** Version of the EULA signed by the user. */
  eulaVersion: string;
}

/**
 * StartUserSessionResponse returns the token the user client must present for every request in this session.
 * If NewUser is true, OrgId, OrgName, TenantId and TenantName will be empty and the UserSessionToken can be
 * used only with NewOrg.
 */
export interface StartUserSessionResponse {
  /** Identifies the user for which the session was started. */
  email: string;
  /** The single sign on (SSO) identity provider (IDP). */
  provider: string;
  /** Identifies the organization in which the session was started. */
  orgId: string;
  /** The name of the organization in which the session was started. */
  orgName: string;
  /**
   * Identifies the tenant in which the session was started.
   * email and tenant_id uniquely identify a user and will be included as claims in UserSessionToken.
   * If tenant_id equals org_id, then this is the default tenant of this organization.
   */
  tenantId: string;
  /** The name of the tenant in which the session was started. */
  tenantName: string;
  /** Is true if this is a new user (not associated with any organization). */
  newUser: boolean;
  /** Is true if this user requires sign up. */
  requireSignUp: boolean;
  /** If true, the UI should redirect to a static html page */
  disableSignUp: boolean;
  /** Tokens that should be set in the cookie field of the header of the HTTP response sent back to the client. */
  tokens: Token[];
}

/**
 * RefreshUserSessionRequest replaces the UserSessionToken associated with the session with one
 * that has a later expiry.  Requires a valid UserSessionToken that is not expired.
 * The expiry is calculated at the time the UserSessionToken creator creates the new token.
 */
export interface RefreshUserSessionRequest {
  /**
   * Expiry, if specified, overrides the value the service creating the UserSessionToken would normally provide.
   * An example value of "now+2m" means "2 minutes past the time the UserSessionToken is created".  If empty,
   * the system value is used.  If set, any value that happens after the system value is rejected.
   */
  expiry: string;
}

/** The response to a request for a list of users. */
export interface UsersResponse {
  /** A list of users. */
  users: User[];
}

/** GetUserSessionRequest allows a user to get information on the current session. */
export interface GetUserSessionRequest {
}

/** DeleteUserSessionRequest allows a user to delete current session. */
export interface DeleteUserSessionRequest {
}

/** GetUserSessionResponse includes information about the user of the current session. */
export interface GetUserSessionResponse {
  /** Identifies the organization of the session to which tenant belongs.  Empty if new user. */
  orgId: string;
  /** The name of the organization of the session to which tenant belongs.  Empty if new user. */
  orgName: string;
  /**
   * Identifies the tenant of the session to which the user belongs.  If tenant_id equals org_id,
   * then the session is in the context of the default-tenant of this organization.  Sessions
   * from default-tenants have different privileges than sessions from non-default tenants.
   * Empty if new user.
   */
  tenantId: string;
  /** The name of the tenant of the session to which the user belongs.  Empty if new user. */
  tenantName: string;
  /** Email of the authenticated user.  Globally unique. */
  email: string;
  /** The single sign on (SSO) identity provider (IDP). */
  provider: string;
  /** Full name of user from identity provider. */
  name: string;
  /** Is true if this is a new user (not associated with any organization and tenant). */
  newUser: boolean;
  /** Expiration timestamp of this user session. */
  expiry:
    | Date
    | undefined;
  /**
   * Is true and new user is true, this new user is allowed to sign up.
   *
   * @deprecated
   */
  allowSignUp: boolean;
  /** Last login time */
  loginTime:
    | Date
    | undefined;
  /** Information about the user of this session. */
  user:
    | User
    | undefined;
  /** @deprecated */
  preferences:
    | UserPreferences
    | undefined;
  /** Information about the user sign up (what version of Eula was accepted, etc). */
  signUp:
    | SignUp
    | undefined;
  /**
   * Contexts in which user is enabled in an enabled tenant in an enabled org.
   *
   * @deprecated
   */
  contexts: UserContext[];
  /** This user is required to sign up if true. */
  requireSignUp: boolean;
  /** Sign up is disabled for this user */
  disableSignUp: boolean;
}

/**
 * The request for a list of users matching the given search criteria.
 *
 * INTERNAL
 *
 * GetUsersRequest allows an admin to get information on tenant users matching the given
 * search criteria.
 */
export interface GetUsersRequest {
  /**
   * Only return users with these emails.
   * If set, the roles and enabled query parameters are ignored.
   */
  emails: string[];
  /**
   * The single sign on (SSO) identity provider (IDP) of emails, optional.
   * If not specified, provider value is not considered when deciding which users to return.
   */
  provider: string;
  /**
   * Identify a specific tenant, optional.  If not specified, the tenant associated with the
   * current user session making the request is used.  Only users belonging to the resulting
   * tenant are returned.  This parameter allows an admin of the default-tenant to get users
   * of a non-default tenant of the organization.
   */
  tenantId: string;
  /**
   * Only return users that are administratively enabled, otherwise include disabled users as well.
   *
   * INTERNAL
   *
   * The tenant and organization to which the users belong must be enabled regardless of the value of enabled.
   */
  enabled: boolean;
  /** Only return users with specific roles. */
  roles: UserRole[];
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** The request for a specific user. */
export interface GetUserRequest {
  /** The user id or email address. */
  userId: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/**
 * The request to add one or more users.
 *
 * INTERNAL
 *
 * SetUsersRequest creates or updates the specified users in the context of this tenant.
 */
export interface SetUsersRequest {
  /**
   * Identify a specific tenant, optional.  If not specified, the tenant associated with the
   * current user session making the request is used.  Users will be created or updated in
   * the resulting tenant.  This parameter allows an admin of the default-tenant to create
   * or update users in a non-default tenant of the organization.
   */
  tenantId: string;
  /**
   * A list of users to be added.
   *
   * INTERNAL
   *
   * A list of users, each one representing a user to be created or updated in the tenant.
   */
  users: User[];
}

/** The request to delete a specific user. */
export interface DeleteUserRequest {
  /** The user id or email address. */
  userId: string;
}

/** DeleteUsersRequest deletes users. */
export interface DeleteUsersRequest {
  /** The ids of users to be deleted. */
  userId: string[];
}

/**
 * Get subscriptions.
 *
 * This asks for a summary of the subscriptions claimed by the caller's organization.
 */
export interface GetSubscriptionsRequest {
}

/**
 * Subscriptions response.
 *
 * This summarizes the resulting subscriptions after the requested operation on those subscriptions.
 */
export interface SubscriptionsResponse {
  subscriptions: SubscriptionSummary[];
}

/**
 * Claim subscriptions.
 *
 * This is used to specify subscriptions to claim or unclaim.
 */
export interface ClaimSubscriptionsRequest {
  /** The subscription reference identifiers of the subscriptions to claim or unclaim. */
  refId: string[];
}

/** Subscription summary. */
export interface SubscriptionSummary {
  /** The subscription reference identifier. */
  refId: string;
  /** The product ID granted by the subscription. */
  pid: SbpPid;
  /** The quantity of the product ID granted by the subscription. */
  quantity: number;
  /** Entitlement level of the product ID granted by the subscription. */
  level: EntitlementLevel;
  /** Device model of the product ID granted by the subscription. */
  model: SbpModel;
  /** Description of the product ID granted by the subscription. */
  description: number;
  /** When the subscription validity period starts. */
  starts:
    | Date
    | undefined;
  /** Deadline of when the subscription must be renewed in order to remain valid. */
  ends: Date | undefined;
}

/**
 * Get entitlements.
 *
 * This asks for a summary of an organization's entitlements usage and compliance.
 */
export interface GetEntitlementsRequest {
  /**
   * Calculate the entitlement usage and compliance based on the needs of the organization's
   * blueprints (as opposed to the devices actually bound to the organization's fabrics).
   */
  blueprint: boolean;
}

/**
 * Entitlements response.
 *
 * This is a summary of the entitlements of the caller's organization and their usage.
 */
export interface EntitlementsResponse {
  /** Summary of an organization's entitlement usage per entitlement level sorted in decreasing level. */
  usages: EntitlementUsage[];
}

/**
 * Entitlement uage.
 *
 * This is a summary of the organization's entitlement usage at a given level.
 */
export interface EntitlementUsage {
  /**
   * An entitlement is a grant from a subscription to use a 'quantity' of devices
   * of a certain 'model' in a fabric of this entitlement 'level'.
   */
  level: EntitlementLevel;
  /**
   * Number entitlements at this level available for use after allocation regardless of model.
   * available_balance = total_entitled - allocated_to - lent
   */
  availableBalance: number;
  /** Number of entitlements granted at this level regardless of model. */
  totalEntitled: number;
  /**
   * Number entitlements of this level bound to fabrics at this level regardless of model.
   * allocated_to = sum(fabric_usage.allocated).
   */
  allocatedTo: number;
  /** Number of entitlements borrowed from a higher level used in fabrics at this level. */
  borrowed: EntitlementsShared[];
  /** Number of entitlements of this level lent to fabrics at a lower level. */
  lent: EntitlementsShared[];
  /** Summarizes how entitlements are used by each fabric at the given level. */
  fabricUsage: EntitlementFabricUsage[];
}

/**
 * Entitlements shared.
 *
 * This is a summary of entitlements lent or borrowed to or from a given entitlement level.
 */
export interface EntitlementsShared {
  /** Level from which entitlements are lent or borrowed. */
  level: EntitlementLevel;
  /** Count of entitlements lent or borrowed. */
  count: number;
}

/**
 * Fabric usage of entitlements.
 *
 * This summarizes a fabric's usage of entitlements.
 */
export interface EntitlementFabricUsage {
  /** Identifier of fabric using entitlements. */
  fabricId: string;
  /** Name of fabric using entitlements. */
  fabricName: string;
  /** Level of fabric using entitlements. */
  level: EntitlementLevel;
  /** Usage grouped by device model. */
  usage: EntitlementModelUsage[];
}

/**
 * Fabric usage of models.
 *
 * This is a summary of a fabric's usage of entitlements of certain device model.
 */
export interface EntitlementModelUsage {
  /** Model of device. */
  model: SbpModel;
  /**
   * Total number of devices of this model bound to this fabric.
   * Total Bound = Allocated + Borrowed + Needed
   */
  totalBound: number;
  /** Number of entitlements of this model of this level bound to this fabric. */
  allocated: number;
  /** Number of entitlements of this model borrowed from a higher level bound to this fabric. */
  borrowed: number;
  /** Number of devices of this model bound to this fabric that are not covered by any subscription. */
  needed: number;
  /** Whether or not this fabric is compliant with number of devices of this model used. */
  compliant: boolean;
}

/**
 * A list of tenants belonging to an organization.
 *
 * INTERNAL
 *
 * Response to requests with list of tenants.
 * FIXME: models.Tenant should only be Name, Description, Tags, Annotations, Enabled, Metadata, Asn;
 *        models.ProvisionTenants should lose 'set_tenants' and 'del_tenants'.
 *        configd should use only models.ProvisionTenants.
 *        Only brig should use models.Tenant (is the only one creating/updating the tenant themselves).
 */
export interface TenantsResponse {
  /** List of up to date tenants in response to requests with list of tenants. */
  tenants: Tenant[];
}

/**
 * NewOrgRequest allows a new user to add a new organization, default-tenant of that new organization and
 * first admin of that default-tenant.  The user making the NewOrgRequest must be new to the system and
 * user_preferences indicates the UserPreferences of this new user.  If an org with this name already exists,
 * the entire operation is aborted and an error is returned.  The UserAccessToken sent with the request
 * must have values for email and sub claims, but must not have values for org_id or tenant_id claims
 * (where the value of the email claim is the email of the new user).  The UserAccessToken returned with
 * the response will have values for email, sub, org_id and tenant_id claims.
 */
export interface NewOrgRequest {
  /** Information about the company and the indvidual signing up. */
  signUp:
    | SignUp
    | undefined;
  /** User preferences of the first admin user of new default-tenant. */
  userPreferences: UserPreferences | undefined;
}

/** Response to NewOrgRequest. */
export interface NewOrgResponse {
  /** The globally unique identifier of the new organization just created. */
  id: string;
  /** Description of the first admin user of the new default-tenant. */
  user:
    | User
    | undefined;
  /**
   * User preferences of the first admin user of the new default-tenant.
   *
   * @deprecated
   */
  userPreferences: UserPreferences | undefined;
}

/** AddTenantRequest allows an admin of the default-tenant (only) to add a non-default tenant. */
export interface AddTenantRequest {
  /** Tenant specification. */
  tenant:
    | Tenant
    | undefined;
  /** Description of first admin user of the new non-default tenant.  User must be enabled with role admin. */
  user: User | undefined;
}

/** Response to AddTenantRequest. */
export interface AddTenantResponse {
  /** Tenant specification. */
  tenant:
    | Tenant
    | undefined;
  /** Description of first admin user of the add non-default tenant. */
  user: User | undefined;
}

/** UpdateTenantRequest allows admin of tenant or default-tenant to update tenant properties. */
export interface UpdateTenantRequest {
  /** The tenant id or name. */
  tenantId: string;
  /** Tenant specification. */
  tenant: Tenant | undefined;
}

/** UpdateTenantsRequest allows admin of the default-tenant updates properties of the one or more tenants. */
export interface UpdateTenantsRequest {
  /** Tenants specifications. */
  tenants: Tenant[];
}

/**
 * GetTenantsRequest allows an admin of the default-tenant (only) to get information on org tenants matching
 * the given search criteria (including the default-tenant).
 */
export interface GetTenantsRequest {
  /** Only return tenants with these names.  If not specified, do not discriminate on tenants based on name. */
  names: string[];
  /** Only return tenants that are administratively enabled. */
  enabled: boolean;
}

/**
 * SwitchTenantRequest allows a user to switch session context to a different tenant.
 * Upon success, a new UserSessionToken is granted.
 */
export interface SwitchTenantRequest {
  /** Identifier of tenant to switch to. */
  tenantId: string;
  /**
   * Expiry, if specified, overrides the value the service creating the UserSessionToken would normally provide.
   * An example value of "now+2m" means "2 minutes past the time the UserSessionToken is created".  If empty,
   * the system value is used.  If set, any value that happens after the system value is rejected.
   */
  expiry: string;
}

/** DeleteTenantRequest allows an admin of the default-tenant to remove a tenant from the organization. */
export interface DeleteTenantRequest {
  /** ID or name of tenant to remove from organization. */
  tenantId: string;
}

/** DeleteTenantsRequest allows an admin of the default-tenant to remove tenants from the organization. */
export interface DeleteTenantsRequest {
  /** A list of tenant IDs, each one representing a tenant to be removed from the organization. */
  tenantId: string[];
}

/** StartAgentSessionRequest requests the start of a session for the switch identified by switch_id. */
export interface StartAgentSessionRequest {
  /**
   * Identifies a switch, mandatory.
   * FIXME: TORT-1320: replace this with SUDI cert and get serial number from SUDI cert.
   */
  serialNumber: string;
  /**
   * Identifies a switch, mandatory.
   * FIXME: TORT-1320: remove this and get switch ID from SUDI cert.
   */
  switchId: string;
  /**
   * Expiry, if specified, overrides the value the service creating the AgentSessionToken would normally provide.
   * An example value of "now+2m" means "2 minutes past the time the AgentSessionToken is created".  If empty,
   * the system value is used.  If set, any value that happens after the system value is rejected.
   */
  expiry: string;
}

/**
 * RefreshAgentSessionRequest replaces the AgentSessionToken associated with the session with one
 * that has a later expiry.  Requires a valid AgentSessionToken that is not expired.
 * The expiry is calculated at the time the AgentSessionToken creator creates the new token.
 */
export interface RefreshAgentSessionRequest {
  /**
   * Expiry, if specified, overrides the value the service creating the AgentSessionToken would normally provide.
   * An example value of "now+2m" means "2 minutes past the time the AgentSessionToken is created".  If empty,
   * the system value is used.  If set, any value that happens after the system value is rejected.
   */
  expiry: string;
}

/** StartAgentSessionResponse returns the tokens the switch client must present for every request in this session. */
export interface StartAgentSessionResponse {
  /** Identifies the switch for which the session was started. */
  serialNumber: string;
  /** Identifies the switch for which the session was started. */
  switchId: string;
  /**
   * Identifies the organization to which the switch belongs.
   * Empty value indicates switch has not been bound to an organization.
   */
  orgId: string;
  /**
   * Identifies the fabric_id to which this switch is tied.
   * Empty value indicates switch has not been assigned to a fabric.
   */
  fabricId: string;
  /** The boolean indicate whether the switch is in parking lot org or not */
  unclaimed: boolean;
  /** The boolean indicate whether the switch is bound or not */
  unbound: boolean;
  /** Tokens that should be set in the cookie field of the header of the HTTP response sent back to the switch. */
  tokens: Token[];
}

/** GetAgentSessionRequest allows a switch agent to get information on the current session. */
export interface GetAgentSessionRequest {
}

/** DeleteAgentSessionRequest allows a agent to delete current session. */
export interface DeleteAgentSessionRequest {
}

/** GetAgentSessionResponse includes information about the switch agent of the current session. */
export interface GetAgentSessionResponse {
  /** Identifies the switch of this session. */
  serialNumber: string;
  /** Identifies the switch of this session. */
  switchId: string;
  /**
   * Identifies the organization to which the switch belongs.
   * Empty value indicates switch has not been bound to an organization.
   */
  orgId: string;
  /**
   * The name of the fabric to which the switch is bound.
   * Empty value indicates switch has not been assigned to a fabric.
   */
  fabricId: string;
  /** The boolean indicate whether the switch is in parking lot org or not */
  unclaimed: boolean;
  /** The boolean indicate whether the switch is bound or not */
  unbound: boolean;
  /** Expiration timestamp of this agent session. */
  expiry: Date | undefined;
}

export interface UpdateUser {
  /** The user role. */
  role: UserRole;
  /** Enable/disable the user. */
  enabled: boolean;
  /** A list of user-defined labels that can be used for grouping and filtering Users. */
  labels: string[];
}

/** The request to update a specific user. */
export interface UpdateUserRequest {
  /** The user id or email address. */
  userId: string;
  /** The updated user definition. */
  user: UpdateUser | undefined;
}

/** The request to add a new organization. */
export interface AddOrgRequest {
  /** Name of the company to be added. */
  orgName: string;
}

/** Response to AddOrgRequest. */
export interface AddOrgResponse {
  /** The globally unique identifier of the new organization just created. */
  orgId: string;
  /** Description of the first admin user of the new default-tenant. */
  user: User | undefined;
}

/**
 * Request to delete an empty organization.
 *
 * See defintion of the DeleteOrg endpoint for details.
 */
export interface DeleteOrgRequest {
}

function createBaseStartUserSessionRequest(): StartUserSessionRequest {
  return {
    email: "",
    provider: "",
    authBypass: false,
    orgName: "",
    tenantName: "",
    tenantId: "",
    expiry: "",
    idToken: "",
    eulaVersion: "",
  };
}

export const StartUserSessionRequest = {
  encode(message: StartUserSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.provider !== "") {
      writer.uint32(18).string(message.provider);
    }
    if (message.authBypass !== false) {
      writer.uint32(24).bool(message.authBypass);
    }
    if (message.orgName !== "") {
      writer.uint32(42).string(message.orgName);
    }
    if (message.tenantName !== "") {
      writer.uint32(50).string(message.tenantName);
    }
    if (message.tenantId !== "") {
      writer.uint32(58).string(message.tenantId);
    }
    if (message.expiry !== "") {
      writer.uint32(66).string(message.expiry);
    }
    if (message.idToken !== "") {
      writer.uint32(74).string(message.idToken);
    }
    if (message.eulaVersion !== "") {
      writer.uint32(82).string(message.eulaVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartUserSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartUserSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.provider = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.authBypass = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.orgName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tenantName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.expiry = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.idToken = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.eulaVersion = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartUserSessionRequest {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      provider: isSet(object.provider) ? globalThis.String(object.provider) : "",
      authBypass: isSet(object.authBypass) ? globalThis.Boolean(object.authBypass) : false,
      orgName: isSet(object.orgName) ? globalThis.String(object.orgName) : "",
      tenantName: isSet(object.tenantName) ? globalThis.String(object.tenantName) : "",
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      expiry: isSet(object.expiry) ? globalThis.String(object.expiry) : "",
      idToken: isSet(object.idToken) ? globalThis.String(object.idToken) : "",
      eulaVersion: isSet(object.eulaVersion) ? globalThis.String(object.eulaVersion) : "",
    };
  },

  toJSON(message: StartUserSessionRequest): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.provider !== "") {
      obj.provider = message.provider;
    }
    if (message.authBypass !== false) {
      obj.authBypass = message.authBypass;
    }
    if (message.orgName !== "") {
      obj.orgName = message.orgName;
    }
    if (message.tenantName !== "") {
      obj.tenantName = message.tenantName;
    }
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.expiry !== "") {
      obj.expiry = message.expiry;
    }
    if (message.idToken !== "") {
      obj.idToken = message.idToken;
    }
    if (message.eulaVersion !== "") {
      obj.eulaVersion = message.eulaVersion;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartUserSessionRequest>, I>>(base?: I): StartUserSessionRequest {
    return StartUserSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartUserSessionRequest>, I>>(object: I): StartUserSessionRequest {
    const message = createBaseStartUserSessionRequest();
    message.email = object.email ?? "";
    message.provider = object.provider ?? "";
    message.authBypass = object.authBypass ?? false;
    message.orgName = object.orgName ?? "";
    message.tenantName = object.tenantName ?? "";
    message.tenantId = object.tenantId ?? "";
    message.expiry = object.expiry ?? "";
    message.idToken = object.idToken ?? "";
    message.eulaVersion = object.eulaVersion ?? "";
    return message;
  },
};

function createBaseStartUserSessionResponse(): StartUserSessionResponse {
  return {
    email: "",
    provider: "",
    orgId: "",
    orgName: "",
    tenantId: "",
    tenantName: "",
    newUser: false,
    requireSignUp: false,
    disableSignUp: false,
    tokens: [],
  };
}

export const StartUserSessionResponse = {
  encode(message: StartUserSessionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.provider !== "") {
      writer.uint32(18).string(message.provider);
    }
    if (message.orgId !== "") {
      writer.uint32(26).string(message.orgId);
    }
    if (message.orgName !== "") {
      writer.uint32(34).string(message.orgName);
    }
    if (message.tenantId !== "") {
      writer.uint32(42).string(message.tenantId);
    }
    if (message.tenantName !== "") {
      writer.uint32(50).string(message.tenantName);
    }
    if (message.newUser !== false) {
      writer.uint32(56).bool(message.newUser);
    }
    if (message.requireSignUp !== false) {
      writer.uint32(64).bool(message.requireSignUp);
    }
    if (message.disableSignUp !== false) {
      writer.uint32(72).bool(message.disableSignUp);
    }
    for (const v of message.tokens) {
      Token.encode(v!, writer.uint32(402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartUserSessionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartUserSessionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.provider = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.orgName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tenantName = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.newUser = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.requireSignUp = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.disableSignUp = reader.bool();
          continue;
        case 50:
          if (tag !== 402) {
            break;
          }

          message.tokens.push(Token.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartUserSessionResponse {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      provider: isSet(object.provider) ? globalThis.String(object.provider) : "",
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      orgName: isSet(object.orgName) ? globalThis.String(object.orgName) : "",
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      tenantName: isSet(object.tenantName) ? globalThis.String(object.tenantName) : "",
      newUser: isSet(object.newUser) ? globalThis.Boolean(object.newUser) : false,
      requireSignUp: isSet(object.requireSignUp) ? globalThis.Boolean(object.requireSignUp) : false,
      disableSignUp: isSet(object.disableSignUp) ? globalThis.Boolean(object.disableSignUp) : false,
      tokens: globalThis.Array.isArray(object?.tokens) ? object.tokens.map((e: any) => Token.fromJSON(e)) : [],
    };
  },

  toJSON(message: StartUserSessionResponse): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.provider !== "") {
      obj.provider = message.provider;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.orgName !== "") {
      obj.orgName = message.orgName;
    }
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.tenantName !== "") {
      obj.tenantName = message.tenantName;
    }
    if (message.newUser !== false) {
      obj.newUser = message.newUser;
    }
    if (message.requireSignUp !== false) {
      obj.requireSignUp = message.requireSignUp;
    }
    if (message.disableSignUp !== false) {
      obj.disableSignUp = message.disableSignUp;
    }
    if (message.tokens?.length) {
      obj.tokens = message.tokens.map((e) => Token.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartUserSessionResponse>, I>>(base?: I): StartUserSessionResponse {
    return StartUserSessionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartUserSessionResponse>, I>>(object: I): StartUserSessionResponse {
    const message = createBaseStartUserSessionResponse();
    message.email = object.email ?? "";
    message.provider = object.provider ?? "";
    message.orgId = object.orgId ?? "";
    message.orgName = object.orgName ?? "";
    message.tenantId = object.tenantId ?? "";
    message.tenantName = object.tenantName ?? "";
    message.newUser = object.newUser ?? false;
    message.requireSignUp = object.requireSignUp ?? false;
    message.disableSignUp = object.disableSignUp ?? false;
    message.tokens = object.tokens?.map((e) => Token.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRefreshUserSessionRequest(): RefreshUserSessionRequest {
  return { expiry: "" };
}

export const RefreshUserSessionRequest = {
  encode(message: RefreshUserSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.expiry !== "") {
      writer.uint32(10).string(message.expiry);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefreshUserSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshUserSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expiry = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefreshUserSessionRequest {
    return { expiry: isSet(object.expiry) ? globalThis.String(object.expiry) : "" };
  },

  toJSON(message: RefreshUserSessionRequest): unknown {
    const obj: any = {};
    if (message.expiry !== "") {
      obj.expiry = message.expiry;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefreshUserSessionRequest>, I>>(base?: I): RefreshUserSessionRequest {
    return RefreshUserSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefreshUserSessionRequest>, I>>(object: I): RefreshUserSessionRequest {
    const message = createBaseRefreshUserSessionRequest();
    message.expiry = object.expiry ?? "";
    return message;
  },
};

function createBaseUsersResponse(): UsersResponse {
  return { users: [] };
}

export const UsersResponse = {
  encode(message: UsersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      User.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UsersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUsersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 21:
          if (tag !== 170) {
            break;
          }

          message.users.push(User.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UsersResponse {
    return { users: globalThis.Array.isArray(object?.users) ? object.users.map((e: any) => User.fromJSON(e)) : [] };
  },

  toJSON(message: UsersResponse): unknown {
    const obj: any = {};
    if (message.users?.length) {
      obj.users = message.users.map((e) => User.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UsersResponse>, I>>(base?: I): UsersResponse {
    return UsersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UsersResponse>, I>>(object: I): UsersResponse {
    const message = createBaseUsersResponse();
    message.users = object.users?.map((e) => User.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetUserSessionRequest(): GetUserSessionRequest {
  return {};
}

export const GetUserSessionRequest = {
  encode(_: GetUserSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetUserSessionRequest {
    return {};
  },

  toJSON(_: GetUserSessionRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserSessionRequest>, I>>(base?: I): GetUserSessionRequest {
    return GetUserSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserSessionRequest>, I>>(_: I): GetUserSessionRequest {
    const message = createBaseGetUserSessionRequest();
    return message;
  },
};

function createBaseDeleteUserSessionRequest(): DeleteUserSessionRequest {
  return {};
}

export const DeleteUserSessionRequest = {
  encode(_: DeleteUserSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteUserSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeleteUserSessionRequest {
    return {};
  },

  toJSON(_: DeleteUserSessionRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteUserSessionRequest>, I>>(base?: I): DeleteUserSessionRequest {
    return DeleteUserSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteUserSessionRequest>, I>>(_: I): DeleteUserSessionRequest {
    const message = createBaseDeleteUserSessionRequest();
    return message;
  },
};

function createBaseGetUserSessionResponse(): GetUserSessionResponse {
  return {
    orgId: "",
    orgName: "",
    tenantId: "",
    tenantName: "",
    email: "",
    provider: "",
    name: "",
    newUser: false,
    expiry: undefined,
    allowSignUp: false,
    loginTime: undefined,
    user: undefined,
    preferences: undefined,
    signUp: undefined,
    contexts: [],
    requireSignUp: false,
    disableSignUp: false,
  };
}

export const GetUserSessionResponse = {
  encode(message: GetUserSessionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    if (message.orgName !== "") {
      writer.uint32(18).string(message.orgName);
    }
    if (message.tenantId !== "") {
      writer.uint32(26).string(message.tenantId);
    }
    if (message.tenantName !== "") {
      writer.uint32(34).string(message.tenantName);
    }
    if (message.email !== "") {
      writer.uint32(42).string(message.email);
    }
    if (message.provider !== "") {
      writer.uint32(50).string(message.provider);
    }
    if (message.name !== "") {
      writer.uint32(58).string(message.name);
    }
    if (message.newUser !== false) {
      writer.uint32(64).bool(message.newUser);
    }
    if (message.expiry !== undefined) {
      Timestamp.encode(toTimestamp(message.expiry), writer.uint32(74).fork()).ldelim();
    }
    if (message.allowSignUp !== false) {
      writer.uint32(80).bool(message.allowSignUp);
    }
    if (message.loginTime !== undefined) {
      Timestamp.encode(toTimestamp(message.loginTime), writer.uint32(90).fork()).ldelim();
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(170).fork()).ldelim();
    }
    if (message.preferences !== undefined) {
      UserPreferences.encode(message.preferences, writer.uint32(178).fork()).ldelim();
    }
    if (message.signUp !== undefined) {
      SignUp.encode(message.signUp, writer.uint32(186).fork()).ldelim();
    }
    for (const v of message.contexts) {
      UserContext.encode(v!, writer.uint32(194).fork()).ldelim();
    }
    if (message.requireSignUp !== false) {
      writer.uint32(200).bool(message.requireSignUp);
    }
    if (message.disableSignUp !== false) {
      writer.uint32(208).bool(message.disableSignUp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserSessionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserSessionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tenantName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.email = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.provider = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.name = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.newUser = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.expiry = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.allowSignUp = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.loginTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.preferences = UserPreferences.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.signUp = SignUp.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.contexts.push(UserContext.decode(reader, reader.uint32()));
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.requireSignUp = reader.bool();
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.disableSignUp = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserSessionResponse {
    return {
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      orgName: isSet(object.orgName) ? globalThis.String(object.orgName) : "",
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      tenantName: isSet(object.tenantName) ? globalThis.String(object.tenantName) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      provider: isSet(object.provider) ? globalThis.String(object.provider) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      newUser: isSet(object.newUser) ? globalThis.Boolean(object.newUser) : false,
      expiry: isSet(object.expiry) ? fromJsonTimestamp(object.expiry) : undefined,
      allowSignUp: isSet(object.allowSignUp) ? globalThis.Boolean(object.allowSignUp) : false,
      loginTime: isSet(object.loginTime) ? fromJsonTimestamp(object.loginTime) : undefined,
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      preferences: isSet(object.preferences) ? UserPreferences.fromJSON(object.preferences) : undefined,
      signUp: isSet(object.signUp) ? SignUp.fromJSON(object.signUp) : undefined,
      contexts: globalThis.Array.isArray(object?.contexts)
        ? object.contexts.map((e: any) => UserContext.fromJSON(e))
        : [],
      requireSignUp: isSet(object.requireSignUp) ? globalThis.Boolean(object.requireSignUp) : false,
      disableSignUp: isSet(object.disableSignUp) ? globalThis.Boolean(object.disableSignUp) : false,
    };
  },

  toJSON(message: GetUserSessionResponse): unknown {
    const obj: any = {};
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.orgName !== "") {
      obj.orgName = message.orgName;
    }
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.tenantName !== "") {
      obj.tenantName = message.tenantName;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.provider !== "") {
      obj.provider = message.provider;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.newUser !== false) {
      obj.newUser = message.newUser;
    }
    if (message.expiry !== undefined) {
      obj.expiry = message.expiry.toISOString();
    }
    if (message.allowSignUp !== false) {
      obj.allowSignUp = message.allowSignUp;
    }
    if (message.loginTime !== undefined) {
      obj.loginTime = message.loginTime.toISOString();
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.preferences !== undefined) {
      obj.preferences = UserPreferences.toJSON(message.preferences);
    }
    if (message.signUp !== undefined) {
      obj.signUp = SignUp.toJSON(message.signUp);
    }
    if (message.contexts?.length) {
      obj.contexts = message.contexts.map((e) => UserContext.toJSON(e));
    }
    if (message.requireSignUp !== false) {
      obj.requireSignUp = message.requireSignUp;
    }
    if (message.disableSignUp !== false) {
      obj.disableSignUp = message.disableSignUp;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserSessionResponse>, I>>(base?: I): GetUserSessionResponse {
    return GetUserSessionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserSessionResponse>, I>>(object: I): GetUserSessionResponse {
    const message = createBaseGetUserSessionResponse();
    message.orgId = object.orgId ?? "";
    message.orgName = object.orgName ?? "";
    message.tenantId = object.tenantId ?? "";
    message.tenantName = object.tenantName ?? "";
    message.email = object.email ?? "";
    message.provider = object.provider ?? "";
    message.name = object.name ?? "";
    message.newUser = object.newUser ?? false;
    message.expiry = object.expiry ?? undefined;
    message.allowSignUp = object.allowSignUp ?? false;
    message.loginTime = object.loginTime ?? undefined;
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.preferences = (object.preferences !== undefined && object.preferences !== null)
      ? UserPreferences.fromPartial(object.preferences)
      : undefined;
    message.signUp = (object.signUp !== undefined && object.signUp !== null)
      ? SignUp.fromPartial(object.signUp)
      : undefined;
    message.contexts = object.contexts?.map((e) => UserContext.fromPartial(e)) || [];
    message.requireSignUp = object.requireSignUp ?? false;
    message.disableSignUp = object.disableSignUp ?? false;
    return message;
  },
};

function createBaseGetUsersRequest(): GetUsersRequest {
  return { emails: [], provider: "", tenantId: "", enabled: false, roles: [], includeMetadata: false };
}

export const GetUsersRequest = {
  encode(message: GetUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.emails) {
      writer.uint32(10).string(v!);
    }
    if (message.provider !== "") {
      writer.uint32(18).string(message.provider);
    }
    if (message.tenantId !== "") {
      writer.uint32(26).string(message.tenantId);
    }
    if (message.enabled !== false) {
      writer.uint32(32).bool(message.enabled);
    }
    writer.uint32(42).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.includeMetadata !== false) {
      writer.uint32(48).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.emails.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.provider = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 5:
          if (tag === 40) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUsersRequest {
    return {
      emails: globalThis.Array.isArray(object?.emails) ? object.emails.map((e: any) => globalThis.String(e)) : [],
      provider: isSet(object.provider) ? globalThis.String(object.provider) : "",
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      roles: globalThis.Array.isArray(object?.roles) ? object.roles.map((e: any) => userRoleFromJSON(e)) : [],
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetUsersRequest): unknown {
    const obj: any = {};
    if (message.emails?.length) {
      obj.emails = message.emails;
    }
    if (message.provider !== "") {
      obj.provider = message.provider;
    }
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.roles?.length) {
      obj.roles = message.roles.map((e) => userRoleToJSON(e));
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUsersRequest>, I>>(base?: I): GetUsersRequest {
    return GetUsersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUsersRequest>, I>>(object: I): GetUsersRequest {
    const message = createBaseGetUsersRequest();
    message.emails = object.emails?.map((e) => e) || [];
    message.provider = object.provider ?? "";
    message.tenantId = object.tenantId ?? "";
    message.enabled = object.enabled ?? false;
    message.roles = object.roles?.map((e) => e) || [];
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseGetUserRequest(): GetUserRequest {
  return { userId: "", includeMetadata: false };
}

export const GetUserRequest = {
  encode(message: GetUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(16).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserRequest {
    return {
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetUserRequest): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserRequest>, I>>(base?: I): GetUserRequest {
    return GetUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserRequest>, I>>(object: I): GetUserRequest {
    const message = createBaseGetUserRequest();
    message.userId = object.userId ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseSetUsersRequest(): SetUsersRequest {
  return { tenantId: "", users: [] };
}

export const SetUsersRequest = {
  encode(message: SetUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    for (const v of message.users) {
      User.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.users.push(User.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetUsersRequest {
    return {
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      users: globalThis.Array.isArray(object?.users) ? object.users.map((e: any) => User.fromJSON(e)) : [],
    };
  },

  toJSON(message: SetUsersRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.users?.length) {
      obj.users = message.users.map((e) => User.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetUsersRequest>, I>>(base?: I): SetUsersRequest {
    return SetUsersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetUsersRequest>, I>>(object: I): SetUsersRequest {
    const message = createBaseSetUsersRequest();
    message.tenantId = object.tenantId ?? "";
    message.users = object.users?.map((e) => User.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteUserRequest(): DeleteUserRequest {
  return { userId: "" };
}

export const DeleteUserRequest = {
  encode(message: DeleteUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteUserRequest {
    return { userId: isSet(object.userId) ? globalThis.String(object.userId) : "" };
  },

  toJSON(message: DeleteUserRequest): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteUserRequest>, I>>(base?: I): DeleteUserRequest {
    return DeleteUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteUserRequest>, I>>(object: I): DeleteUserRequest {
    const message = createBaseDeleteUserRequest();
    message.userId = object.userId ?? "";
    return message;
  },
};

function createBaseDeleteUsersRequest(): DeleteUsersRequest {
  return { userId: [] };
}

export const DeleteUsersRequest = {
  encode(message: DeleteUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.userId) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteUsersRequest {
    return {
      userId: globalThis.Array.isArray(object?.userId) ? object.userId.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: DeleteUsersRequest): unknown {
    const obj: any = {};
    if (message.userId?.length) {
      obj.userId = message.userId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteUsersRequest>, I>>(base?: I): DeleteUsersRequest {
    return DeleteUsersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteUsersRequest>, I>>(object: I): DeleteUsersRequest {
    const message = createBaseDeleteUsersRequest();
    message.userId = object.userId?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetSubscriptionsRequest(): GetSubscriptionsRequest {
  return {};
}

export const GetSubscriptionsRequest = {
  encode(_: GetSubscriptionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSubscriptionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSubscriptionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetSubscriptionsRequest {
    return {};
  },

  toJSON(_: GetSubscriptionsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSubscriptionsRequest>, I>>(base?: I): GetSubscriptionsRequest {
    return GetSubscriptionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSubscriptionsRequest>, I>>(_: I): GetSubscriptionsRequest {
    const message = createBaseGetSubscriptionsRequest();
    return message;
  },
};

function createBaseSubscriptionsResponse(): SubscriptionsResponse {
  return { subscriptions: [] };
}

export const SubscriptionsResponse = {
  encode(message: SubscriptionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.subscriptions) {
      SubscriptionSummary.encode(v!, writer.uint32(802).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubscriptionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 100:
          if (tag !== 802) {
            break;
          }

          message.subscriptions.push(SubscriptionSummary.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubscriptionsResponse {
    return {
      subscriptions: globalThis.Array.isArray(object?.subscriptions)
        ? object.subscriptions.map((e: any) => SubscriptionSummary.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SubscriptionsResponse): unknown {
    const obj: any = {};
    if (message.subscriptions?.length) {
      obj.subscriptions = message.subscriptions.map((e) => SubscriptionSummary.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionsResponse>, I>>(base?: I): SubscriptionsResponse {
    return SubscriptionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubscriptionsResponse>, I>>(object: I): SubscriptionsResponse {
    const message = createBaseSubscriptionsResponse();
    message.subscriptions = object.subscriptions?.map((e) => SubscriptionSummary.fromPartial(e)) || [];
    return message;
  },
};

function createBaseClaimSubscriptionsRequest(): ClaimSubscriptionsRequest {
  return { refId: [] };
}

export const ClaimSubscriptionsRequest = {
  encode(message: ClaimSubscriptionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.refId) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClaimSubscriptionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClaimSubscriptionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.refId.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClaimSubscriptionsRequest {
    return { refId: globalThis.Array.isArray(object?.refId) ? object.refId.map((e: any) => globalThis.String(e)) : [] };
  },

  toJSON(message: ClaimSubscriptionsRequest): unknown {
    const obj: any = {};
    if (message.refId?.length) {
      obj.refId = message.refId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClaimSubscriptionsRequest>, I>>(base?: I): ClaimSubscriptionsRequest {
    return ClaimSubscriptionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClaimSubscriptionsRequest>, I>>(object: I): ClaimSubscriptionsRequest {
    const message = createBaseClaimSubscriptionsRequest();
    message.refId = object.refId?.map((e) => e) || [];
    return message;
  },
};

function createBaseSubscriptionSummary(): SubscriptionSummary {
  return { refId: "", pid: 0, quantity: 0, level: 0, model: 0, description: 0, starts: undefined, ends: undefined };
}

export const SubscriptionSummary = {
  encode(message: SubscriptionSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.refId !== "") {
      writer.uint32(10).string(message.refId);
    }
    if (message.pid !== 0) {
      writer.uint32(16).int32(message.pid);
    }
    if (message.quantity !== 0) {
      writer.uint32(24).uint32(message.quantity);
    }
    if (message.level !== 0) {
      writer.uint32(32).int32(message.level);
    }
    if (message.model !== 0) {
      writer.uint32(40).int32(message.model);
    }
    if (message.description !== 0) {
      writer.uint32(48).uint32(message.description);
    }
    if (message.starts !== undefined) {
      Timestamp.encode(toTimestamp(message.starts), writer.uint32(58).fork()).ldelim();
    }
    if (message.ends !== undefined) {
      Timestamp.encode(toTimestamp(message.ends), writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubscriptionSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.refId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pid = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.quantity = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.level = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.model = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.description = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.starts = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.ends = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubscriptionSummary {
    return {
      refId: isSet(object.refId) ? globalThis.String(object.refId) : "",
      pid: isSet(object.pid) ? sbpPidFromJSON(object.pid) : 0,
      quantity: isSet(object.quantity) ? globalThis.Number(object.quantity) : 0,
      level: isSet(object.level) ? entitlementLevelFromJSON(object.level) : 0,
      model: isSet(object.model) ? sbpModelFromJSON(object.model) : 0,
      description: isSet(object.description) ? globalThis.Number(object.description) : 0,
      starts: isSet(object.starts) ? fromJsonTimestamp(object.starts) : undefined,
      ends: isSet(object.ends) ? fromJsonTimestamp(object.ends) : undefined,
    };
  },

  toJSON(message: SubscriptionSummary): unknown {
    const obj: any = {};
    if (message.refId !== "") {
      obj.refId = message.refId;
    }
    if (message.pid !== 0) {
      obj.pid = sbpPidToJSON(message.pid);
    }
    if (message.quantity !== 0) {
      obj.quantity = Math.round(message.quantity);
    }
    if (message.level !== 0) {
      obj.level = entitlementLevelToJSON(message.level);
    }
    if (message.model !== 0) {
      obj.model = sbpModelToJSON(message.model);
    }
    if (message.description !== 0) {
      obj.description = Math.round(message.description);
    }
    if (message.starts !== undefined) {
      obj.starts = message.starts.toISOString();
    }
    if (message.ends !== undefined) {
      obj.ends = message.ends.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionSummary>, I>>(base?: I): SubscriptionSummary {
    return SubscriptionSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubscriptionSummary>, I>>(object: I): SubscriptionSummary {
    const message = createBaseSubscriptionSummary();
    message.refId = object.refId ?? "";
    message.pid = object.pid ?? 0;
    message.quantity = object.quantity ?? 0;
    message.level = object.level ?? 0;
    message.model = object.model ?? 0;
    message.description = object.description ?? 0;
    message.starts = object.starts ?? undefined;
    message.ends = object.ends ?? undefined;
    return message;
  },
};

function createBaseGetEntitlementsRequest(): GetEntitlementsRequest {
  return { blueprint: false };
}

export const GetEntitlementsRequest = {
  encode(message: GetEntitlementsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.blueprint !== false) {
      writer.uint32(8).bool(message.blueprint);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetEntitlementsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetEntitlementsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.blueprint = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetEntitlementsRequest {
    return { blueprint: isSet(object.blueprint) ? globalThis.Boolean(object.blueprint) : false };
  },

  toJSON(message: GetEntitlementsRequest): unknown {
    const obj: any = {};
    if (message.blueprint !== false) {
      obj.blueprint = message.blueprint;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetEntitlementsRequest>, I>>(base?: I): GetEntitlementsRequest {
    return GetEntitlementsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetEntitlementsRequest>, I>>(object: I): GetEntitlementsRequest {
    const message = createBaseGetEntitlementsRequest();
    message.blueprint = object.blueprint ?? false;
    return message;
  },
};

function createBaseEntitlementsResponse(): EntitlementsResponse {
  return { usages: [] };
}

export const EntitlementsResponse = {
  encode(message: EntitlementsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.usages) {
      EntitlementUsage.encode(v!, writer.uint32(802).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitlementsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 100:
          if (tag !== 802) {
            break;
          }

          message.usages.push(EntitlementUsage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntitlementsResponse {
    return {
      usages: globalThis.Array.isArray(object?.usages)
        ? object.usages.map((e: any) => EntitlementUsage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EntitlementsResponse): unknown {
    const obj: any = {};
    if (message.usages?.length) {
      obj.usages = message.usages.map((e) => EntitlementUsage.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitlementsResponse>, I>>(base?: I): EntitlementsResponse {
    return EntitlementsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementsResponse>, I>>(object: I): EntitlementsResponse {
    const message = createBaseEntitlementsResponse();
    message.usages = object.usages?.map((e) => EntitlementUsage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEntitlementUsage(): EntitlementUsage {
  return { level: 0, availableBalance: 0, totalEntitled: 0, allocatedTo: 0, borrowed: [], lent: [], fabricUsage: [] };
}

export const EntitlementUsage = {
  encode(message: EntitlementUsage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.level !== 0) {
      writer.uint32(8).int32(message.level);
    }
    if (message.availableBalance !== 0) {
      writer.uint32(80).uint32(message.availableBalance);
    }
    if (message.totalEntitled !== 0) {
      writer.uint32(88).uint32(message.totalEntitled);
    }
    if (message.allocatedTo !== 0) {
      writer.uint32(96).uint32(message.allocatedTo);
    }
    for (const v of message.borrowed) {
      EntitlementsShared.encode(v!, writer.uint32(802).fork()).ldelim();
    }
    for (const v of message.lent) {
      EntitlementsShared.encode(v!, writer.uint32(810).fork()).ldelim();
    }
    for (const v of message.fabricUsage) {
      EntitlementFabricUsage.encode(v!, writer.uint32(8002).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitlementUsage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementUsage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.level = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.availableBalance = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.totalEntitled = reader.uint32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.allocatedTo = reader.uint32();
          continue;
        case 100:
          if (tag !== 802) {
            break;
          }

          message.borrowed.push(EntitlementsShared.decode(reader, reader.uint32()));
          continue;
        case 101:
          if (tag !== 810) {
            break;
          }

          message.lent.push(EntitlementsShared.decode(reader, reader.uint32()));
          continue;
        case 1000:
          if (tag !== 8002) {
            break;
          }

          message.fabricUsage.push(EntitlementFabricUsage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntitlementUsage {
    return {
      level: isSet(object.level) ? entitlementLevelFromJSON(object.level) : 0,
      availableBalance: isSet(object.availableBalance) ? globalThis.Number(object.availableBalance) : 0,
      totalEntitled: isSet(object.totalEntitled) ? globalThis.Number(object.totalEntitled) : 0,
      allocatedTo: isSet(object.allocatedTo) ? globalThis.Number(object.allocatedTo) : 0,
      borrowed: globalThis.Array.isArray(object?.borrowed)
        ? object.borrowed.map((e: any) => EntitlementsShared.fromJSON(e))
        : [],
      lent: globalThis.Array.isArray(object?.lent) ? object.lent.map((e: any) => EntitlementsShared.fromJSON(e)) : [],
      fabricUsage: globalThis.Array.isArray(object?.fabricUsage)
        ? object.fabricUsage.map((e: any) => EntitlementFabricUsage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EntitlementUsage): unknown {
    const obj: any = {};
    if (message.level !== 0) {
      obj.level = entitlementLevelToJSON(message.level);
    }
    if (message.availableBalance !== 0) {
      obj.availableBalance = Math.round(message.availableBalance);
    }
    if (message.totalEntitled !== 0) {
      obj.totalEntitled = Math.round(message.totalEntitled);
    }
    if (message.allocatedTo !== 0) {
      obj.allocatedTo = Math.round(message.allocatedTo);
    }
    if (message.borrowed?.length) {
      obj.borrowed = message.borrowed.map((e) => EntitlementsShared.toJSON(e));
    }
    if (message.lent?.length) {
      obj.lent = message.lent.map((e) => EntitlementsShared.toJSON(e));
    }
    if (message.fabricUsage?.length) {
      obj.fabricUsage = message.fabricUsage.map((e) => EntitlementFabricUsage.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitlementUsage>, I>>(base?: I): EntitlementUsage {
    return EntitlementUsage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementUsage>, I>>(object: I): EntitlementUsage {
    const message = createBaseEntitlementUsage();
    message.level = object.level ?? 0;
    message.availableBalance = object.availableBalance ?? 0;
    message.totalEntitled = object.totalEntitled ?? 0;
    message.allocatedTo = object.allocatedTo ?? 0;
    message.borrowed = object.borrowed?.map((e) => EntitlementsShared.fromPartial(e)) || [];
    message.lent = object.lent?.map((e) => EntitlementsShared.fromPartial(e)) || [];
    message.fabricUsage = object.fabricUsage?.map((e) => EntitlementFabricUsage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEntitlementsShared(): EntitlementsShared {
  return { level: 0, count: 0 };
}

export const EntitlementsShared = {
  encode(message: EntitlementsShared, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.level !== 0) {
      writer.uint32(8).int32(message.level);
    }
    if (message.count !== 0) {
      writer.uint32(16).uint32(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitlementsShared {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementsShared();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.level = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.count = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntitlementsShared {
    return {
      level: isSet(object.level) ? entitlementLevelFromJSON(object.level) : 0,
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
    };
  },

  toJSON(message: EntitlementsShared): unknown {
    const obj: any = {};
    if (message.level !== 0) {
      obj.level = entitlementLevelToJSON(message.level);
    }
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitlementsShared>, I>>(base?: I): EntitlementsShared {
    return EntitlementsShared.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementsShared>, I>>(object: I): EntitlementsShared {
    const message = createBaseEntitlementsShared();
    message.level = object.level ?? 0;
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseEntitlementFabricUsage(): EntitlementFabricUsage {
  return { fabricId: "", fabricName: "", level: 0, usage: [] };
}

export const EntitlementFabricUsage = {
  encode(message: EntitlementFabricUsage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.fabricName !== "") {
      writer.uint32(18).string(message.fabricName);
    }
    if (message.level !== 0) {
      writer.uint32(24).int32(message.level);
    }
    for (const v of message.usage) {
      EntitlementModelUsage.encode(v!, writer.uint32(802).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitlementFabricUsage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementFabricUsage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.level = reader.int32() as any;
          continue;
        case 100:
          if (tag !== 802) {
            break;
          }

          message.usage.push(EntitlementModelUsage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntitlementFabricUsage {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      fabricName: isSet(object.fabricName) ? globalThis.String(object.fabricName) : "",
      level: isSet(object.level) ? entitlementLevelFromJSON(object.level) : 0,
      usage: globalThis.Array.isArray(object?.usage)
        ? object.usage.map((e: any) => EntitlementModelUsage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EntitlementFabricUsage): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.fabricName !== "") {
      obj.fabricName = message.fabricName;
    }
    if (message.level !== 0) {
      obj.level = entitlementLevelToJSON(message.level);
    }
    if (message.usage?.length) {
      obj.usage = message.usage.map((e) => EntitlementModelUsage.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitlementFabricUsage>, I>>(base?: I): EntitlementFabricUsage {
    return EntitlementFabricUsage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementFabricUsage>, I>>(object: I): EntitlementFabricUsage {
    const message = createBaseEntitlementFabricUsage();
    message.fabricId = object.fabricId ?? "";
    message.fabricName = object.fabricName ?? "";
    message.level = object.level ?? 0;
    message.usage = object.usage?.map((e) => EntitlementModelUsage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEntitlementModelUsage(): EntitlementModelUsage {
  return { model: 0, totalBound: 0, allocated: 0, borrowed: 0, needed: 0, compliant: false };
}

export const EntitlementModelUsage = {
  encode(message: EntitlementModelUsage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.model !== 0) {
      writer.uint32(8).int32(message.model);
    }
    if (message.totalBound !== 0) {
      writer.uint32(80).uint32(message.totalBound);
    }
    if (message.allocated !== 0) {
      writer.uint32(88).uint32(message.allocated);
    }
    if (message.borrowed !== 0) {
      writer.uint32(96).uint32(message.borrowed);
    }
    if (message.needed !== 0) {
      writer.uint32(104).uint32(message.needed);
    }
    if (message.compliant !== false) {
      writer.uint32(112).bool(message.compliant);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitlementModelUsage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementModelUsage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.model = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.totalBound = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.allocated = reader.uint32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.borrowed = reader.uint32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.needed = reader.uint32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.compliant = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntitlementModelUsage {
    return {
      model: isSet(object.model) ? sbpModelFromJSON(object.model) : 0,
      totalBound: isSet(object.totalBound) ? globalThis.Number(object.totalBound) : 0,
      allocated: isSet(object.allocated) ? globalThis.Number(object.allocated) : 0,
      borrowed: isSet(object.borrowed) ? globalThis.Number(object.borrowed) : 0,
      needed: isSet(object.needed) ? globalThis.Number(object.needed) : 0,
      compliant: isSet(object.compliant) ? globalThis.Boolean(object.compliant) : false,
    };
  },

  toJSON(message: EntitlementModelUsage): unknown {
    const obj: any = {};
    if (message.model !== 0) {
      obj.model = sbpModelToJSON(message.model);
    }
    if (message.totalBound !== 0) {
      obj.totalBound = Math.round(message.totalBound);
    }
    if (message.allocated !== 0) {
      obj.allocated = Math.round(message.allocated);
    }
    if (message.borrowed !== 0) {
      obj.borrowed = Math.round(message.borrowed);
    }
    if (message.needed !== 0) {
      obj.needed = Math.round(message.needed);
    }
    if (message.compliant !== false) {
      obj.compliant = message.compliant;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitlementModelUsage>, I>>(base?: I): EntitlementModelUsage {
    return EntitlementModelUsage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementModelUsage>, I>>(object: I): EntitlementModelUsage {
    const message = createBaseEntitlementModelUsage();
    message.model = object.model ?? 0;
    message.totalBound = object.totalBound ?? 0;
    message.allocated = object.allocated ?? 0;
    message.borrowed = object.borrowed ?? 0;
    message.needed = object.needed ?? 0;
    message.compliant = object.compliant ?? false;
    return message;
  },
};

function createBaseTenantsResponse(): TenantsResponse {
  return { tenants: [] };
}

export const TenantsResponse = {
  encode(message: TenantsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tenants) {
      Tenant.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TenantsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTenantsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenants.push(Tenant.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TenantsResponse {
    return {
      tenants: globalThis.Array.isArray(object?.tenants) ? object.tenants.map((e: any) => Tenant.fromJSON(e)) : [],
    };
  },

  toJSON(message: TenantsResponse): unknown {
    const obj: any = {};
    if (message.tenants?.length) {
      obj.tenants = message.tenants.map((e) => Tenant.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TenantsResponse>, I>>(base?: I): TenantsResponse {
    return TenantsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TenantsResponse>, I>>(object: I): TenantsResponse {
    const message = createBaseTenantsResponse();
    message.tenants = object.tenants?.map((e) => Tenant.fromPartial(e)) || [];
    return message;
  },
};

function createBaseNewOrgRequest(): NewOrgRequest {
  return { signUp: undefined, userPreferences: undefined };
}

export const NewOrgRequest = {
  encode(message: NewOrgRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.signUp !== undefined) {
      SignUp.encode(message.signUp, writer.uint32(10).fork()).ldelim();
    }
    if (message.userPreferences !== undefined) {
      UserPreferences.encode(message.userPreferences, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewOrgRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNewOrgRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.signUp = SignUp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userPreferences = UserPreferences.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NewOrgRequest {
    return {
      signUp: isSet(object.signUp) ? SignUp.fromJSON(object.signUp) : undefined,
      userPreferences: isSet(object.userPreferences) ? UserPreferences.fromJSON(object.userPreferences) : undefined,
    };
  },

  toJSON(message: NewOrgRequest): unknown {
    const obj: any = {};
    if (message.signUp !== undefined) {
      obj.signUp = SignUp.toJSON(message.signUp);
    }
    if (message.userPreferences !== undefined) {
      obj.userPreferences = UserPreferences.toJSON(message.userPreferences);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NewOrgRequest>, I>>(base?: I): NewOrgRequest {
    return NewOrgRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NewOrgRequest>, I>>(object: I): NewOrgRequest {
    const message = createBaseNewOrgRequest();
    message.signUp = (object.signUp !== undefined && object.signUp !== null)
      ? SignUp.fromPartial(object.signUp)
      : undefined;
    message.userPreferences = (object.userPreferences !== undefined && object.userPreferences !== null)
      ? UserPreferences.fromPartial(object.userPreferences)
      : undefined;
    return message;
  },
};

function createBaseNewOrgResponse(): NewOrgResponse {
  return { id: "", user: undefined, userPreferences: undefined };
}

export const NewOrgResponse = {
  encode(message: NewOrgResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    if (message.userPreferences !== undefined) {
      UserPreferences.encode(message.userPreferences, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewOrgResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNewOrgResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userPreferences = UserPreferences.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NewOrgResponse {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      userPreferences: isSet(object.userPreferences) ? UserPreferences.fromJSON(object.userPreferences) : undefined,
    };
  },

  toJSON(message: NewOrgResponse): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.userPreferences !== undefined) {
      obj.userPreferences = UserPreferences.toJSON(message.userPreferences);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NewOrgResponse>, I>>(base?: I): NewOrgResponse {
    return NewOrgResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NewOrgResponse>, I>>(object: I): NewOrgResponse {
    const message = createBaseNewOrgResponse();
    message.id = object.id ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.userPreferences = (object.userPreferences !== undefined && object.userPreferences !== null)
      ? UserPreferences.fromPartial(object.userPreferences)
      : undefined;
    return message;
  },
};

function createBaseAddTenantRequest(): AddTenantRequest {
  return { tenant: undefined, user: undefined };
}

export const AddTenantRequest = {
  encode(message: AddTenantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenant !== undefined) {
      Tenant.encode(message.tenant, writer.uint32(10).fork()).ldelim();
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddTenantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddTenantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenant = Tenant.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddTenantRequest {
    return {
      tenant: isSet(object.tenant) ? Tenant.fromJSON(object.tenant) : undefined,
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: AddTenantRequest): unknown {
    const obj: any = {};
    if (message.tenant !== undefined) {
      obj.tenant = Tenant.toJSON(message.tenant);
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddTenantRequest>, I>>(base?: I): AddTenantRequest {
    return AddTenantRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddTenantRequest>, I>>(object: I): AddTenantRequest {
    const message = createBaseAddTenantRequest();
    message.tenant = (object.tenant !== undefined && object.tenant !== null)
      ? Tenant.fromPartial(object.tenant)
      : undefined;
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    return message;
  },
};

function createBaseAddTenantResponse(): AddTenantResponse {
  return { tenant: undefined, user: undefined };
}

export const AddTenantResponse = {
  encode(message: AddTenantResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenant !== undefined) {
      Tenant.encode(message.tenant, writer.uint32(10).fork()).ldelim();
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddTenantResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddTenantResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenant = Tenant.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddTenantResponse {
    return {
      tenant: isSet(object.tenant) ? Tenant.fromJSON(object.tenant) : undefined,
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: AddTenantResponse): unknown {
    const obj: any = {};
    if (message.tenant !== undefined) {
      obj.tenant = Tenant.toJSON(message.tenant);
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddTenantResponse>, I>>(base?: I): AddTenantResponse {
    return AddTenantResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddTenantResponse>, I>>(object: I): AddTenantResponse {
    const message = createBaseAddTenantResponse();
    message.tenant = (object.tenant !== undefined && object.tenant !== null)
      ? Tenant.fromPartial(object.tenant)
      : undefined;
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    return message;
  },
};

function createBaseUpdateTenantRequest(): UpdateTenantRequest {
  return { tenantId: "", tenant: undefined };
}

export const UpdateTenantRequest = {
  encode(message: UpdateTenantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    if (message.tenant !== undefined) {
      Tenant.encode(message.tenant, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTenantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTenantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tenant = Tenant.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTenantRequest {
    return {
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      tenant: isSet(object.tenant) ? Tenant.fromJSON(object.tenant) : undefined,
    };
  },

  toJSON(message: UpdateTenantRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.tenant !== undefined) {
      obj.tenant = Tenant.toJSON(message.tenant);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTenantRequest>, I>>(base?: I): UpdateTenantRequest {
    return UpdateTenantRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTenantRequest>, I>>(object: I): UpdateTenantRequest {
    const message = createBaseUpdateTenantRequest();
    message.tenantId = object.tenantId ?? "";
    message.tenant = (object.tenant !== undefined && object.tenant !== null)
      ? Tenant.fromPartial(object.tenant)
      : undefined;
    return message;
  },
};

function createBaseUpdateTenantsRequest(): UpdateTenantsRequest {
  return { tenants: [] };
}

export const UpdateTenantsRequest = {
  encode(message: UpdateTenantsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tenants) {
      Tenant.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTenantsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTenantsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenants.push(Tenant.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTenantsRequest {
    return {
      tenants: globalThis.Array.isArray(object?.tenants) ? object.tenants.map((e: any) => Tenant.fromJSON(e)) : [],
    };
  },

  toJSON(message: UpdateTenantsRequest): unknown {
    const obj: any = {};
    if (message.tenants?.length) {
      obj.tenants = message.tenants.map((e) => Tenant.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTenantsRequest>, I>>(base?: I): UpdateTenantsRequest {
    return UpdateTenantsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTenantsRequest>, I>>(object: I): UpdateTenantsRequest {
    const message = createBaseUpdateTenantsRequest();
    message.tenants = object.tenants?.map((e) => Tenant.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetTenantsRequest(): GetTenantsRequest {
  return { names: [], enabled: false };
}

export const GetTenantsRequest = {
  encode(message: GetTenantsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.names) {
      writer.uint32(10).string(v!);
    }
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTenantsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.names.push(reader.string());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTenantsRequest {
    return {
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
    };
  },

  toJSON(message: GetTenantsRequest): unknown {
    const obj: any = {};
    if (message.names?.length) {
      obj.names = message.names;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTenantsRequest>, I>>(base?: I): GetTenantsRequest {
    return GetTenantsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTenantsRequest>, I>>(object: I): GetTenantsRequest {
    const message = createBaseGetTenantsRequest();
    message.names = object.names?.map((e) => e) || [];
    message.enabled = object.enabled ?? false;
    return message;
  },
};

function createBaseSwitchTenantRequest(): SwitchTenantRequest {
  return { tenantId: "", expiry: "" };
}

export const SwitchTenantRequest = {
  encode(message: SwitchTenantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    if (message.expiry !== "") {
      writer.uint32(66).string(message.expiry);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SwitchTenantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSwitchTenantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.expiry = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SwitchTenantRequest {
    return {
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      expiry: isSet(object.expiry) ? globalThis.String(object.expiry) : "",
    };
  },

  toJSON(message: SwitchTenantRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.expiry !== "") {
      obj.expiry = message.expiry;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SwitchTenantRequest>, I>>(base?: I): SwitchTenantRequest {
    return SwitchTenantRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SwitchTenantRequest>, I>>(object: I): SwitchTenantRequest {
    const message = createBaseSwitchTenantRequest();
    message.tenantId = object.tenantId ?? "";
    message.expiry = object.expiry ?? "";
    return message;
  },
};

function createBaseDeleteTenantRequest(): DeleteTenantRequest {
  return { tenantId: "" };
}

export const DeleteTenantRequest = {
  encode(message: DeleteTenantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTenantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTenantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteTenantRequest {
    return { tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "" };
  },

  toJSON(message: DeleteTenantRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteTenantRequest>, I>>(base?: I): DeleteTenantRequest {
    return DeleteTenantRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteTenantRequest>, I>>(object: I): DeleteTenantRequest {
    const message = createBaseDeleteTenantRequest();
    message.tenantId = object.tenantId ?? "";
    return message;
  },
};

function createBaseDeleteTenantsRequest(): DeleteTenantsRequest {
  return { tenantId: [] };
}

export const DeleteTenantsRequest = {
  encode(message: DeleteTenantsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tenantId) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTenantsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTenantsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteTenantsRequest {
    return {
      tenantId: globalThis.Array.isArray(object?.tenantId) ? object.tenantId.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: DeleteTenantsRequest): unknown {
    const obj: any = {};
    if (message.tenantId?.length) {
      obj.tenantId = message.tenantId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteTenantsRequest>, I>>(base?: I): DeleteTenantsRequest {
    return DeleteTenantsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteTenantsRequest>, I>>(object: I): DeleteTenantsRequest {
    const message = createBaseDeleteTenantsRequest();
    message.tenantId = object.tenantId?.map((e) => e) || [];
    return message;
  },
};

function createBaseStartAgentSessionRequest(): StartAgentSessionRequest {
  return { serialNumber: "", switchId: "", expiry: "" };
}

export const StartAgentSessionRequest = {
  encode(message: StartAgentSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serialNumber !== "") {
      writer.uint32(10).string(message.serialNumber);
    }
    if (message.switchId !== "") {
      writer.uint32(18).string(message.switchId);
    }
    if (message.expiry !== "") {
      writer.uint32(26).string(message.expiry);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartAgentSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartAgentSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.switchId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.expiry = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartAgentSessionRequest {
    return {
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      switchId: isSet(object.switchId) ? globalThis.String(object.switchId) : "",
      expiry: isSet(object.expiry) ? globalThis.String(object.expiry) : "",
    };
  },

  toJSON(message: StartAgentSessionRequest): unknown {
    const obj: any = {};
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.switchId !== "") {
      obj.switchId = message.switchId;
    }
    if (message.expiry !== "") {
      obj.expiry = message.expiry;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartAgentSessionRequest>, I>>(base?: I): StartAgentSessionRequest {
    return StartAgentSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartAgentSessionRequest>, I>>(object: I): StartAgentSessionRequest {
    const message = createBaseStartAgentSessionRequest();
    message.serialNumber = object.serialNumber ?? "";
    message.switchId = object.switchId ?? "";
    message.expiry = object.expiry ?? "";
    return message;
  },
};

function createBaseRefreshAgentSessionRequest(): RefreshAgentSessionRequest {
  return { expiry: "" };
}

export const RefreshAgentSessionRequest = {
  encode(message: RefreshAgentSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.expiry !== "") {
      writer.uint32(10).string(message.expiry);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefreshAgentSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshAgentSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expiry = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefreshAgentSessionRequest {
    return { expiry: isSet(object.expiry) ? globalThis.String(object.expiry) : "" };
  },

  toJSON(message: RefreshAgentSessionRequest): unknown {
    const obj: any = {};
    if (message.expiry !== "") {
      obj.expiry = message.expiry;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefreshAgentSessionRequest>, I>>(base?: I): RefreshAgentSessionRequest {
    return RefreshAgentSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefreshAgentSessionRequest>, I>>(object: I): RefreshAgentSessionRequest {
    const message = createBaseRefreshAgentSessionRequest();
    message.expiry = object.expiry ?? "";
    return message;
  },
};

function createBaseStartAgentSessionResponse(): StartAgentSessionResponse {
  return { serialNumber: "", switchId: "", orgId: "", fabricId: "", unclaimed: false, unbound: false, tokens: [] };
}

export const StartAgentSessionResponse = {
  encode(message: StartAgentSessionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serialNumber !== "") {
      writer.uint32(10).string(message.serialNumber);
    }
    if (message.switchId !== "") {
      writer.uint32(18).string(message.switchId);
    }
    if (message.orgId !== "") {
      writer.uint32(26).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(42).string(message.fabricId);
    }
    if (message.unclaimed !== false) {
      writer.uint32(56).bool(message.unclaimed);
    }
    if (message.unbound !== false) {
      writer.uint32(64).bool(message.unbound);
    }
    for (const v of message.tokens) {
      Token.encode(v!, writer.uint32(402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartAgentSessionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartAgentSessionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.switchId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.unclaimed = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.unbound = reader.bool();
          continue;
        case 50:
          if (tag !== 402) {
            break;
          }

          message.tokens.push(Token.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartAgentSessionResponse {
    return {
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      switchId: isSet(object.switchId) ? globalThis.String(object.switchId) : "",
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      unclaimed: isSet(object.unclaimed) ? globalThis.Boolean(object.unclaimed) : false,
      unbound: isSet(object.unbound) ? globalThis.Boolean(object.unbound) : false,
      tokens: globalThis.Array.isArray(object?.tokens) ? object.tokens.map((e: any) => Token.fromJSON(e)) : [],
    };
  },

  toJSON(message: StartAgentSessionResponse): unknown {
    const obj: any = {};
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.switchId !== "") {
      obj.switchId = message.switchId;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.unclaimed !== false) {
      obj.unclaimed = message.unclaimed;
    }
    if (message.unbound !== false) {
      obj.unbound = message.unbound;
    }
    if (message.tokens?.length) {
      obj.tokens = message.tokens.map((e) => Token.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartAgentSessionResponse>, I>>(base?: I): StartAgentSessionResponse {
    return StartAgentSessionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartAgentSessionResponse>, I>>(object: I): StartAgentSessionResponse {
    const message = createBaseStartAgentSessionResponse();
    message.serialNumber = object.serialNumber ?? "";
    message.switchId = object.switchId ?? "";
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    message.unclaimed = object.unclaimed ?? false;
    message.unbound = object.unbound ?? false;
    message.tokens = object.tokens?.map((e) => Token.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetAgentSessionRequest(): GetAgentSessionRequest {
  return {};
}

export const GetAgentSessionRequest = {
  encode(_: GetAgentSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAgentSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAgentSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetAgentSessionRequest {
    return {};
  },

  toJSON(_: GetAgentSessionRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAgentSessionRequest>, I>>(base?: I): GetAgentSessionRequest {
    return GetAgentSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAgentSessionRequest>, I>>(_: I): GetAgentSessionRequest {
    const message = createBaseGetAgentSessionRequest();
    return message;
  },
};

function createBaseDeleteAgentSessionRequest(): DeleteAgentSessionRequest {
  return {};
}

export const DeleteAgentSessionRequest = {
  encode(_: DeleteAgentSessionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAgentSessionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAgentSessionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeleteAgentSessionRequest {
    return {};
  },

  toJSON(_: DeleteAgentSessionRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteAgentSessionRequest>, I>>(base?: I): DeleteAgentSessionRequest {
    return DeleteAgentSessionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteAgentSessionRequest>, I>>(_: I): DeleteAgentSessionRequest {
    const message = createBaseDeleteAgentSessionRequest();
    return message;
  },
};

function createBaseGetAgentSessionResponse(): GetAgentSessionResponse {
  return {
    serialNumber: "",
    switchId: "",
    orgId: "",
    fabricId: "",
    unclaimed: false,
    unbound: false,
    expiry: undefined,
  };
}

export const GetAgentSessionResponse = {
  encode(message: GetAgentSessionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serialNumber !== "") {
      writer.uint32(10).string(message.serialNumber);
    }
    if (message.switchId !== "") {
      writer.uint32(18).string(message.switchId);
    }
    if (message.orgId !== "") {
      writer.uint32(26).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(42).string(message.fabricId);
    }
    if (message.unclaimed !== false) {
      writer.uint32(56).bool(message.unclaimed);
    }
    if (message.unbound !== false) {
      writer.uint32(64).bool(message.unbound);
    }
    if (message.expiry !== undefined) {
      Timestamp.encode(toTimestamp(message.expiry), writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAgentSessionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAgentSessionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.switchId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.unclaimed = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.unbound = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.expiry = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAgentSessionResponse {
    return {
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      switchId: isSet(object.switchId) ? globalThis.String(object.switchId) : "",
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      unclaimed: isSet(object.unclaimed) ? globalThis.Boolean(object.unclaimed) : false,
      unbound: isSet(object.unbound) ? globalThis.Boolean(object.unbound) : false,
      expiry: isSet(object.expiry) ? fromJsonTimestamp(object.expiry) : undefined,
    };
  },

  toJSON(message: GetAgentSessionResponse): unknown {
    const obj: any = {};
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.switchId !== "") {
      obj.switchId = message.switchId;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.unclaimed !== false) {
      obj.unclaimed = message.unclaimed;
    }
    if (message.unbound !== false) {
      obj.unbound = message.unbound;
    }
    if (message.expiry !== undefined) {
      obj.expiry = message.expiry.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAgentSessionResponse>, I>>(base?: I): GetAgentSessionResponse {
    return GetAgentSessionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAgentSessionResponse>, I>>(object: I): GetAgentSessionResponse {
    const message = createBaseGetAgentSessionResponse();
    message.serialNumber = object.serialNumber ?? "";
    message.switchId = object.switchId ?? "";
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    message.unclaimed = object.unclaimed ?? false;
    message.unbound = object.unbound ?? false;
    message.expiry = object.expiry ?? undefined;
    return message;
  },
};

function createBaseUpdateUser(): UpdateUser {
  return { role: 0, enabled: false, labels: [] };
}

export const UpdateUser = {
  encode(message: UpdateUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.role !== 0) {
      writer.uint32(8).int32(message.role);
    }
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    for (const v of message.labels) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.labels.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateUser {
    return {
      role: isSet(object.role) ? userRoleFromJSON(object.role) : 0,
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: UpdateUser): unknown {
    const obj: any = {};
    if (message.role !== 0) {
      obj.role = userRoleToJSON(message.role);
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateUser>, I>>(base?: I): UpdateUser {
    return UpdateUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUser>, I>>(object: I): UpdateUser {
    const message = createBaseUpdateUser();
    message.role = object.role ?? 0;
    message.enabled = object.enabled ?? false;
    message.labels = object.labels?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateUserRequest(): UpdateUserRequest {
  return { userId: "", user: undefined };
}

export const UpdateUserRequest = {
  encode(message: UpdateUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.user !== undefined) {
      UpdateUser.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = UpdateUser.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateUserRequest {
    return {
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      user: isSet(object.user) ? UpdateUser.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: UpdateUserRequest): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.user !== undefined) {
      obj.user = UpdateUser.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateUserRequest>, I>>(base?: I): UpdateUserRequest {
    return UpdateUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUserRequest>, I>>(object: I): UpdateUserRequest {
    const message = createBaseUpdateUserRequest();
    message.userId = object.userId ?? "";
    message.user = (object.user !== undefined && object.user !== null)
      ? UpdateUser.fromPartial(object.user)
      : undefined;
    return message;
  },
};

function createBaseAddOrgRequest(): AddOrgRequest {
  return { orgName: "" };
}

export const AddOrgRequest = {
  encode(message: AddOrgRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgName !== "") {
      writer.uint32(10).string(message.orgName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddOrgRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddOrgRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddOrgRequest {
    return { orgName: isSet(object.orgName) ? globalThis.String(object.orgName) : "" };
  },

  toJSON(message: AddOrgRequest): unknown {
    const obj: any = {};
    if (message.orgName !== "") {
      obj.orgName = message.orgName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddOrgRequest>, I>>(base?: I): AddOrgRequest {
    return AddOrgRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddOrgRequest>, I>>(object: I): AddOrgRequest {
    const message = createBaseAddOrgRequest();
    message.orgName = object.orgName ?? "";
    return message;
  },
};

function createBaseAddOrgResponse(): AddOrgResponse {
  return { orgId: "", user: undefined };
}

export const AddOrgResponse = {
  encode(message: AddOrgResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddOrgResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddOrgResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddOrgResponse {
    return {
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: AddOrgResponse): unknown {
    const obj: any = {};
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddOrgResponse>, I>>(base?: I): AddOrgResponse {
    return AddOrgResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddOrgResponse>, I>>(object: I): AddOrgResponse {
    const message = createBaseAddOrgResponse();
    message.orgId = object.orgId ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    return message;
  },
};

function createBaseDeleteOrgRequest(): DeleteOrgRequest {
  return {};
}

export const DeleteOrgRequest = {
  encode(_: DeleteOrgRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteOrgRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteOrgRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeleteOrgRequest {
    return {};
  },

  toJSON(_: DeleteOrgRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteOrgRequest>, I>>(base?: I): DeleteOrgRequest {
    return DeleteOrgRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteOrgRequest>, I>>(_: I): DeleteOrgRequest {
    const message = createBaseDeleteOrgRequest();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
