import {
  ProxyConnectionState,
  TlsSessionState
} from "../../gen/schema/schema/schema";
import { Status } from "@magnetic/types";
import { StatusType } from "@magnetic/icons";
import { PathId } from "../../gen/schema/schema_path/path_id";
import { createContext } from "react";

export interface SectionItem {
  info: string;
  isHex: boolean;
  labelId: string;
}

export interface CertItem {
  critical: boolean;
  sectionId: string;
  sectionItems: SectionItem[];
}

export interface ParsedCertificate {
  tabName: string;
  certItems: Map<string, CertItem>;
}

/**
 * Used for displaying the proper Icons and Notifications
 * in the Certificate Viewer
 * TagStatus used by StatusIcons
 * Status used by Notifications
 * desc is used in the Notification text.
 */
export type SessionState = {
  iconStatus: StatusType;
  notificationStatus: Status;
  desc: string;
};

type SessionStateMapSchema = {
  [key in TlsSessionState]: SessionState;
};

const SessionStateMap: SessionStateMapSchema = {
  [TlsSessionState.VERIFY_IN_PROGRESS]: {
    iconStatus: "dormant",
    desc: "In Progress",
    notificationStatus: "warning"
  },
  [TlsSessionState.VERIFY_VALID]: {
    iconStatus: "positive",
    desc: "Valid",
    notificationStatus: "positive"
  },
  [TlsSessionState.VERIFY_INVALID]: {
    iconStatus: "negative",
    desc: "Invalid",
    notificationStatus: "negative"
  },
  [TlsSessionState.TLS_SESSION_STATE_UNSPECIFIED]: {
    iconStatus: "disabled",
    desc: "Unspecified",
    notificationStatus: "warning"
  },
  [TlsSessionState.UNRECOGNIZED]: {
    iconStatus: "negative",
    desc: "Unrecognized",
    notificationStatus: "negative"
  }
};

export const getTlsSessionState = (
  outerTlsSessionState: TlsSessionState
): SessionState => {
  switch (outerTlsSessionState) {
    case TlsSessionState.VERIFY_IN_PROGRESS:
    case TlsSessionState.VERIFY_VALID:
    case TlsSessionState.VERIFY_INVALID:
    case TlsSessionState.TLS_SESSION_STATE_UNSPECIFIED:
    case TlsSessionState.UNRECOGNIZED:
      return SessionStateMap[outerTlsSessionState];
    default:
      return SessionStateMap[TlsSessionState.UNRECOGNIZED];
  }
};

export const getProxyConnectionState = (
  state: ProxyConnectionState
): SessionState => {
  const { outerTlsSessionState } = state;
  return getTlsSessionState(outerTlsSessionState);
};

export interface CertificateMeta {
  chain: string[];
  state?: SessionState;
  pathId?: PathId;
}

export interface CertificateDrawerSchema {
  setData: (chain: CertificateMeta | undefined) => void;
}

export const CertificateDrawerContext = createContext<CertificateDrawerSchema>({
  setData: () => {}
});
