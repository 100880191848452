import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "@magnetic/link";
import { Heading } from "@magnetic/heading";
import { Button } from "@magnetic/button";
import { Input } from "@magnetic/input";
import { Checkbox } from "@magnetic/checkbox";
import { CiscoLogo } from "src/common/ciscoLogo";
import { ContactSales } from "./contactSales";
import { NewUserRegistrationUnavailable } from "./newUserRegistrationUnavailable";

import "./welcomeScreen.scss";
import { LoginContext } from "./loginContext";
import VFlex from "components/vFlex";
import HFlex from "components/hFlex";
import { NewOrgRequest, NewOrgResponse } from "src/gen/schema/brig/bapi";
import { Theme } from "src/gen/schema/models/auth";
import { Apis } from "src/utils/api";
import { ApiError } from "./apiError";
import { Constants } from "src/utils/constants";
import { ErrorNotification } from "components/errorNotification";
import { useForm } from "src/hooks";

const { Brig } = Apis;

const ERRORS = {
  PHONE: "Enter a valid phone number.",
  COUNTRY: "Select your country.",
  TOC: "Accept the terms and conditions to continue."
};

type SignUpFormFields = {
  email: string;
  orgName: string;
  toc: boolean;
};

const defaultFormValues: SignUpFormFields = {
  email: "",
  orgName: "",
  toc: false
};

export const WelcomeScreen = () => {
  const { session, logout, switchTenant } = useContext(LoginContext);
  const [error, setError] = useState<string>();
  const { form, handleInputChange, handleFormValueChange, setForm } =
    useForm(defaultFormValues);
  const [timeZone] = useState<string>("Local");
  const [theme] = useState<number>(Theme.LIGHT);

  useEffect(() => {
    if (session) {
      setForm({
        email: session.email,
        orgName: `${session.email} scratch`,
        toc: false
      });
    }
  }, [session, setForm]);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const { toc, orgName, email } = form;
      if (!toc) {
        setError(ERRORS.TOC);
        return;
      }

      const req: NewOrgRequest = NewOrgRequest.create({
        signUp: {
          companyName: orgName,
          email: email,
          //hard coded for now, UI has no way to tell the EULA version atm
          eulaVersion: "1.0.0"
        },
        userPreferences: {
          timezone: timeZone,
          theme: theme
        }
      });
      Brig.newOrg(req).then(
        (resp: NewOrgResponse) => {
          if (resp) {
            switchTenant(resp.id);
          }
        },
        (err: () => ApiError) => {
          setError(err().getMessage());
        }
      );
    },
    [theme, timeZone, switchTenant, form]
  );

  const handleTermsChange = useCallback(
    (e: React.FormEvent<HTMLButtonElement>) => {
      e.preventDefault();
      handleFormValueChange({ ...form, toc: !form.toc });
    },
    [form, handleFormValueChange]
  );

  /**
   * If the user is a new user and
   * required to sign up, we will
   * display the register form.
   */
  if (session?.requireSignUp) {
    /**
     * If the disableSignUp is set,
     * the user needs to be blocked
     * from the register form until
     * the system can handle the load
     */
    if (session?.disableSignUp) {
      return <NewUserRegistrationUnavailable />;
    }
    return (
      <VFlex className="welcome" align="center" gap="xl">
        <CiscoLogo />
        <VFlex gap="md" className="welcome-header" align="center">
          <Heading size="h1">Welcome to {Constants.APP_NAME}</Heading>
          <p>Provide the following information to begin.</p>
        </VFlex>
        <form onSubmit={onSubmit}>
          <VFlex gap="xl">
            <VFlex gap="md">
              <Input
                label="Email"
                name="email"
                type="email"
                value={form.email}
                onChange={handleInputChange}
                required={true}
                fixedWidth={true}
                autoComplete="off"
                disabled={true}
              />
              <Input
                label="Default organization name"
                name="orgName"
                onChange={handleInputChange}
                value={form.orgName}
                type="text"
                size="md"
                required={true}
                fixedWidth={true}
                autoComplete="off"
              />
              <Checkbox onClick={handleTermsChange} checked={form.toc}>
                I agree to the
                <Link
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                  href="https://www.cisco.com/c/en/us/about/legal/terms-conditions.html"
                >
                  terms and conditions
                </Link>
                of {Constants.APP_NAME}.
              </Checkbox>
              <ErrorNotification msg={error} />
            </VFlex>
            <VFlex gap="md">
              <Button
                type="submit"
                size="md"
                name="getstarted"
                className="save"
              >
                Get started
              </Button>
              <HFlex align="stretch" justify="center">
                <Button name="logout" kind="tertiary" onClick={logout}>
                  Back to login
                </Button>
              </HFlex>
            </VFlex>
          </VFlex>
        </form>
      </VFlex>
    );
  }
  return <ContactSales />;
};
