import { logger, uuid } from "src/core/globals";
import { UnaryApiContext } from "src/utils/api/grpc/grpcDebugableWebImpl";
import { Message } from "../gen/schema/syncfollow/syncfollow";
import { StreamingCachePrintType } from "./dataflow/streamingCache";
import LocalSettings from "./localSettings";
import { Stream } from "./streaming/core/stream";
import {
  BilgeStreamPool,
  ConfigDStreamPool,
  SextantStreamPool
} from "./streaming/streamPool";

export default class AppState {
  public settings: LocalSettings;
  public sextantPool: SextantStreamPool;
  public configDPool: ConfigDStreamPool;
  public bilgePool: BilgeStreamPool;
  public unaryHistory: UnaryApiContext[];
  public clientId = uuid(); // this is to uniquely identify the client instance (tab for example)
  public xCsrfToken?: string; // this is set in xCsrfTransportFactory and used for all api calls

  constructor() {
    this.settings = new LocalSettings();
    this.sextantPool = new SextantStreamPool();
    this.configDPool = new ConfigDStreamPool();
    this.bilgePool = new BilgeStreamPool();
    this.unaryHistory = [];
  }

  public debugSync: () => void = () => {};

  logUnaryContext(context: UnaryApiContext) {
    // keep history of last 100 unary calls
    this.unaryHistory = [...this.unaryHistory, context].slice(-100);
  }

  enableAuthBypass(enable: boolean) {
    this.settings.set("authBypass", enable);
    logger.warn("using authBypass,", enable);
  }

  pageRefresh() {
    window.location.reload();
  }

  // a console command so that people don't have to hand craft url, hopefully reducing friction
  goToDevSettings() {
    window.location.href = "/dev/settings";
  }

  // a console command to enable dev navigation item in the UI
  enableDev() {
    this.settings.set("dev", true);
    this.pageRefresh();
  }

  getStreams(): Stream<Message, Message>[] {
    const b = this.bilgePool.getStreams();
    const c = this.configDPool.getStreams();
    const s = this.sextantPool.getStreams();
    return [...b, ...c, ...s];
  }

  setSync(cb: () => void) {
    this.debugSync = cb;
  }

  showContent(): StreamingCachePrintType[] {
    const b = this.bilgePool.showContent().map((d) => {
      d.service = "Bilge";
      return d;
    });
    const c = this.configDPool.showContent().map((d) => {
      d.service = "ConfigD";
      return d;
    });
    const s = this.sextantPool.showContent().map((d) => {
      d.service = "Sextant";
      return d;
    });
    return [...b, ...c, ...s];
  }
}

declare global {
  interface Window {
    cnc: AppState;
    env: {
      VITE_API_HOST_PORT: string; // map the env variables
    };
  }
}
