import { BaseColumnConfig } from "./baseColumnConfig";

export class StringColumn extends BaseColumnConfig<string> {
  compare(a: string, b: string) {
    return a?.localeCompare(b);
  }
  render(data: string) {
    return <div className="mds-text-p3">{data}</div>;
  }
}
