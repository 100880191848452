// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/types.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";
import {
  AirflowType,
  airflowTypeFromJSON,
  airflowTypeToJSON,
  InterfaceType,
  interfaceTypeFromJSON,
  interfaceTypeToJSON,
  OsType,
  osTypeFromJSON,
  osTypeToJSON,
} from "./fabric";
import { CredentialsType, credentialsTypeFromJSON, credentialsTypeToJSON } from "./tenancy";

export const protobufPackage = "models";

/** RouteTag defines an enumeration of custom route tags. */
export enum RouteTag {
  /** TAG_UNSPECIFIED - Unspecified route tag (do not use). */
  TAG_UNSPECIFIED = 0,
  /** TAG_AMBER - Default route tag (do not use). */
  TAG_AMBER = 1,
  /** TAG_BLACK - Route tag used by discard or blackhole routes. */
  TAG_BLACK = 2,
  /** TAG_BLUE - User-defined route tag "blue". */
  TAG_BLUE = 3,
  /** TAG_GREEN - User-defined route tag "green". */
  TAG_GREEN = 4,
  /** TAG_RED - User-defined route tag "red". */
  TAG_RED = 5,
  /** TAG_PURPLE - User-defined route tag "purple". */
  TAG_PURPLE = 6,
  /** TAG_YELLOW - User-defined route tag "yellow". */
  TAG_YELLOW = 7,
  UNRECOGNIZED = -1,
}

export function routeTagFromJSON(object: any): RouteTag {
  switch (object) {
    case 0:
    case "TAG_UNSPECIFIED":
      return RouteTag.TAG_UNSPECIFIED;
    case 1:
    case "TAG_AMBER":
      return RouteTag.TAG_AMBER;
    case 2:
    case "TAG_BLACK":
      return RouteTag.TAG_BLACK;
    case 3:
    case "TAG_BLUE":
      return RouteTag.TAG_BLUE;
    case 4:
    case "TAG_GREEN":
      return RouteTag.TAG_GREEN;
    case 5:
    case "TAG_RED":
      return RouteTag.TAG_RED;
    case 6:
    case "TAG_PURPLE":
      return RouteTag.TAG_PURPLE;
    case 7:
    case "TAG_YELLOW":
      return RouteTag.TAG_YELLOW;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RouteTag.UNRECOGNIZED;
  }
}

export function routeTagToJSON(object: RouteTag): string {
  switch (object) {
    case RouteTag.TAG_UNSPECIFIED:
      return "TAG_UNSPECIFIED";
    case RouteTag.TAG_AMBER:
      return "TAG_AMBER";
    case RouteTag.TAG_BLACK:
      return "TAG_BLACK";
    case RouteTag.TAG_BLUE:
      return "TAG_BLUE";
    case RouteTag.TAG_GREEN:
      return "TAG_GREEN";
    case RouteTag.TAG_RED:
      return "TAG_RED";
    case RouteTag.TAG_PURPLE:
      return "TAG_PURPLE";
    case RouteTag.TAG_YELLOW:
      return "TAG_YELLOW";
    case RouteTag.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Metadata defines a map of attributes related to the lifecycle of the object. */
export interface Metadata {
  /** An integer that represent the current revision of the object. */
  revisionId: number;
  /** The identity provider and email of the user that created this object. */
  createdBy: string;
  /**
   * The timestamp when this object was created in
   * [RFC3339](https://datatracker.ietf.org/doc/html/rfc3339#section-5.8) format
   * (e.g. `YYYY-MM-DDTHH:MM:SSZ`).
   */
  createdAt:
    | Date
    | undefined;
  /** The identity provider and email of the user that modified this object last. */
  modifiedBy: string;
  /**
   * The timestamp when this object was last modified in
   * [RFC3339](https://datatracker.ietf.org/doc/html/rfc3339#section-5.8) format
   * (e.g. `YYYY-MM-DDTHH:MM:SSZ`).
   */
  modifiedAt: Date | undefined;
}

/** Credentials encapsulates organization's credential objects. */
export interface Credentials {
  /** The credentials identifier. */
  credentialsId: string;
  /** User defined name of the credentials. */
  name: string;
  /** User defined description of the credentials. */
  description: string;
  /** The username; valid only for password type. */
  username: string;
  /** Type of the credential. */
  credentialsType: CredentialsType;
  /** Password of the user. */
  passwords: string[];
  /** Revision history of the credentials. */
  metadata: Metadata | undefined;
}

/**
 * NetworkInterface encapsulates properties of a network interface
 * attached to a VRF. A NetworkInterface maybe a network port, VLAN
 * interface (SVI), loopback, port channel or a sub-interface.
 */
export interface NetworkInterface {
  /** The name of the network interface. */
  name: string;
  /**
   * VLAN identifier - required only for VLAN interface. Name field is
   * ignored when VlanId is specified.
   */
  vlanId: number;
  /** The interface type. */
  type: InterfaceType;
  /**
   * The node identifier where the interface is located. API users may
   * specify either node name or node identifier.
   */
  nodeId: string;
  /** The parent VNI of the VLAN. Valid only for VLAN interfaces. */
  vni: number;
  /**
   * IPv4 addresses of network interface. IPv4 addresses are readonly,
   * and are set by some APIs (E.g. GetVrfs API when NeedIps is set to true).
   */
  ipv4Addresses: string[];
  /**
   * IPv6 addresses of network interface. IPv6 addresses are readonly,
   * and are set by some APIs (E.g. GetVrfs API when NeedIps is set to true).
   */
  ipv6Addresses: string[];
}

/**
 * RouteInfo defines a specific single IPv4 or IPv6 static route which inludes an IPv4 or IPv6 prefix
 * in CIDR notation and a next-hop address of the same IP version.
 */
export interface RouteInfo {
  /** The IPv4 or IPv6 prefix in CIDR notation. */
  prefix: string;
  /** The next-hop IP address. Must be a host IP address. */
  nextHop: string;
  /** The name of the VRF where the next-hop is present. */
  nextVrf: string;
  /** The unique identifier of the node where the route is deployed. */
  nodeId: string;
  /** The next-hop interface or network port name. */
  interface: string;
  /**
   * The route preference value (ascending) which sets the administrative distance.
   * The range is from 1 to 255.
   */
  preference: number;
  /**
   * The flag that defines if the traffic matching the static route is silently discarded or not.
   * This is also referred to as a null route or a black hole route.
   */
  discard: boolean;
  /** The tag associated with the static route to match the route in a routing policy. */
  tag: RouteTag;
}

/** PortEndpoint defines a globally unique port location or endpoint. */
export interface PortEndpoint {
  /** The canonical name of the port (E.g. Ethernet1_1). */
  portName: string;
  /** The unique identifier of the node. */
  nodeId: string;
  /** The user-defined name of the node. */
  nodeName: string;
}

/**
 * A Connection represents the bidirectional physical interconnection between two ports of two
 * nodes in a fabric. The role of the ports in a connection will be automatically configured as
 * `FABRIC_PORT`. Cisco Nexus Hyperfabric uses the connections to generate a possible Bill Of
 * Material, cabling plan and to verify the correct implementation of the desired connectivity
 * intent in a fabric.
 *
 * INTERNAL
 *
 * A PortConnection is bidirectional, and that means that Local and Remote are interchangeable.
 */
export interface PortConnection {
  /** The unique identifier of the connection. */
  id: string;
  /** The identifier of the fabric to which this connection belongs to. */
  fabricId: string;
  /** The description is a user-defined field to store notes about the connection. */
  description: string;
  /** A map that represents the local side of the connection. */
  local:
    | PortEndpoint
    | undefined;
  /** A map that represents the remote side of the connection. */
  remote:
    | PortEndpoint
    | undefined;
  /** The derived operating system type of the remote side of the connection. */
  osType: OsType;
  /** The flag that indicates if the remote side of the connection is recognized or not. */
  unrecognized: boolean;
  /**
   * The type or name of the model (PID) of the pluggable cable or optic expected to be used
   * for the connection.
   */
  pluggable: string;
}

/**
 * A VNI VLAN Member represents the attachement of a specific VNI to a port or port channel
 * untagged or using a dot1q VLAN ID (also known as a trunk). The role of the port or port
 * channel has to be configured as `HOST_PORT`.
 *
 * INTERNAL
 *
 * VlanMember encapsulates properties of a Vlan member port. Every VlanMember
 * can have its own VlanId that is locally visible in the node, but not
 * visible to external entities. Parent Vlan has its own authoritative VlanId
 * that is visible to external entities.
 *
 * NOTES: When member's VlanId is different from parent Vlan's VlanId,
 * then the service configures VlanId translation.
 */
export interface VlanMember {
  /**
   * The VLAN ID to be used as dot1Q encapsulation for the VNI on a port of the node or
   * a port channel. The VLAN ID must be between 2 and 3600.
   *
   * INTERNAL
   *
   * The service reserves Vlan identifier between 3601 and 4096.
   */
  vlanId: number;
  /** A map that represents a port of the node or a port channel on which to deploy the VNI. */
  port:
    | PortEndpoint
    | undefined;
  /** The flag that defines if the VNI VLAN member is configured as untagged or not. */
  untagged: boolean;
}

/**
 * A Switch Virtual Interface (SVI) or VLAN interface is a logical Layer 3 interface that
 * connects a VLAN to a Layer 3 virtual routing and forwarding (VRF) instance and acts as
 * a gateway for the hosts connected to the VLAN.
 * A Static Anycast Gateway SVI is an SVI deployed on multiple devices enabling the use of the same
 * gateway IP addresses across all the nodes that are part of a VNI.
 */
export interface Svi {
  /**
   * The unique identifier of the specific node on which this SVI is deployed.
   * Must be empty when using the Static Anycast Gateway.
   */
  nodeId: string;
  /** The identifier of the VLAN to which this SVI belongs to. */
  vlanId: number;
  /**
   * A list of up to two IPv4 host addresses with subnet mask to be configured on the SVI.
   * If there are two IP addresses on the SVI, then there are two possibilities.
   * 	a) If IPS are in two different networks, then SVI gets two networks.
   * 	b) If IPS are in the same network, then the first IP address is the primary gateway
   * 	   and second IP address is the secondary gateway.
   *
   * INTERNAL
   *
   * Meaning, address must be in CIDR format with CIDR < 32.
   */
  ipv4Addresses: string[];
  /**
   * A list of up to one IPv6 host address with subnet mask to be configured on the SVI.
   *
   * INTERNAL
   *
   * Meaning, address must be in CIDR format with CIDR < 128.
   */
  ipv6Addresses: string[];
  /** The enabled state of the SVI which indicates if the SVI is enabled or disabled. */
  enabled: boolean;
}

/**
 * VniDhcp encapsulates properties of a VNI DHCP relay. VniDhcp is used for
 * setting up inter-VNI DHCP relay.
 */
export interface VniDhcp {
  /** The identifier of VNI that needs DHCP relay. */
  vni: number;
  /** Indicates that IPv4 DHCP relay is enabled for VNI. */
  ipv4Enabled: boolean;
  /** Indicates that IPv6 DHCP relay is enabled for VNI. */
  ipv6Enabled: boolean;
}

/**
 * The Spanning Tree Protocol (STP) interface configuration for the port. The configuration is
 * only used when a VLAN is deployed on the port.
 */
export interface InterfaceStp {
  /** The name of the port or port channel. */
  name: string;
  /**
   * The enabled state of the Spanning-Tree Protocol (STP) which indicates if STP is enabled
   * or disabled on the port. Requires at least one of the other properties to also be enabled.
   */
  enabled: boolean;
  /**
   * Root guard enforces the root bridge placement in the network and allows STP to interoperate with
   * user network bridges while still maintaining the bridged network topology that the
   * administrator requires. When BPDUs are received on a root guard enabled port, the STP
   * state will be moved to "Root inconsistent" state to indicate this condition. Once the
   * port stops receiving superior BPDUs, root guard will automatically set the port back
   * to a FORWARDING state after the timeout period has expired.
   */
  rootGuard: boolean;
  /**
   * BPDU guard enforces that the connected device does not send STP BPDUs on the port.
   * If STP BPDUs are received on the port where BPDU guard is enabled the port will be
   * automatically shutdown. User can re-enable the port administratively after ensuring the
   * BPDUs have stopped coming on the port.
   */
  bpduGuard: boolean;
  /**
   * PortFast allows ports to move to forwarding state quickly when the connected device
   * that is not participating in spanning-tree. PortFast is enabled by default on all ports.
   */
  portFast: boolean;
  /**
   * UplinkFast feature enhances STP performance for switches with redundant uplinks. Using
   * the default value for the standard STP forward delay, convergence following a transition
   * from an active link to a redundant link can take up to 30 seconds; 15 seconds for listening
   * and an additional 15 seconds for learning. UplinkFast maintains a list of backup path to
   * the root bridge and immediately move one of the backup paths to forwarding state to speed
   * up the network recovery.
   */
  uplinkFast: boolean;
}

/** VlanStp encapsulates configurable parameters of a single Vlan STP. */
export interface VlanStp {
  /** The Vlan identifier. */
  vlanId: number;
  /** Indicates that STP is enabled at Vlan level. */
  enabled: boolean;
  /** Packet forward delay in seconds. Default value is 15s, and range is 4-30s. */
  forwardDelay: number;
  /** Hello interval in seconds for transmission of BPDUs. Default values is 2s, and range is 1-10s. */
  helloInterval: number;
  /** Maximum time to listen for root bridge in seconds. Default value is 20s, and range is 6-40s. */
  maxAge: number;
  /** Bridge priority value in the increments of 4096. Default is 32768, and range is 0-61440. */
  priority: number;
  /** Vlan member interface properties. */
  interfaces: InterfaceStp[];
}

/** SpeedMode defines port speed mode. */
export interface SpeedMode {
  /** Speed defines port speed (E.g. 100G, 200G etc.). */
  speed: string;
  /** MNode defines breakout mode (E.g. 1x100G(4), 1x200G(2) etc.). */
  mode: string;
  /** IsDefault indicates that breakout is a default config. */
  isDefault: boolean;
  /** Ports defines the network port names. */
  ports: string[];
}

/** Certificates encapsulates PEM encoded PKI certificates. */
export interface Certificates {
  /** The unique identifier of a certificate. */
  id: string;
  /** The user-defined name of the certificate. */
  name: string;
  /** The user-defined description of the certificates. */
  description: string;
  /** Indicates if certificates is enabled or not. */
  enabled: boolean;
  /** Computed fingerprint of certificates. */
  fingerprint: string;
  /** A list of PEM encoded certificates. */
  certificates: string;
}

/** PsuAirflow encapsulates Airflow and corresponding Psu model. */
export interface PsuAirflow {
  airflow: AirflowType;
  psuModel: string;
}

function createBaseMetadata(): Metadata {
  return { revisionId: 0, createdBy: "", createdAt: undefined, modifiedBy: "", modifiedAt: undefined };
}

export const Metadata = {
  encode(message: Metadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.revisionId !== 0) {
      writer.uint32(8).int64(message.revisionId);
    }
    if (message.createdBy !== "") {
      writer.uint32(18).string(message.createdBy);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.modifiedBy !== "") {
      writer.uint32(34).string(message.modifiedBy);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Metadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.revisionId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.modifiedBy = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Metadata {
    return {
      revisionId: isSet(object.revisionId) ? globalThis.Number(object.revisionId) : 0,
      createdBy: isSet(object.createdBy) ? globalThis.String(object.createdBy) : "",
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      modifiedBy: isSet(object.modifiedBy) ? globalThis.String(object.modifiedBy) : "",
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
    };
  },

  toJSON(message: Metadata): unknown {
    const obj: any = {};
    if (message.revisionId !== 0) {
      obj.revisionId = Math.round(message.revisionId);
    }
    if (message.createdBy !== "") {
      obj.createdBy = message.createdBy;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.modifiedBy !== "") {
      obj.modifiedBy = message.modifiedBy;
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Metadata>, I>>(base?: I): Metadata {
    return Metadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Metadata>, I>>(object: I): Metadata {
    const message = createBaseMetadata();
    message.revisionId = object.revisionId ?? 0;
    message.createdBy = object.createdBy ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.modifiedBy = object.modifiedBy ?? "";
    message.modifiedAt = object.modifiedAt ?? undefined;
    return message;
  },
};

function createBaseCredentials(): Credentials {
  return {
    credentialsId: "",
    name: "",
    description: "",
    username: "",
    credentialsType: 0,
    passwords: [],
    metadata: undefined,
  };
}

export const Credentials = {
  encode(message: Credentials, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.credentialsId !== "") {
      writer.uint32(10).string(message.credentialsId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.username !== "") {
      writer.uint32(34).string(message.username);
    }
    if (message.credentialsType !== 0) {
      writer.uint32(40).int32(message.credentialsType);
    }
    for (const v of message.passwords) {
      writer.uint32(50).string(v!);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Credentials {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCredentials();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.credentialsId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.username = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.credentialsType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.passwords.push(reader.string());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Credentials {
    return {
      credentialsId: isSet(object.credentialsId) ? globalThis.String(object.credentialsId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      username: isSet(object.username) ? globalThis.String(object.username) : "",
      credentialsType: isSet(object.credentialsType) ? credentialsTypeFromJSON(object.credentialsType) : 0,
      passwords: globalThis.Array.isArray(object?.passwords)
        ? object.passwords.map((e: any) => globalThis.String(e))
        : [],
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
    };
  },

  toJSON(message: Credentials): unknown {
    const obj: any = {};
    if (message.credentialsId !== "") {
      obj.credentialsId = message.credentialsId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.username !== "") {
      obj.username = message.username;
    }
    if (message.credentialsType !== 0) {
      obj.credentialsType = credentialsTypeToJSON(message.credentialsType);
    }
    if (message.passwords?.length) {
      obj.passwords = message.passwords;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Credentials>, I>>(base?: I): Credentials {
    return Credentials.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Credentials>, I>>(object: I): Credentials {
    const message = createBaseCredentials();
    message.credentialsId = object.credentialsId ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.username = object.username ?? "";
    message.credentialsType = object.credentialsType ?? 0;
    message.passwords = object.passwords?.map((e) => e) || [];
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    return message;
  },
};

function createBaseNetworkInterface(): NetworkInterface {
  return { name: "", vlanId: 0, type: 0, nodeId: "", vni: 0, ipv4Addresses: [], ipv6Addresses: [] };
}

export const NetworkInterface = {
  encode(message: NetworkInterface, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.vlanId !== 0) {
      writer.uint32(16).uint32(message.vlanId);
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    if (message.nodeId !== "") {
      writer.uint32(34).string(message.nodeId);
    }
    if (message.vni !== 0) {
      writer.uint32(40).uint32(message.vni);
    }
    for (const v of message.ipv4Addresses) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.ipv6Addresses) {
      writer.uint32(58).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NetworkInterface {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNetworkInterface();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.vlanId = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.vni = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.ipv4Addresses.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.ipv6Addresses.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NetworkInterface {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      vlanId: isSet(object.vlanId) ? globalThis.Number(object.vlanId) : 0,
      type: isSet(object.type) ? interfaceTypeFromJSON(object.type) : 0,
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      vni: isSet(object.vni) ? globalThis.Number(object.vni) : 0,
      ipv4Addresses: globalThis.Array.isArray(object?.ipv4Addresses)
        ? object.ipv4Addresses.map((e: any) => globalThis.String(e))
        : [],
      ipv6Addresses: globalThis.Array.isArray(object?.ipv6Addresses)
        ? object.ipv6Addresses.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: NetworkInterface): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.vlanId !== 0) {
      obj.vlanId = Math.round(message.vlanId);
    }
    if (message.type !== 0) {
      obj.type = interfaceTypeToJSON(message.type);
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.vni !== 0) {
      obj.vni = Math.round(message.vni);
    }
    if (message.ipv4Addresses?.length) {
      obj.ipv4Addresses = message.ipv4Addresses;
    }
    if (message.ipv6Addresses?.length) {
      obj.ipv6Addresses = message.ipv6Addresses;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NetworkInterface>, I>>(base?: I): NetworkInterface {
    return NetworkInterface.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NetworkInterface>, I>>(object: I): NetworkInterface {
    const message = createBaseNetworkInterface();
    message.name = object.name ?? "";
    message.vlanId = object.vlanId ?? 0;
    message.type = object.type ?? 0;
    message.nodeId = object.nodeId ?? "";
    message.vni = object.vni ?? 0;
    message.ipv4Addresses = object.ipv4Addresses?.map((e) => e) || [];
    message.ipv6Addresses = object.ipv6Addresses?.map((e) => e) || [];
    return message;
  },
};

function createBaseRouteInfo(): RouteInfo {
  return { prefix: "", nextHop: "", nextVrf: "", nodeId: "", interface: "", preference: 0, discard: false, tag: 0 };
}

export const RouteInfo = {
  encode(message: RouteInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.prefix !== "") {
      writer.uint32(10).string(message.prefix);
    }
    if (message.nextHop !== "") {
      writer.uint32(18).string(message.nextHop);
    }
    if (message.nextVrf !== "") {
      writer.uint32(26).string(message.nextVrf);
    }
    if (message.nodeId !== "") {
      writer.uint32(34).string(message.nodeId);
    }
    if (message.interface !== "") {
      writer.uint32(42).string(message.interface);
    }
    if (message.preference !== 0) {
      writer.uint32(48).uint32(message.preference);
    }
    if (message.discard !== false) {
      writer.uint32(56).bool(message.discard);
    }
    if (message.tag !== 0) {
      writer.uint32(72).int32(message.tag);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RouteInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRouteInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.prefix = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nextHop = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nextVrf = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.interface = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.preference = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.discard = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.tag = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RouteInfo {
    return {
      prefix: isSet(object.prefix) ? globalThis.String(object.prefix) : "",
      nextHop: isSet(object.nextHop) ? globalThis.String(object.nextHop) : "",
      nextVrf: isSet(object.nextVrf) ? globalThis.String(object.nextVrf) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      interface: isSet(object.interface) ? globalThis.String(object.interface) : "",
      preference: isSet(object.preference) ? globalThis.Number(object.preference) : 0,
      discard: isSet(object.discard) ? globalThis.Boolean(object.discard) : false,
      tag: isSet(object.tag) ? routeTagFromJSON(object.tag) : 0,
    };
  },

  toJSON(message: RouteInfo): unknown {
    const obj: any = {};
    if (message.prefix !== "") {
      obj.prefix = message.prefix;
    }
    if (message.nextHop !== "") {
      obj.nextHop = message.nextHop;
    }
    if (message.nextVrf !== "") {
      obj.nextVrf = message.nextVrf;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.interface !== "") {
      obj.interface = message.interface;
    }
    if (message.preference !== 0) {
      obj.preference = Math.round(message.preference);
    }
    if (message.discard !== false) {
      obj.discard = message.discard;
    }
    if (message.tag !== 0) {
      obj.tag = routeTagToJSON(message.tag);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RouteInfo>, I>>(base?: I): RouteInfo {
    return RouteInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RouteInfo>, I>>(object: I): RouteInfo {
    const message = createBaseRouteInfo();
    message.prefix = object.prefix ?? "";
    message.nextHop = object.nextHop ?? "";
    message.nextVrf = object.nextVrf ?? "";
    message.nodeId = object.nodeId ?? "";
    message.interface = object.interface ?? "";
    message.preference = object.preference ?? 0;
    message.discard = object.discard ?? false;
    message.tag = object.tag ?? 0;
    return message;
  },
};

function createBasePortEndpoint(): PortEndpoint {
  return { portName: "", nodeId: "", nodeName: "" };
}

export const PortEndpoint = {
  encode(message: PortEndpoint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.portName !== "") {
      writer.uint32(10).string(message.portName);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.nodeName !== "") {
      writer.uint32(26).string(message.nodeName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortEndpoint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortEndpoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.portName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nodeName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortEndpoint {
    return {
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      nodeName: isSet(object.nodeName) ? globalThis.String(object.nodeName) : "",
    };
  },

  toJSON(message: PortEndpoint): unknown {
    const obj: any = {};
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.nodeName !== "") {
      obj.nodeName = message.nodeName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortEndpoint>, I>>(base?: I): PortEndpoint {
    return PortEndpoint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortEndpoint>, I>>(object: I): PortEndpoint {
    const message = createBasePortEndpoint();
    message.portName = object.portName ?? "";
    message.nodeId = object.nodeId ?? "";
    message.nodeName = object.nodeName ?? "";
    return message;
  },
};

function createBasePortConnection(): PortConnection {
  return {
    id: "",
    fabricId: "",
    description: "",
    local: undefined,
    remote: undefined,
    osType: 0,
    unrecognized: false,
    pluggable: "",
  };
}

export const PortConnection = {
  encode(message: PortConnection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.local !== undefined) {
      PortEndpoint.encode(message.local, writer.uint32(34).fork()).ldelim();
    }
    if (message.remote !== undefined) {
      PortEndpoint.encode(message.remote, writer.uint32(42).fork()).ldelim();
    }
    if (message.osType !== 0) {
      writer.uint32(48).int32(message.osType);
    }
    if (message.unrecognized !== false) {
      writer.uint32(56).bool(message.unrecognized);
    }
    if (message.pluggable !== "") {
      writer.uint32(74).string(message.pluggable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortConnection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortConnection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.local = PortEndpoint.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.remote = PortEndpoint.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.osType = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.unrecognized = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.pluggable = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortConnection {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      local: isSet(object.local) ? PortEndpoint.fromJSON(object.local) : undefined,
      remote: isSet(object.remote) ? PortEndpoint.fromJSON(object.remote) : undefined,
      osType: isSet(object.osType) ? osTypeFromJSON(object.osType) : 0,
      unrecognized: isSet(object.unrecognized) ? globalThis.Boolean(object.unrecognized) : false,
      pluggable: isSet(object.pluggable) ? globalThis.String(object.pluggable) : "",
    };
  },

  toJSON(message: PortConnection): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.local !== undefined) {
      obj.local = PortEndpoint.toJSON(message.local);
    }
    if (message.remote !== undefined) {
      obj.remote = PortEndpoint.toJSON(message.remote);
    }
    if (message.osType !== 0) {
      obj.osType = osTypeToJSON(message.osType);
    }
    if (message.unrecognized !== false) {
      obj.unrecognized = message.unrecognized;
    }
    if (message.pluggable !== "") {
      obj.pluggable = message.pluggable;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortConnection>, I>>(base?: I): PortConnection {
    return PortConnection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortConnection>, I>>(object: I): PortConnection {
    const message = createBasePortConnection();
    message.id = object.id ?? "";
    message.fabricId = object.fabricId ?? "";
    message.description = object.description ?? "";
    message.local = (object.local !== undefined && object.local !== null)
      ? PortEndpoint.fromPartial(object.local)
      : undefined;
    message.remote = (object.remote !== undefined && object.remote !== null)
      ? PortEndpoint.fromPartial(object.remote)
      : undefined;
    message.osType = object.osType ?? 0;
    message.unrecognized = object.unrecognized ?? false;
    message.pluggable = object.pluggable ?? "";
    return message;
  },
};

function createBaseVlanMember(): VlanMember {
  return { vlanId: 0, port: undefined, untagged: false };
}

export const VlanMember = {
  encode(message: VlanMember, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vlanId !== 0) {
      writer.uint32(8).uint32(message.vlanId);
    }
    if (message.port !== undefined) {
      PortEndpoint.encode(message.port, writer.uint32(18).fork()).ldelim();
    }
    if (message.untagged !== false) {
      writer.uint32(24).bool(message.untagged);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VlanMember {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVlanMember();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.vlanId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.port = PortEndpoint.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.untagged = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VlanMember {
    return {
      vlanId: isSet(object.vlanId) ? globalThis.Number(object.vlanId) : 0,
      port: isSet(object.port) ? PortEndpoint.fromJSON(object.port) : undefined,
      untagged: isSet(object.untagged) ? globalThis.Boolean(object.untagged) : false,
    };
  },

  toJSON(message: VlanMember): unknown {
    const obj: any = {};
    if (message.vlanId !== 0) {
      obj.vlanId = Math.round(message.vlanId);
    }
    if (message.port !== undefined) {
      obj.port = PortEndpoint.toJSON(message.port);
    }
    if (message.untagged !== false) {
      obj.untagged = message.untagged;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VlanMember>, I>>(base?: I): VlanMember {
    return VlanMember.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VlanMember>, I>>(object: I): VlanMember {
    const message = createBaseVlanMember();
    message.vlanId = object.vlanId ?? 0;
    message.port = (object.port !== undefined && object.port !== null)
      ? PortEndpoint.fromPartial(object.port)
      : undefined;
    message.untagged = object.untagged ?? false;
    return message;
  },
};

function createBaseSvi(): Svi {
  return { nodeId: "", vlanId: 0, ipv4Addresses: [], ipv6Addresses: [], enabled: false };
}

export const Svi = {
  encode(message: Svi, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeId !== "") {
      writer.uint32(10).string(message.nodeId);
    }
    if (message.vlanId !== 0) {
      writer.uint32(24).uint32(message.vlanId);
    }
    for (const v of message.ipv4Addresses) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.ipv6Addresses) {
      writer.uint32(34).string(v!);
    }
    if (message.enabled !== false) {
      writer.uint32(40).bool(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Svi {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSvi();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.vlanId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ipv4Addresses.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ipv6Addresses.push(reader.string());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Svi {
    return {
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      vlanId: isSet(object.vlanId) ? globalThis.Number(object.vlanId) : 0,
      ipv4Addresses: globalThis.Array.isArray(object?.ipv4Addresses)
        ? object.ipv4Addresses.map((e: any) => globalThis.String(e))
        : [],
      ipv6Addresses: globalThis.Array.isArray(object?.ipv6Addresses)
        ? object.ipv6Addresses.map((e: any) => globalThis.String(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
    };
  },

  toJSON(message: Svi): unknown {
    const obj: any = {};
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.vlanId !== 0) {
      obj.vlanId = Math.round(message.vlanId);
    }
    if (message.ipv4Addresses?.length) {
      obj.ipv4Addresses = message.ipv4Addresses;
    }
    if (message.ipv6Addresses?.length) {
      obj.ipv6Addresses = message.ipv6Addresses;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Svi>, I>>(base?: I): Svi {
    return Svi.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Svi>, I>>(object: I): Svi {
    const message = createBaseSvi();
    message.nodeId = object.nodeId ?? "";
    message.vlanId = object.vlanId ?? 0;
    message.ipv4Addresses = object.ipv4Addresses?.map((e) => e) || [];
    message.ipv6Addresses = object.ipv6Addresses?.map((e) => e) || [];
    message.enabled = object.enabled ?? false;
    return message;
  },
};

function createBaseVniDhcp(): VniDhcp {
  return { vni: 0, ipv4Enabled: false, ipv6Enabled: false };
}

export const VniDhcp = {
  encode(message: VniDhcp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vni !== 0) {
      writer.uint32(8).uint32(message.vni);
    }
    if (message.ipv4Enabled !== false) {
      writer.uint32(16).bool(message.ipv4Enabled);
    }
    if (message.ipv6Enabled !== false) {
      writer.uint32(24).bool(message.ipv6Enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VniDhcp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVniDhcp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.vni = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.ipv4Enabled = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.ipv6Enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VniDhcp {
    return {
      vni: isSet(object.vni) ? globalThis.Number(object.vni) : 0,
      ipv4Enabled: isSet(object.ipv4Enabled) ? globalThis.Boolean(object.ipv4Enabled) : false,
      ipv6Enabled: isSet(object.ipv6Enabled) ? globalThis.Boolean(object.ipv6Enabled) : false,
    };
  },

  toJSON(message: VniDhcp): unknown {
    const obj: any = {};
    if (message.vni !== 0) {
      obj.vni = Math.round(message.vni);
    }
    if (message.ipv4Enabled !== false) {
      obj.ipv4Enabled = message.ipv4Enabled;
    }
    if (message.ipv6Enabled !== false) {
      obj.ipv6Enabled = message.ipv6Enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VniDhcp>, I>>(base?: I): VniDhcp {
    return VniDhcp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VniDhcp>, I>>(object: I): VniDhcp {
    const message = createBaseVniDhcp();
    message.vni = object.vni ?? 0;
    message.ipv4Enabled = object.ipv4Enabled ?? false;
    message.ipv6Enabled = object.ipv6Enabled ?? false;
    return message;
  },
};

function createBaseInterfaceStp(): InterfaceStp {
  return { name: "", enabled: false, rootGuard: false, bpduGuard: false, portFast: false, uplinkFast: false };
}

export const InterfaceStp = {
  encode(message: InterfaceStp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    if (message.rootGuard !== false) {
      writer.uint32(24).bool(message.rootGuard);
    }
    if (message.bpduGuard !== false) {
      writer.uint32(32).bool(message.bpduGuard);
    }
    if (message.portFast !== false) {
      writer.uint32(40).bool(message.portFast);
    }
    if (message.uplinkFast !== false) {
      writer.uint32(48).bool(message.uplinkFast);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InterfaceStp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterfaceStp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.rootGuard = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bpduGuard = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.portFast = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.uplinkFast = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InterfaceStp {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      rootGuard: isSet(object.rootGuard) ? globalThis.Boolean(object.rootGuard) : false,
      bpduGuard: isSet(object.bpduGuard) ? globalThis.Boolean(object.bpduGuard) : false,
      portFast: isSet(object.portFast) ? globalThis.Boolean(object.portFast) : false,
      uplinkFast: isSet(object.uplinkFast) ? globalThis.Boolean(object.uplinkFast) : false,
    };
  },

  toJSON(message: InterfaceStp): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.rootGuard !== false) {
      obj.rootGuard = message.rootGuard;
    }
    if (message.bpduGuard !== false) {
      obj.bpduGuard = message.bpduGuard;
    }
    if (message.portFast !== false) {
      obj.portFast = message.portFast;
    }
    if (message.uplinkFast !== false) {
      obj.uplinkFast = message.uplinkFast;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InterfaceStp>, I>>(base?: I): InterfaceStp {
    return InterfaceStp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InterfaceStp>, I>>(object: I): InterfaceStp {
    const message = createBaseInterfaceStp();
    message.name = object.name ?? "";
    message.enabled = object.enabled ?? false;
    message.rootGuard = object.rootGuard ?? false;
    message.bpduGuard = object.bpduGuard ?? false;
    message.portFast = object.portFast ?? false;
    message.uplinkFast = object.uplinkFast ?? false;
    return message;
  },
};

function createBaseVlanStp(): VlanStp {
  return { vlanId: 0, enabled: false, forwardDelay: 0, helloInterval: 0, maxAge: 0, priority: 0, interfaces: [] };
}

export const VlanStp = {
  encode(message: VlanStp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vlanId !== 0) {
      writer.uint32(8).uint32(message.vlanId);
    }
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    if (message.forwardDelay !== 0) {
      writer.uint32(40).uint32(message.forwardDelay);
    }
    if (message.helloInterval !== 0) {
      writer.uint32(48).uint32(message.helloInterval);
    }
    if (message.maxAge !== 0) {
      writer.uint32(56).uint32(message.maxAge);
    }
    if (message.priority !== 0) {
      writer.uint32(64).uint32(message.priority);
    }
    for (const v of message.interfaces) {
      InterfaceStp.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VlanStp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVlanStp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.vlanId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.forwardDelay = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.helloInterval = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.maxAge = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.priority = reader.uint32();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.interfaces.push(InterfaceStp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VlanStp {
    return {
      vlanId: isSet(object.vlanId) ? globalThis.Number(object.vlanId) : 0,
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      forwardDelay: isSet(object.forwardDelay) ? globalThis.Number(object.forwardDelay) : 0,
      helloInterval: isSet(object.helloInterval) ? globalThis.Number(object.helloInterval) : 0,
      maxAge: isSet(object.maxAge) ? globalThis.Number(object.maxAge) : 0,
      priority: isSet(object.priority) ? globalThis.Number(object.priority) : 0,
      interfaces: globalThis.Array.isArray(object?.interfaces)
        ? object.interfaces.map((e: any) => InterfaceStp.fromJSON(e))
        : [],
    };
  },

  toJSON(message: VlanStp): unknown {
    const obj: any = {};
    if (message.vlanId !== 0) {
      obj.vlanId = Math.round(message.vlanId);
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.forwardDelay !== 0) {
      obj.forwardDelay = Math.round(message.forwardDelay);
    }
    if (message.helloInterval !== 0) {
      obj.helloInterval = Math.round(message.helloInterval);
    }
    if (message.maxAge !== 0) {
      obj.maxAge = Math.round(message.maxAge);
    }
    if (message.priority !== 0) {
      obj.priority = Math.round(message.priority);
    }
    if (message.interfaces?.length) {
      obj.interfaces = message.interfaces.map((e) => InterfaceStp.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VlanStp>, I>>(base?: I): VlanStp {
    return VlanStp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VlanStp>, I>>(object: I): VlanStp {
    const message = createBaseVlanStp();
    message.vlanId = object.vlanId ?? 0;
    message.enabled = object.enabled ?? false;
    message.forwardDelay = object.forwardDelay ?? 0;
    message.helloInterval = object.helloInterval ?? 0;
    message.maxAge = object.maxAge ?? 0;
    message.priority = object.priority ?? 0;
    message.interfaces = object.interfaces?.map((e) => InterfaceStp.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSpeedMode(): SpeedMode {
  return { speed: "", mode: "", isDefault: false, ports: [] };
}

export const SpeedMode = {
  encode(message: SpeedMode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.speed !== "") {
      writer.uint32(10).string(message.speed);
    }
    if (message.mode !== "") {
      writer.uint32(18).string(message.mode);
    }
    if (message.isDefault !== false) {
      writer.uint32(24).bool(message.isDefault);
    }
    for (const v of message.ports) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpeedMode {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpeedMode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.speed = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mode = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isDefault = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ports.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpeedMode {
    return {
      speed: isSet(object.speed) ? globalThis.String(object.speed) : "",
      mode: isSet(object.mode) ? globalThis.String(object.mode) : "",
      isDefault: isSet(object.isDefault) ? globalThis.Boolean(object.isDefault) : false,
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: SpeedMode): unknown {
    const obj: any = {};
    if (message.speed !== "") {
      obj.speed = message.speed;
    }
    if (message.mode !== "") {
      obj.mode = message.mode;
    }
    if (message.isDefault !== false) {
      obj.isDefault = message.isDefault;
    }
    if (message.ports?.length) {
      obj.ports = message.ports;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpeedMode>, I>>(base?: I): SpeedMode {
    return SpeedMode.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpeedMode>, I>>(object: I): SpeedMode {
    const message = createBaseSpeedMode();
    message.speed = object.speed ?? "";
    message.mode = object.mode ?? "";
    message.isDefault = object.isDefault ?? false;
    message.ports = object.ports?.map((e) => e) || [];
    return message;
  },
};

function createBaseCertificates(): Certificates {
  return { id: "", name: "", description: "", enabled: false, fingerprint: "", certificates: "" };
}

export const Certificates = {
  encode(message: Certificates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.enabled !== false) {
      writer.uint32(32).bool(message.enabled);
    }
    if (message.fingerprint !== "") {
      writer.uint32(42).string(message.fingerprint);
    }
    if (message.certificates !== "") {
      writer.uint32(50).string(message.certificates);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Certificates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCertificates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fingerprint = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.certificates = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Certificates {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      fingerprint: isSet(object.fingerprint) ? globalThis.String(object.fingerprint) : "",
      certificates: isSet(object.certificates) ? globalThis.String(object.certificates) : "",
    };
  },

  toJSON(message: Certificates): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.fingerprint !== "") {
      obj.fingerprint = message.fingerprint;
    }
    if (message.certificates !== "") {
      obj.certificates = message.certificates;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Certificates>, I>>(base?: I): Certificates {
    return Certificates.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Certificates>, I>>(object: I): Certificates {
    const message = createBaseCertificates();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.enabled = object.enabled ?? false;
    message.fingerprint = object.fingerprint ?? "";
    message.certificates = object.certificates ?? "";
    return message;
  },
};

function createBasePsuAirflow(): PsuAirflow {
  return { airflow: 0, psuModel: "" };
}

export const PsuAirflow = {
  encode(message: PsuAirflow, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.airflow !== 0) {
      writer.uint32(8).int32(message.airflow);
    }
    if (message.psuModel !== "") {
      writer.uint32(18).string(message.psuModel);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PsuAirflow {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePsuAirflow();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.airflow = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.psuModel = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PsuAirflow {
    return {
      airflow: isSet(object.airflow) ? airflowTypeFromJSON(object.airflow) : 0,
      psuModel: isSet(object.psuModel) ? globalThis.String(object.psuModel) : "",
    };
  },

  toJSON(message: PsuAirflow): unknown {
    const obj: any = {};
    if (message.airflow !== 0) {
      obj.airflow = airflowTypeToJSON(message.airflow);
    }
    if (message.psuModel !== "") {
      obj.psuModel = message.psuModel;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PsuAirflow>, I>>(base?: I): PsuAirflow {
    return PsuAirflow.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PsuAirflow>, I>>(object: I): PsuAirflow {
    const message = createBasePsuAirflow();
    message.airflow = object.airflow ?? 0;
    message.psuModel = object.psuModel ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
