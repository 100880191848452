import { Modal } from "@magnetic/modal";
import React, { useCallback, useContext, useState } from "react";
import { useForm } from "src/hooks";
import { ApiError } from "src/core";
import VFlex from "components/vFlex";
import PageHeader from "src/common/pageHeader";
import { Notification } from "@magnetic/notification";
import { Input } from "src/components";
import HFlex from "components/hFlex";
import { Button } from "@magnetic/button";
import { AddOrgRequest, AddOrgResponse } from "src/gen/schema/brig/bapi";
import { LoginContext } from "src/core/loginContext";
import { Apis } from "src/utils/api";
const { Brig } = Apis;

type OrgFormFields = {
  name: string;
};

const defaultFormValues: OrgFormFields = {
  name: ""
};

interface OrgModalProps {
  readonly onClose: () => void;
}

export const OrgModal = ({ onClose }: OrgModalProps): React.ReactNode => {
  const { switchTenant } = useContext(LoginContext);
  const { form, handleInputChange } = useForm(defaultFormValues);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const onSubmit = useCallback(() => {
    setLoading(true);
    const req: AddOrgRequest = AddOrgRequest.create({
      orgName: form.name.trim()
    });
    Brig.addOrg(req)
      .then(
        (resp: AddOrgResponse) => {
          if (resp) {
            switchTenant(resp.orgId);
          }
        },
        (err: () => ApiError) => {
          setError(err().getMessage());
        }
      )
      .finally(() => {
        setLoading(false);
      });
  }, [form, switchTenant]);

  return (
    <Modal isOpen={true} size="md" data-qa="org-modal" className="org-modal">
      <VFlex>
        <PageHeader title={"Create new organization"} />
        <Notification status="informational">
          Once an organization is created it cannot be deleted.
        </Notification>
        {error && <Notification status="negative">{error}</Notification>}
        <Input
          key="org-name"
          name="name"
          id="org-name"
          value={form.name}
          label="Organization name"
          required={true}
          size="md"
          autoComplete="off"
          onChange={handleInputChange}
        />
        <HFlex justify="flex-end">
          <Button.Group>
            <Button
              name="org-cancel"
              kind="tertiary"
              size="md"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              name="org-save"
              onClick={onSubmit}
              size="md"
              disabled={loading || !form.name}
            >
              Save
            </Button>
          </Button.Group>
        </HFlex>
      </VFlex>
    </Modal>
  );
};
