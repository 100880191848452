// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: brig/bagent.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";

export const protobufPackage = "brig";

export enum HashAlgo {
  HASH_INVALID = 0,
  SHA256 = 1,
  UNRECOGNIZED = -1,
}

export function hashAlgoFromJSON(object: any): HashAlgo {
  switch (object) {
    case 0:
    case "HASH_INVALID":
      return HashAlgo.HASH_INVALID;
    case 1:
    case "SHA256":
      return HashAlgo.SHA256;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HashAlgo.UNRECOGNIZED;
  }
}

export function hashAlgoToJSON(object: HashAlgo): string {
  switch (object) {
    case HashAlgo.HASH_INVALID:
      return "HASH_INVALID";
    case HashAlgo.SHA256:
      return "SHA256";
    case HashAlgo.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface AuthRequestHeader {
  /** Signature generated over the data */
  signature: Uint8Array;
  /** Signature type, RSA-PKCS1 by default */
  signatureType: string;
  sudiCertificatesChain: Uint8Array[];
}

export interface AuthResponseHeader {
  /** Signature generated over the data */
  signature: Uint8Array;
  /** Signature type, RSA-PKCS1 by default */
  signatureType: string;
  brigCertificatesChain: Uint8Array[];
}

/** All the switch metadata are dereived from "show platform idprom" */
export interface SwitchMetaData {
  /** Switch base macaddress */
  deviceId: string;
  /** Switch Chassis Serial number */
  switchSerialNum: string;
  /** Switch Model number */
  switchPid: string;
}

export interface AttestStartData {
  transactionId: string;
  nonce1: Uint8Array;
  switchMeta: SwitchMetaData | undefined;
}

export interface AgentAttestStartRequest {
  deviceId: string;
  agentVersion: string;
  requestData: AttestStartData | undefined;
}

export interface CloudBlobResponse {
  keyId: string;
  hmac: Uint8Array;
  blobData: Uint8Array;
}

export interface CloudBlob {
  serverAttestStartTimestampUTC: Date | undefined;
  transactionId: string;
  switchMeta: SwitchMetaData | undefined;
  nonce1: Uint8Array;
  nonce2: Uint8Array;
}

export interface Quote {
  /** SUDI-quote data */
  sudiQuote: Uint8Array;
  /** SUDI-QUOTE detached signature */
  signature: Uint8Array;
  /** PKCS1 by default */
  sudiQuoteSignatureType: string;
  /** PCR values of the bank being quoted */
  pcrs: PCRS | undefined;
}

export interface PCREntry {
  index: number;
  digest: Uint8Array;
}

export interface PCRS {
  hash: HashAlgo;
  pcrs: PCREntry[];
}

export interface SudiQuoteMetadata {
  /** Actual SUDI-QUOTE blob */
  sudiQuote: Quote[];
  /** Model name or ProductID */
  productId: string;
  /** SONiC image version */
  sonicVersion: string;
  /** UEFI BIOS version */
  uefiBiosVersion: string;
  /** Boot program (GRUB currently) sha256 hash */
  bootProgramVersion: string;
  /** Linux kernel sha256 hash */
  kernelVersion: string;
  /** system service manager version */
  systemdVersion: string;
  /** true if SUDI-QUOTE is retrieved from TAM HW. SW platforms will set this as false */
  hwQuote: boolean;
  /** Certificate(s), leaf(at 0 index) and all SubCAs, for verifying the SUDI-QUOTE */
  certificateChain: Uint8Array[];
}

export interface AttestStartResponseData {
  transactionId: string;
  serverAttestStartTimestampUTC: Date | undefined;
  nonce1: Uint8Array;
  nonce2: Uint8Array;
  blob: Uint8Array;
}

export interface AttestStartResponseError {
  transactionId: string;
  serverAttestStartTimestampUTC: Date | undefined;
  Nonce1: Uint8Array;
  ErrCode: string;
  ErrMsg: string;
}

export interface AgentAttestStartResponse {
  respHdr: AuthResponseHeader | undefined;
  respData: AttestStartResponseData | undefined;
  respErrData: AttestStartResponseError | undefined;
}

export interface AttestCompleteRequestData {
  transactionId: string;
  switchMeta: SwitchMetaData | undefined;
  deviceTimestampUTC: Date | undefined;
  nonce2: Uint8Array;
  nonce3: Uint8Array;
  blob: Uint8Array;
  sudiQuoteMeta: SudiQuoteMetadata | undefined;
}

export interface AgentAttestCompleteRequest {
  deviceId: string;
  agentVersion: string;
  reqHdr: AuthRequestHeader | undefined;
  completeData: AttestCompleteRequestData | undefined;
}

export interface AttestCompleteResponseData {
  transactionId: string;
  serverAttestCompleteTimestampUTC: Date | undefined;
  nonce3: Uint8Array;
  agentSessionToken: Uint8Array;
}

export interface AttestCompleteResponseErrorData {
  transactionId: string;
  serverAttestCompleteTimestampUTC: Date | undefined;
  nonce3: Uint8Array;
  errCode: string;
  errMsg: string;
  retryAfterSeconds: number;
}

export interface AgentAttestCompleteResponse {
  respHdr: AuthResponseHeader | undefined;
  respData: AttestCompleteResponseData | undefined;
  respErrData: AttestCompleteResponseErrorData | undefined;
}

function createBaseAuthRequestHeader(): AuthRequestHeader {
  return { signature: new Uint8Array(0), signatureType: "", sudiCertificatesChain: [] };
}

export const AuthRequestHeader = {
  encode(message: AuthRequestHeader, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.signature.length !== 0) {
      writer.uint32(10).bytes(message.signature);
    }
    if (message.signatureType !== "") {
      writer.uint32(18).string(message.signatureType);
    }
    for (const v of message.sudiCertificatesChain) {
      writer.uint32(26).bytes(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthRequestHeader {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthRequestHeader();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.signature = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.signatureType = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sudiCertificatesChain.push(reader.bytes());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthRequestHeader {
    return {
      signature: isSet(object.signature) ? bytesFromBase64(object.signature) : new Uint8Array(0),
      signatureType: isSet(object.signatureType) ? globalThis.String(object.signatureType) : "",
      sudiCertificatesChain: globalThis.Array.isArray(object?.sudiCertificatesChain)
        ? object.sudiCertificatesChain.map((e: any) => bytesFromBase64(e))
        : [],
    };
  },

  toJSON(message: AuthRequestHeader): unknown {
    const obj: any = {};
    if (message.signature.length !== 0) {
      obj.signature = base64FromBytes(message.signature);
    }
    if (message.signatureType !== "") {
      obj.signatureType = message.signatureType;
    }
    if (message.sudiCertificatesChain?.length) {
      obj.sudiCertificatesChain = message.sudiCertificatesChain.map((e) => base64FromBytes(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthRequestHeader>, I>>(base?: I): AuthRequestHeader {
    return AuthRequestHeader.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthRequestHeader>, I>>(object: I): AuthRequestHeader {
    const message = createBaseAuthRequestHeader();
    message.signature = object.signature ?? new Uint8Array(0);
    message.signatureType = object.signatureType ?? "";
    message.sudiCertificatesChain = object.sudiCertificatesChain?.map((e) => e) || [];
    return message;
  },
};

function createBaseAuthResponseHeader(): AuthResponseHeader {
  return { signature: new Uint8Array(0), signatureType: "", brigCertificatesChain: [] };
}

export const AuthResponseHeader = {
  encode(message: AuthResponseHeader, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.signature.length !== 0) {
      writer.uint32(10).bytes(message.signature);
    }
    if (message.signatureType !== "") {
      writer.uint32(18).string(message.signatureType);
    }
    for (const v of message.brigCertificatesChain) {
      writer.uint32(26).bytes(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthResponseHeader {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthResponseHeader();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.signature = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.signatureType = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.brigCertificatesChain.push(reader.bytes());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthResponseHeader {
    return {
      signature: isSet(object.signature) ? bytesFromBase64(object.signature) : new Uint8Array(0),
      signatureType: isSet(object.signatureType) ? globalThis.String(object.signatureType) : "",
      brigCertificatesChain: globalThis.Array.isArray(object?.brigCertificatesChain)
        ? object.brigCertificatesChain.map((e: any) => bytesFromBase64(e))
        : [],
    };
  },

  toJSON(message: AuthResponseHeader): unknown {
    const obj: any = {};
    if (message.signature.length !== 0) {
      obj.signature = base64FromBytes(message.signature);
    }
    if (message.signatureType !== "") {
      obj.signatureType = message.signatureType;
    }
    if (message.brigCertificatesChain?.length) {
      obj.brigCertificatesChain = message.brigCertificatesChain.map((e) => base64FromBytes(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthResponseHeader>, I>>(base?: I): AuthResponseHeader {
    return AuthResponseHeader.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthResponseHeader>, I>>(object: I): AuthResponseHeader {
    const message = createBaseAuthResponseHeader();
    message.signature = object.signature ?? new Uint8Array(0);
    message.signatureType = object.signatureType ?? "";
    message.brigCertificatesChain = object.brigCertificatesChain?.map((e) => e) || [];
    return message;
  },
};

function createBaseSwitchMetaData(): SwitchMetaData {
  return { deviceId: "", switchSerialNum: "", switchPid: "" };
}

export const SwitchMetaData = {
  encode(message: SwitchMetaData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.switchSerialNum !== "") {
      writer.uint32(18).string(message.switchSerialNum);
    }
    if (message.switchPid !== "") {
      writer.uint32(26).string(message.switchPid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SwitchMetaData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSwitchMetaData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.switchSerialNum = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.switchPid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SwitchMetaData {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      switchSerialNum: isSet(object.switchSerialNum) ? globalThis.String(object.switchSerialNum) : "",
      switchPid: isSet(object.switchPid) ? globalThis.String(object.switchPid) : "",
    };
  },

  toJSON(message: SwitchMetaData): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.switchSerialNum !== "") {
      obj.switchSerialNum = message.switchSerialNum;
    }
    if (message.switchPid !== "") {
      obj.switchPid = message.switchPid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SwitchMetaData>, I>>(base?: I): SwitchMetaData {
    return SwitchMetaData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SwitchMetaData>, I>>(object: I): SwitchMetaData {
    const message = createBaseSwitchMetaData();
    message.deviceId = object.deviceId ?? "";
    message.switchSerialNum = object.switchSerialNum ?? "";
    message.switchPid = object.switchPid ?? "";
    return message;
  },
};

function createBaseAttestStartData(): AttestStartData {
  return { transactionId: "", nonce1: new Uint8Array(0), switchMeta: undefined };
}

export const AttestStartData = {
  encode(message: AttestStartData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transactionId !== "") {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.nonce1.length !== 0) {
      writer.uint32(18).bytes(message.nonce1);
    }
    if (message.switchMeta !== undefined) {
      SwitchMetaData.encode(message.switchMeta, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttestStartData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttestStartData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nonce1 = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.switchMeta = SwitchMetaData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttestStartData {
    return {
      transactionId: isSet(object.transactionId) ? globalThis.String(object.transactionId) : "",
      nonce1: isSet(object.nonce1) ? bytesFromBase64(object.nonce1) : new Uint8Array(0),
      switchMeta: isSet(object.switchMeta) ? SwitchMetaData.fromJSON(object.switchMeta) : undefined,
    };
  },

  toJSON(message: AttestStartData): unknown {
    const obj: any = {};
    if (message.transactionId !== "") {
      obj.transactionId = message.transactionId;
    }
    if (message.nonce1.length !== 0) {
      obj.nonce1 = base64FromBytes(message.nonce1);
    }
    if (message.switchMeta !== undefined) {
      obj.switchMeta = SwitchMetaData.toJSON(message.switchMeta);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttestStartData>, I>>(base?: I): AttestStartData {
    return AttestStartData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttestStartData>, I>>(object: I): AttestStartData {
    const message = createBaseAttestStartData();
    message.transactionId = object.transactionId ?? "";
    message.nonce1 = object.nonce1 ?? new Uint8Array(0);
    message.switchMeta = (object.switchMeta !== undefined && object.switchMeta !== null)
      ? SwitchMetaData.fromPartial(object.switchMeta)
      : undefined;
    return message;
  },
};

function createBaseAgentAttestStartRequest(): AgentAttestStartRequest {
  return { deviceId: "", agentVersion: "", requestData: undefined };
}

export const AgentAttestStartRequest = {
  encode(message: AgentAttestStartRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.agentVersion !== "") {
      writer.uint32(18).string(message.agentVersion);
    }
    if (message.requestData !== undefined) {
      AttestStartData.encode(message.requestData, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AgentAttestStartRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAgentAttestStartRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.agentVersion = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.requestData = AttestStartData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AgentAttestStartRequest {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      agentVersion: isSet(object.agentVersion) ? globalThis.String(object.agentVersion) : "",
      requestData: isSet(object.requestData) ? AttestStartData.fromJSON(object.requestData) : undefined,
    };
  },

  toJSON(message: AgentAttestStartRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.agentVersion !== "") {
      obj.agentVersion = message.agentVersion;
    }
    if (message.requestData !== undefined) {
      obj.requestData = AttestStartData.toJSON(message.requestData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AgentAttestStartRequest>, I>>(base?: I): AgentAttestStartRequest {
    return AgentAttestStartRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AgentAttestStartRequest>, I>>(object: I): AgentAttestStartRequest {
    const message = createBaseAgentAttestStartRequest();
    message.deviceId = object.deviceId ?? "";
    message.agentVersion = object.agentVersion ?? "";
    message.requestData = (object.requestData !== undefined && object.requestData !== null)
      ? AttestStartData.fromPartial(object.requestData)
      : undefined;
    return message;
  },
};

function createBaseCloudBlobResponse(): CloudBlobResponse {
  return { keyId: "", hmac: new Uint8Array(0), blobData: new Uint8Array(0) };
}

export const CloudBlobResponse = {
  encode(message: CloudBlobResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.keyId !== "") {
      writer.uint32(10).string(message.keyId);
    }
    if (message.hmac.length !== 0) {
      writer.uint32(18).bytes(message.hmac);
    }
    if (message.blobData.length !== 0) {
      writer.uint32(26).bytes(message.blobData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CloudBlobResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCloudBlobResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keyId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.hmac = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.blobData = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CloudBlobResponse {
    return {
      keyId: isSet(object.keyId) ? globalThis.String(object.keyId) : "",
      hmac: isSet(object.hmac) ? bytesFromBase64(object.hmac) : new Uint8Array(0),
      blobData: isSet(object.blobData) ? bytesFromBase64(object.blobData) : new Uint8Array(0),
    };
  },

  toJSON(message: CloudBlobResponse): unknown {
    const obj: any = {};
    if (message.keyId !== "") {
      obj.keyId = message.keyId;
    }
    if (message.hmac.length !== 0) {
      obj.hmac = base64FromBytes(message.hmac);
    }
    if (message.blobData.length !== 0) {
      obj.blobData = base64FromBytes(message.blobData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CloudBlobResponse>, I>>(base?: I): CloudBlobResponse {
    return CloudBlobResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CloudBlobResponse>, I>>(object: I): CloudBlobResponse {
    const message = createBaseCloudBlobResponse();
    message.keyId = object.keyId ?? "";
    message.hmac = object.hmac ?? new Uint8Array(0);
    message.blobData = object.blobData ?? new Uint8Array(0);
    return message;
  },
};

function createBaseCloudBlob(): CloudBlob {
  return {
    serverAttestStartTimestampUTC: undefined,
    transactionId: "",
    switchMeta: undefined,
    nonce1: new Uint8Array(0),
    nonce2: new Uint8Array(0),
  };
}

export const CloudBlob = {
  encode(message: CloudBlob, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serverAttestStartTimestampUTC !== undefined) {
      Timestamp.encode(toTimestamp(message.serverAttestStartTimestampUTC), writer.uint32(10).fork()).ldelim();
    }
    if (message.transactionId !== "") {
      writer.uint32(18).string(message.transactionId);
    }
    if (message.switchMeta !== undefined) {
      SwitchMetaData.encode(message.switchMeta, writer.uint32(26).fork()).ldelim();
    }
    if (message.nonce1.length !== 0) {
      writer.uint32(34).bytes(message.nonce1);
    }
    if (message.nonce2.length !== 0) {
      writer.uint32(42).bytes(message.nonce2);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CloudBlob {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCloudBlob();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serverAttestStartTimestampUTC = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.switchMeta = SwitchMetaData.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.nonce1 = reader.bytes();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.nonce2 = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CloudBlob {
    return {
      serverAttestStartTimestampUTC: isSet(object.serverAttestStartTimestampUTC)
        ? fromJsonTimestamp(object.serverAttestStartTimestampUTC)
        : undefined,
      transactionId: isSet(object.transactionId) ? globalThis.String(object.transactionId) : "",
      switchMeta: isSet(object.switchMeta) ? SwitchMetaData.fromJSON(object.switchMeta) : undefined,
      nonce1: isSet(object.nonce1) ? bytesFromBase64(object.nonce1) : new Uint8Array(0),
      nonce2: isSet(object.nonce2) ? bytesFromBase64(object.nonce2) : new Uint8Array(0),
    };
  },

  toJSON(message: CloudBlob): unknown {
    const obj: any = {};
    if (message.serverAttestStartTimestampUTC !== undefined) {
      obj.serverAttestStartTimestampUTC = message.serverAttestStartTimestampUTC.toISOString();
    }
    if (message.transactionId !== "") {
      obj.transactionId = message.transactionId;
    }
    if (message.switchMeta !== undefined) {
      obj.switchMeta = SwitchMetaData.toJSON(message.switchMeta);
    }
    if (message.nonce1.length !== 0) {
      obj.nonce1 = base64FromBytes(message.nonce1);
    }
    if (message.nonce2.length !== 0) {
      obj.nonce2 = base64FromBytes(message.nonce2);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CloudBlob>, I>>(base?: I): CloudBlob {
    return CloudBlob.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CloudBlob>, I>>(object: I): CloudBlob {
    const message = createBaseCloudBlob();
    message.serverAttestStartTimestampUTC = object.serverAttestStartTimestampUTC ?? undefined;
    message.transactionId = object.transactionId ?? "";
    message.switchMeta = (object.switchMeta !== undefined && object.switchMeta !== null)
      ? SwitchMetaData.fromPartial(object.switchMeta)
      : undefined;
    message.nonce1 = object.nonce1 ?? new Uint8Array(0);
    message.nonce2 = object.nonce2 ?? new Uint8Array(0);
    return message;
  },
};

function createBaseQuote(): Quote {
  return { sudiQuote: new Uint8Array(0), signature: new Uint8Array(0), sudiQuoteSignatureType: "", pcrs: undefined };
}

export const Quote = {
  encode(message: Quote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sudiQuote.length !== 0) {
      writer.uint32(10).bytes(message.sudiQuote);
    }
    if (message.signature.length !== 0) {
      writer.uint32(18).bytes(message.signature);
    }
    if (message.sudiQuoteSignatureType !== "") {
      writer.uint32(26).string(message.sudiQuoteSignatureType);
    }
    if (message.pcrs !== undefined) {
      PCRS.encode(message.pcrs, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Quote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sudiQuote = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.signature = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sudiQuoteSignatureType = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pcrs = PCRS.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Quote {
    return {
      sudiQuote: isSet(object.sudiQuote) ? bytesFromBase64(object.sudiQuote) : new Uint8Array(0),
      signature: isSet(object.signature) ? bytesFromBase64(object.signature) : new Uint8Array(0),
      sudiQuoteSignatureType: isSet(object.sudiQuoteSignatureType)
        ? globalThis.String(object.sudiQuoteSignatureType)
        : "",
      pcrs: isSet(object.pcrs) ? PCRS.fromJSON(object.pcrs) : undefined,
    };
  },

  toJSON(message: Quote): unknown {
    const obj: any = {};
    if (message.sudiQuote.length !== 0) {
      obj.sudiQuote = base64FromBytes(message.sudiQuote);
    }
    if (message.signature.length !== 0) {
      obj.signature = base64FromBytes(message.signature);
    }
    if (message.sudiQuoteSignatureType !== "") {
      obj.sudiQuoteSignatureType = message.sudiQuoteSignatureType;
    }
    if (message.pcrs !== undefined) {
      obj.pcrs = PCRS.toJSON(message.pcrs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Quote>, I>>(base?: I): Quote {
    return Quote.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Quote>, I>>(object: I): Quote {
    const message = createBaseQuote();
    message.sudiQuote = object.sudiQuote ?? new Uint8Array(0);
    message.signature = object.signature ?? new Uint8Array(0);
    message.sudiQuoteSignatureType = object.sudiQuoteSignatureType ?? "";
    message.pcrs = (object.pcrs !== undefined && object.pcrs !== null) ? PCRS.fromPartial(object.pcrs) : undefined;
    return message;
  },
};

function createBasePCREntry(): PCREntry {
  return { index: 0, digest: new Uint8Array(0) };
}

export const PCREntry = {
  encode(message: PCREntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.index !== 0) {
      writer.uint32(8).uint32(message.index);
    }
    if (message.digest.length !== 0) {
      writer.uint32(18).bytes(message.digest);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PCREntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePCREntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.index = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.digest = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PCREntry {
    return {
      index: isSet(object.index) ? globalThis.Number(object.index) : 0,
      digest: isSet(object.digest) ? bytesFromBase64(object.digest) : new Uint8Array(0),
    };
  },

  toJSON(message: PCREntry): unknown {
    const obj: any = {};
    if (message.index !== 0) {
      obj.index = Math.round(message.index);
    }
    if (message.digest.length !== 0) {
      obj.digest = base64FromBytes(message.digest);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PCREntry>, I>>(base?: I): PCREntry {
    return PCREntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PCREntry>, I>>(object: I): PCREntry {
    const message = createBasePCREntry();
    message.index = object.index ?? 0;
    message.digest = object.digest ?? new Uint8Array(0);
    return message;
  },
};

function createBasePCRS(): PCRS {
  return { hash: 0, pcrs: [] };
}

export const PCRS = {
  encode(message: PCRS, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.hash !== 0) {
      writer.uint32(8).int32(message.hash);
    }
    for (const v of message.pcrs) {
      PCREntry.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PCRS {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePCRS();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.hash = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pcrs.push(PCREntry.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PCRS {
    return {
      hash: isSet(object.hash) ? hashAlgoFromJSON(object.hash) : 0,
      pcrs: globalThis.Array.isArray(object?.pcrs) ? object.pcrs.map((e: any) => PCREntry.fromJSON(e)) : [],
    };
  },

  toJSON(message: PCRS): unknown {
    const obj: any = {};
    if (message.hash !== 0) {
      obj.hash = hashAlgoToJSON(message.hash);
    }
    if (message.pcrs?.length) {
      obj.pcrs = message.pcrs.map((e) => PCREntry.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PCRS>, I>>(base?: I): PCRS {
    return PCRS.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PCRS>, I>>(object: I): PCRS {
    const message = createBasePCRS();
    message.hash = object.hash ?? 0;
    message.pcrs = object.pcrs?.map((e) => PCREntry.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSudiQuoteMetadata(): SudiQuoteMetadata {
  return {
    sudiQuote: [],
    productId: "",
    sonicVersion: "",
    uefiBiosVersion: "",
    bootProgramVersion: "",
    kernelVersion: "",
    systemdVersion: "",
    hwQuote: false,
    certificateChain: [],
  };
}

export const SudiQuoteMetadata = {
  encode(message: SudiQuoteMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.sudiQuote) {
      Quote.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.productId !== "") {
      writer.uint32(18).string(message.productId);
    }
    if (message.sonicVersion !== "") {
      writer.uint32(26).string(message.sonicVersion);
    }
    if (message.uefiBiosVersion !== "") {
      writer.uint32(34).string(message.uefiBiosVersion);
    }
    if (message.bootProgramVersion !== "") {
      writer.uint32(42).string(message.bootProgramVersion);
    }
    if (message.kernelVersion !== "") {
      writer.uint32(50).string(message.kernelVersion);
    }
    if (message.systemdVersion !== "") {
      writer.uint32(58).string(message.systemdVersion);
    }
    if (message.hwQuote !== false) {
      writer.uint32(64).bool(message.hwQuote);
    }
    for (const v of message.certificateChain) {
      writer.uint32(74).bytes(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SudiQuoteMetadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSudiQuoteMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sudiQuote.push(Quote.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.productId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sonicVersion = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.uefiBiosVersion = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.bootProgramVersion = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.kernelVersion = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.systemdVersion = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.hwQuote = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.certificateChain.push(reader.bytes());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SudiQuoteMetadata {
    return {
      sudiQuote: globalThis.Array.isArray(object?.sudiQuote) ? object.sudiQuote.map((e: any) => Quote.fromJSON(e)) : [],
      productId: isSet(object.productId) ? globalThis.String(object.productId) : "",
      sonicVersion: isSet(object.sonicVersion) ? globalThis.String(object.sonicVersion) : "",
      uefiBiosVersion: isSet(object.uefiBiosVersion) ? globalThis.String(object.uefiBiosVersion) : "",
      bootProgramVersion: isSet(object.bootProgramVersion) ? globalThis.String(object.bootProgramVersion) : "",
      kernelVersion: isSet(object.kernelVersion) ? globalThis.String(object.kernelVersion) : "",
      systemdVersion: isSet(object.systemdVersion) ? globalThis.String(object.systemdVersion) : "",
      hwQuote: isSet(object.hwQuote) ? globalThis.Boolean(object.hwQuote) : false,
      certificateChain: globalThis.Array.isArray(object?.certificateChain)
        ? object.certificateChain.map((e: any) => bytesFromBase64(e))
        : [],
    };
  },

  toJSON(message: SudiQuoteMetadata): unknown {
    const obj: any = {};
    if (message.sudiQuote?.length) {
      obj.sudiQuote = message.sudiQuote.map((e) => Quote.toJSON(e));
    }
    if (message.productId !== "") {
      obj.productId = message.productId;
    }
    if (message.sonicVersion !== "") {
      obj.sonicVersion = message.sonicVersion;
    }
    if (message.uefiBiosVersion !== "") {
      obj.uefiBiosVersion = message.uefiBiosVersion;
    }
    if (message.bootProgramVersion !== "") {
      obj.bootProgramVersion = message.bootProgramVersion;
    }
    if (message.kernelVersion !== "") {
      obj.kernelVersion = message.kernelVersion;
    }
    if (message.systemdVersion !== "") {
      obj.systemdVersion = message.systemdVersion;
    }
    if (message.hwQuote !== false) {
      obj.hwQuote = message.hwQuote;
    }
    if (message.certificateChain?.length) {
      obj.certificateChain = message.certificateChain.map((e) => base64FromBytes(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SudiQuoteMetadata>, I>>(base?: I): SudiQuoteMetadata {
    return SudiQuoteMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SudiQuoteMetadata>, I>>(object: I): SudiQuoteMetadata {
    const message = createBaseSudiQuoteMetadata();
    message.sudiQuote = object.sudiQuote?.map((e) => Quote.fromPartial(e)) || [];
    message.productId = object.productId ?? "";
    message.sonicVersion = object.sonicVersion ?? "";
    message.uefiBiosVersion = object.uefiBiosVersion ?? "";
    message.bootProgramVersion = object.bootProgramVersion ?? "";
    message.kernelVersion = object.kernelVersion ?? "";
    message.systemdVersion = object.systemdVersion ?? "";
    message.hwQuote = object.hwQuote ?? false;
    message.certificateChain = object.certificateChain?.map((e) => e) || [];
    return message;
  },
};

function createBaseAttestStartResponseData(): AttestStartResponseData {
  return {
    transactionId: "",
    serverAttestStartTimestampUTC: undefined,
    nonce1: new Uint8Array(0),
    nonce2: new Uint8Array(0),
    blob: new Uint8Array(0),
  };
}

export const AttestStartResponseData = {
  encode(message: AttestStartResponseData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transactionId !== "") {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.serverAttestStartTimestampUTC !== undefined) {
      Timestamp.encode(toTimestamp(message.serverAttestStartTimestampUTC), writer.uint32(18).fork()).ldelim();
    }
    if (message.nonce1.length !== 0) {
      writer.uint32(26).bytes(message.nonce1);
    }
    if (message.nonce2.length !== 0) {
      writer.uint32(34).bytes(message.nonce2);
    }
    if (message.blob.length !== 0) {
      writer.uint32(42).bytes(message.blob);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttestStartResponseData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttestStartResponseData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serverAttestStartTimestampUTC = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nonce1 = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.nonce2 = reader.bytes();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.blob = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttestStartResponseData {
    return {
      transactionId: isSet(object.transactionId) ? globalThis.String(object.transactionId) : "",
      serverAttestStartTimestampUTC: isSet(object.serverAttestStartTimestampUTC)
        ? fromJsonTimestamp(object.serverAttestStartTimestampUTC)
        : undefined,
      nonce1: isSet(object.nonce1) ? bytesFromBase64(object.nonce1) : new Uint8Array(0),
      nonce2: isSet(object.nonce2) ? bytesFromBase64(object.nonce2) : new Uint8Array(0),
      blob: isSet(object.blob) ? bytesFromBase64(object.blob) : new Uint8Array(0),
    };
  },

  toJSON(message: AttestStartResponseData): unknown {
    const obj: any = {};
    if (message.transactionId !== "") {
      obj.transactionId = message.transactionId;
    }
    if (message.serverAttestStartTimestampUTC !== undefined) {
      obj.serverAttestStartTimestampUTC = message.serverAttestStartTimestampUTC.toISOString();
    }
    if (message.nonce1.length !== 0) {
      obj.nonce1 = base64FromBytes(message.nonce1);
    }
    if (message.nonce2.length !== 0) {
      obj.nonce2 = base64FromBytes(message.nonce2);
    }
    if (message.blob.length !== 0) {
      obj.blob = base64FromBytes(message.blob);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttestStartResponseData>, I>>(base?: I): AttestStartResponseData {
    return AttestStartResponseData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttestStartResponseData>, I>>(object: I): AttestStartResponseData {
    const message = createBaseAttestStartResponseData();
    message.transactionId = object.transactionId ?? "";
    message.serverAttestStartTimestampUTC = object.serverAttestStartTimestampUTC ?? undefined;
    message.nonce1 = object.nonce1 ?? new Uint8Array(0);
    message.nonce2 = object.nonce2 ?? new Uint8Array(0);
    message.blob = object.blob ?? new Uint8Array(0);
    return message;
  },
};

function createBaseAttestStartResponseError(): AttestStartResponseError {
  return {
    transactionId: "",
    serverAttestStartTimestampUTC: undefined,
    Nonce1: new Uint8Array(0),
    ErrCode: "",
    ErrMsg: "",
  };
}

export const AttestStartResponseError = {
  encode(message: AttestStartResponseError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transactionId !== "") {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.serverAttestStartTimestampUTC !== undefined) {
      Timestamp.encode(toTimestamp(message.serverAttestStartTimestampUTC), writer.uint32(18).fork()).ldelim();
    }
    if (message.Nonce1.length !== 0) {
      writer.uint32(26).bytes(message.Nonce1);
    }
    if (message.ErrCode !== "") {
      writer.uint32(34).string(message.ErrCode);
    }
    if (message.ErrMsg !== "") {
      writer.uint32(42).string(message.ErrMsg);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttestStartResponseError {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttestStartResponseError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serverAttestStartTimestampUTC = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.Nonce1 = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ErrCode = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ErrMsg = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttestStartResponseError {
    return {
      transactionId: isSet(object.transactionId) ? globalThis.String(object.transactionId) : "",
      serverAttestStartTimestampUTC: isSet(object.serverAttestStartTimestampUTC)
        ? fromJsonTimestamp(object.serverAttestStartTimestampUTC)
        : undefined,
      Nonce1: isSet(object.Nonce1) ? bytesFromBase64(object.Nonce1) : new Uint8Array(0),
      ErrCode: isSet(object.ErrCode) ? globalThis.String(object.ErrCode) : "",
      ErrMsg: isSet(object.ErrMsg) ? globalThis.String(object.ErrMsg) : "",
    };
  },

  toJSON(message: AttestStartResponseError): unknown {
    const obj: any = {};
    if (message.transactionId !== "") {
      obj.transactionId = message.transactionId;
    }
    if (message.serverAttestStartTimestampUTC !== undefined) {
      obj.serverAttestStartTimestampUTC = message.serverAttestStartTimestampUTC.toISOString();
    }
    if (message.Nonce1.length !== 0) {
      obj.Nonce1 = base64FromBytes(message.Nonce1);
    }
    if (message.ErrCode !== "") {
      obj.ErrCode = message.ErrCode;
    }
    if (message.ErrMsg !== "") {
      obj.ErrMsg = message.ErrMsg;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttestStartResponseError>, I>>(base?: I): AttestStartResponseError {
    return AttestStartResponseError.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttestStartResponseError>, I>>(object: I): AttestStartResponseError {
    const message = createBaseAttestStartResponseError();
    message.transactionId = object.transactionId ?? "";
    message.serverAttestStartTimestampUTC = object.serverAttestStartTimestampUTC ?? undefined;
    message.Nonce1 = object.Nonce1 ?? new Uint8Array(0);
    message.ErrCode = object.ErrCode ?? "";
    message.ErrMsg = object.ErrMsg ?? "";
    return message;
  },
};

function createBaseAgentAttestStartResponse(): AgentAttestStartResponse {
  return { respHdr: undefined, respData: undefined, respErrData: undefined };
}

export const AgentAttestStartResponse = {
  encode(message: AgentAttestStartResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.respHdr !== undefined) {
      AuthResponseHeader.encode(message.respHdr, writer.uint32(10).fork()).ldelim();
    }
    if (message.respData !== undefined) {
      AttestStartResponseData.encode(message.respData, writer.uint32(18).fork()).ldelim();
    }
    if (message.respErrData !== undefined) {
      AttestStartResponseError.encode(message.respErrData, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AgentAttestStartResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAgentAttestStartResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.respHdr = AuthResponseHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.respData = AttestStartResponseData.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.respErrData = AttestStartResponseError.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AgentAttestStartResponse {
    return {
      respHdr: isSet(object.respHdr) ? AuthResponseHeader.fromJSON(object.respHdr) : undefined,
      respData: isSet(object.respData) ? AttestStartResponseData.fromJSON(object.respData) : undefined,
      respErrData: isSet(object.respErrData) ? AttestStartResponseError.fromJSON(object.respErrData) : undefined,
    };
  },

  toJSON(message: AgentAttestStartResponse): unknown {
    const obj: any = {};
    if (message.respHdr !== undefined) {
      obj.respHdr = AuthResponseHeader.toJSON(message.respHdr);
    }
    if (message.respData !== undefined) {
      obj.respData = AttestStartResponseData.toJSON(message.respData);
    }
    if (message.respErrData !== undefined) {
      obj.respErrData = AttestStartResponseError.toJSON(message.respErrData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AgentAttestStartResponse>, I>>(base?: I): AgentAttestStartResponse {
    return AgentAttestStartResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AgentAttestStartResponse>, I>>(object: I): AgentAttestStartResponse {
    const message = createBaseAgentAttestStartResponse();
    message.respHdr = (object.respHdr !== undefined && object.respHdr !== null)
      ? AuthResponseHeader.fromPartial(object.respHdr)
      : undefined;
    message.respData = (object.respData !== undefined && object.respData !== null)
      ? AttestStartResponseData.fromPartial(object.respData)
      : undefined;
    message.respErrData = (object.respErrData !== undefined && object.respErrData !== null)
      ? AttestStartResponseError.fromPartial(object.respErrData)
      : undefined;
    return message;
  },
};

function createBaseAttestCompleteRequestData(): AttestCompleteRequestData {
  return {
    transactionId: "",
    switchMeta: undefined,
    deviceTimestampUTC: undefined,
    nonce2: new Uint8Array(0),
    nonce3: new Uint8Array(0),
    blob: new Uint8Array(0),
    sudiQuoteMeta: undefined,
  };
}

export const AttestCompleteRequestData = {
  encode(message: AttestCompleteRequestData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transactionId !== "") {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.switchMeta !== undefined) {
      SwitchMetaData.encode(message.switchMeta, writer.uint32(18).fork()).ldelim();
    }
    if (message.deviceTimestampUTC !== undefined) {
      Timestamp.encode(toTimestamp(message.deviceTimestampUTC), writer.uint32(26).fork()).ldelim();
    }
    if (message.nonce2.length !== 0) {
      writer.uint32(34).bytes(message.nonce2);
    }
    if (message.nonce3.length !== 0) {
      writer.uint32(42).bytes(message.nonce3);
    }
    if (message.blob.length !== 0) {
      writer.uint32(50).bytes(message.blob);
    }
    if (message.sudiQuoteMeta !== undefined) {
      SudiQuoteMetadata.encode(message.sudiQuoteMeta, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttestCompleteRequestData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttestCompleteRequestData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.switchMeta = SwitchMetaData.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceTimestampUTC = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.nonce2 = reader.bytes();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.nonce3 = reader.bytes();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.blob = reader.bytes();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.sudiQuoteMeta = SudiQuoteMetadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttestCompleteRequestData {
    return {
      transactionId: isSet(object.transactionId) ? globalThis.String(object.transactionId) : "",
      switchMeta: isSet(object.switchMeta) ? SwitchMetaData.fromJSON(object.switchMeta) : undefined,
      deviceTimestampUTC: isSet(object.deviceTimestampUTC) ? fromJsonTimestamp(object.deviceTimestampUTC) : undefined,
      nonce2: isSet(object.nonce2) ? bytesFromBase64(object.nonce2) : new Uint8Array(0),
      nonce3: isSet(object.nonce3) ? bytesFromBase64(object.nonce3) : new Uint8Array(0),
      blob: isSet(object.blob) ? bytesFromBase64(object.blob) : new Uint8Array(0),
      sudiQuoteMeta: isSet(object.sudiQuoteMeta) ? SudiQuoteMetadata.fromJSON(object.sudiQuoteMeta) : undefined,
    };
  },

  toJSON(message: AttestCompleteRequestData): unknown {
    const obj: any = {};
    if (message.transactionId !== "") {
      obj.transactionId = message.transactionId;
    }
    if (message.switchMeta !== undefined) {
      obj.switchMeta = SwitchMetaData.toJSON(message.switchMeta);
    }
    if (message.deviceTimestampUTC !== undefined) {
      obj.deviceTimestampUTC = message.deviceTimestampUTC.toISOString();
    }
    if (message.nonce2.length !== 0) {
      obj.nonce2 = base64FromBytes(message.nonce2);
    }
    if (message.nonce3.length !== 0) {
      obj.nonce3 = base64FromBytes(message.nonce3);
    }
    if (message.blob.length !== 0) {
      obj.blob = base64FromBytes(message.blob);
    }
    if (message.sudiQuoteMeta !== undefined) {
      obj.sudiQuoteMeta = SudiQuoteMetadata.toJSON(message.sudiQuoteMeta);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttestCompleteRequestData>, I>>(base?: I): AttestCompleteRequestData {
    return AttestCompleteRequestData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttestCompleteRequestData>, I>>(object: I): AttestCompleteRequestData {
    const message = createBaseAttestCompleteRequestData();
    message.transactionId = object.transactionId ?? "";
    message.switchMeta = (object.switchMeta !== undefined && object.switchMeta !== null)
      ? SwitchMetaData.fromPartial(object.switchMeta)
      : undefined;
    message.deviceTimestampUTC = object.deviceTimestampUTC ?? undefined;
    message.nonce2 = object.nonce2 ?? new Uint8Array(0);
    message.nonce3 = object.nonce3 ?? new Uint8Array(0);
    message.blob = object.blob ?? new Uint8Array(0);
    message.sudiQuoteMeta = (object.sudiQuoteMeta !== undefined && object.sudiQuoteMeta !== null)
      ? SudiQuoteMetadata.fromPartial(object.sudiQuoteMeta)
      : undefined;
    return message;
  },
};

function createBaseAgentAttestCompleteRequest(): AgentAttestCompleteRequest {
  return { deviceId: "", agentVersion: "", reqHdr: undefined, completeData: undefined };
}

export const AgentAttestCompleteRequest = {
  encode(message: AgentAttestCompleteRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.agentVersion !== "") {
      writer.uint32(18).string(message.agentVersion);
    }
    if (message.reqHdr !== undefined) {
      AuthRequestHeader.encode(message.reqHdr, writer.uint32(26).fork()).ldelim();
    }
    if (message.completeData !== undefined) {
      AttestCompleteRequestData.encode(message.completeData, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AgentAttestCompleteRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAgentAttestCompleteRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.agentVersion = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.reqHdr = AuthRequestHeader.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.completeData = AttestCompleteRequestData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AgentAttestCompleteRequest {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      agentVersion: isSet(object.agentVersion) ? globalThis.String(object.agentVersion) : "",
      reqHdr: isSet(object.reqHdr) ? AuthRequestHeader.fromJSON(object.reqHdr) : undefined,
      completeData: isSet(object.completeData) ? AttestCompleteRequestData.fromJSON(object.completeData) : undefined,
    };
  },

  toJSON(message: AgentAttestCompleteRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.agentVersion !== "") {
      obj.agentVersion = message.agentVersion;
    }
    if (message.reqHdr !== undefined) {
      obj.reqHdr = AuthRequestHeader.toJSON(message.reqHdr);
    }
    if (message.completeData !== undefined) {
      obj.completeData = AttestCompleteRequestData.toJSON(message.completeData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AgentAttestCompleteRequest>, I>>(base?: I): AgentAttestCompleteRequest {
    return AgentAttestCompleteRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AgentAttestCompleteRequest>, I>>(object: I): AgentAttestCompleteRequest {
    const message = createBaseAgentAttestCompleteRequest();
    message.deviceId = object.deviceId ?? "";
    message.agentVersion = object.agentVersion ?? "";
    message.reqHdr = (object.reqHdr !== undefined && object.reqHdr !== null)
      ? AuthRequestHeader.fromPartial(object.reqHdr)
      : undefined;
    message.completeData = (object.completeData !== undefined && object.completeData !== null)
      ? AttestCompleteRequestData.fromPartial(object.completeData)
      : undefined;
    return message;
  },
};

function createBaseAttestCompleteResponseData(): AttestCompleteResponseData {
  return {
    transactionId: "",
    serverAttestCompleteTimestampUTC: undefined,
    nonce3: new Uint8Array(0),
    agentSessionToken: new Uint8Array(0),
  };
}

export const AttestCompleteResponseData = {
  encode(message: AttestCompleteResponseData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transactionId !== "") {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.serverAttestCompleteTimestampUTC !== undefined) {
      Timestamp.encode(toTimestamp(message.serverAttestCompleteTimestampUTC), writer.uint32(18).fork()).ldelim();
    }
    if (message.nonce3.length !== 0) {
      writer.uint32(26).bytes(message.nonce3);
    }
    if (message.agentSessionToken.length !== 0) {
      writer.uint32(34).bytes(message.agentSessionToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttestCompleteResponseData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttestCompleteResponseData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serverAttestCompleteTimestampUTC = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nonce3 = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.agentSessionToken = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttestCompleteResponseData {
    return {
      transactionId: isSet(object.transactionId) ? globalThis.String(object.transactionId) : "",
      serverAttestCompleteTimestampUTC: isSet(object.serverAttestCompleteTimestampUTC)
        ? fromJsonTimestamp(object.serverAttestCompleteTimestampUTC)
        : undefined,
      nonce3: isSet(object.nonce3) ? bytesFromBase64(object.nonce3) : new Uint8Array(0),
      agentSessionToken: isSet(object.agentSessionToken)
        ? bytesFromBase64(object.agentSessionToken)
        : new Uint8Array(0),
    };
  },

  toJSON(message: AttestCompleteResponseData): unknown {
    const obj: any = {};
    if (message.transactionId !== "") {
      obj.transactionId = message.transactionId;
    }
    if (message.serverAttestCompleteTimestampUTC !== undefined) {
      obj.serverAttestCompleteTimestampUTC = message.serverAttestCompleteTimestampUTC.toISOString();
    }
    if (message.nonce3.length !== 0) {
      obj.nonce3 = base64FromBytes(message.nonce3);
    }
    if (message.agentSessionToken.length !== 0) {
      obj.agentSessionToken = base64FromBytes(message.agentSessionToken);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttestCompleteResponseData>, I>>(base?: I): AttestCompleteResponseData {
    return AttestCompleteResponseData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttestCompleteResponseData>, I>>(object: I): AttestCompleteResponseData {
    const message = createBaseAttestCompleteResponseData();
    message.transactionId = object.transactionId ?? "";
    message.serverAttestCompleteTimestampUTC = object.serverAttestCompleteTimestampUTC ?? undefined;
    message.nonce3 = object.nonce3 ?? new Uint8Array(0);
    message.agentSessionToken = object.agentSessionToken ?? new Uint8Array(0);
    return message;
  },
};

function createBaseAttestCompleteResponseErrorData(): AttestCompleteResponseErrorData {
  return {
    transactionId: "",
    serverAttestCompleteTimestampUTC: undefined,
    nonce3: new Uint8Array(0),
    errCode: "",
    errMsg: "",
    retryAfterSeconds: 0,
  };
}

export const AttestCompleteResponseErrorData = {
  encode(message: AttestCompleteResponseErrorData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transactionId !== "") {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.serverAttestCompleteTimestampUTC !== undefined) {
      Timestamp.encode(toTimestamp(message.serverAttestCompleteTimestampUTC), writer.uint32(18).fork()).ldelim();
    }
    if (message.nonce3.length !== 0) {
      writer.uint32(26).bytes(message.nonce3);
    }
    if (message.errCode !== "") {
      writer.uint32(34).string(message.errCode);
    }
    if (message.errMsg !== "") {
      writer.uint32(42).string(message.errMsg);
    }
    if (message.retryAfterSeconds !== 0) {
      writer.uint32(48).uint64(message.retryAfterSeconds);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttestCompleteResponseErrorData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttestCompleteResponseErrorData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serverAttestCompleteTimestampUTC = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nonce3 = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.errCode = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.errMsg = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.retryAfterSeconds = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttestCompleteResponseErrorData {
    return {
      transactionId: isSet(object.transactionId) ? globalThis.String(object.transactionId) : "",
      serverAttestCompleteTimestampUTC: isSet(object.serverAttestCompleteTimestampUTC)
        ? fromJsonTimestamp(object.serverAttestCompleteTimestampUTC)
        : undefined,
      nonce3: isSet(object.nonce3) ? bytesFromBase64(object.nonce3) : new Uint8Array(0),
      errCode: isSet(object.errCode) ? globalThis.String(object.errCode) : "",
      errMsg: isSet(object.errMsg) ? globalThis.String(object.errMsg) : "",
      retryAfterSeconds: isSet(object.retryAfterSeconds) ? globalThis.Number(object.retryAfterSeconds) : 0,
    };
  },

  toJSON(message: AttestCompleteResponseErrorData): unknown {
    const obj: any = {};
    if (message.transactionId !== "") {
      obj.transactionId = message.transactionId;
    }
    if (message.serverAttestCompleteTimestampUTC !== undefined) {
      obj.serverAttestCompleteTimestampUTC = message.serverAttestCompleteTimestampUTC.toISOString();
    }
    if (message.nonce3.length !== 0) {
      obj.nonce3 = base64FromBytes(message.nonce3);
    }
    if (message.errCode !== "") {
      obj.errCode = message.errCode;
    }
    if (message.errMsg !== "") {
      obj.errMsg = message.errMsg;
    }
    if (message.retryAfterSeconds !== 0) {
      obj.retryAfterSeconds = Math.round(message.retryAfterSeconds);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttestCompleteResponseErrorData>, I>>(base?: I): AttestCompleteResponseErrorData {
    return AttestCompleteResponseErrorData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttestCompleteResponseErrorData>, I>>(
    object: I,
  ): AttestCompleteResponseErrorData {
    const message = createBaseAttestCompleteResponseErrorData();
    message.transactionId = object.transactionId ?? "";
    message.serverAttestCompleteTimestampUTC = object.serverAttestCompleteTimestampUTC ?? undefined;
    message.nonce3 = object.nonce3 ?? new Uint8Array(0);
    message.errCode = object.errCode ?? "";
    message.errMsg = object.errMsg ?? "";
    message.retryAfterSeconds = object.retryAfterSeconds ?? 0;
    return message;
  },
};

function createBaseAgentAttestCompleteResponse(): AgentAttestCompleteResponse {
  return { respHdr: undefined, respData: undefined, respErrData: undefined };
}

export const AgentAttestCompleteResponse = {
  encode(message: AgentAttestCompleteResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.respHdr !== undefined) {
      AuthResponseHeader.encode(message.respHdr, writer.uint32(10).fork()).ldelim();
    }
    if (message.respData !== undefined) {
      AttestCompleteResponseData.encode(message.respData, writer.uint32(18).fork()).ldelim();
    }
    if (message.respErrData !== undefined) {
      AttestCompleteResponseErrorData.encode(message.respErrData, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AgentAttestCompleteResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAgentAttestCompleteResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.respHdr = AuthResponseHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.respData = AttestCompleteResponseData.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.respErrData = AttestCompleteResponseErrorData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AgentAttestCompleteResponse {
    return {
      respHdr: isSet(object.respHdr) ? AuthResponseHeader.fromJSON(object.respHdr) : undefined,
      respData: isSet(object.respData) ? AttestCompleteResponseData.fromJSON(object.respData) : undefined,
      respErrData: isSet(object.respErrData) ? AttestCompleteResponseErrorData.fromJSON(object.respErrData) : undefined,
    };
  },

  toJSON(message: AgentAttestCompleteResponse): unknown {
    const obj: any = {};
    if (message.respHdr !== undefined) {
      obj.respHdr = AuthResponseHeader.toJSON(message.respHdr);
    }
    if (message.respData !== undefined) {
      obj.respData = AttestCompleteResponseData.toJSON(message.respData);
    }
    if (message.respErrData !== undefined) {
      obj.respErrData = AttestCompleteResponseErrorData.toJSON(message.respErrData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AgentAttestCompleteResponse>, I>>(base?: I): AgentAttestCompleteResponse {
    return AgentAttestCompleteResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AgentAttestCompleteResponse>, I>>(object: I): AgentAttestCompleteResponse {
    const message = createBaseAgentAttestCompleteResponse();
    message.respHdr = (object.respHdr !== undefined && object.respHdr !== null)
      ? AuthResponseHeader.fromPartial(object.respHdr)
      : undefined;
    message.respData = (object.respData !== undefined && object.respData !== null)
      ? AttestCompleteResponseData.fromPartial(object.respData)
      : undefined;
    message.respErrData = (object.respErrData !== undefined && object.respErrData !== null)
      ? AttestCompleteResponseErrorData.fromPartial(object.respErrData)
      : undefined;
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
