import { ApiError } from "src/core/apiError";
import {
  GetBgpPeersRequest,
  FabricCandidate,
  GetDeviceModelsRequest,
  GetDeviceModelsResponse,
  FabricConfig,
  GetCableModelsRequest,
  GetCableModelsResponse,
  GetFabricBomRequest,
  GetFabricBomResponse,
  GetFabricCandidatesRequest,
  GetFabricCandidatesResponse,
  GetFabricConfigRequest,
  GetFabricConfigResponse,
  GetFabricInventoriesRequest,
  GetFabricInventoriesResponse,
  GetFabricsFilters,
  GetFabricsRequest,
  GetFabricsResponse,
  GetSubInterfacesRequest,
  GetNodesFilters,
  GetNodesPortsRequest,
  GetNodesPortsResponse,
  GetNodesRequest,
  GetNodesResponse,
  GetPerVlanStpRequest,
  GetPerVlanStpResponse,
  GetVlansRequest,
  GetVnisRequest,
  GetVrfsRequest,
  GetPortChannelsRequest,
  ProvisionRequest,
  ProvisionResponse,
  ReviewFabricCandidateRequest,
  ReviewFabricCandidateResponse,
  TenantsObjectsResponse,
  GetFabricAssemblyRequest,
  GetFabricAssemblyResponse,
  GetStaticRoutesRequest,
  GetPortBreakoutsRequest,
  GetPortBreakoutsResponse,
  GetDhcpRelaysRequest,
  GetBgpPoliciesRequest,
  GetDevicePsuModelsRequest,
  GetDevicePsuModelsResponse,
  ClaimDevicesRequest,
  ClaimDevicesResponse,
  UnclaimDeviceRequest
} from "src/gen/schema/configd/api";
import {
  AddManagementPortsRequest,
  GetManagementPortsRequest,
  ManagementPortsResponse,
  UpdateManagementPortRequest
} from "src/gen/schema/configd/node";
import {
  Fabric,
  ManagementPort,
  SubInterface,
  Node,
  PortBreakout,
  Vlan,
  Vni,
  Vrf,
  PortChannel,
  StaticRoutes,
  DhcpRelay
} from "src/gen/schema/models/models";
import { Brig, ConfigD, Bilge, Sextant, Fred } from "./grpc";
import { FabricTopology } from "src/gen/schema/models/fabric";

import { Auth } from "./rest";
import {
  DeleteUsersRequest,
  SetUsersRequest,
  UsersResponse
} from "src/gen/schema/brig/bapi";
import { BgpPeer, BgpPolicy, PerVlanStp } from "src/gen/schema/models/routing";
import { DeviceModel, CableModel, DevicePSu } from "src/gen/schema/models/bom";
import { User } from "src/gen/schema/models/user";
import { DeleteBearerTokenRequest } from "src/gen/schema/brig/btokens";
import { Empty } from "src/gen/schema/common/empty";
import {
  AssertCounterReport,
  HistoryRequest,
  HistoryResponse
} from "src/gen/schema/reader/reader_api";
import { AssertHistoryApiRecord } from "src/gen/schema/reader/assert_record_api";

export const KEY_NAME = "running-mode";

export enum CandidateMode {
  Edit = "",
  Running = "running"
}

/**
 * Standard Vanilla get<Type>(id) returning a
 * single item in most cases.
 */
export const Apis = {
  ConfigD: {
    getOrgCertificates: ConfigD.getOrgCertificates,
    addOrgCertificates: ConfigD.addOrgCertificates,
    deleteOrgCertificates: ConfigD.deleteOrgCertificates,
    /**
     *
     * @param mode CandidateMode
     * @param fabricIds string[]
     * @param filters GetFabricsFilters - Partial set of filters. fabricIds in
     *                                    filter will override the fabricIds param
     * GetFabricsFilters:
     *   partialMatch?: boolean;
     *   loadChildren?: boolean;
     *   fabricIds?: string[];
     *   names?: string[];
     *   tags?: string[];
     *   chassisIds?: string[];
     */
    getFabrics: (
      mode: CandidateMode,
      fabricIds: string[],
      filters: Partial<GetFabricsFilters> = {}
    ): Promise<Fabric[]> => {
      const fabricsRequest = GetFabricsRequest.create({
        filters: GetFabricsFilters.create({
          fabricIds: fabricIds,
          ...filters,
          candidate: mode
        })
      });
      return ConfigD.getFabrics(fabricsRequest).then(
        (resp: GetFabricsResponse) => {
          return resp.fabrics ?? [];
        }
      );
    },
    /**
     * @param mode CandidateMode
     * @param fabricId string
     * @param filters GetFabricsFilters - Partial set of filters. fabricIds in filter
     *                                    will override the fabricIds param
     * GetFabricsFilters:
     *   partialMatch: boolean;
     *   loadChildren: boolean;
     *   fabricIds: string[];
     *   names: string[];
     *   tags: string[];
     *   chassisIds: string[];
     */
    getFabric: (
      mode: CandidateMode,
      fabricId: string,
      filters: Partial<GetFabricsFilters> = { loadChildren: true }
    ): Promise<Fabric | undefined> => {
      return Apis.ConfigD.getFabrics(mode, [fabricId], filters).then(
        (fabrics) => {
          return fabrics?.[0];
        }
      );
    },
    /**
     * @param fabricId string
     * @param filters GetFabricsFilters - Partial set of filters. fabricIds in filter
     *                                    will override the fabricIds param
     * GetFabricsFilters:
     *   partialMatch: boolean;
     *   loadChildren: boolean;
     *   fabricIds: string[];
     *   names: string[];
     *   tags: string[];
     *   chassisIds: string[];
     */
    getFabricTransactions: (
      fabricId: string,
      filters: Partial<GetFabricCandidatesRequest> = {
        needInactive: false,
        needReviews: true,
        needEvents: true,
        name: ""
      }
    ): Promise<FabricCandidate[] | undefined> => {
      const req = GetFabricCandidatesRequest.create({
        fabricId: fabricId,
        ...filters
      });
      return ConfigD.getFabricTransactions(req).then(
        (resp: GetFabricCandidatesResponse) => {
          return resp.candidates;
        }
      );
    },
    /**
     * @param mode CandidateMode
     * @param fabricId string
     * A promise that resolves to the fabric
     * configuration containing a fabricConfig
     * or undefined.
     */
    getFabricConfig: (
      mode: CandidateMode,
      fabricId: string
    ): Promise<FabricConfig | undefined> => {
      const req = GetFabricConfigRequest.create({
        candidate: mode,
        fabricId: fabricId
      });
      return ConfigD.getFabricConfig(req).then(
        (resp: GetFabricConfigResponse) => {
          return resp?.config;
        }
      );
    },
    /**
     * @param mode CandidateMode
     * @param fabricId string
     * @param switchIds string[] - empty array is also acceptable
     * @param filters - GetChassisFilters - Partial set of filters with switchId would override the switchIds param
     *   partialMatch?: boolean;
     *   loadChildren?: boolean;
     *   chassisIds?: string[];
     *   names?: string[];
     *   tags?: string[];
     *   roles?: ChassisRole[];
     *   fabricIds?: string[];
     *   serialNumbers?: string[];
     *   modelNames?: string[];
     *   switchIds?: string[];
     *   fabricNames?: string[];
     */
    getSwitches: (
      mode: CandidateMode,
      fabricId: string,
      switchIds: string[] = [],
      filters: Partial<GetNodesFilters> = {}
    ): Promise<Node[] | undefined> => {
      const req = GetNodesRequest.create({
        filters: GetNodesFilters.create({
          candidate: mode,
          fabricId,
          nodeIds: switchIds,
          ...filters
        })
      });
      return ConfigD.getSwitch(req).then((resp: GetNodesResponse) => {
        return resp.nodes;
      });
    },
    /**
     * @param mode CandidateMode
     * @param fabricId string - empty is also acceptable
     * @param switchId string - empty is also acceptable
     * @param filters - Partial<GetChassisFilters> - filters with switchId would override the switchIds param
     *   partialMatch?: boolean;
     *   loadChildren?: boolean;
     *   chassisIds?: string[];
     *   names?: string[];
     *   tags?: string[];
     *   roles?: ChassisRole[];
     *   fabricIds?: string[];
     *   serialNumbers?: string[];
     *   modelNames?: string[];
     *   switchIds?: string[];
     *   fabricNames?: string[];
     */
    getSwitch: (
      mode: CandidateMode,
      fabricId: string,
      switchId: string,
      filters: Partial<GetNodesFilters> = { loadChildren: true }
    ): Promise<Node | undefined> => {
      return Apis.ConfigD.getSwitches(mode, fabricId, [switchId], filters).then(
        (chassis) => {
          return chassis?.[0];
        }
      );
    },
    getPerVlanStpRequest: (
      mode: CandidateMode,
      fabricId: string
    ): Promise<PerVlanStp | undefined> => {
      const req = GetPerVlanStpRequest.fromPartial({
        candidate: mode,
        fabricId
      });
      return ConfigD.getPerVlanStpRequest(req).then(
        (resp: GetPerVlanStpResponse | undefined) => {
          return resp?.perVlanStp;
        }
      );
    },
    getFabricInventory: (
      fabricId?: string,
      topology?: FabricTopology,
      nodeIds?: string[]
    ): Promise<Fabric[] | undefined> => {
      const req = GetFabricInventoriesRequest.create({
        fabricId,
        topology,
        nodeIds
      });
      return ConfigD.getFabricInventory(req).then(
        (resp: GetFabricInventoriesResponse) => {
          return resp.fabrics ?? [];
        }
      );
    },
    getAllFabricInventory: (): Promise<Fabric[] | undefined> => {
      const req = GetFabricInventoriesRequest.create();
      return ConfigD.getFabricInventory(req).then(
        (resp: GetFabricInventoriesResponse) => {
          return resp.fabrics ?? [];
        }
      );
    },
    addManagementPort: (
      fabricId: string,
      nodeId: string,
      port: Partial<ManagementPort>
    ): Promise<ManagementPortsResponse> => {
      const req = AddManagementPortsRequest.create({
        nodeId,
        fabricId,
        ports: [port]
      });

      return ConfigD.addManagementPort(req);
    },
    updateManagementPort: (
      fabricId: string,
      nodeId: string,
      port: Partial<ManagementPort>
    ): Promise<ManagementPort> => {
      const req = UpdateManagementPortRequest.create({
        id: port?.id,
        fabricId,
        nodeId,
        port
      });
      return ConfigD.updateManagementPort(req);
    },
    getVLANs: (
      mode: CandidateMode,
      fabricId: string,
      vlanIds: number[] = []
    ): Promise<Vlan[]> => {
      const req = GetVlansRequest.create({
        candidate: mode,
        fabricId: fabricId,
        vlanIds
      });
      return ConfigD.getVlans(req).then((resp: TenantsObjectsResponse) => {
        return resp?.tenants?.[0]?.vlans ?? [];
      });
    },
    getSwitchPorts: (
      mode: CandidateMode,
      fabricId: string,
      filters: Partial<GetNodesPortsRequest>
    ): Promise<Node | undefined> => {
      const req = GetNodesPortsRequest.create({
        candidate: mode,
        fabricId,
        ...filters
      });
      return ConfigD.getSwitchPorts(req).then((resp: GetNodesPortsResponse) => {
        return resp.nodes[0];
      });
    },
    getDeviceModels: (
      filters: Partial<GetDeviceModelsRequest> = {}
    ): Promise<DeviceModel[]> => {
      const req = GetDeviceModelsRequest.create({
        ...filters
      });
      return ConfigD.getDeviceModels(req).then(
        (resp: GetDeviceModelsResponse) => {
          if (resp.devices) {
            return resp.devices;
          }
          return [];
        }
      );
    },
    getDevicePsuModels: (
      filters: Partial<GetDevicePsuModelsRequest> = {}
    ): Promise<DevicePSu[]> => {
      const req = GetDevicePsuModelsRequest.create({
        ...filters
      });
      return ConfigD.getDevicePsuModels(req).then(
        (resp: GetDevicePsuModelsResponse) => {
          return resp.devicePsus ?? [];
        }
      );
    },
    getPortBreakouts: (
      filters: Partial<GetPortBreakoutsRequest> = {}
    ): Promise<PortBreakout[]> => {
      const req = GetPortBreakoutsRequest.create({
        ...filters
      });
      return ConfigD.getPortBreakouts(req).then(
        (resp: GetPortBreakoutsResponse) => {
          if (resp.breakouts) {
            return resp.breakouts;
          }
          return [];
        }
      );
    },
    getCableModels: (modelNames: string[]): Promise<CableModel[]> => {
      const req = GetCableModelsRequest.create({
        modelNames
      });
      return ConfigD.getCableModels(req).then(
        (resp: GetCableModelsResponse) => {
          return resp.cables;
        }
      );
    },
    getVnis: (
      mode: CandidateMode,
      fabricId: string,
      filters: Partial<GetVnisRequest> = {}
    ): Promise<Vni[]> => {
      const req = GetVnisRequest.create({
        fabricId: fabricId,
        candidate: mode,
        ...filters
      });
      return ConfigD.getVnis(req).then((resp: TenantsObjectsResponse) => {
        if (resp.tenants[0]) {
          return resp.tenants[0].vnis;
        }
        return [];
      });
    },
    getPortChannels: (
      mode: CandidateMode,
      fabricId: string,
      filters: Partial<GetPortChannelsRequest> = {}
    ): Promise<PortChannel[]> => {
      const req = GetPortChannelsRequest.create({
        fabricId: fabricId,
        candidate: mode,
        ...filters
      });
      return ConfigD.getPortChannels(req).then(
        (resp: TenantsObjectsResponse) => resp.tenants[0]?.portChannels ?? []
      );
    },
    getDHCPRelays: (
      mode: CandidateMode,
      fabricId: string,
      filters: Partial<GetDhcpRelaysRequest> = {}
    ): Promise<DhcpRelay[]> => {
      const req = GetDhcpRelaysRequest.create({
        fabricId: fabricId,
        candidate: mode,
        ...filters
      });
      return ConfigD.getDHCPRelays(req).then((resp: TenantsObjectsResponse) => {
        if (resp.tenants[0]) {
          return resp.tenants[0].dhcpRelays;
        }
        return [];
      });
    },
    getVRFs: (
      mode: CandidateMode,
      fabricId?: string,
      filters: Partial<GetVrfsRequest> = {}
    ): Promise<Vrf[]> => {
      const req = GetVrfsRequest.create({
        candidate: mode,
        fabricId: fabricId,
        ...filters
      });
      return ConfigD.getVRFs(req).then((resp: TenantsObjectsResponse) => {
        if (resp.tenants[0]) {
          return resp.tenants[0].vrfs;
        }
        return [];
      });
    },
    getBGPPeers: (
      mode: CandidateMode,
      fabricId: string,
      filters: Partial<GetBgpPeersRequest> = {}
    ): Promise<BgpPeer[]> => {
      const req = GetBgpPeersRequest.create({
        candidate: mode,
        fabricId,
        ...filters
      });
      return ConfigD.getBgpPeers(req).then((resp: TenantsObjectsResponse) => {
        if (resp.tenants[0]) {
          return resp.tenants[0].bgpPeers;
        }
        return [];
      });
    },
    getBGPPolicy: (
      mode: CandidateMode,
      fabricId: string,
      filters: Partial<GetBgpPoliciesRequest> = {}
    ): Promise<BgpPolicy[]> => {
      const req = GetBgpPoliciesRequest.create({
        candidate: mode,
        fabricId,
        ...filters
      });
      return ConfigD.getBgpPolicies(req).then(
        (resp: TenantsObjectsResponse) => {
          if (resp.tenants[0]) {
            return resp.tenants[0].bgpPolicies;
          }
          return [];
        }
      );
    },
    getSubInterfaces: (
      mode: CandidateMode,
      nodeIds?: string[],
      filters: Partial<GetSubInterfacesRequest> = {}
    ): Promise<SubInterface[]> => {
      const req = GetSubInterfacesRequest.create({
        candidate: mode,
        nodeIds,
        ...filters
      });
      return ConfigD.getSubInterfaces(req).then(
        (resp: TenantsObjectsResponse) => {
          if (resp.tenants[0]) {
            return resp.tenants[0].subInterfaces;
          }
          return [];
        }
      );
    },
    getStaticRoutes: (
      mode: CandidateMode,
      fabricId?: string,
      filters: Partial<GetStaticRoutesRequest> = {}
    ): Promise<StaticRoutes[]> => {
      const req = GetStaticRoutesRequest.create({
        candidate: mode,
        fabricId: fabricId,
        ...filters
      });
      return ConfigD.getStaticRoutes(req).then(
        (resp: TenantsObjectsResponse) => {
          if (resp.tenants[0]) {
            return resp.tenants[0].staticRoutes;
          }
          return [];
        }
      );
    },
    getFabricBOM: (
      mode: CandidateMode,
      fabricId?: string
    ): Promise<GetFabricBomResponse> => {
      const req = GetFabricBomRequest.create({
        candidate: mode,
        fabricId: fabricId
      });
      return ConfigD.getFabricBOM(req).then((resp: GetFabricBomResponse) => {
        return resp;
      });
    },
    getFabricAssembly: (
      mode: CandidateMode,
      fabricId?: string
    ): Promise<GetFabricAssemblyResponse> => {
      const req = GetFabricAssemblyRequest.create({
        candidate: mode,
        fabricId: fabricId
      });
      return ConfigD.getFabricAssembly(req).then(
        (resp: GetFabricAssemblyResponse) => {
          return resp;
        }
      );
    },
    reviewFabricTransaction: (
      fabricId: string,
      comments: string
    ): Promise<boolean> => {
      const req = ReviewFabricCandidateRequest.create({
        fabricId,
        comments
      });
      return ConfigD.reviewFabricTransaction(req).then(
        (resp: ReviewFabricCandidateResponse) => {
          return resp !== undefined;
        }
      );
    },
    getManagementPort: (
      fabricId: string,
      nodeId: string
    ): Promise<ManagementPort[]> => {
      const req = GetManagementPortsRequest.create({
        fabricId,
        nodeId
      });
      return ConfigD.getManagementPort(req).then(
        (resp: ManagementPortsResponse) => {
          return resp.ports;
        }
      );
    },
    provision: (
      fabricId: string,
      req: ProvisionRequest,
      callback: ((response: ProvisionResponse) => void) | undefined,
      errorCallback: (err: ApiError) => void
    ): Promise<ProvisionResponse | ApiError> => {
      if (!req.fabricId) {
        req.fabricId = fabricId;
      }
      return ConfigD.provision(req)
        .then((resp) => {
          if (callback) {
            callback(resp);
          }
          return resp;
        })
        .catch((err: () => ApiError) => {
          const error = err();
          if (errorCallback) {
            errorCallback(error);
          }
          return error;
        });
    },
    commitFabricCandidate: (
      fabricId?: string,
      name?: string,
      comments?: string
    ): Promise<boolean> => {
      return ConfigD.commitFabricCandidate({ fabricId, name, comments }).then(
        () => {
          return true;
        }
      );
    },
    revertFabricCandidate: (
      fabricId?: string,
      name?: string,
      comments?: string
    ): Promise<boolean> => {
      return ConfigD.revertFabricCandidate({ fabricId, name, comments })
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    claimDevices: (claimCodes: string[]): Promise<ClaimDevicesResponse> => {
      const req = ClaimDevicesRequest.fromPartial({ claimCodes });
      return ConfigD.claimDevices(req);
    },
    unclaimDevice: (deviceId: string): Promise<Empty> => {
      const req = UnclaimDeviceRequest.fromPartial({ deviceId });
      return ConfigD.unclaimDevices(req);
    }
  },
  /**
   * Brig session & user apis
   */
  Brig: {
    setUsers: (users: User[]): Promise<UsersResponse> => {
      const req = SetUsersRequest.fromPartial({
        users
      });
      return Brig.setUsers(req);
    },
    deleteUsers: (users: string[]): Promise<UsersResponse> => {
      const req = DeleteUsersRequest.fromPartial({
        userId: users
      });
      return Brig.deleteUsers(req);
    },
    deleteBearerToken: (tokenId: string): Promise<Empty> => {
      const req = DeleteBearerTokenRequest.fromPartial({
        tokenId: tokenId
      });
      return Brig.deleteBearerToken(req);
    },
    // Brig pass through apis
    refreshUserSession: Brig.refreshUserSession,
    newOrg: Brig.newOrg,
    addOrg: Brig.addOrg,
    getUserSession: Brig.getUserSession,
    getUsers: Brig.getUsers,
    getBearerTokens: Brig.getBearerTokens,
    createBearerToken: Brig.createBearerToken,
    getTenants: Brig.getTenants,
    updateTenants: Brig.updateTenants,
    updateUserPreferences: Brig.updateUserPreferences
  },
  /**
   * These druid apis are not sync follow apis, they are unary.
   * Sextant is used here as a proxy to read from druid.
   * Defined calls are helper functions that takes request parameters and forward it to grpc calls
   */
  Sextant: {
    // To display assert counts on event viewer chart
    queryAssertionHistoryCount: (
      filters: Partial<HistoryRequest> = {}
    ): Promise<AssertCounterReport[]> => {
      const req = HistoryRequest.create({
        ...filters
      });
      return Sextant.getQueryAssertionHistory(req).then(
        (resp: HistoryResponse) => {
          if (resp.assertCounterReport) {
            return resp.assertCounterReport.counterReport;
          }
          return [];
        }
      );
    },
    // To display assert record details in event viewer table
    queryAssertionHistoryRecords: (
      filters: Partial<HistoryRequest> = {}
    ): Promise<AssertHistoryApiRecord[]> => {
      const req = HistoryRequest.create({
        ...filters
      });
      return Sextant.getQueryAssertionHistory(req).then(
        (resp: HistoryResponse) => {
          if (resp.assertApiResponse?.assertions.length) {
            return resp.assertApiResponse?.assertions;
          }
          return [];
        }
      );
    },
    druidTelemetry: (
      fabricId: string,
      filters: Partial<HistoryRequest> = {}
    ): Promise<HistoryResponse | undefined> => {
      const req = HistoryRequest.create({
        ...filters
      });
      return Sextant.getDruidTelemetry(req).then((resp: HistoryResponse) => {
        if (resp) {
          return resp;
        }
        return undefined;
      });
    }
  },
  Fred,
  /**
   * Some Auth rest api calls Paul
   * added for Auth/org stuff
   * @see ./rest/auth.ts
   * so far these are all pass through
   */
  Auth,
  Bilge
};
