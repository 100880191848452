// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/image.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";

export const protobufPackage = "models";

/** FabricImageUpdate describes the image update configuration in a fabric. */
export interface FabricImageUpdate {
  /** The fabric id */
  fabricId: string;
  /** The image update tag */
  tag: string;
  /** The optional planed deploy time */
  deployAt:
    | Date
    | undefined;
  /** The optional fabric specific image expire time */
  expireAt:
    | Date
    | undefined;
  /** amd64 (default) or arm64 */
  arch: string;
  /** The fabric name */
  fabricName: string;
}

/** NodeImageUpdate describes the image update configuration at a node. */
export interface NodeImageUpdate {
  /** The node id or name. */
  nodeId: string;
  /** The image update tag */
  tag: string;
  /** The optional activation time */
  activateAt:
    | Date
    | undefined;
  /** The fabric id or name. */
  fabricId: string;
}

export interface ImagePackageInfo {
  /** The package release tag */
  tag: string;
  /** The package name */
  name: string;
  /** H/W architecture (amd64 or arm64). */
  arch: string;
  /** Target platform for the artifact if it is platform-specific (HF6100-32D, HF6100-60L4D). */
  platform: string;
  /** Version defines package version. */
  version: string;
  /** The package download URL */
  url: string;
  /** The package file size */
  sizeBytes: number;
  /** The SHA512/224 hash of the package file */
  sha512: string;
  /** CheckInterval defines image check interval. */
  checkInterval: string;
  /** The cache expiration duration */
  cacheDuration: string;
  /** The package file generation time */
  fileTime: string;
  /** The package expected expiration time */
  expiresAt: string;
  /** The image release document link */
  docLink: string;
  /** The release image type, released, pre-released, test or dev. */
  releaseType: string;
  /** The SHA512/224 hash of the embedded package file. */
  innerSha512: string;
}

function createBaseFabricImageUpdate(): FabricImageUpdate {
  return { fabricId: "", tag: "", deployAt: undefined, expireAt: undefined, arch: "", fabricName: "" };
}

export const FabricImageUpdate = {
  encode(message: FabricImageUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.tag !== "") {
      writer.uint32(18).string(message.tag);
    }
    if (message.deployAt !== undefined) {
      Timestamp.encode(toTimestamp(message.deployAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.expireAt !== undefined) {
      Timestamp.encode(toTimestamp(message.expireAt), writer.uint32(34).fork()).ldelim();
    }
    if (message.arch !== "") {
      writer.uint32(42).string(message.arch);
    }
    if (message.fabricName !== "") {
      writer.uint32(50).string(message.fabricName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricImageUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricImageUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tag = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deployAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.expireAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.arch = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fabricName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricImageUpdate {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
      deployAt: isSet(object.deployAt) ? fromJsonTimestamp(object.deployAt) : undefined,
      expireAt: isSet(object.expireAt) ? fromJsonTimestamp(object.expireAt) : undefined,
      arch: isSet(object.arch) ? globalThis.String(object.arch) : "",
      fabricName: isSet(object.fabricName) ? globalThis.String(object.fabricName) : "",
    };
  },

  toJSON(message: FabricImageUpdate): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    if (message.deployAt !== undefined) {
      obj.deployAt = message.deployAt.toISOString();
    }
    if (message.expireAt !== undefined) {
      obj.expireAt = message.expireAt.toISOString();
    }
    if (message.arch !== "") {
      obj.arch = message.arch;
    }
    if (message.fabricName !== "") {
      obj.fabricName = message.fabricName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricImageUpdate>, I>>(base?: I): FabricImageUpdate {
    return FabricImageUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricImageUpdate>, I>>(object: I): FabricImageUpdate {
    const message = createBaseFabricImageUpdate();
    message.fabricId = object.fabricId ?? "";
    message.tag = object.tag ?? "";
    message.deployAt = object.deployAt ?? undefined;
    message.expireAt = object.expireAt ?? undefined;
    message.arch = object.arch ?? "";
    message.fabricName = object.fabricName ?? "";
    return message;
  },
};

function createBaseNodeImageUpdate(): NodeImageUpdate {
  return { nodeId: "", tag: "", activateAt: undefined, fabricId: "" };
}

export const NodeImageUpdate = {
  encode(message: NodeImageUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeId !== "") {
      writer.uint32(10).string(message.nodeId);
    }
    if (message.tag !== "") {
      writer.uint32(18).string(message.tag);
    }
    if (message.activateAt !== undefined) {
      Timestamp.encode(toTimestamp(message.activateAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.fabricId !== "") {
      writer.uint32(34).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NodeImageUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodeImageUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tag = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.activateAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodeImageUpdate {
    return {
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
      activateAt: isSet(object.activateAt) ? fromJsonTimestamp(object.activateAt) : undefined,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: NodeImageUpdate): unknown {
    const obj: any = {};
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    if (message.activateAt !== undefined) {
      obj.activateAt = message.activateAt.toISOString();
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodeImageUpdate>, I>>(base?: I): NodeImageUpdate {
    return NodeImageUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodeImageUpdate>, I>>(object: I): NodeImageUpdate {
    const message = createBaseNodeImageUpdate();
    message.nodeId = object.nodeId ?? "";
    message.tag = object.tag ?? "";
    message.activateAt = object.activateAt ?? undefined;
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseImagePackageInfo(): ImagePackageInfo {
  return {
    tag: "",
    name: "",
    arch: "",
    platform: "",
    version: "",
    url: "",
    sizeBytes: 0,
    sha512: "",
    checkInterval: "",
    cacheDuration: "",
    fileTime: "",
    expiresAt: "",
    docLink: "",
    releaseType: "",
    innerSha512: "",
  };
}

export const ImagePackageInfo = {
  encode(message: ImagePackageInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== "") {
      writer.uint32(10).string(message.tag);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.arch !== "") {
      writer.uint32(26).string(message.arch);
    }
    if (message.platform !== "") {
      writer.uint32(34).string(message.platform);
    }
    if (message.version !== "") {
      writer.uint32(42).string(message.version);
    }
    if (message.url !== "") {
      writer.uint32(50).string(message.url);
    }
    if (message.sizeBytes !== 0) {
      writer.uint32(56).int64(message.sizeBytes);
    }
    if (message.sha512 !== "") {
      writer.uint32(66).string(message.sha512);
    }
    if (message.checkInterval !== "") {
      writer.uint32(74).string(message.checkInterval);
    }
    if (message.cacheDuration !== "") {
      writer.uint32(82).string(message.cacheDuration);
    }
    if (message.fileTime !== "") {
      writer.uint32(90).string(message.fileTime);
    }
    if (message.expiresAt !== "") {
      writer.uint32(98).string(message.expiresAt);
    }
    if (message.docLink !== "") {
      writer.uint32(106).string(message.docLink);
    }
    if (message.releaseType !== "") {
      writer.uint32(114).string(message.releaseType);
    }
    if (message.innerSha512 !== "") {
      writer.uint32(122).string(message.innerSha512);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImagePackageInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImagePackageInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tag = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.arch = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.platform = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.version = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.url = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.sizeBytes = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.sha512 = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.checkInterval = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.cacheDuration = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.fileTime = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.expiresAt = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.docLink = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.releaseType = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.innerSha512 = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImagePackageInfo {
    return {
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      arch: isSet(object.arch) ? globalThis.String(object.arch) : "",
      platform: isSet(object.platform) ? globalThis.String(object.platform) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      sizeBytes: isSet(object.sizeBytes) ? globalThis.Number(object.sizeBytes) : 0,
      sha512: isSet(object.sha512) ? globalThis.String(object.sha512) : "",
      checkInterval: isSet(object.checkInterval) ? globalThis.String(object.checkInterval) : "",
      cacheDuration: isSet(object.cacheDuration) ? globalThis.String(object.cacheDuration) : "",
      fileTime: isSet(object.fileTime) ? globalThis.String(object.fileTime) : "",
      expiresAt: isSet(object.expiresAt) ? globalThis.String(object.expiresAt) : "",
      docLink: isSet(object.docLink) ? globalThis.String(object.docLink) : "",
      releaseType: isSet(object.releaseType) ? globalThis.String(object.releaseType) : "",
      innerSha512: isSet(object.innerSha512) ? globalThis.String(object.innerSha512) : "",
    };
  },

  toJSON(message: ImagePackageInfo): unknown {
    const obj: any = {};
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.arch !== "") {
      obj.arch = message.arch;
    }
    if (message.platform !== "") {
      obj.platform = message.platform;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.sizeBytes !== 0) {
      obj.sizeBytes = Math.round(message.sizeBytes);
    }
    if (message.sha512 !== "") {
      obj.sha512 = message.sha512;
    }
    if (message.checkInterval !== "") {
      obj.checkInterval = message.checkInterval;
    }
    if (message.cacheDuration !== "") {
      obj.cacheDuration = message.cacheDuration;
    }
    if (message.fileTime !== "") {
      obj.fileTime = message.fileTime;
    }
    if (message.expiresAt !== "") {
      obj.expiresAt = message.expiresAt;
    }
    if (message.docLink !== "") {
      obj.docLink = message.docLink;
    }
    if (message.releaseType !== "") {
      obj.releaseType = message.releaseType;
    }
    if (message.innerSha512 !== "") {
      obj.innerSha512 = message.innerSha512;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImagePackageInfo>, I>>(base?: I): ImagePackageInfo {
    return ImagePackageInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImagePackageInfo>, I>>(object: I): ImagePackageInfo {
    const message = createBaseImagePackageInfo();
    message.tag = object.tag ?? "";
    message.name = object.name ?? "";
    message.arch = object.arch ?? "";
    message.platform = object.platform ?? "";
    message.version = object.version ?? "";
    message.url = object.url ?? "";
    message.sizeBytes = object.sizeBytes ?? 0;
    message.sha512 = object.sha512 ?? "";
    message.checkInterval = object.checkInterval ?? "";
    message.cacheDuration = object.cacheDuration ?? "";
    message.fileTime = object.fileTime ?? "";
    message.expiresAt = object.expiresAt ?? "";
    message.docLink = object.docLink ?? "";
    message.releaseType = object.releaseType ?? "";
    message.innerSha512 = object.innerSha512 ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
