import {
  DeepPartial,
  Message,
  MonitoredObjects,
  QueryType,
  Request
} from "../../../gen/schema/syncfollow/syncfollow";
import { DataSeries } from "../../../gen/schema/syncfollow/syncfollow_threadlist";
import { PathId } from "../../../gen/schema/schema_path/path_id";
import { mergeStreams } from "../../mergeStreams";

export type MessageTransformer<TResponse> = (m: Message) => TResponse;

/**
 * Used to dig the desired data
 * out of the DataSeries delivered
 * in the Message.
 */
export type SeriesCallback<T> = (s: DataSeries) => T[];

export type MessageReducer<TResponse> = (
  prev: TResponse | undefined,
  curr: TResponse
) => TResponse;

const keyFn = <T extends Partial<{ id: PathId }>>(d: T) => {
  return d?.id?.pathString || "";
};

/**
 * Designed to be the glue between the Message
 * object returned by the socket and the
 * data needed by the client to create a
 * request.
 */
export class MessageManager {
  /**
   * transformer will return a callback
   * function closure to unwrap the DataSeries
   * from the Message, then the passed in callback
   * can return the desired message data array
   */
  static transformer<T>(cb: SeriesCallback<T>): MessageTransformer<T[]> {
    return (msg: Message): T[] => {
      const series: DataSeries | undefined = msg.data?.series;
      if (series) {
        return cb(series);
      }
      return [];
    };
  }
  /**
   * A fallback functions for useEffect to have
   * consistent return. This was the compiler will
   * not generate an error. Allows useEffect to
   * consistently call the unload function.
   */
  static unsubscribe(): void {
    return;
  }
  /**
   * Reducer that merges data based on the
   * PathId object in the return type.
   */
  static reducer<T extends Partial<{ id: PathId }>>(
    curr: T[] | undefined,
    next: T[]
  ) {
    let mergedData: T[] = [];
    if (curr) {
      mergedData = mergeStreams([], curr, keyFn);
    }
    return mergeStreams(mergedData, next, keyFn);
  }

  /**
   * Creates a new Message to be sent to
   * the socket. This only takes a Partial
   * MonitoredObjects object as its argument
   * @param limit returns only N records
   */
  static create(mo: DeepPartial<MonitoredObjects>, limit?: number): Message {
    return Message.fromPartial({
      request: Request.fromPartial({
        objects: [MonitoredObjects.fromPartial(mo)],
        query: QueryType.SyncAndFollow,
        includePathString: true,
        limitN: limit
      })
    });
  }
}
