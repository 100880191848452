export const BulletList = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.75 4C4.75 3.58579 5.08579 3.25 5.5 3.25H13.5C13.9142 3.25 14.25 3.58579 14.25 4C14.25 4.41421 13.9142 4.75 13.5 4.75H5.5C5.08579 4.75 4.75 4.41421 4.75 4Z"
      fill="var(--interact-icon-default)"
    />
    <path
      d="M4.75037 8C4.75037 7.58579 5.08615 7.25 5.50037 7.25H13.5C13.9142 7.25 14.25 7.58579 14.25 8C14.25 8.41421 13.9142 8.75 13.5 8.75H5.50037C5.08615 8.75 4.75037 8.41421 4.75037 8Z"
      fill="var(--interact-icon-default)"
    />
    <path
      d="M4.75037 12C4.75037 11.5858 5.08615 11.25 5.50037 11.25H13.5C13.9142 11.25 14.25 11.5858 14.25 12C14.25 12.4142 13.9142 12.75 13.5 12.75H5.50037C5.08615 12.75 4.75037 12.4142 4.75037 12Z"
      fill="var(--interact-icon-default)"
    />
    <path
      d="M2.75 9C3.30228 9 3.75 8.55228 3.75 8C3.75 7.44772 3.30228 7 2.75 7C2.19772 7 1.75 7.44772 1.75 8C1.75 8.55228 2.19772 9 2.75 9Z"
      fill="var(--interact-icon-default)"
    />
    <path
      d="M2.75 5C3.30228 5 3.75 4.55228 3.75 4C3.75 3.44772 3.30228 3 2.75 3C2.19772 3 1.75 3.44772 1.75 4C1.75 4.55228 2.19772 5 2.75 5Z"
      fill="var(--interact-icon-default)"
    />
    <path
      d="M2.75 13C3.30228 13 3.75 12.5523 3.75 12C3.75 11.4477 3.30228 11 2.75 11C2.19772 11 1.75 11.4477 1.75 12C1.75 12.5523 2.19772 13 2.75 13Z"
      fill="var(--interact-icon-default)"
    />
  </svg>
);
