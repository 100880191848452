import { SextantStreamManager } from "./sextantStreamManager";
import { StreamManager } from "./streamManager";
import { StreamingCachePrintType } from "../dataflow/streamingCache";
import { Stream } from "./core/stream";
import { Message } from "src/gen/schema/syncfollow/syncfollow";
import { BilgeStreamManager } from "./bilgeStreamManager";
import { ConfigDStreamManager } from "./configDStreamManager";

/**
 * StreamPool is an abstraction for StreamManager
 * subclassed. It makes it transparent to use an
 * individual StreamManager for a given pool of
 * StreamManagers
 */
export abstract class StreamPool<TKey, TStreamManager extends StreamManager> {
  protected maxSize: number = 10;
  protected pool = new Map<TKey, TStreamManager>();

  /**
   * createSocket is an abstract method that
   * must be implemented by the subclass.
   * It should return a new instance of a
   * subclass of StreamManager.
   */
  abstract createSocket(): TStreamManager;

  useStream(id: TKey | undefined) {
    if (id) {
      let socket: TStreamManager | undefined = this.pool.get(id);
      if (!socket) {
        socket = this.createSocket();
        socket.on(StreamManager.PRUNE, () => this.prune(socket), this);
        this.pool.set(id, socket);
      }
      if (!socket.isConnected()) {
        /**
         * If the socket is not connected
         * we need to initialize it.
         *
         * "id as string" may be the wrong
         * assumption, but it is safe to assume
         * that id will be a string or undefined.
         * if undefined the socket most likely
         * will ignore it.
         */
        socket.init(id as string);
      }
      return socket;
    }
    return undefined;
  }

  prune(m: TStreamManager | undefined) {
    if (m) {
      setTimeout(() => {
        this.pool.forEach((manager, key) => {
          if (m === manager) {
            if (!manager.isConnected()) {
              this.pool.delete(key);
            }
          }
        });
      }, 1000);
    }
  }

  close(id: TKey) {
    const socket = this.pool.get(id);
    if (socket) {
      socket.close();
    }
  }

  getStreams(): Stream<Message, Message>[] {
    const data: Stream<Message, Message>[] = [];
    this.pool.forEach((socket: StreamManager) => {
      const tmp = socket.getStreams();
      data.push(...tmp);
    }, []);
    return data;
  }

  showContent(): StreamingCachePrintType[] {
    const data: StreamingCachePrintType[] = [];
    this.pool.forEach((socket: StreamManager) => {
      const tmp = socket.showContent();
      data.push(...tmp);
    }, []);
    return data;
  }
}

/**
 * StreamManager specific implementation
 * of the StreamPool class.
 */
export class SextantStreamPool extends StreamPool<
  string | undefined,
  SextantStreamManager
> {
  /**
   * createSocket will return a new
   * SextantStreamManager
   */
  createSocket = () => new SextantStreamManager();
}

/**
 * StreamManager specific implementation
 * of the StreamPool class.
 */
export class ConfigDStreamPool extends StreamPool<
  string | undefined,
  ConfigDStreamManager
> {
  /**
   * createSocket will return a new
   * ConfigDStreamManager
   */
  createSocket = () => new ConfigDStreamManager();
}

/**
 * StreamManager specific implementation
 * of the StreamPool class.
 */
export class BilgeStreamPool extends StreamPool<
  string | undefined,
  BilgeStreamManager
> {
  /**
   * createSocket will return a new
   * BilgeStreamManager
   */
  createSocket = () => new BilgeStreamManager();
}
