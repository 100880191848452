import React, { useCallback, useMemo, useState } from "react";
import { OrgModal } from "pages/org/orgModal";

interface OrgModalType {
  openOrgModal: () => void;
  orgModalContent: React.ReactNode;
}

const useOrgModal = (): OrgModalType => {
  const [open, setOpen] = useState<boolean>(false);
  const orgModalContent = useMemo(() => {
    const onClose = () => {
      setOpen(false);
    };

    if (open) {
      return <OrgModal onClose={onClose} />;
    }
    return null;
  }, [open]);

  const openOrgModal = useCallback((): void => {
    setOpen(true);
  }, []);

  return {
    openOrgModal,
    orgModalContent
  };
};

export { useOrgModal };
