// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: configd/certificates.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Certificates } from "../models/types";

export const protobufPackage = "configd";

/** Request for organization certificates. */
export interface GetOrgCertificatesRequest {
  /** A set of identifiers. */
  ids: string[];
  /** A set of names. */
  names: string[];
}

/** Get the organization certificates. */
export interface GetOrgCertificatesResponse {
  certificates: Certificates[];
}

/** Request for a single certificate. */
export interface GetOrgCertificateRequest {
  /** The id or name of the certificate. */
  certificateId: string;
}

/** Get an organization certificate. */
export interface GetOrgCertificateResponse {
  certificate: Certificates | undefined;
}

/** Create organization certificates. */
export interface AddOrgCertificatesRequest {
  /** The id or name of the certificate. */
  certificates: Certificates[];
}

/** Response for creating organization certificates. */
export interface AddOrgCertificatesResponse {
  certificates: Certificates[];
}

/** Update an organization certificate. */
export interface UpdateOrgCertificateRequest {
  /** The id or name of the certificate. */
  certificateId: string;
  certificate: Certificates | undefined;
}

/** Response for updating an organization certificate. */
export interface UpdateOrgCertificateResponse {
  certificate: Certificates | undefined;
}

/** Delete an organization certificate. */
export interface DeleteOrgCertificateRequest {
  /** The id or name of the certificate. */
  certificateId: string;
}

function createBaseGetOrgCertificatesRequest(): GetOrgCertificatesRequest {
  return { ids: [], names: [] };
}

export const GetOrgCertificatesRequest = {
  encode(message: GetOrgCertificatesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ids) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrgCertificatesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrgCertificatesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ids.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOrgCertificatesRequest {
    return {
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.String(e)) : [],
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetOrgCertificatesRequest): unknown {
    const obj: any = {};
    if (message.ids?.length) {
      obj.ids = message.ids;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOrgCertificatesRequest>, I>>(base?: I): GetOrgCertificatesRequest {
    return GetOrgCertificatesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOrgCertificatesRequest>, I>>(object: I): GetOrgCertificatesRequest {
    const message = createBaseGetOrgCertificatesRequest();
    message.ids = object.ids?.map((e) => e) || [];
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetOrgCertificatesResponse(): GetOrgCertificatesResponse {
  return { certificates: [] };
}

export const GetOrgCertificatesResponse = {
  encode(message: GetOrgCertificatesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.certificates) {
      Certificates.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrgCertificatesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrgCertificatesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.certificates.push(Certificates.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOrgCertificatesResponse {
    return {
      certificates: globalThis.Array.isArray(object?.certificates)
        ? object.certificates.map((e: any) => Certificates.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetOrgCertificatesResponse): unknown {
    const obj: any = {};
    if (message.certificates?.length) {
      obj.certificates = message.certificates.map((e) => Certificates.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOrgCertificatesResponse>, I>>(base?: I): GetOrgCertificatesResponse {
    return GetOrgCertificatesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOrgCertificatesResponse>, I>>(object: I): GetOrgCertificatesResponse {
    const message = createBaseGetOrgCertificatesResponse();
    message.certificates = object.certificates?.map((e) => Certificates.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetOrgCertificateRequest(): GetOrgCertificateRequest {
  return { certificateId: "" };
}

export const GetOrgCertificateRequest = {
  encode(message: GetOrgCertificateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.certificateId !== "") {
      writer.uint32(10).string(message.certificateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrgCertificateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrgCertificateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.certificateId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOrgCertificateRequest {
    return { certificateId: isSet(object.certificateId) ? globalThis.String(object.certificateId) : "" };
  },

  toJSON(message: GetOrgCertificateRequest): unknown {
    const obj: any = {};
    if (message.certificateId !== "") {
      obj.certificateId = message.certificateId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOrgCertificateRequest>, I>>(base?: I): GetOrgCertificateRequest {
    return GetOrgCertificateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOrgCertificateRequest>, I>>(object: I): GetOrgCertificateRequest {
    const message = createBaseGetOrgCertificateRequest();
    message.certificateId = object.certificateId ?? "";
    return message;
  },
};

function createBaseGetOrgCertificateResponse(): GetOrgCertificateResponse {
  return { certificate: undefined };
}

export const GetOrgCertificateResponse = {
  encode(message: GetOrgCertificateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.certificate !== undefined) {
      Certificates.encode(message.certificate, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrgCertificateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrgCertificateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.certificate = Certificates.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOrgCertificateResponse {
    return { certificate: isSet(object.certificate) ? Certificates.fromJSON(object.certificate) : undefined };
  },

  toJSON(message: GetOrgCertificateResponse): unknown {
    const obj: any = {};
    if (message.certificate !== undefined) {
      obj.certificate = Certificates.toJSON(message.certificate);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOrgCertificateResponse>, I>>(base?: I): GetOrgCertificateResponse {
    return GetOrgCertificateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOrgCertificateResponse>, I>>(object: I): GetOrgCertificateResponse {
    const message = createBaseGetOrgCertificateResponse();
    message.certificate = (object.certificate !== undefined && object.certificate !== null)
      ? Certificates.fromPartial(object.certificate)
      : undefined;
    return message;
  },
};

function createBaseAddOrgCertificatesRequest(): AddOrgCertificatesRequest {
  return { certificates: [] };
}

export const AddOrgCertificatesRequest = {
  encode(message: AddOrgCertificatesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.certificates) {
      Certificates.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddOrgCertificatesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddOrgCertificatesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.certificates.push(Certificates.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddOrgCertificatesRequest {
    return {
      certificates: globalThis.Array.isArray(object?.certificates)
        ? object.certificates.map((e: any) => Certificates.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AddOrgCertificatesRequest): unknown {
    const obj: any = {};
    if (message.certificates?.length) {
      obj.certificates = message.certificates.map((e) => Certificates.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddOrgCertificatesRequest>, I>>(base?: I): AddOrgCertificatesRequest {
    return AddOrgCertificatesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddOrgCertificatesRequest>, I>>(object: I): AddOrgCertificatesRequest {
    const message = createBaseAddOrgCertificatesRequest();
    message.certificates = object.certificates?.map((e) => Certificates.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAddOrgCertificatesResponse(): AddOrgCertificatesResponse {
  return { certificates: [] };
}

export const AddOrgCertificatesResponse = {
  encode(message: AddOrgCertificatesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.certificates) {
      Certificates.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddOrgCertificatesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddOrgCertificatesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.certificates.push(Certificates.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddOrgCertificatesResponse {
    return {
      certificates: globalThis.Array.isArray(object?.certificates)
        ? object.certificates.map((e: any) => Certificates.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AddOrgCertificatesResponse): unknown {
    const obj: any = {};
    if (message.certificates?.length) {
      obj.certificates = message.certificates.map((e) => Certificates.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddOrgCertificatesResponse>, I>>(base?: I): AddOrgCertificatesResponse {
    return AddOrgCertificatesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddOrgCertificatesResponse>, I>>(object: I): AddOrgCertificatesResponse {
    const message = createBaseAddOrgCertificatesResponse();
    message.certificates = object.certificates?.map((e) => Certificates.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateOrgCertificateRequest(): UpdateOrgCertificateRequest {
  return { certificateId: "", certificate: undefined };
}

export const UpdateOrgCertificateRequest = {
  encode(message: UpdateOrgCertificateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.certificateId !== "") {
      writer.uint32(10).string(message.certificateId);
    }
    if (message.certificate !== undefined) {
      Certificates.encode(message.certificate, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOrgCertificateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateOrgCertificateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.certificateId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.certificate = Certificates.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateOrgCertificateRequest {
    return {
      certificateId: isSet(object.certificateId) ? globalThis.String(object.certificateId) : "",
      certificate: isSet(object.certificate) ? Certificates.fromJSON(object.certificate) : undefined,
    };
  },

  toJSON(message: UpdateOrgCertificateRequest): unknown {
    const obj: any = {};
    if (message.certificateId !== "") {
      obj.certificateId = message.certificateId;
    }
    if (message.certificate !== undefined) {
      obj.certificate = Certificates.toJSON(message.certificate);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateOrgCertificateRequest>, I>>(base?: I): UpdateOrgCertificateRequest {
    return UpdateOrgCertificateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateOrgCertificateRequest>, I>>(object: I): UpdateOrgCertificateRequest {
    const message = createBaseUpdateOrgCertificateRequest();
    message.certificateId = object.certificateId ?? "";
    message.certificate = (object.certificate !== undefined && object.certificate !== null)
      ? Certificates.fromPartial(object.certificate)
      : undefined;
    return message;
  },
};

function createBaseUpdateOrgCertificateResponse(): UpdateOrgCertificateResponse {
  return { certificate: undefined };
}

export const UpdateOrgCertificateResponse = {
  encode(message: UpdateOrgCertificateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.certificate !== undefined) {
      Certificates.encode(message.certificate, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOrgCertificateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateOrgCertificateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.certificate = Certificates.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateOrgCertificateResponse {
    return { certificate: isSet(object.certificate) ? Certificates.fromJSON(object.certificate) : undefined };
  },

  toJSON(message: UpdateOrgCertificateResponse): unknown {
    const obj: any = {};
    if (message.certificate !== undefined) {
      obj.certificate = Certificates.toJSON(message.certificate);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateOrgCertificateResponse>, I>>(base?: I): UpdateOrgCertificateResponse {
    return UpdateOrgCertificateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateOrgCertificateResponse>, I>>(object: I): UpdateOrgCertificateResponse {
    const message = createBaseUpdateOrgCertificateResponse();
    message.certificate = (object.certificate !== undefined && object.certificate !== null)
      ? Certificates.fromPartial(object.certificate)
      : undefined;
    return message;
  },
};

function createBaseDeleteOrgCertificateRequest(): DeleteOrgCertificateRequest {
  return { certificateId: "" };
}

export const DeleteOrgCertificateRequest = {
  encode(message: DeleteOrgCertificateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.certificateId !== "") {
      writer.uint32(10).string(message.certificateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteOrgCertificateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteOrgCertificateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.certificateId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteOrgCertificateRequest {
    return { certificateId: isSet(object.certificateId) ? globalThis.String(object.certificateId) : "" };
  },

  toJSON(message: DeleteOrgCertificateRequest): unknown {
    const obj: any = {};
    if (message.certificateId !== "") {
      obj.certificateId = message.certificateId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteOrgCertificateRequest>, I>>(base?: I): DeleteOrgCertificateRequest {
    return DeleteOrgCertificateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteOrgCertificateRequest>, I>>(object: I): DeleteOrgCertificateRequest {
    const message = createBaseDeleteOrgCertificateRequest();
    message.certificateId = object.certificateId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
