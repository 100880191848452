import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import { logger } from "src/core/globals";
import { GrpcWebImpl } from "src/gen/schema/brig/bservice";
import { ApiError } from "src/core/apiError";
import { GrpcWebError } from "src/gen/schema/drake/drake";

/* eslint-disable @typescript-eslint/no-explicit-any */
interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

export type UnaryApiContext = {
  service: string;
  method: string;
  request: object;
  requestTs: Date;
  response: object;
  responseTs: Date;
  isError?: boolean; // does response contain error
};

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;
export class GrpcDebugableWebImpl extends GrpcWebImpl {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: BrowserHeaders | undefined
  ): Promise<any> {
    const apiLoggingInConsole =
      localStorage.getItem("apiLoggingInConsole") === "True";
    const headers = metadata || new BrowserHeaders();
    if (_request.fabricId) {
      headers.set("fabricId", _request.fabricId);
    }
    if (window.cnc?.xCsrfToken) {
      headers.set("X-CSRF-Token", window.cnc.xCsrfToken);
    }
    const unaryHistory = localStorage.getItem("unaryHistory") === "True";
    if (apiLoggingInConsole || unaryHistory) {
      const requestTs = new Date();
      return new Promise<any>((resolve, reject) => {
        super
          .unary(methodDesc, _request, headers)
          .then(
            (resp: any) => {
              const logObject: UnaryApiContext = {
                service: methodDesc?.service.serviceName,
                method: methodDesc?.methodName,
                requestTs: requestTs,
                request: _request,
                response: resp,
                responseTs: new Date()
              };
              if (unaryHistory) {
                window.cnc?.logUnaryContext(logObject);
              }
              if (apiLoggingInConsole) {
                logger.log(logObject);
              }
              resolve(resp);
            },
            (err) => {
              const logObject: UnaryApiContext = {
                service: methodDesc?.service.serviceName,
                method: methodDesc?.methodName,
                requestTs: requestTs,
                request: _request,
                response: err,
                responseTs: new Date(),
                isError: true // actual error object in response
              };
              if (unaryHistory) {
                window.cnc?.logUnaryContext(logObject);
              }
              if (apiLoggingInConsole) {
                logger.error(logObject);
              }
              /**
               * Rejects the promise with
               * a function that returns a
               * new ApiError instance.
               * Basically a getError function
               */
              reject(() => new ApiError(err));
            }
          )
          .catch((err: Error) => {
            /**
             * Rejects the promise with
             * a function that returns a
             * new ApiError instance.
             * Basically a getError function
             */
            reject(() => new ApiError(err));
          });
      });
    }
    //return super.unary(methodDesc, _request, metadata);
    return new Promise<any>((resolve, reject) => {
      super
        .unary(methodDesc, _request, metadata)
        .then(
          (resp: any) => {
            resolve(resp);
          },
          (err: GrpcWebError) => {
            /**
             * Rejects the promise with
             * a function that returns a
             * new ApiError instance.
             * Basically a getError function
             */
            reject(() => new ApiError(err));
          }
        )
        .catch((err: Error) => {
          /**
           * Rejects the promise with
           * a function that returns a
           * new ApiError instance.
           * Basically a getError function
           */
          reject(() => new ApiError(err));
        });
    });
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */
