import { Button } from "@magnetic/button";
import { Container } from "@magnetic/container";
import { Flex } from "@magnetic/flex";
import { Header } from "@magnetic/header";
import { Heading } from "@magnetic/heading";
import { Skeleton } from "@magnetic/skeleton";
import { Divider } from "@magnetic/divider";
import { Toggle } from "@magnetic/toggle";
import React, { useCallback, useContext } from "react";
import { CiscoLogo } from "../common/ciscoLogo";
import HFlex from "../components/hFlex";
import { Link } from "../components/link";
import { useUrlState } from "../hooks/useUrlState";
import { Constants } from "../utils/constants";
import "./globalHeader.scss";
import { LoginContext } from "./loginContext";
import { StreamingCacheMonitor } from "./streamingCacheMonitor";
import { DataProviderContext } from "./provider/dataProvider";
import { UnaryHistoryMonitor } from "./unaryHistoryMonitor";
import { MockShellGlobalHeader } from "src/pages/mock-shell/mockShellGlobalHeader";
import { Icon } from "src/components/icons";
import { useForceUpdate } from "src/common/hooks/updateHooks";
import { setLocalTheme, Theme2Magnetic } from "src/utils/theme";
import { getTheme } from "@magnetic/theme";
import { Theme } from "src/gen/schema/models/auth";

const PendingFabricChanges = () => {
  const { events, fabric } = useContext(DataProviderContext);
  if (fabric?.id) {
    return (
      <Header.Dropdown
        className="pending-changes"
        icon="deploy"
        label="Pending Changes"
        panel={false}
        alert={events?.length || 0}
      >
        <></>
      </Header.Dropdown>
    );
  }
  return null;
};

export default function GlobalHeader() {
  const [searchText, setSearchText] = useUrlState("g");
  const { session, logout } = useContext(LoginContext);

  const handleSearchChange = (): ((
    e: React.FormEvent<HTMLInputElement>
  ) => void) => {
    return (e: React.FormEvent<HTMLInputElement>): void => {
      setSearchText(e.currentTarget.value);
    };
  };

  const isDevMode = window.cnc.settings.get("dev");
  // This will be OK for now but boolean will not scale if there are more than 2 themes
  const isDarkMode = getTheme() === Theme2Magnetic[Theme.DARK];
  const showUnaryHistory = window.cnc.settings.get("unaryHistory");

  const handleForceUpdate = useForceUpdate();
  const toggleTheme = useCallback(() => {
    const newTheme = isDarkMode ? Theme.LIGHT : Theme.DARK;
    setLocalTheme(newTheme, true);
    handleForceUpdate();
  }, [handleForceUpdate, isDarkMode]);

  const dropdownItems: {
    id: string;
    content: JSX.Element;
    icon?: JSX.Element;
  }[] = [
    {
      id: "themeSwitch",
      icon: <Icon name="paintBrush" size="xl" />,
      content: (
        <Flex justify="space-between" align="center">
          <p className="item-name">Theme</p>
          <Flex direction="horizontal" gap="sm" align="center">
            <div className="toggle-label">Light</div>
            <Toggle checked={isDarkMode} onClick={toggleTheme} />
            <div className="toggle-label">Dark</div>
          </Flex>
        </Flex>
      )
    },
    {
      id: "tokensLink",
      icon: <Icon name="vector" size="xl" />,
      content: (
        <Link
          name="apiBearerTokens"
          className="api-bearer-tokens"
          targetRoute="/api-bearer-tokens"
        >
          <p className="item-name">API bearer tokens</p>
        </Link>
      )
    },
    {
      id: "logoutButton",
      content: (
        <Button
          name="logout"
          className="logout"
          kind="secondary"
          onClick={logout}
        >
          Log Out
        </Button>
      )
    }
    /* <Button
      name="changePassword"
      className="change-password"
      kind="tertiary"
      onClick={() => changePassword(() => {})}
    >
      Change Password
    </Button> */
  ];

  return (
    <Header
      className="global-header"
      logo={<CiscoLogo />}
      productName={
        isDevMode
          ? `${Constants.APP_NAME} - Developer Mode`
          : Constants.APP_NAME
      }
      profileAndTenant={{
        icon: "user",
        profile: {
          heading: session?.user?.email || Constants.EMPTY_VALUE,
          subHeading: session?.tenantName || Constants.EMPTY_VALUE
        },
        content: (
          <Container style={{ width: 362 }} className="user-profile">
            <Flex
              className="user-profile-actions"
              direction="vertical"
              gap="md"
            >
              {dropdownItems.map(({ id, content, icon }, index) => (
                <div key={id}>
                  <Flex className="dropdown-item" align="center">
                    <div className="icon-container">{icon}</div>
                    <div className="content-container">{content}</div>
                  </Flex>
                  {index !== dropdownItems.length - 1 && <Divider />}
                </div>
              ))}
            </Flex>
          </Container>
        )
      }}
      showXPN={false}
    >
      {isDevMode && false /* Disable until we support this */ && (
        <Header.Search
          label="example search"
          panel={true}
          open={!!searchText}
          onChange={handleSearchChange()}
        >
          <Container style={{ width: 500 }}>
            <Heading size="h1">Results for {`"${searchText}"`}</Heading>
            <HFlex>
              <Heading>Switches Top 5</Heading>
              <Link
                name="allSwitches"
                targetRoute={`/globalSearch/switches/${searchText}`}
              >
                All Switches
              </Link>
            </HFlex>

            <Flex direction="vertical" gap="md">
              <Skeleton height="lg" />
              <Skeleton height="md" />
              <Skeleton height="sm" />
              <Skeleton height={72} />
            </Flex>
            <HFlex>
              <Heading>Ports Top 5</Heading>
              <Link
                name="allPorts"
                targetRoute={`/globalSearch/ports/${searchText}`}
              >
                All Ports
              </Link>
            </HFlex>
            <Flex direction="vertical" gap="md">
              <Skeleton height="lg" />
              <Skeleton height="md" />
              <Skeleton height="sm" />
              <Skeleton height={72} />
            </Flex>
          </Container>
        </Header.Search>
      )}
      {window.cnc.settings.get("mockShell") ? <MockShellGlobalHeader /> : ""}
      {isDevMode && showUnaryHistory && <UnaryHistoryMonitor />}
      {isDevMode && <StreamingCacheMonitor />}

      {/* <Header.Button icon="info" label="info" onClick={handleClick} /> */}
      {/*
          <Header.Dropdown icon="question" label="question" panel={true}>
            <Container style={{ width: 500 }}>
              <Flex direction="vertical" gap="md">
                <Skeleton height="lg" />
                <Skeleton height="md" />
                <Skeleton height="sm" />
                <Skeleton height={72} />
              </Flex>
            </Container>
          </Header.Dropdown>
          <Header.Dropdown icon="bell" label="question" alert={81} panel={true}>
            <Container style={{ width: 500 }}>
              <Flex direction="vertical" gap="md">
                <Skeleton height="lg" />
                <Skeleton height="md" />
                <Skeleton height="sm" />
                <Skeleton height={72} />
              </Flex>
            </Container>
          </Header.Dropdown> */}
      {/* <Header.Button
        alert={100}
        icon="bell"
        label="example dropdown"
        onClick={console.log}
      /> */}
      <PendingFabricChanges />
    </Header>
  );
}
