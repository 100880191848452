import React, { useContext, useEffect } from "react";
import { PageLayout } from "src/layouts";
import GlobalHeader from "./globalHeader";
import { Navbar } from "./navbar";
import { Container } from "@magnetic/container";
import { Login } from "./login";
// TODO: remove this after Alpha
import { LoginContext } from "./loginContext";
import { WelcomeScreen } from "./welcomeScreen";
import { useLocation, useNavigate } from "react-router-dom";

export const Shell = (props: {
  readonly children: React.ReactNode;
}): React.ReactElement | null => {
  const { session } = useContext(LoginContext);
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * wrapping the navigate calls in useEffect
   * as to not call setState while rendering
   */
  useEffect(() => {
    /**
     * This is automated QA testing.
     * A request for the browser location
     * reflect if you are on the login or
     * signup page.
     */
    if (!session) {
      if (window.location.pathname !== "/login") {
        navigate("/login", { state: { location } });
      }
    }
    if (session?.newUser || session?.requireSignUp) {
      if (window.location.pathname !== "/signup") {
        navigate("/signup");
      }
    }
  }, [location, navigate, session]);

  if (!session) {
    return <Login />;
  }

  if (session?.newUser || session?.requireSignUp) {
    return <WelcomeScreen />;
  }

  return (
    <PageLayout
      banner={<GlobalHeader />}
      sideNav={<Navbar />}
      content={<Container>{props.children}</Container>}
    />
  );
};
