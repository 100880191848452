export const Arrows = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.03033 0.96967C8.73744 0.676777 8.26256 0.676777 7.96967 0.96967C7.67678 1.26256 7.67678 1.73744 7.96967 2.03033L10.9393 5H1.25C0.835786 5 0.5 5.33579 0.5 5.75C0.5 6.16421 0.835786 6.5 1.25 6.5H12.4482C13.2278 6.5 13.6182 5.5575 13.0669 5.00628L9.03033 0.96967Z"
      fill="var(--interact-icon-default)"
    />
    <path
      d="M1.5518 7.5C0.772266 7.5 0.381863 8.4425 0.933085 8.99372L4.9697 13.0303C5.26259 13.3232 5.73746 13.3232 6.03036 13.0303C6.32325 12.7374 6.32325 12.2626 6.03036 11.9697L3.06069 9H12.75C13.1642 9 13.5 8.66421 13.5 8.25C13.5 7.83579 13.1642 7.5 12.75 7.5H1.5518Z"
      fill="var(--interact-icon-default)"
    />
  </svg>
);
