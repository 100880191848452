import { grpc } from "@improbable-eng/grpc-web";
import { BrigClientImpl } from "src/gen/schema/brig/bservice";
import {
  DeleteUsersRequest,
  GetUserSessionRequest,
  GetUserSessionResponse,
  GetUsersRequest,
  NewOrgRequest,
  NewOrgResponse,
  RefreshUserSessionRequest,
  SetUsersRequest,
  StartUserSessionResponse,
  UsersResponse,
  UpdateTenantsRequest,
  GetTenantsRequest,
  TenantsResponse,
  AddOrgRequest,
  AddOrgResponse
} from "src/gen/schema/brig/bapi";
import {
  BearerToken,
  BearerTokensResponse,
  CreateBearerTokenRequest,
  DeleteBearerTokenRequest,
  GetBearerTokensRequest
} from "src/gen/schema/brig/btokens";
import { Empty } from "src/gen/schema/common/empty";
import { UserPreferences } from "src/gen/schema/models/user";
import { GrpcDebugableWebImpl } from "./grpcDebugableWebImpl";
import { xCsrfTransportFactory } from "./xCsrfTransportFactory";

const transport: grpc.TransportFactory = xCsrfTransportFactory();

const rpc = new GrpcDebugableWebImpl("", {
  debug: false, // change later
  transport
});

const brigClient = new BrigClientImpl(rpc);

export const Brig = {
  getUserSession: (
    req: GetUserSessionRequest = GetUserSessionRequest.create()
  ): Promise<GetUserSessionResponse> => {
    return brigClient.GetUserSession(req);
  },
  getUsers: (
    req: GetUsersRequest = GetUsersRequest.create()
  ): Promise<UsersResponse> => {
    return brigClient.GetUsers(req);
  },
  setUsers: (req: SetUsersRequest): Promise<UsersResponse> => {
    return brigClient.SetUsers(req);
  },
  newOrg: (
    req: NewOrgRequest = NewOrgRequest.create()
  ): Promise<NewOrgResponse> => {
    return brigClient.NewOrg(req);
  },
  addOrg: (
    req: AddOrgRequest = AddOrgRequest.create()
  ): Promise<AddOrgResponse> => {
    return brigClient.AddOrg(req);
  },
  getTenants: (
    req: GetTenantsRequest = GetTenantsRequest.create()
  ): Promise<TenantsResponse> => {
    return brigClient.GetTenants(req);
  },
  updateTenants: (req: UpdateTenantsRequest): Promise<TenantsResponse> => {
    return brigClient.UpdateTenants(req);
  },
  deleteUsers: (req: DeleteUsersRequest): Promise<UsersResponse> => {
    return brigClient.DeleteUsers(req);
  },
  refreshUserSession: (
    req: RefreshUserSessionRequest = RefreshUserSessionRequest.create()
  ): Promise<StartUserSessionResponse> => {
    return brigClient.RefreshUserSession(req);
  },
  getBearerTokens: (
    req: GetBearerTokensRequest = GetBearerTokensRequest.create()
  ): Promise<BearerTokensResponse> => {
    return brigClient.GetBearerTokens(req);
  },
  createBearerToken: (req: CreateBearerTokenRequest): Promise<BearerToken> => {
    return brigClient.CreateBearerToken(req);
  },
  deleteBearerToken: (req: DeleteBearerTokenRequest): Promise<Empty> => {
    return brigClient.DeleteBearerToken(req);
  },
  updateUserPreferences: (req: UserPreferences): Promise<UserPreferences> => {
    return brigClient.UpdateUserPreferences(req);
  }
};
