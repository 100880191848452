// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/auth.proto

/* eslint-disable */

export const protobufPackage = "models";

/** Known Single Sign On providers.  Other providers will be named by user. */
export enum KnownProvider {
  KNOWN_PROVIDER_UNSPECIFIED = 0,
  CISCO = 1,
  BOTS = 2,
  UNRECOGNIZED = -1,
}

export function knownProviderFromJSON(object: any): KnownProvider {
  switch (object) {
    case 0:
    case "KNOWN_PROVIDER_UNSPECIFIED":
      return KnownProvider.KNOWN_PROVIDER_UNSPECIFIED;
    case 1:
    case "CISCO":
      return KnownProvider.CISCO;
    case 2:
    case "BOTS":
      return KnownProvider.BOTS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return KnownProvider.UNRECOGNIZED;
  }
}

export function knownProviderToJSON(object: KnownProvider): string {
  switch (object) {
    case KnownProvider.KNOWN_PROVIDER_UNSPECIFIED:
      return "KNOWN_PROVIDER_UNSPECIFIED";
    case KnownProvider.CISCO:
      return "CISCO";
    case KnownProvider.BOTS:
      return "BOTS";
    case KnownProvider.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** PurchaseMethod defines an enumeration of license purchase methods. */
export enum PurchaseMethod {
  PURCHASE_METHOD_UNSPECIFIED = 0,
  DIRECTLY = 1,
  PARTNER = 2,
  DISTRIBUTOR = 3,
  NOT_SURE = 4,
  UNRECOGNIZED = -1,
}

export function purchaseMethodFromJSON(object: any): PurchaseMethod {
  switch (object) {
    case 0:
    case "PURCHASE_METHOD_UNSPECIFIED":
      return PurchaseMethod.PURCHASE_METHOD_UNSPECIFIED;
    case 1:
    case "DIRECTLY":
      return PurchaseMethod.DIRECTLY;
    case 2:
    case "PARTNER":
      return PurchaseMethod.PARTNER;
    case 3:
    case "DISTRIBUTOR":
      return PurchaseMethod.DISTRIBUTOR;
    case 4:
    case "NOT_SURE":
      return PurchaseMethod.NOT_SURE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PurchaseMethod.UNRECOGNIZED;
  }
}

export function purchaseMethodToJSON(object: PurchaseMethod): string {
  switch (object) {
    case PurchaseMethod.PURCHASE_METHOD_UNSPECIFIED:
      return "PURCHASE_METHOD_UNSPECIFIED";
    case PurchaseMethod.DIRECTLY:
      return "DIRECTLY";
    case PurchaseMethod.PARTNER:
      return "PARTNER";
    case PurchaseMethod.DISTRIBUTOR:
      return "DISTRIBUTOR";
    case PurchaseMethod.NOT_SURE:
      return "NOT_SURE";
    case PurchaseMethod.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Used to indicate either an ed25519/RSA key. */
export enum RestKeyType {
  REST_KEY_TYPE_UNSPECIFIED = 0,
  REST_KEY_TYPE_ED25519 = 1,
  REST_KEY_TYPE_RSA = 2,
  UNRECOGNIZED = -1,
}

export function restKeyTypeFromJSON(object: any): RestKeyType {
  switch (object) {
    case 0:
    case "REST_KEY_TYPE_UNSPECIFIED":
      return RestKeyType.REST_KEY_TYPE_UNSPECIFIED;
    case 1:
    case "REST_KEY_TYPE_ED25519":
      return RestKeyType.REST_KEY_TYPE_ED25519;
    case 2:
    case "REST_KEY_TYPE_RSA":
      return RestKeyType.REST_KEY_TYPE_RSA;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RestKeyType.UNRECOGNIZED;
  }
}

export function restKeyTypeToJSON(object: RestKeyType): string {
  switch (object) {
    case RestKeyType.REST_KEY_TYPE_UNSPECIFIED:
      return "REST_KEY_TYPE_UNSPECIFIED";
    case RestKeyType.REST_KEY_TYPE_ED25519:
      return "REST_KEY_TYPE_ED25519";
    case RestKeyType.REST_KEY_TYPE_RSA:
      return "REST_KEY_TYPE_RSA";
    case RestKeyType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** A list of supported device models. */
export enum SbpModel {
  SBP_MODEL_UNSPECIFIED = 0,
  HF6100_32D = 100,
  HF6100_60L4D = 200,
  HF6100_64ED = 300,
  UNRECOGNIZED = -1,
}

export function sbpModelFromJSON(object: any): SbpModel {
  switch (object) {
    case 0:
    case "SBP_MODEL_UNSPECIFIED":
      return SbpModel.SBP_MODEL_UNSPECIFIED;
    case 100:
    case "HF6100_32D":
      return SbpModel.HF6100_32D;
    case 200:
    case "HF6100_60L4D":
      return SbpModel.HF6100_60L4D;
    case 300:
    case "HF6100_64ED":
      return SbpModel.HF6100_64ED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SbpModel.UNRECOGNIZED;
  }
}

export function sbpModelToJSON(object: SbpModel): string {
  switch (object) {
    case SbpModel.SBP_MODEL_UNSPECIFIED:
      return "SBP_MODEL_UNSPECIFIED";
    case SbpModel.HF6100_32D:
      return "HF6100_32D";
    case SbpModel.HF6100_60L4D:
      return "HF6100_60L4D";
    case SbpModel.HF6100_64ED:
      return "HF6100_64ED";
    case SbpModel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * A list of supported Product IDs (PIDs). There is a direct mapping from a PID to a
 * entitlement level and device model.
 */
export enum SbpPid {
  PID_UNSPECIFIED = 0,
  /** HF6100_32D_SVC_E - Hyperfabric Essentials HF6100-32D */
  HF6100_32D_SVC_E = 1,
  /** HF6100_60L4D_SVC_E - Hyperfabric Essentials HF6100-60L4D */
  HF6100_60L4D_SVC_E = 2,
  /** HF6100_64ED_SVC_E - Hyperfabric Essentials HF6100-64ED */
  HF6100_64ED_SVC_E = 3,
  /** HF6100_32D_SVC_A - Hyperfabric Advantage HF6100-32D */
  HF6100_32D_SVC_A = 4,
  /** HF6100_60L4D_SVC_A - Hyperfabric Advantage HF6100-60L4D */
  HF6100_60L4D_SVC_A = 5,
  /** HF6100_64ED_SVC_A - Hyperfabric Advantage HF6100-64ED */
  HF6100_64ED_SVC_A = 6,
  /** HF6100_32D_SVC_P - Hyperfabric Premier HF6100-32D */
  HF6100_32D_SVC_P = 7,
  /** HF6100_60L4D_SVC_P - Hyperfabric Premier HF6100-60L4D */
  HF6100_60L4D_SVC_P = 8,
  /** HF6100_64ED_SVC_P - Hyperfabric Premier HF6100-64ED */
  HF6100_64ED_SVC_P = 9,
  /** HF6100_32D_DEMO - Hyperfabric Advantage Demo HF6100-32D */
  HF6100_32D_DEMO = 10,
  /** HF6100_60L4D_DEMO - Hyperfabric Advantage Demo HF6100-60L4D */
  HF6100_60L4D_DEMO = 11,
  /** HF6100_64ED_DEMO - Hyperfabric Advantage Demo HF6100-64ED */
  HF6100_64ED_DEMO = 12,
  UNRECOGNIZED = -1,
}

export function sbpPidFromJSON(object: any): SbpPid {
  switch (object) {
    case 0:
    case "PID_UNSPECIFIED":
      return SbpPid.PID_UNSPECIFIED;
    case 1:
    case "HF6100_32D_SVC_E":
      return SbpPid.HF6100_32D_SVC_E;
    case 2:
    case "HF6100_60L4D_SVC_E":
      return SbpPid.HF6100_60L4D_SVC_E;
    case 3:
    case "HF6100_64ED_SVC_E":
      return SbpPid.HF6100_64ED_SVC_E;
    case 4:
    case "HF6100_32D_SVC_A":
      return SbpPid.HF6100_32D_SVC_A;
    case 5:
    case "HF6100_60L4D_SVC_A":
      return SbpPid.HF6100_60L4D_SVC_A;
    case 6:
    case "HF6100_64ED_SVC_A":
      return SbpPid.HF6100_64ED_SVC_A;
    case 7:
    case "HF6100_32D_SVC_P":
      return SbpPid.HF6100_32D_SVC_P;
    case 8:
    case "HF6100_60L4D_SVC_P":
      return SbpPid.HF6100_60L4D_SVC_P;
    case 9:
    case "HF6100_64ED_SVC_P":
      return SbpPid.HF6100_64ED_SVC_P;
    case 10:
    case "HF6100_32D_DEMO":
      return SbpPid.HF6100_32D_DEMO;
    case 11:
    case "HF6100_60L4D_DEMO":
      return SbpPid.HF6100_60L4D_DEMO;
    case 12:
    case "HF6100_64ED_DEMO":
      return SbpPid.HF6100_64ED_DEMO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SbpPid.UNRECOGNIZED;
  }
}

export function sbpPidToJSON(object: SbpPid): string {
  switch (object) {
    case SbpPid.PID_UNSPECIFIED:
      return "PID_UNSPECIFIED";
    case SbpPid.HF6100_32D_SVC_E:
      return "HF6100_32D_SVC_E";
    case SbpPid.HF6100_60L4D_SVC_E:
      return "HF6100_60L4D_SVC_E";
    case SbpPid.HF6100_64ED_SVC_E:
      return "HF6100_64ED_SVC_E";
    case SbpPid.HF6100_32D_SVC_A:
      return "HF6100_32D_SVC_A";
    case SbpPid.HF6100_60L4D_SVC_A:
      return "HF6100_60L4D_SVC_A";
    case SbpPid.HF6100_64ED_SVC_A:
      return "HF6100_64ED_SVC_A";
    case SbpPid.HF6100_32D_SVC_P:
      return "HF6100_32D_SVC_P";
    case SbpPid.HF6100_60L4D_SVC_P:
      return "HF6100_60L4D_SVC_P";
    case SbpPid.HF6100_64ED_SVC_P:
      return "HF6100_64ED_SVC_P";
    case SbpPid.HF6100_32D_DEMO:
      return "HF6100_32D_DEMO";
    case SbpPid.HF6100_60L4D_DEMO:
      return "HF6100_60L4D_DEMO";
    case SbpPid.HF6100_64ED_DEMO:
      return "HF6100_64ED_DEMO";
    case SbpPid.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Theme defines an enumeration of UI themes. */
export enum Theme {
  THEME_UNSPECIFIED = 0,
  DARK = 1,
  LIGHT = 2,
  HIGH_CONTRAST = 3,
  UNRECOGNIZED = -1,
}

export function themeFromJSON(object: any): Theme {
  switch (object) {
    case 0:
    case "THEME_UNSPECIFIED":
      return Theme.THEME_UNSPECIFIED;
    case 1:
    case "DARK":
      return Theme.DARK;
    case 2:
    case "LIGHT":
      return Theme.LIGHT;
    case 3:
    case "HIGH_CONTRAST":
      return Theme.HIGH_CONTRAST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Theme.UNRECOGNIZED;
  }
}

export function themeToJSON(object: Theme): string {
  switch (object) {
    case Theme.THEME_UNSPECIFIED:
      return "THEME_UNSPECIFIED";
    case Theme.DARK:
      return "DARK";
    case Theme.LIGHT:
      return "LIGHT";
    case Theme.HIGH_CONTRAST:
      return "HIGH_CONTRAST";
    case Theme.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * TokenScope defines an enumeration of scopes that represents different level
 * of privileges that can be associated with bearer tokens and API keys.
 */
export enum TokenScope {
  TOKEN_SCOPE_UNSPECIFIED = 0,
  TOKEN_SCOPE_ADMIN = 1,
  TOKEN_SCOPE_READ_ONLY = 2,
  TOKEN_SCOPE_READ_WRITE = 3,
  UNRECOGNIZED = -1,
}

export function tokenScopeFromJSON(object: any): TokenScope {
  switch (object) {
    case 0:
    case "TOKEN_SCOPE_UNSPECIFIED":
      return TokenScope.TOKEN_SCOPE_UNSPECIFIED;
    case 1:
    case "TOKEN_SCOPE_ADMIN":
      return TokenScope.TOKEN_SCOPE_ADMIN;
    case 2:
    case "TOKEN_SCOPE_READ_ONLY":
      return TokenScope.TOKEN_SCOPE_READ_ONLY;
    case 3:
    case "TOKEN_SCOPE_READ_WRITE":
      return TokenScope.TOKEN_SCOPE_READ_WRITE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TokenScope.UNRECOGNIZED;
  }
}

export function tokenScopeToJSON(object: TokenScope): string {
  switch (object) {
    case TokenScope.TOKEN_SCOPE_UNSPECIFIED:
      return "TOKEN_SCOPE_UNSPECIFIED";
    case TokenScope.TOKEN_SCOPE_ADMIN:
      return "TOKEN_SCOPE_ADMIN";
    case TokenScope.TOKEN_SCOPE_READ_ONLY:
      return "TOKEN_SCOPE_READ_ONLY";
    case TokenScope.TOKEN_SCOPE_READ_WRITE:
      return "TOKEN_SCOPE_READ_WRITE";
    case TokenScope.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * UserRole defines an enumeration of roles that represents different level
 * of privileges that can be associated with a user.
 */
export enum UserRole {
  USER_ROLE_UNSPECIFIED = 0,
  ADMIN = 1,
  READ_WRITE = 2,
  READ_ONLY = 3,
  UNRECOGNIZED = -1,
}

export function userRoleFromJSON(object: any): UserRole {
  switch (object) {
    case 0:
    case "USER_ROLE_UNSPECIFIED":
      return UserRole.USER_ROLE_UNSPECIFIED;
    case 1:
    case "ADMIN":
      return UserRole.ADMIN;
    case 2:
    case "READ_WRITE":
      return UserRole.READ_WRITE;
    case 3:
    case "READ_ONLY":
      return UserRole.READ_ONLY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserRole.UNRECOGNIZED;
  }
}

export function userRoleToJSON(object: UserRole): string {
  switch (object) {
    case UserRole.USER_ROLE_UNSPECIFIED:
      return "USER_ROLE_UNSPECIFIED";
    case UserRole.ADMIN:
      return "ADMIN";
    case UserRole.READ_WRITE:
      return "READ_WRITE";
    case UserRole.READ_ONLY:
      return "READ_ONLY";
    case UserRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** UserTracking defines an enumeration for user-tracking states. */
export enum UserTracking {
  USER_TRACKING_UNSPECIFIED = 0,
  USER_TRACKING_ENABLED = 1,
  USER_TRACKING_DISABLED = 2,
  UNRECOGNIZED = -1,
}

export function userTrackingFromJSON(object: any): UserTracking {
  switch (object) {
    case 0:
    case "USER_TRACKING_UNSPECIFIED":
      return UserTracking.USER_TRACKING_UNSPECIFIED;
    case 1:
    case "USER_TRACKING_ENABLED":
      return UserTracking.USER_TRACKING_ENABLED;
    case 2:
    case "USER_TRACKING_DISABLED":
      return UserTracking.USER_TRACKING_DISABLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserTracking.UNRECOGNIZED;
  }
}

export function userTrackingToJSON(object: UserTracking): string {
  switch (object) {
    case UserTracking.USER_TRACKING_UNSPECIFIED:
      return "USER_TRACKING_UNSPECIFIED";
    case UserTracking.USER_TRACKING_ENABLED:
      return "USER_TRACKING_ENABLED";
    case UserTracking.USER_TRACKING_DISABLED:
      return "USER_TRACKING_DISABLED";
    case UserTracking.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
