// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: sextant/rest_assertions.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import {
  Assertion,
  AssertType,
  assertTypeFromJSON,
  assertTypeToJSON,
  LatchState,
  latchStateFromJSON,
  latchStateToJSON,
} from "../assertions/assertion_types";

export const protobufPackage = "sextant";

/** The response returned to a request for a list of assertions. */
export interface AssertionsResponse {
  /** A list of assertions associated with a specific device. */
  assertions: Assertion[];
}

/** The request for the list of assertions of a specific device. */
export interface GetDeviceAssertionsRequest {
  /** The device id or name. */
  deviceId: string;
  /** Filter by assertion latch state. */
  state: LatchState;
  /** Filter by one or more assertion types. */
  type: AssertType[];
  /** The fabric id or name. */
  fabricId: string;
}

/** The request for the list of assertions of a specific port of a device. */
export interface GetDevicePortAssertionsRequest {
  /** The device id or name. */
  deviceId: string;
  /** The port index or name. */
  port: string;
  /** Filter by assertion latch state. */
  state: LatchState;
  /** Filter by one or more assertion types. */
  type: AssertType[];
  /** The fabric id or name. */
  fabricId: string;
}

/** The request for the list of assertions of a specific node. */
export interface GetNodeAssertionsRequest {
  /** The node id or name. */
  nodeId: string;
  /** Filter by assertion latch state. */
  state: LatchState;
  /** Filter by one or more assertion types. */
  type: AssertType[];
  /** The fabric id or name. */
  fabricId: string;
}

/** The request for the list of assertions of a specific port of a node. */
export interface GetNodePortAssertionsRequest {
  /** The node id or name. */
  nodeId: string;
  /** The port index or name. */
  port: string;
  /** Filter by assertion latch state. */
  state: LatchState;
  /** Filter by one or more assertion types. */
  type: AssertType[];
  /** The fabric id or name. */
  fabricId: string;
}

function createBaseAssertionsResponse(): AssertionsResponse {
  return { assertions: [] };
}

export const AssertionsResponse = {
  encode(message: AssertionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assertions) {
      Assertion.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assertions.push(Assertion.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertionsResponse {
    return {
      assertions: globalThis.Array.isArray(object?.assertions)
        ? object.assertions.map((e: any) => Assertion.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AssertionsResponse): unknown {
    const obj: any = {};
    if (message.assertions?.length) {
      obj.assertions = message.assertions.map((e) => Assertion.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertionsResponse>, I>>(base?: I): AssertionsResponse {
    return AssertionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertionsResponse>, I>>(object: I): AssertionsResponse {
    const message = createBaseAssertionsResponse();
    message.assertions = object.assertions?.map((e) => Assertion.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetDeviceAssertionsRequest(): GetDeviceAssertionsRequest {
  return { deviceId: "", state: 0, type: [], fabricId: "" };
}

export const GetDeviceAssertionsRequest = {
  encode(message: GetDeviceAssertionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.state !== 0) {
      writer.uint32(16).int32(message.state);
    }
    writer.uint32(26).fork();
    for (const v of message.type) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.fabricId !== "") {
      writer.uint32(34).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDeviceAssertionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceAssertionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 3:
          if (tag === 24) {
            message.type.push(reader.int32() as any);

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.type.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceAssertionsRequest {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      state: isSet(object.state) ? latchStateFromJSON(object.state) : 0,
      type: globalThis.Array.isArray(object?.type) ? object.type.map((e: any) => assertTypeFromJSON(e)) : [],
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: GetDeviceAssertionsRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.state !== 0) {
      obj.state = latchStateToJSON(message.state);
    }
    if (message.type?.length) {
      obj.type = message.type.map((e) => assertTypeToJSON(e));
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeviceAssertionsRequest>, I>>(base?: I): GetDeviceAssertionsRequest {
    return GetDeviceAssertionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeviceAssertionsRequest>, I>>(object: I): GetDeviceAssertionsRequest {
    const message = createBaseGetDeviceAssertionsRequest();
    message.deviceId = object.deviceId ?? "";
    message.state = object.state ?? 0;
    message.type = object.type?.map((e) => e) || [];
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseGetDevicePortAssertionsRequest(): GetDevicePortAssertionsRequest {
  return { deviceId: "", port: "", state: 0, type: [], fabricId: "" };
}

export const GetDevicePortAssertionsRequest = {
  encode(message: GetDevicePortAssertionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.port !== "") {
      writer.uint32(18).string(message.port);
    }
    if (message.state !== 0) {
      writer.uint32(24).int32(message.state);
    }
    writer.uint32(34).fork();
    for (const v of message.type) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.fabricId !== "") {
      writer.uint32(42).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDevicePortAssertionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDevicePortAssertionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.port = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 4:
          if (tag === 32) {
            message.type.push(reader.int32() as any);

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.type.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDevicePortAssertionsRequest {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      port: isSet(object.port) ? globalThis.String(object.port) : "",
      state: isSet(object.state) ? latchStateFromJSON(object.state) : 0,
      type: globalThis.Array.isArray(object?.type) ? object.type.map((e: any) => assertTypeFromJSON(e)) : [],
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: GetDevicePortAssertionsRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.port !== "") {
      obj.port = message.port;
    }
    if (message.state !== 0) {
      obj.state = latchStateToJSON(message.state);
    }
    if (message.type?.length) {
      obj.type = message.type.map((e) => assertTypeToJSON(e));
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDevicePortAssertionsRequest>, I>>(base?: I): GetDevicePortAssertionsRequest {
    return GetDevicePortAssertionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDevicePortAssertionsRequest>, I>>(
    object: I,
  ): GetDevicePortAssertionsRequest {
    const message = createBaseGetDevicePortAssertionsRequest();
    message.deviceId = object.deviceId ?? "";
    message.port = object.port ?? "";
    message.state = object.state ?? 0;
    message.type = object.type?.map((e) => e) || [];
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseGetNodeAssertionsRequest(): GetNodeAssertionsRequest {
  return { nodeId: "", state: 0, type: [], fabricId: "" };
}

export const GetNodeAssertionsRequest = {
  encode(message: GetNodeAssertionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeId !== "") {
      writer.uint32(10).string(message.nodeId);
    }
    if (message.state !== 0) {
      writer.uint32(16).int32(message.state);
    }
    writer.uint32(26).fork();
    for (const v of message.type) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.fabricId !== "") {
      writer.uint32(34).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodeAssertionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodeAssertionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 3:
          if (tag === 24) {
            message.type.push(reader.int32() as any);

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.type.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodeAssertionsRequest {
    return {
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      state: isSet(object.state) ? latchStateFromJSON(object.state) : 0,
      type: globalThis.Array.isArray(object?.type) ? object.type.map((e: any) => assertTypeFromJSON(e)) : [],
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: GetNodeAssertionsRequest): unknown {
    const obj: any = {};
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.state !== 0) {
      obj.state = latchStateToJSON(message.state);
    }
    if (message.type?.length) {
      obj.type = message.type.map((e) => assertTypeToJSON(e));
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodeAssertionsRequest>, I>>(base?: I): GetNodeAssertionsRequest {
    return GetNodeAssertionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodeAssertionsRequest>, I>>(object: I): GetNodeAssertionsRequest {
    const message = createBaseGetNodeAssertionsRequest();
    message.nodeId = object.nodeId ?? "";
    message.state = object.state ?? 0;
    message.type = object.type?.map((e) => e) || [];
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseGetNodePortAssertionsRequest(): GetNodePortAssertionsRequest {
  return { nodeId: "", port: "", state: 0, type: [], fabricId: "" };
}

export const GetNodePortAssertionsRequest = {
  encode(message: GetNodePortAssertionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeId !== "") {
      writer.uint32(10).string(message.nodeId);
    }
    if (message.port !== "") {
      writer.uint32(18).string(message.port);
    }
    if (message.state !== 0) {
      writer.uint32(24).int32(message.state);
    }
    writer.uint32(34).fork();
    for (const v of message.type) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.fabricId !== "") {
      writer.uint32(42).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodePortAssertionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodePortAssertionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.port = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 4:
          if (tag === 32) {
            message.type.push(reader.int32() as any);

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.type.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodePortAssertionsRequest {
    return {
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      port: isSet(object.port) ? globalThis.String(object.port) : "",
      state: isSet(object.state) ? latchStateFromJSON(object.state) : 0,
      type: globalThis.Array.isArray(object?.type) ? object.type.map((e: any) => assertTypeFromJSON(e)) : [],
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: GetNodePortAssertionsRequest): unknown {
    const obj: any = {};
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.port !== "") {
      obj.port = message.port;
    }
    if (message.state !== 0) {
      obj.state = latchStateToJSON(message.state);
    }
    if (message.type?.length) {
      obj.type = message.type.map((e) => assertTypeToJSON(e));
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodePortAssertionsRequest>, I>>(base?: I): GetNodePortAssertionsRequest {
    return GetNodePortAssertionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodePortAssertionsRequest>, I>>(object: I): GetNodePortAssertionsRequest {
    const message = createBaseGetNodePortAssertionsRequest();
    message.nodeId = object.nodeId ?? "";
    message.port = object.port ?? "";
    message.state = object.state ?? 0;
    message.type = object.type?.map((e) => e) || [];
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
