import { BaseColumnConfig, Config } from "./baseColumnConfig";
import { Constants } from "../../../utils/constants";

interface NumberColumnConfig extends Config {
  precision?: number;
  showZeroAsEmpty?: boolean;
}

export class NumberColumn extends BaseColumnConfig<number> {
  /**
   * used to limit the number of decimal
   * places to show
   */
  protected precision: number | undefined;
  protected showZeroAsEmpty: boolean; // determines if we show 0 or EMPTY_VALUE
  constructor({
    precision,
    showZeroAsEmpty = false,
    ...baseColumnConfig
  }: NumberColumnConfig) {
    super(baseColumnConfig);
    this.precision = precision;
    this.showZeroAsEmpty = showZeroAsEmpty;
  }
  compare(a: number = 0, b: number = 0) {
    return a - b;
  }
  render(data: number) {
    if (this.showZeroAsEmpty && data === 0) {
      return <div className="mds-text-p3">{Constants.EMPTY_VALUE}</div>;
    }
    if (this.precision) {
      return <div className="mds-text-p3">{data.toFixed(this.precision)}</div>;
    }
    return <div className="mds-text-p3">{data}</div>;
  }
}

export class PercentColumn extends BaseColumnConfig<number | undefined> {
  compare(a: number = 0, b: number = 0) {
    return a - b;
  }
  getValue(data: number): string {
    if (data) {
      if (data < 1 && data > 0) {
        return `${data * 100}%`;
      }
      return `${data}%`;
    }
    return Constants.EMPTY_VALUE;
  }
  render(data: number) {
    return <div className="percent">{this.getValue(data)}</div>;
  }
}
