import { BaseColumnConfig } from "./baseColumnConfig";

export class BooleanColumn extends BaseColumnConfig<boolean> {
  compare(a: boolean, b: boolean) {
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  }
  render(data: boolean) {
    return <div className="mds-text-p3">{data ? "True" : "False"}</div>;
  }
}

export class EnabledColumn extends BooleanColumn {
  render(data: boolean) {
    return <div className="mds-text-p3">{data ? "enabled" : "disabled"}</div>;
  }
}

export class YesNoColumn extends BooleanColumn {
  render(data: boolean) {
    return <div className="mds-text-p3">{data ? "Yes" : "No"}</div>;
  }
}
