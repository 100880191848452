import React, { useContext } from "react";
import { Button } from "@magnetic/button";
import { Heading } from "@magnetic/heading";
import { CiscoLogo } from "../common/ciscoLogo";
import HFlex from "../components/hFlex";
import { LoginContext } from "./loginContext";
import "./newUserRegistrationUnavailable.scss";
import { GlobalFooter } from "src/layouts";

export const NewUserRegistrationUnavailable = () => {
  const { logout } = useContext(LoginContext);

  return (
    <div className="new-reg-unavailable">
      <CiscoLogo />
      <div className="content">
        <Heading size="h1">New user registration</Heading>
        <p>Our systems are busy due to very high launch week interest!</p>

        <p>New user registration is temporarily paused.</p>

        <p>We&apos;ll be back soon.</p>
        <HFlex align="stretch" justify="center" shrink={1}>
          <Button name="logout" kind="tertiary" onClick={logout}>
            &larr; Back to login
          </Button>
        </HFlex>
      </div>
      <GlobalFooter />
    </div>
  );
};
