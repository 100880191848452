import {
  Themes,
  setTheme as setMagneticTheme,
  getTheme
} from "@magnetic/theme";
import { Theme } from "src/gen/schema/models/auth";
import { Apis } from "./api";
import { UserPreferences } from "src/gen/schema/models/user";
import { logger } from "src/core";

export const Theme2Magnetic: Record<Theme, keyof Themes> = {
  [Theme.THEME_UNSPECIFIED]: "light-classic",
  [Theme.DARK]: "dark-classic",
  [Theme.LIGHT]: "light-classic",
  [Theme.HIGH_CONTRAST]: "light-classic",
  [Theme.UNRECOGNIZED]: "dark-classic"
};

export const Magtic2Theme: Record<keyof Themes, Theme> = {
  light: Theme.LIGHT,
  dark: Theme.DARK,
  "light-classic": Theme.LIGHT,
  "dark-classic": Theme.DARK
};

const THEME = "theme";

/**
 * Retrieve the theme from the
 * local storage so it can be set
 * prior to the application loading
 * user preferences
 */
export function getLocalTheme() {
  /**
   * can't just use window.cnc.settings
   * since for login this will not exist.
   */
  const theme = localStorage.getItem(THEME);
  return Magtic2Theme[theme as keyof Themes];
}

/**
 * Wrapper for the magnetic theme
 * setter that also sets the theme
 * in the local storage.
 */
export function setLocalTheme(theme: Theme, persistInBackend?: boolean) {
  const value = Theme2Magnetic[theme];
  if (persistInBackend) {
    window.cnc.settings.set(THEME, value);
    Apis.Fred.getUserPreferences()
      .then((preferences: UserPreferences) => {
        Apis.Brig.updateUserPreferences({
          ...preferences,
          theme
        }).catch((err) => {
          logger.error("Update User Preferences Error: ", err);
        });
      })
      .catch((err) => {
        logger.error("Get User Preferences Error: ", err);
      });
  }

  const root = document.documentElement;
  if (theme === Theme.DARK) {
    root.style.setProperty("--base-bg-default", "#0f1214");
    root.style.setProperty("--shadow-color", "rgba(255, 255, 255, 0.12157");
  } else if (theme === Theme.LIGHT) {
    root.style.setProperty("--base-bg-default", "#f7f7f7");
    root.style.setProperty("--shadow-color", "rgba(0, 0, 0, 0.12157");
  }

  const current = getTheme();
  if (current !== value) {
    setMagneticTheme(value);
  }
}
