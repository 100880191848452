// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: configd/endpoints.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import {
  Endpoint,
  EndpointType,
  endpointTypeFromJSON,
  endpointTypeToJSON,
  NotificationSettings,
} from "../models/endpoints";

export const protobufPackage = "configd";

/** Get configured endpoints. */
export interface GetEndpointsRequest {
  /** Get endpoints by one or more endpoint type. */
  type: EndpointType[];
  /** Get endpoints by one or more endpoint id. */
  endpointId: string[];
  /** Return metadata for the endpoint. */
  includeMetadata: boolean;
}

/** Get a configured endpoint.. */
export interface GetEndpointRequest {
  /** The endpoint id or name. */
  endpointId: string;
  /** Return metadata for the endpoint. */
  includeMetadata: boolean;
}

/** Add new endpoints. */
export interface AddEndpointsRequest {
  /** The new endpoints to add. */
  endpoints: Endpoint[];
  /** Send a confirmation code. Currently, only email endpoints will receive a code. */
  sendConfirmationCode: boolean;
}

/** Update an endpoint. */
export interface UpdateEndpointRequest {
  /** The endpoint id or name. */
  endpointId: string;
  /** The updated definition of the endpoint. */
  endpoint: Endpoint | undefined;
}

export interface VerifyEndpointCode {
  /** The verification code. */
  code: string;
}

/** Verify an endpoint. */
export interface VerifyEndpointRequest {
  /** The endpoint id or name. */
  endpointId: string;
  /** The payload containing the verification code. */
  code: VerifyEndpointCode | undefined;
}

/** Delete an endpoint. */
export interface DeleteEndpointRequest {
  /** The endpoint id or name. */
  endpointId: string;
}

/** A list of endpoints. */
export interface EndpointsResponse {
  /** One or more endpoint definitions. */
  endpoints: Endpoint[];
}

/** Resend a confirmation code for an endpoint. */
export interface ResendCodeForEndpointRequest {
  /** The endpoint id or name. */
  endpointId: string;
}

/** Returns a list of fabrics where notifications are enabled. */
export interface NotificationSettingsResponse {
  /** A list of fabrics with notification settings configured. */
  settings: NotificationSettings[];
}

/** Get notification settings for all fabrics. */
export interface GetNotificationSettingsRequest {
  /** Return metadata for the settings. */
  includeMetadata: boolean;
}

/** Get the notification settings for a fabric. */
export interface GetFabricNotificationSettingsRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** Return metadata for the settings. */
  includeMetadata: boolean;
}

/** Delete notification settings for a fabric. */
export interface DeleteFabricNotificationSettingsRequest {
  /** The fabric id or name. */
  fabricId: string;
}

function createBaseGetEndpointsRequest(): GetEndpointsRequest {
  return { type: [], endpointId: [], includeMetadata: false };
}

export const GetEndpointsRequest = {
  encode(message: GetEndpointsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.type) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.endpointId) {
      writer.uint32(18).string(v!);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(24).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetEndpointsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetEndpointsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.type.push(reader.int32() as any);

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.type.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endpointId.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetEndpointsRequest {
    return {
      type: globalThis.Array.isArray(object?.type) ? object.type.map((e: any) => endpointTypeFromJSON(e)) : [],
      endpointId: globalThis.Array.isArray(object?.endpointId)
        ? object.endpointId.map((e: any) => globalThis.String(e))
        : [],
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetEndpointsRequest): unknown {
    const obj: any = {};
    if (message.type?.length) {
      obj.type = message.type.map((e) => endpointTypeToJSON(e));
    }
    if (message.endpointId?.length) {
      obj.endpointId = message.endpointId;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetEndpointsRequest>, I>>(base?: I): GetEndpointsRequest {
    return GetEndpointsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetEndpointsRequest>, I>>(object: I): GetEndpointsRequest {
    const message = createBaseGetEndpointsRequest();
    message.type = object.type?.map((e) => e) || [];
    message.endpointId = object.endpointId?.map((e) => e) || [];
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseGetEndpointRequest(): GetEndpointRequest {
  return { endpointId: "", includeMetadata: false };
}

export const GetEndpointRequest = {
  encode(message: GetEndpointRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.endpointId !== "") {
      writer.uint32(10).string(message.endpointId);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(16).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetEndpointRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetEndpointRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.endpointId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetEndpointRequest {
    return {
      endpointId: isSet(object.endpointId) ? globalThis.String(object.endpointId) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetEndpointRequest): unknown {
    const obj: any = {};
    if (message.endpointId !== "") {
      obj.endpointId = message.endpointId;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetEndpointRequest>, I>>(base?: I): GetEndpointRequest {
    return GetEndpointRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetEndpointRequest>, I>>(object: I): GetEndpointRequest {
    const message = createBaseGetEndpointRequest();
    message.endpointId = object.endpointId ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseAddEndpointsRequest(): AddEndpointsRequest {
  return { endpoints: [], sendConfirmationCode: false };
}

export const AddEndpointsRequest = {
  encode(message: AddEndpointsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.endpoints) {
      Endpoint.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.sendConfirmationCode !== false) {
      writer.uint32(16).bool(message.sendConfirmationCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddEndpointsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddEndpointsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.endpoints.push(Endpoint.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.sendConfirmationCode = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddEndpointsRequest {
    return {
      endpoints: globalThis.Array.isArray(object?.endpoints)
        ? object.endpoints.map((e: any) => Endpoint.fromJSON(e))
        : [],
      sendConfirmationCode: isSet(object.sendConfirmationCode)
        ? globalThis.Boolean(object.sendConfirmationCode)
        : false,
    };
  },

  toJSON(message: AddEndpointsRequest): unknown {
    const obj: any = {};
    if (message.endpoints?.length) {
      obj.endpoints = message.endpoints.map((e) => Endpoint.toJSON(e));
    }
    if (message.sendConfirmationCode !== false) {
      obj.sendConfirmationCode = message.sendConfirmationCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddEndpointsRequest>, I>>(base?: I): AddEndpointsRequest {
    return AddEndpointsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddEndpointsRequest>, I>>(object: I): AddEndpointsRequest {
    const message = createBaseAddEndpointsRequest();
    message.endpoints = object.endpoints?.map((e) => Endpoint.fromPartial(e)) || [];
    message.sendConfirmationCode = object.sendConfirmationCode ?? false;
    return message;
  },
};

function createBaseUpdateEndpointRequest(): UpdateEndpointRequest {
  return { endpointId: "", endpoint: undefined };
}

export const UpdateEndpointRequest = {
  encode(message: UpdateEndpointRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.endpointId !== "") {
      writer.uint32(10).string(message.endpointId);
    }
    if (message.endpoint !== undefined) {
      Endpoint.encode(message.endpoint, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEndpointRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEndpointRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.endpointId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endpoint = Endpoint.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEndpointRequest {
    return {
      endpointId: isSet(object.endpointId) ? globalThis.String(object.endpointId) : "",
      endpoint: isSet(object.endpoint) ? Endpoint.fromJSON(object.endpoint) : undefined,
    };
  },

  toJSON(message: UpdateEndpointRequest): unknown {
    const obj: any = {};
    if (message.endpointId !== "") {
      obj.endpointId = message.endpointId;
    }
    if (message.endpoint !== undefined) {
      obj.endpoint = Endpoint.toJSON(message.endpoint);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEndpointRequest>, I>>(base?: I): UpdateEndpointRequest {
    return UpdateEndpointRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEndpointRequest>, I>>(object: I): UpdateEndpointRequest {
    const message = createBaseUpdateEndpointRequest();
    message.endpointId = object.endpointId ?? "";
    message.endpoint = (object.endpoint !== undefined && object.endpoint !== null)
      ? Endpoint.fromPartial(object.endpoint)
      : undefined;
    return message;
  },
};

function createBaseVerifyEndpointCode(): VerifyEndpointCode {
  return { code: "" };
}

export const VerifyEndpointCode = {
  encode(message: VerifyEndpointCode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifyEndpointCode {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifyEndpointCode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VerifyEndpointCode {
    return { code: isSet(object.code) ? globalThis.String(object.code) : "" };
  },

  toJSON(message: VerifyEndpointCode): unknown {
    const obj: any = {};
    if (message.code !== "") {
      obj.code = message.code;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VerifyEndpointCode>, I>>(base?: I): VerifyEndpointCode {
    return VerifyEndpointCode.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VerifyEndpointCode>, I>>(object: I): VerifyEndpointCode {
    const message = createBaseVerifyEndpointCode();
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseVerifyEndpointRequest(): VerifyEndpointRequest {
  return { endpointId: "", code: undefined };
}

export const VerifyEndpointRequest = {
  encode(message: VerifyEndpointRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.endpointId !== "") {
      writer.uint32(10).string(message.endpointId);
    }
    if (message.code !== undefined) {
      VerifyEndpointCode.encode(message.code, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifyEndpointRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifyEndpointRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.endpointId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = VerifyEndpointCode.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VerifyEndpointRequest {
    return {
      endpointId: isSet(object.endpointId) ? globalThis.String(object.endpointId) : "",
      code: isSet(object.code) ? VerifyEndpointCode.fromJSON(object.code) : undefined,
    };
  },

  toJSON(message: VerifyEndpointRequest): unknown {
    const obj: any = {};
    if (message.endpointId !== "") {
      obj.endpointId = message.endpointId;
    }
    if (message.code !== undefined) {
      obj.code = VerifyEndpointCode.toJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VerifyEndpointRequest>, I>>(base?: I): VerifyEndpointRequest {
    return VerifyEndpointRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VerifyEndpointRequest>, I>>(object: I): VerifyEndpointRequest {
    const message = createBaseVerifyEndpointRequest();
    message.endpointId = object.endpointId ?? "";
    message.code = (object.code !== undefined && object.code !== null)
      ? VerifyEndpointCode.fromPartial(object.code)
      : undefined;
    return message;
  },
};

function createBaseDeleteEndpointRequest(): DeleteEndpointRequest {
  return { endpointId: "" };
}

export const DeleteEndpointRequest = {
  encode(message: DeleteEndpointRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.endpointId !== "") {
      writer.uint32(10).string(message.endpointId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteEndpointRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteEndpointRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.endpointId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteEndpointRequest {
    return { endpointId: isSet(object.endpointId) ? globalThis.String(object.endpointId) : "" };
  },

  toJSON(message: DeleteEndpointRequest): unknown {
    const obj: any = {};
    if (message.endpointId !== "") {
      obj.endpointId = message.endpointId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteEndpointRequest>, I>>(base?: I): DeleteEndpointRequest {
    return DeleteEndpointRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteEndpointRequest>, I>>(object: I): DeleteEndpointRequest {
    const message = createBaseDeleteEndpointRequest();
    message.endpointId = object.endpointId ?? "";
    return message;
  },
};

function createBaseEndpointsResponse(): EndpointsResponse {
  return { endpoints: [] };
}

export const EndpointsResponse = {
  encode(message: EndpointsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.endpoints) {
      Endpoint.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EndpointsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEndpointsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.endpoints.push(Endpoint.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EndpointsResponse {
    return {
      endpoints: globalThis.Array.isArray(object?.endpoints)
        ? object.endpoints.map((e: any) => Endpoint.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EndpointsResponse): unknown {
    const obj: any = {};
    if (message.endpoints?.length) {
      obj.endpoints = message.endpoints.map((e) => Endpoint.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EndpointsResponse>, I>>(base?: I): EndpointsResponse {
    return EndpointsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EndpointsResponse>, I>>(object: I): EndpointsResponse {
    const message = createBaseEndpointsResponse();
    message.endpoints = object.endpoints?.map((e) => Endpoint.fromPartial(e)) || [];
    return message;
  },
};

function createBaseResendCodeForEndpointRequest(): ResendCodeForEndpointRequest {
  return { endpointId: "" };
}

export const ResendCodeForEndpointRequest = {
  encode(message: ResendCodeForEndpointRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.endpointId !== "") {
      writer.uint32(10).string(message.endpointId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResendCodeForEndpointRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResendCodeForEndpointRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.endpointId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ResendCodeForEndpointRequest {
    return { endpointId: isSet(object.endpointId) ? globalThis.String(object.endpointId) : "" };
  },

  toJSON(message: ResendCodeForEndpointRequest): unknown {
    const obj: any = {};
    if (message.endpointId !== "") {
      obj.endpointId = message.endpointId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ResendCodeForEndpointRequest>, I>>(base?: I): ResendCodeForEndpointRequest {
    return ResendCodeForEndpointRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResendCodeForEndpointRequest>, I>>(object: I): ResendCodeForEndpointRequest {
    const message = createBaseResendCodeForEndpointRequest();
    message.endpointId = object.endpointId ?? "";
    return message;
  },
};

function createBaseNotificationSettingsResponse(): NotificationSettingsResponse {
  return { settings: [] };
}

export const NotificationSettingsResponse = {
  encode(message: NotificationSettingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.settings) {
      NotificationSettings.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NotificationSettingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationSettingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.settings.push(NotificationSettings.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotificationSettingsResponse {
    return {
      settings: globalThis.Array.isArray(object?.settings)
        ? object.settings.map((e: any) => NotificationSettings.fromJSON(e))
        : [],
    };
  },

  toJSON(message: NotificationSettingsResponse): unknown {
    const obj: any = {};
    if (message.settings?.length) {
      obj.settings = message.settings.map((e) => NotificationSettings.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NotificationSettingsResponse>, I>>(base?: I): NotificationSettingsResponse {
    return NotificationSettingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NotificationSettingsResponse>, I>>(object: I): NotificationSettingsResponse {
    const message = createBaseNotificationSettingsResponse();
    message.settings = object.settings?.map((e) => NotificationSettings.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetNotificationSettingsRequest(): GetNotificationSettingsRequest {
  return { includeMetadata: false };
}

export const GetNotificationSettingsRequest = {
  encode(message: GetNotificationSettingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.includeMetadata !== false) {
      writer.uint32(8).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNotificationSettingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNotificationSettingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNotificationSettingsRequest {
    return { includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false };
  },

  toJSON(message: GetNotificationSettingsRequest): unknown {
    const obj: any = {};
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNotificationSettingsRequest>, I>>(base?: I): GetNotificationSettingsRequest {
    return GetNotificationSettingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNotificationSettingsRequest>, I>>(
    object: I,
  ): GetNotificationSettingsRequest {
    const message = createBaseGetNotificationSettingsRequest();
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseGetFabricNotificationSettingsRequest(): GetFabricNotificationSettingsRequest {
  return { fabricId: "", includeMetadata: false };
}

export const GetFabricNotificationSettingsRequest = {
  encode(message: GetFabricNotificationSettingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(16).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricNotificationSettingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricNotificationSettingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricNotificationSettingsRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetFabricNotificationSettingsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricNotificationSettingsRequest>, I>>(
    base?: I,
  ): GetFabricNotificationSettingsRequest {
    return GetFabricNotificationSettingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricNotificationSettingsRequest>, I>>(
    object: I,
  ): GetFabricNotificationSettingsRequest {
    const message = createBaseGetFabricNotificationSettingsRequest();
    message.fabricId = object.fabricId ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseDeleteFabricNotificationSettingsRequest(): DeleteFabricNotificationSettingsRequest {
  return { fabricId: "" };
}

export const DeleteFabricNotificationSettingsRequest = {
  encode(message: DeleteFabricNotificationSettingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteFabricNotificationSettingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteFabricNotificationSettingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteFabricNotificationSettingsRequest {
    return { fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "" };
  },

  toJSON(message: DeleteFabricNotificationSettingsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteFabricNotificationSettingsRequest>, I>>(
    base?: I,
  ): DeleteFabricNotificationSettingsRequest {
    return DeleteFabricNotificationSettingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteFabricNotificationSettingsRequest>, I>>(
    object: I,
  ): DeleteFabricNotificationSettingsRequest {
    const message = createBaseDeleteFabricNotificationSettingsRequest();
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
