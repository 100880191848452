import { PATHS } from "../core/routes";

export const getFabricRoute = (
  fabricId?: string,
  tab: string | undefined = PATHS.SUMMARY
): string => {
  return `/${PATHS.FABRIC}/${fabricId}/${tab}`;
};

export const getSwitchRoute = (
  fabricId?: string,
  nodeId?: string,
  tab: string = PATHS.SUMMARY
): string => {
  return `${getFabricRoute(fabricId, PATHS.CHASSIS)}/${nodeId}/${tab}`;
};

export const getPortRoute = (
  fabricId?: string,
  nodeId?: string,
  portId?: string
): string => {
  return `${getSwitchRoute(fabricId, nodeId, PATHS.PORT)}/${portId}`;
};

export const getVrfRoute = (fabricId?: string, vrfId?: string): string => {
  return `${getFabricRoute(fabricId, PATHS.VRF)}/${vrfId}`;
};

export const getBgpPeerRoute = (
  fabricId: string,
  vrfId: string,
  bgpPeerId: string
): string => {
  return `${getVrfRoute(fabricId, vrfId)}/${PATHS.BGP_PEER}/${bgpPeerId}`;
};

export const getVniRoute = (fabricId?: string, vniId?: string): string => {
  return `${getFabricRoute(fabricId, PATHS.VNI)}/${vniId}`;
};

export const getPortChannelRoute = (
  fabricId?: string,
  portChannelId?: string
): string => {
  return `${getFabricRoute(fabricId, PATHS.PORT_CHANNEL)}/${portChannelId}`;
};

export const getPolicyRoute = (
  fabricId?: string,
  policyId?: string
): string => {
  return `${getFabricRoute(fabricId, PATHS.POLICY)}/${policyId}`;
};
