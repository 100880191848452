import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Input } from "@magnetic/input";
import "./mockShellInput.scss";
import {
  CliCommandCompletion,
  CommandAction,
  ResultFormat
} from "src/gen/schema/bilge/api";
import { DataProviderContext } from "src/core/provider/dataProvider";
import { useLocation } from "react-router-dom";
import {
  getCommandCompletionData,
  getInitialCommand,
  moveCursorBackwardByWord,
  moveCursorForwardByWord
} from "./mockShellUtils";
import { CommandExecution } from "./manageMockShellModal";
import { ApiError } from "src/core/apiError";

type MockShellInputProps = {
  readonly commandExecution: (cmdObj: CommandExecution) => void;
  readonly completeCommand: (cmdObj: CommandExecution) => void;
  readonly commandCompleteSuggestions: CliCommandCompletion[];
  readonly onCommandCompleteSuggestionsChange: (
    value: CliCommandCompletion[]
  ) => void;
  readonly onClearError: (value: ApiError | undefined) => void;
  readonly loading: boolean;
  readonly isSuggestionError: boolean;
  readonly setIsSuggestionError: Dispatch<SetStateAction<boolean>>;
  readonly longestMatchToAutoComplete: string;
  readonly setLongestMatchToAutoComplete: Dispatch<SetStateAction<string>>;
};
/**
 * MockShellInput is intended to mostly mimic bash shell prompt
 * The main keystrokes (input) requirement as per TORT-2094:
 * 1. " " (space) -> Autocomplete. There can be 3 situations to handle
 *     a) Single command to autocomplete (unambiguous) where hitting of space will autocomplete with the command for user
 *     b) Multiple commands to autocomplete(ambiguous) where we show a dropdown with available commands that user can select from to autocomplete
 *     c) Actual need of entering space between commands(here backend does not return any commands to autocomplete as the command typed by user would be already complete)
 * 2. tab -> Expands. It outputs the expanded form of the current command or provides the next commands
 * 3. ? -> Expounds. Same as tab but also provides the helpstring explaining each command
 * 4. enter -> Executes. Executes the commad until where exactly the enter key is pressed
 * The other important keys strokes that mimics the bash shell that are implemented are:
 * 5. Ctrl+P and  Up Arrow: Moves the cursor to the previous command in history.
 * 6. Ctrl+N and Down Arrow: Moves the cursor to the next command in history.
 * 7. M+f: Move forward one word.
 * 8. M+b: Move backward one word.
 * 9. Ctrl+a: Move to the beginning of the line.
 * 10. Ctrl+e: Move to the end of the line.
 * 11. Ctrl+k: Kill (cut) text from the cursor to the end of the line.
 * 12. Ctrl+y: Yank (paste) the most recently killed text.
 */
export const MockShellInput = (props: MockShellInputProps) => {
  const {
    completeCommand,
    commandExecution,
    commandCompleteSuggestions,
    onCommandCompleteSuggestionsChange,
    onClearError,
    isSuggestionError,
    setIsSuggestionError,
    longestMatchToAutoComplete,
    setLongestMatchToAutoComplete
  } = props;
  const { fabric } = useContext(DataProviderContext);
  const location = useLocation();
  const [command, setCommand] = useState(
    getInitialCommand(location.pathname, fabric)
  );
  const [commandHistory, setCommandHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [killRing, setKillRing] = useState<string>("");
  const [cursorPos, setCursorPos] = useState<number>(command.length);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Focus the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const navigateHistory = useCallback(
    (direction: number) => {
      const newIndex = historyIndex + direction;
      if (newIndex < -1 || newIndex >= commandHistory.length) return;
      if (newIndex === -1) {
        setCommand("");
        setCursorPos(0);
      } else {
        setCommand(commandHistory?.[newIndex] ?? "");
        setCursorPos(commandHistory?.[newIndex]?.length || 0);
      }
      setHistoryIndex(newIndex);
    },
    [historyIndex, commandHistory]
  );

  const killText = useCallback(() => {
    if (inputRef.current) {
      const input = inputRef.current;
      const start = input.selectionStart || 0;
      const end = command.length;
      setKillRing(command.substring(start, end));
      setCommand(command.substring(0, start));
      setCursorPos(command.substring(0, start).length);
    }
  }, [command]);

  const yankText = useCallback(() => {
    if (inputRef.current) {
      const input = inputRef.current;
      const start = input.selectionStart || 0;
      // Insert the text from the kill ring at the current cursor position
      const newValue =
        command.substring(0, start) + killRing + command.substring(start);
      setCommand(newValue);
    }
  }, [command, killRing]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCommand(e.target.value);
      if (e.currentTarget.selectionStart) {
        setCursorPos(e.currentTarget.selectionStart);
      }
      onCommandCompleteSuggestionsChange([]);
    },
    [onCommandCompleteSuggestionsChange]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      setIsSuggestionError(false);
      // escape the "meta + shift + 4/5" (to take screenshot of error)
      if (!e.metaKey) {
        onClearError(undefined);
      }
      switch (e.key) {
        // space allows for autocompletion of command,
        case " ":
          completeCommand({
            cmd: command,
            cmdAction: CommandAction.COMPLETE,
            resultFormat: ResultFormat.JSON,
            cmdPos: inputRef.current?.selectionStart || 0
          });
          break;
        case "Tab":
          // tab provides all the commands that can be expanded from the given command
          e.preventDefault();
          commandExecution({
            cmd: command,
            cmdAction: CommandAction.EXPAND,
            resultFormat: ResultFormat.JSON,
            cmdPos: inputRef.current?.selectionStart || 0
          });
          break;
        case "?":
          // similar to tab but with help string to explain the command
          e.preventDefault();
          commandExecution({
            cmd: command,
            cmdAction: CommandAction.EXPOUND,
            resultFormat: ResultFormat.JSON,
            cmdPos: inputRef.current?.selectionStart || 0
          });
          break;
        case "Enter":
          // executes the command
          e.preventDefault();
          setHistoryIndex((prevHistoryIndex) => prevHistoryIndex + 1);
          setCommandHistory([...commandHistory, command]);
          commandExecution({
            cmd: command,
            cmdAction: CommandAction.EXECUTE,
            resultFormat: ResultFormat.CSV,
            // when user hits enter, the cursorPosition should be always sent as the end of the command
            cmdPos: command.length
          });
          break;
        case "ArrowUp":
          e.preventDefault();
          navigateHistory(-1);
          break;
        case "ArrowDown":
          e.preventDefault();
          navigateHistory(1);
          break;
        default:
          break;
      }
      if (e.metaKey) {
        switch (e.key) {
          case "f":
            e.preventDefault();
            moveCursorForwardByWord(inputRef, command);
            break;
          case "b":
            e.preventDefault();
            moveCursorBackwardByWord(inputRef, command);
            break;
          default:
            break;
        }
      }
      if (e.ctrlKey) {
        switch (e.key) {
          case "k":
            e.preventDefault();
            killText();
            break;
          case "y":
            e.preventDefault();
            yankText();
            break;
          case "p":
            e.preventDefault();
            navigateHistory(-1);
            break;
          case "n":
            e.preventDefault();
            navigateHistory(1);
            break;
          default:
            break;
        }
      }
    },
    [
      command,
      commandExecution,
      commandHistory,
      completeCommand,
      navigateHistory,
      killText,
      yankText,
      onClearError,
      setIsSuggestionError
    ]
  );
  useEffect(() => {
    // if the error occurs with suggestion, place the cursor at the error
    if (isSuggestionError) {
      setCommand((prevValue) => prevValue.trimEnd());
      setCursorPos((prevCursor) => prevCursor - 1);
    }
    // Autocomplete the input command, when there is only 1 longest match (unambiguous)
    if (commandCompleteSuggestions.length === 0 && longestMatchToAutoComplete) {
      const { newCursorPos = 0, newValue = "" } = getCommandCompletionData(
        inputRef,
        commandCompleteSuggestions,
        longestMatchToAutoComplete
      );
      setCommand(newValue);
      setCursorPos(newCursorPos);
      onCommandCompleteSuggestionsChange([]);
      setLongestMatchToAutoComplete("");
    } else if (
      commandCompleteSuggestions.length > 1 &&
      longestMatchToAutoComplete
    ) {
      //user needs to type keyword from the available options, when the keyword is ambiguous to autocomplete
      const { newCursorPos = 0, newValue = "" } = getCommandCompletionData(
        inputRef,
        undefined,
        longestMatchToAutoComplete
      );
      setCommand(newValue);
      setLongestMatchToAutoComplete("");
      setCursorPos(newCursorPos - 1);
    }
  }, [
    commandCompleteSuggestions,
    onCommandCompleteSuggestionsChange,
    isSuggestionError,
    longestMatchToAutoComplete,
    setLongestMatchToAutoComplete
  ]);

  useEffect(() => {
    if (cursorPos && inputRef.current) {
      inputRef.current.setSelectionRange(cursorPos, cursorPos);
    }
  }, [command, cursorPos]);

  return (
    <div className="cnc-manage-mock-shell-input-container">
      <Input
        ref={inputRef}
        value={command}
        onChange={handleChange}
        label=""
        onKeyDown={handleKeyDown}
        className="mock-shell-input"
        prefix={`${fabric?.name}#`}
      />
    </div>
  );
};
