import { Breadcrumb } from "@magnetic/breadcrumb";
import { useNavigate } from "react-router-dom";
import { Heading } from "@magnetic/heading";
import VFlex from "../components/vFlex";
import "./pageHeader.scss";
export interface HeaderBreadbrumbProps {
  readonly breadcrumbs?: [string, string][];
}

export const BreadbrumbNavigation = ({
  breadcrumbs = []
}: HeaderBreadbrumbProps): React.ReactElement | null => {
  const navigate = useNavigate();
  const elements = breadcrumbs.map((breadcrumb: string[], index) => {
    const [text, link = ""] = breadcrumb;
    return (
      <Breadcrumb.Link
        withArrow={index === 0}
        onClick={() => navigate(link)}
        href={"#"}
        key={text}
      >
        {text}
      </Breadcrumb.Link>
    );
  });

  // Meraki <Breadcrumb> crashes if children are only a single element. so just return the element itself. wonderful.
  if (elements?.length === 1) {
    return elements[0] ?? null;
  }

  if (elements.length) {
    return <Breadcrumb>{elements}</Breadcrumb>;
  }
  return null;
};

interface PageHeaderProps {
  readonly title: string;
  readonly breadcrumbs?: [string, string][];
}

export default function PageHeader(props: PageHeaderProps) {
  const { breadcrumbs, title } = props;
  return (
    <div className="cnc-page-header">
      <VFlex gap="sm">
        {breadcrumbs?.length && (
          <BreadbrumbNavigation breadcrumbs={breadcrumbs} />
        )}
        <Heading size="h1">{title}</Heading>
      </VFlex>
    </div>
  );
}
