// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: bilge/api.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";
import { ChatPlatform, chatPlatformFromJSON, chatPlatformToJSON } from "../models/chatbot";
import { CableType, cableTypeFromJSON, cableTypeToJSON } from "../models/fabric";
import { AdminState, adminStateFromJSON, adminStateToJSON } from "../models/global";

export const protobufPackage = "bilge";

/** Run CLI commands */
export enum CommandAction {
  ACTION_UNSPECIFIED = 0,
  EXPAND = 1,
  EXPOUND = 2,
  COMPLETE = 3,
  EXECUTE = 4,
  UNRECOGNIZED = -1,
}

export function commandActionFromJSON(object: any): CommandAction {
  switch (object) {
    case 0:
    case "ACTION_UNSPECIFIED":
      return CommandAction.ACTION_UNSPECIFIED;
    case 1:
    case "EXPAND":
      return CommandAction.EXPAND;
    case 2:
    case "EXPOUND":
      return CommandAction.EXPOUND;
    case 3:
    case "COMPLETE":
      return CommandAction.COMPLETE;
    case 4:
    case "EXECUTE":
      return CommandAction.EXECUTE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CommandAction.UNRECOGNIZED;
  }
}

export function commandActionToJSON(object: CommandAction): string {
  switch (object) {
    case CommandAction.ACTION_UNSPECIFIED:
      return "ACTION_UNSPECIFIED";
    case CommandAction.EXPAND:
      return "EXPAND";
    case CommandAction.EXPOUND:
      return "EXPOUND";
    case CommandAction.COMPLETE:
      return "COMPLETE";
    case CommandAction.EXECUTE:
      return "EXECUTE";
    case CommandAction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ResultFormat {
  RESULT_FORMAT_UNSPECIFIED = 0,
  TEXT = 1,
  JSON = 2,
  HTML = 3,
  MARKDOWN = 4,
  CSV = 5,
  UNRECOGNIZED = -1,
}

export function resultFormatFromJSON(object: any): ResultFormat {
  switch (object) {
    case 0:
    case "RESULT_FORMAT_UNSPECIFIED":
      return ResultFormat.RESULT_FORMAT_UNSPECIFIED;
    case 1:
    case "TEXT":
      return ResultFormat.TEXT;
    case 2:
    case "JSON":
      return ResultFormat.JSON;
    case 3:
    case "HTML":
      return ResultFormat.HTML;
    case 4:
    case "MARKDOWN":
      return ResultFormat.MARKDOWN;
    case 5:
    case "CSV":
      return ResultFormat.CSV;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ResultFormat.UNRECOGNIZED;
  }
}

export function resultFormatToJSON(object: ResultFormat): string {
  switch (object) {
    case ResultFormat.RESULT_FORMAT_UNSPECIFIED:
      return "RESULT_FORMAT_UNSPECIFIED";
    case ResultFormat.TEXT:
      return "TEXT";
    case ResultFormat.JSON:
      return "JSON";
    case ResultFormat.HTML:
      return "HTML";
    case ResultFormat.MARKDOWN:
      return "MARKDOWN";
    case ResultFormat.CSV:
      return "CSV";
    case ResultFormat.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum CliParam {
  CLI_PARAM_UNSPECIFIED = 0,
  NODE_ID = 1,
  LINECARD = 2,
  PORT = 3,
  BREAKOUT = 4,
  UNRECOGNIZED = -1,
}

export function cliParamFromJSON(object: any): CliParam {
  switch (object) {
    case 0:
    case "CLI_PARAM_UNSPECIFIED":
      return CliParam.CLI_PARAM_UNSPECIFIED;
    case 1:
    case "NODE_ID":
      return CliParam.NODE_ID;
    case 2:
    case "LINECARD":
      return CliParam.LINECARD;
    case 3:
    case "PORT":
      return CliParam.PORT;
    case 4:
    case "BREAKOUT":
      return CliParam.BREAKOUT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CliParam.UNRECOGNIZED;
  }
}

export function cliParamToJSON(object: CliParam): string {
  switch (object) {
    case CliParam.CLI_PARAM_UNSPECIFIED:
      return "CLI_PARAM_UNSPECIFIED";
    case CliParam.NODE_ID:
      return "NODE_ID";
    case CliParam.LINECARD:
      return "LINECARD";
    case CliParam.PORT:
      return "PORT";
    case CliParam.BREAKOUT:
      return "BREAKOUT";
    case CliParam.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum CablingDeviceType {
  CABLING_DEVICE_UNSPECIFIED = 0,
  CARIB = 1,
  SIREN = 2,
  UNRECOGNIZED = -1,
}

export function cablingDeviceTypeFromJSON(object: any): CablingDeviceType {
  switch (object) {
    case 0:
    case "CABLING_DEVICE_UNSPECIFIED":
      return CablingDeviceType.CABLING_DEVICE_UNSPECIFIED;
    case 1:
    case "CARIB":
      return CablingDeviceType.CARIB;
    case 2:
    case "SIREN":
      return CablingDeviceType.SIREN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CablingDeviceType.UNRECOGNIZED;
  }
}

export function cablingDeviceTypeToJSON(object: CablingDeviceType): string {
  switch (object) {
    case CablingDeviceType.CABLING_DEVICE_UNSPECIFIED:
      return "CABLING_DEVICE_UNSPECIFIED";
    case CablingDeviceType.CARIB:
      return "CARIB";
    case CablingDeviceType.SIREN:
      return "SIREN";
    case CablingDeviceType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum FabricImageSortField {
  FABRIC_IMAGE_SORT_FIELD_UNSPECIFIED = 0,
  EXPIRE_AT = 1,
  RELEASE_AT = 2,
  UNRECOGNIZED = -1,
}

export function fabricImageSortFieldFromJSON(object: any): FabricImageSortField {
  switch (object) {
    case 0:
    case "FABRIC_IMAGE_SORT_FIELD_UNSPECIFIED":
      return FabricImageSortField.FABRIC_IMAGE_SORT_FIELD_UNSPECIFIED;
    case 1:
    case "EXPIRE_AT":
      return FabricImageSortField.EXPIRE_AT;
    case 2:
    case "RELEASE_AT":
      return FabricImageSortField.RELEASE_AT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FabricImageSortField.UNRECOGNIZED;
  }
}

export function fabricImageSortFieldToJSON(object: FabricImageSortField): string {
  switch (object) {
    case FabricImageSortField.FABRIC_IMAGE_SORT_FIELD_UNSPECIFIED:
      return "FABRIC_IMAGE_SORT_FIELD_UNSPECIFIED";
    case FabricImageSortField.EXPIRE_AT:
      return "EXPIRE_AT";
    case FabricImageSortField.RELEASE_AT:
      return "RELEASE_AT";
    case FabricImageSortField.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * GetCandidateInfoWithPasscodeRequest validates the totp passcode and retrieves the remote chat user
 * account candidate information associated with the passcode.
 */
export interface GetCandidateInfoWithPasscodeRequest {
  /** Time based one time passcode generated by the chatbot app to validate the linked account request */
  passcode: string;
}

/** GetCandidateInfoWithPasscodeResponse is the response to GetCandidateInfoWithTotpRequest. */
export interface GetCandidateInfoWithPasscodeResponse {
  candidate: LinkedAccountCandidate | undefined;
}

/**
 * LinkedAccountCandidate encapsulates the information about a remote chatbot user account candidate. The actual candidate information
 * is chat platform specific. It is associated with a passcode. For example, for Discord, the remote candidate account is represented by a guild id and channel id.
 * GetCandidateInfoWithTotpResponse returns a LinkedAccountCandidate as content.
 */
export interface LinkedAccountCandidate {
  /** Time based one time passcode generated by the chatbot app to validate the linked account request. */
  passcode: string;
  /** Chat platform of the remote chat user account. */
  chatPlatform: ChatPlatform;
  /** Discord user account info. */
  discordAccount:
    | DiscordAccount
    | undefined;
  /** Webex user account info */
  webexAccount: WebexAccount | undefined;
}

/** SetLinkedAccountRequest creates a new linked account. */
export interface SetLinkedAccountRequest {
  /** Time based one time password generated by the chatbot app to validate the linked account request. */
  passcode: string;
  /** Local virtual username with "BOTS" provider to be linked with a remote account candidate. */
  userName: string;
}

/** SetLinkedAccountResponse is the response of SetLinkedAccountRequest */
export interface SetLinkedAccountResponse {
  linkedAccount: LinkedAccount | undefined;
}

/** DeleteLinkedAccountRequest changes an existing linked account admin state to DELETED. */
export interface DeleteLinkedAccountRequest {
  /** Identifier of the linked account to be marked as DELETED. */
  accountId: string;
}

/** UpdateLinkedAccountRequest updates an existing linked account identified with an account id. */
export interface UpdateLinkedAccountRequest {
  /** Identifier of the target linked account record to be updated. */
  accountId: string;
  /**
   * Target admin state of the target linked account. The valid values are ENABLED and DISABLED.
   * The default value UNSPECIFIED will be ignored.
   */
  adminState: AdminState;
}

/** UpdateLinkedAccountResponse is the response of UpdateLinkedAccountRequest. */
export interface UpdateLinkedAccountResponse {
  linkedAccount: LinkedAccount | undefined;
}

/** GetLinkedAccountRequest retrieves a list of linked account. */
export interface GetLinkedAccountsRequest {
  /** Collects the linked accounts for the specified chat platform. If unspecified, returns all linked accounts for that org. */
  chatPlatform: ChatPlatform;
}

/** GetLinkedAccountsResponse is the response of GetLinkedAccountsRequest. It includes a list of LinkedAccount records. */
export interface GetLinkedAccountsResponse {
  linkedAccounts: LinkedAccount[];
}

/** LinkedAccount associates a remote chatbot user account with a local user and tenant. */
export interface LinkedAccount {
  /** Account id of the linked account. */
  accountId: string;
  /** Local virtual username with "BOTS" provider to be linked with a remote account candidate. */
  userName: string;
  /** Admin state of the linked account. */
  adminState: AdminState;
  /** Chat platform the remote chat user account belongs to. */
  chatPlatform: ChatPlatform;
  /** Discord user account info. */
  discordAccount:
    | DiscordAccount
    | undefined;
  /** Webex user account info */
  webexAccount: WebexAccount | undefined;
}

/** DiscordAccount represents a remote Discord user account. */
export interface DiscordAccount {
  /** Discord server id. */
  guildId: string;
  /** Discord server name. */
  guildName: string;
  /** Discord channel id. */
  channelId: string;
  /** Discord channel name. */
  channelName: string;
}

export interface WebexAccount {
  spaceId: string;
}

export interface SlackAccount {
  workspaceId: string;
  workspaceName: string;
  channelId: string;
  channelName: string;
}

/**
 * Invitation represents a chatbot distribution pointer for a particular platform. The end user can use
 * the invitation entry to add the chatbot to a chat space.
 * The actual nature of the invitation entry varies from platform to platform. For Discord, it's a URL. For Webex, it's
 * the bot username in the form of an email address.
 */
export interface Invitation {
  /** Platform of the bot */
  chatPlatform: ChatPlatform;
  /** Bot invitation link or in case of webex bot, bot username (email) */
  entry: string;
}

/** GetInvitationsRequest defines the request to retrieve chatbot invitation entries for all supported chat platforms */
export interface GetInvitationsRequest {
}

/** GetInvitationsResponse defines the response to GetInvitationsRequest. It contains a set of the chatbot invitation entries */
export interface GetInvitationsResponse {
  invitations: Invitation[];
}

export interface CliCommandParams {
  /** The parameter name. */
  cliParam: CliParam;
  /** The parameter value. */
  value: string;
}

export interface CliCommandRequest {
  /** The CLI command to run. */
  command: string;
  /**
   * The action to perform on the command.
   * can be expand, expound, complete, execute.
   * The default action is execute.
   */
  action: CommandAction;
  /** The fabric id that the the CLI command is is scoped to for */
  fabricId: string;
  /**
   * The rendering method for the CLI output.
   * The Rendering format can be specified as text, json, html, markdown, or csv.
   * The default format is text when no rendering format is specified.
   * csv format is only applicable to the results field and when the action is "execute".
   * Additionally, csv format is only considered for cli commands that specify the keyword "table".
   * csv format specified for any command that does not specify the cli keyword table, will be ignored and will default to text mode.
   */
  resultFormat: ResultFormat;
  /**
   * UI cursor position for the command line.
   * Used for computing the UI cusor position for command completions and returned in the response.
   */
  cursorPos: number;
  /**
   * parameters for the CLI command.
   * These parameters are used to as implicit parameters for the CLI command if the command does not specify them.
   * The FabricId must always be specified as a parameter for the CLI command.
   */
  cmdParams: CliCommandParams[];
}

/**
 * CliCommandOutput represents the output of a CLI command.
 * The CLI command output varies based on the command and the action.
 * The output may contain a token, help string, and the actual command output.
 */
export interface CliCommandCompletion {
  /** optional field. Not populated for Execute action. */
  keyword: string;
  /** optional field, only populated for expound action. */
  helpString: string;
}

export interface CliCommandResponse {
  /** action that was run: expand, expound, complete, execute */
  cmdAction: CommandAction;
  /** cli command that was run */
  command: string;
  /**
   * optional field. only populated for execute action. contains the data fetched from a threadlist
   * contents of the results field are base64 encoded
   */
  executionResults: string;
  /**
   * command_completions field contains possible completions for actions such as expound, expand and complete.
   * It is not populated for execute action.
   */
  commandCompletions: CliCommandCompletion[];
  /**
   * format for the execution_results and completions fields: can be text, json, html, markdown, or csv.
   * the execution_results field contents are in csv format, only if the request specified result_format as csv and execution_results returned is in a tabular format.
   */
  resultFormat: ResultFormat;
  /** Command Parameters specified in the request */
  cmdParams: CliCommandParams[];
  /**
   * UI cursor position in the command string.
   * cursor_pos represents the character index in the command string where the cursor should be placed.
   */
  cursorPos: number;
  /** Indicates if the command is a valid command and can be executed at the current cursor position. */
  validCommand: boolean;
  /**
   * the longest_common_substring field contains the unambiguous portion of a substring for the command completion.
   * in case of an unambiguous match for the string, the command_completions field is nil,
   * an the longest_common_substring field contains the matching string and no error is returned.
   * in case of an ambiguous match for the substring, longest_common_substring field contains the unambiguous portion of the substring,
   * and the command_completions field contains the list of possible substrings needed to make the longest_common_substring unambiguous and no error is returned.
   * In case of no match for the substring, an error is returned and the longest_common_substring field is nil and command_completions fields are nil.
   * when the substring being matched is empty, the longest_common_substring field is nil and the command_completions field contains the list of all possible completions.
   */
  longestCommonSubstring: string;
}

export interface CablingRemotePort {
  nodeId: string;
  port: CablingPort | undefined;
}

export interface CablingPort {
  /** 1-based linecard index */
  lineCard: number;
  /** 1-based port index */
  port: number;
  /** 0 for no-breakout, otherwise 1-based */
  breakout: number;
  /** Speed of the port in Gbps */
  portSpeed: number;
  /** Max speed of the port in Gbps */
  portMaxSpeed: number;
  /** Expected cable type for this port */
  expectedCableType: CableType;
  /** Speed of the cable in Gbps in use for this port */
  cableSpeed: number;
  /** Type of the cable in use for this port */
  cableType: number;
}

export interface CablingConnection {
  from: CablingPort | undefined;
  to: CablingRemotePort | undefined;
}

export interface CablingDevice {
  deviceType: CablingDeviceType;
  /** Node ID for this device slot (UUID) */
  nodeId: string;
  /** List of ports to use for fabric to fabric connections */
  FabricPorts: CablingPort[];
  /** List of backup ports that could be used for fabric to fabric connections if need be but would require role change to fabric */
  AdditionalPorts: CablingPort[];
  /** In request used to indicate pre-existing cabling, in the response used to indicate the cabling solution */
  connections: CablingConnection[];
  /** Device ID for this device slot (MAC address) */
  deviceId: string;
}

export interface CablingRequest {
  fabricId: string;
  leafs: CablingDevice[];
  /** For full mesh on leafs, omit spines */
  spines: CablingDevice[];
  /** Integer value specifying how many links should be made between each device */
  order: number;
}

export interface CablingResponse {
  leafs: CablingDevice[];
  spines: CablingDevice[];
}

/** Message to get a list of target images for the device image update */
export interface GetImageTargetsRequest {
  /** The lookback window in days */
  lookbackDays: number;
  /** The image release types */
  releaseTypes: string[];
  /** The field for sorting the returning target image list */
  sortField: FabricImageSortField;
}

/** Message to provide the list of target images */
export interface GetImageTargetsResponse {
  /** The latest release image info */
  latest:
    | ReleaseImageInfo
    | undefined;
  /** The list of the images updated in the past lookback_days */
  fabricImages: FabricImageInfo[];
  /** The list of the available images other than the latest released in the past lookback_days */
  otherImages: ReleaseImageInfo[];
}

/** Message to provide the image information that has been applied to a fabric */
export interface FabricImageInfo {
  /** The fabric id */
  fabricId: string;
  /** The fabric name */
  fabricName: string;
  /** The image release tag */
  tag: string;
  /** The time when this image is applied to the fabric */
  releaseAt:
    | Date
    | undefined;
  /** The expected image expire time */
  expireAt:
    | Date
    | undefined;
  /** The release document link */
  docLink: string;
}

/** Message to provide information of a release image */
export interface ReleaseImageInfo {
  /** The image release tag */
  tag: string;
  /** The image release time */
  releaseAt:
    | Date
    | undefined;
  /** The expected image expire time */
  expireAt:
    | Date
    | undefined;
  /** The release document link */
  docLink: string;
}

function createBaseGetCandidateInfoWithPasscodeRequest(): GetCandidateInfoWithPasscodeRequest {
  return { passcode: "" };
}

export const GetCandidateInfoWithPasscodeRequest = {
  encode(message: GetCandidateInfoWithPasscodeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.passcode !== "") {
      writer.uint32(10).string(message.passcode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCandidateInfoWithPasscodeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCandidateInfoWithPasscodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.passcode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCandidateInfoWithPasscodeRequest {
    return { passcode: isSet(object.passcode) ? globalThis.String(object.passcode) : "" };
  },

  toJSON(message: GetCandidateInfoWithPasscodeRequest): unknown {
    const obj: any = {};
    if (message.passcode !== "") {
      obj.passcode = message.passcode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCandidateInfoWithPasscodeRequest>, I>>(
    base?: I,
  ): GetCandidateInfoWithPasscodeRequest {
    return GetCandidateInfoWithPasscodeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCandidateInfoWithPasscodeRequest>, I>>(
    object: I,
  ): GetCandidateInfoWithPasscodeRequest {
    const message = createBaseGetCandidateInfoWithPasscodeRequest();
    message.passcode = object.passcode ?? "";
    return message;
  },
};

function createBaseGetCandidateInfoWithPasscodeResponse(): GetCandidateInfoWithPasscodeResponse {
  return { candidate: undefined };
}

export const GetCandidateInfoWithPasscodeResponse = {
  encode(message: GetCandidateInfoWithPasscodeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== undefined) {
      LinkedAccountCandidate.encode(message.candidate, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCandidateInfoWithPasscodeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCandidateInfoWithPasscodeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = LinkedAccountCandidate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCandidateInfoWithPasscodeResponse {
    return { candidate: isSet(object.candidate) ? LinkedAccountCandidate.fromJSON(object.candidate) : undefined };
  },

  toJSON(message: GetCandidateInfoWithPasscodeResponse): unknown {
    const obj: any = {};
    if (message.candidate !== undefined) {
      obj.candidate = LinkedAccountCandidate.toJSON(message.candidate);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCandidateInfoWithPasscodeResponse>, I>>(
    base?: I,
  ): GetCandidateInfoWithPasscodeResponse {
    return GetCandidateInfoWithPasscodeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCandidateInfoWithPasscodeResponse>, I>>(
    object: I,
  ): GetCandidateInfoWithPasscodeResponse {
    const message = createBaseGetCandidateInfoWithPasscodeResponse();
    message.candidate = (object.candidate !== undefined && object.candidate !== null)
      ? LinkedAccountCandidate.fromPartial(object.candidate)
      : undefined;
    return message;
  },
};

function createBaseLinkedAccountCandidate(): LinkedAccountCandidate {
  return { passcode: "", chatPlatform: 0, discordAccount: undefined, webexAccount: undefined };
}

export const LinkedAccountCandidate = {
  encode(message: LinkedAccountCandidate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.passcode !== "") {
      writer.uint32(10).string(message.passcode);
    }
    if (message.chatPlatform !== 0) {
      writer.uint32(16).int32(message.chatPlatform);
    }
    if (message.discordAccount !== undefined) {
      DiscordAccount.encode(message.discordAccount, writer.uint32(26).fork()).ldelim();
    }
    if (message.webexAccount !== undefined) {
      WebexAccount.encode(message.webexAccount, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LinkedAccountCandidate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLinkedAccountCandidate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.passcode = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.chatPlatform = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discordAccount = DiscordAccount.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.webexAccount = WebexAccount.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LinkedAccountCandidate {
    return {
      passcode: isSet(object.passcode) ? globalThis.String(object.passcode) : "",
      chatPlatform: isSet(object.chatPlatform) ? chatPlatformFromJSON(object.chatPlatform) : 0,
      discordAccount: isSet(object.discordAccount) ? DiscordAccount.fromJSON(object.discordAccount) : undefined,
      webexAccount: isSet(object.webexAccount) ? WebexAccount.fromJSON(object.webexAccount) : undefined,
    };
  },

  toJSON(message: LinkedAccountCandidate): unknown {
    const obj: any = {};
    if (message.passcode !== "") {
      obj.passcode = message.passcode;
    }
    if (message.chatPlatform !== 0) {
      obj.chatPlatform = chatPlatformToJSON(message.chatPlatform);
    }
    if (message.discordAccount !== undefined) {
      obj.discordAccount = DiscordAccount.toJSON(message.discordAccount);
    }
    if (message.webexAccount !== undefined) {
      obj.webexAccount = WebexAccount.toJSON(message.webexAccount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LinkedAccountCandidate>, I>>(base?: I): LinkedAccountCandidate {
    return LinkedAccountCandidate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LinkedAccountCandidate>, I>>(object: I): LinkedAccountCandidate {
    const message = createBaseLinkedAccountCandidate();
    message.passcode = object.passcode ?? "";
    message.chatPlatform = object.chatPlatform ?? 0;
    message.discordAccount = (object.discordAccount !== undefined && object.discordAccount !== null)
      ? DiscordAccount.fromPartial(object.discordAccount)
      : undefined;
    message.webexAccount = (object.webexAccount !== undefined && object.webexAccount !== null)
      ? WebexAccount.fromPartial(object.webexAccount)
      : undefined;
    return message;
  },
};

function createBaseSetLinkedAccountRequest(): SetLinkedAccountRequest {
  return { passcode: "", userName: "" };
}

export const SetLinkedAccountRequest = {
  encode(message: SetLinkedAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.passcode !== "") {
      writer.uint32(10).string(message.passcode);
    }
    if (message.userName !== "") {
      writer.uint32(26).string(message.userName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetLinkedAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetLinkedAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.passcode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetLinkedAccountRequest {
    return {
      passcode: isSet(object.passcode) ? globalThis.String(object.passcode) : "",
      userName: isSet(object.userName) ? globalThis.String(object.userName) : "",
    };
  },

  toJSON(message: SetLinkedAccountRequest): unknown {
    const obj: any = {};
    if (message.passcode !== "") {
      obj.passcode = message.passcode;
    }
    if (message.userName !== "") {
      obj.userName = message.userName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetLinkedAccountRequest>, I>>(base?: I): SetLinkedAccountRequest {
    return SetLinkedAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetLinkedAccountRequest>, I>>(object: I): SetLinkedAccountRequest {
    const message = createBaseSetLinkedAccountRequest();
    message.passcode = object.passcode ?? "";
    message.userName = object.userName ?? "";
    return message;
  },
};

function createBaseSetLinkedAccountResponse(): SetLinkedAccountResponse {
  return { linkedAccount: undefined };
}

export const SetLinkedAccountResponse = {
  encode(message: SetLinkedAccountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.linkedAccount !== undefined) {
      LinkedAccount.encode(message.linkedAccount, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetLinkedAccountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetLinkedAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkedAccount = LinkedAccount.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetLinkedAccountResponse {
    return { linkedAccount: isSet(object.linkedAccount) ? LinkedAccount.fromJSON(object.linkedAccount) : undefined };
  },

  toJSON(message: SetLinkedAccountResponse): unknown {
    const obj: any = {};
    if (message.linkedAccount !== undefined) {
      obj.linkedAccount = LinkedAccount.toJSON(message.linkedAccount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetLinkedAccountResponse>, I>>(base?: I): SetLinkedAccountResponse {
    return SetLinkedAccountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetLinkedAccountResponse>, I>>(object: I): SetLinkedAccountResponse {
    const message = createBaseSetLinkedAccountResponse();
    message.linkedAccount = (object.linkedAccount !== undefined && object.linkedAccount !== null)
      ? LinkedAccount.fromPartial(object.linkedAccount)
      : undefined;
    return message;
  },
};

function createBaseDeleteLinkedAccountRequest(): DeleteLinkedAccountRequest {
  return { accountId: "" };
}

export const DeleteLinkedAccountRequest = {
  encode(message: DeleteLinkedAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteLinkedAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteLinkedAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteLinkedAccountRequest {
    return { accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "" };
  },

  toJSON(message: DeleteLinkedAccountRequest): unknown {
    const obj: any = {};
    if (message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteLinkedAccountRequest>, I>>(base?: I): DeleteLinkedAccountRequest {
    return DeleteLinkedAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteLinkedAccountRequest>, I>>(object: I): DeleteLinkedAccountRequest {
    const message = createBaseDeleteLinkedAccountRequest();
    message.accountId = object.accountId ?? "";
    return message;
  },
};

function createBaseUpdateLinkedAccountRequest(): UpdateLinkedAccountRequest {
  return { accountId: "", adminState: 0 };
}

export const UpdateLinkedAccountRequest = {
  encode(message: UpdateLinkedAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    if (message.adminState !== 0) {
      writer.uint32(16).int32(message.adminState);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateLinkedAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateLinkedAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.adminState = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateLinkedAccountRequest {
    return {
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      adminState: isSet(object.adminState) ? adminStateFromJSON(object.adminState) : 0,
    };
  },

  toJSON(message: UpdateLinkedAccountRequest): unknown {
    const obj: any = {};
    if (message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.adminState !== 0) {
      obj.adminState = adminStateToJSON(message.adminState);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateLinkedAccountRequest>, I>>(base?: I): UpdateLinkedAccountRequest {
    return UpdateLinkedAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateLinkedAccountRequest>, I>>(object: I): UpdateLinkedAccountRequest {
    const message = createBaseUpdateLinkedAccountRequest();
    message.accountId = object.accountId ?? "";
    message.adminState = object.adminState ?? 0;
    return message;
  },
};

function createBaseUpdateLinkedAccountResponse(): UpdateLinkedAccountResponse {
  return { linkedAccount: undefined };
}

export const UpdateLinkedAccountResponse = {
  encode(message: UpdateLinkedAccountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.linkedAccount !== undefined) {
      LinkedAccount.encode(message.linkedAccount, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateLinkedAccountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateLinkedAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkedAccount = LinkedAccount.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateLinkedAccountResponse {
    return { linkedAccount: isSet(object.linkedAccount) ? LinkedAccount.fromJSON(object.linkedAccount) : undefined };
  },

  toJSON(message: UpdateLinkedAccountResponse): unknown {
    const obj: any = {};
    if (message.linkedAccount !== undefined) {
      obj.linkedAccount = LinkedAccount.toJSON(message.linkedAccount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateLinkedAccountResponse>, I>>(base?: I): UpdateLinkedAccountResponse {
    return UpdateLinkedAccountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateLinkedAccountResponse>, I>>(object: I): UpdateLinkedAccountResponse {
    const message = createBaseUpdateLinkedAccountResponse();
    message.linkedAccount = (object.linkedAccount !== undefined && object.linkedAccount !== null)
      ? LinkedAccount.fromPartial(object.linkedAccount)
      : undefined;
    return message;
  },
};

function createBaseGetLinkedAccountsRequest(): GetLinkedAccountsRequest {
  return { chatPlatform: 0 };
}

export const GetLinkedAccountsRequest = {
  encode(message: GetLinkedAccountsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chatPlatform !== 0) {
      writer.uint32(8).int32(message.chatPlatform);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLinkedAccountsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLinkedAccountsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.chatPlatform = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLinkedAccountsRequest {
    return { chatPlatform: isSet(object.chatPlatform) ? chatPlatformFromJSON(object.chatPlatform) : 0 };
  },

  toJSON(message: GetLinkedAccountsRequest): unknown {
    const obj: any = {};
    if (message.chatPlatform !== 0) {
      obj.chatPlatform = chatPlatformToJSON(message.chatPlatform);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLinkedAccountsRequest>, I>>(base?: I): GetLinkedAccountsRequest {
    return GetLinkedAccountsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLinkedAccountsRequest>, I>>(object: I): GetLinkedAccountsRequest {
    const message = createBaseGetLinkedAccountsRequest();
    message.chatPlatform = object.chatPlatform ?? 0;
    return message;
  },
};

function createBaseGetLinkedAccountsResponse(): GetLinkedAccountsResponse {
  return { linkedAccounts: [] };
}

export const GetLinkedAccountsResponse = {
  encode(message: GetLinkedAccountsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.linkedAccounts) {
      LinkedAccount.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLinkedAccountsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLinkedAccountsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkedAccounts.push(LinkedAccount.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLinkedAccountsResponse {
    return {
      linkedAccounts: globalThis.Array.isArray(object?.linkedAccounts)
        ? object.linkedAccounts.map((e: any) => LinkedAccount.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetLinkedAccountsResponse): unknown {
    const obj: any = {};
    if (message.linkedAccounts?.length) {
      obj.linkedAccounts = message.linkedAccounts.map((e) => LinkedAccount.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLinkedAccountsResponse>, I>>(base?: I): GetLinkedAccountsResponse {
    return GetLinkedAccountsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLinkedAccountsResponse>, I>>(object: I): GetLinkedAccountsResponse {
    const message = createBaseGetLinkedAccountsResponse();
    message.linkedAccounts = object.linkedAccounts?.map((e) => LinkedAccount.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLinkedAccount(): LinkedAccount {
  return {
    accountId: "",
    userName: "",
    adminState: 0,
    chatPlatform: 0,
    discordAccount: undefined,
    webexAccount: undefined,
  };
}

export const LinkedAccount = {
  encode(message: LinkedAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    if (message.userName !== "") {
      writer.uint32(50).string(message.userName);
    }
    if (message.adminState !== 0) {
      writer.uint32(56).int32(message.adminState);
    }
    if (message.chatPlatform !== 0) {
      writer.uint32(64).int32(message.chatPlatform);
    }
    if (message.discordAccount !== undefined) {
      DiscordAccount.encode(message.discordAccount, writer.uint32(74).fork()).ldelim();
    }
    if (message.webexAccount !== undefined) {
      WebexAccount.encode(message.webexAccount, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LinkedAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLinkedAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.userName = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.adminState = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.chatPlatform = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.discordAccount = DiscordAccount.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.webexAccount = WebexAccount.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LinkedAccount {
    return {
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      userName: isSet(object.userName) ? globalThis.String(object.userName) : "",
      adminState: isSet(object.adminState) ? adminStateFromJSON(object.adminState) : 0,
      chatPlatform: isSet(object.chatPlatform) ? chatPlatformFromJSON(object.chatPlatform) : 0,
      discordAccount: isSet(object.discordAccount) ? DiscordAccount.fromJSON(object.discordAccount) : undefined,
      webexAccount: isSet(object.webexAccount) ? WebexAccount.fromJSON(object.webexAccount) : undefined,
    };
  },

  toJSON(message: LinkedAccount): unknown {
    const obj: any = {};
    if (message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.userName !== "") {
      obj.userName = message.userName;
    }
    if (message.adminState !== 0) {
      obj.adminState = adminStateToJSON(message.adminState);
    }
    if (message.chatPlatform !== 0) {
      obj.chatPlatform = chatPlatformToJSON(message.chatPlatform);
    }
    if (message.discordAccount !== undefined) {
      obj.discordAccount = DiscordAccount.toJSON(message.discordAccount);
    }
    if (message.webexAccount !== undefined) {
      obj.webexAccount = WebexAccount.toJSON(message.webexAccount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LinkedAccount>, I>>(base?: I): LinkedAccount {
    return LinkedAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LinkedAccount>, I>>(object: I): LinkedAccount {
    const message = createBaseLinkedAccount();
    message.accountId = object.accountId ?? "";
    message.userName = object.userName ?? "";
    message.adminState = object.adminState ?? 0;
    message.chatPlatform = object.chatPlatform ?? 0;
    message.discordAccount = (object.discordAccount !== undefined && object.discordAccount !== null)
      ? DiscordAccount.fromPartial(object.discordAccount)
      : undefined;
    message.webexAccount = (object.webexAccount !== undefined && object.webexAccount !== null)
      ? WebexAccount.fromPartial(object.webexAccount)
      : undefined;
    return message;
  },
};

function createBaseDiscordAccount(): DiscordAccount {
  return { guildId: "", guildName: "", channelId: "", channelName: "" };
}

export const DiscordAccount = {
  encode(message: DiscordAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.guildId !== "") {
      writer.uint32(10).string(message.guildId);
    }
    if (message.guildName !== "") {
      writer.uint32(18).string(message.guildName);
    }
    if (message.channelId !== "") {
      writer.uint32(26).string(message.channelId);
    }
    if (message.channelName !== "") {
      writer.uint32(34).string(message.channelName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiscordAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiscordAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.guildId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.guildName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.channelId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.channelName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DiscordAccount {
    return {
      guildId: isSet(object.guildId) ? globalThis.String(object.guildId) : "",
      guildName: isSet(object.guildName) ? globalThis.String(object.guildName) : "",
      channelId: isSet(object.channelId) ? globalThis.String(object.channelId) : "",
      channelName: isSet(object.channelName) ? globalThis.String(object.channelName) : "",
    };
  },

  toJSON(message: DiscordAccount): unknown {
    const obj: any = {};
    if (message.guildId !== "") {
      obj.guildId = message.guildId;
    }
    if (message.guildName !== "") {
      obj.guildName = message.guildName;
    }
    if (message.channelId !== "") {
      obj.channelId = message.channelId;
    }
    if (message.channelName !== "") {
      obj.channelName = message.channelName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DiscordAccount>, I>>(base?: I): DiscordAccount {
    return DiscordAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DiscordAccount>, I>>(object: I): DiscordAccount {
    const message = createBaseDiscordAccount();
    message.guildId = object.guildId ?? "";
    message.guildName = object.guildName ?? "";
    message.channelId = object.channelId ?? "";
    message.channelName = object.channelName ?? "";
    return message;
  },
};

function createBaseWebexAccount(): WebexAccount {
  return { spaceId: "" };
}

export const WebexAccount = {
  encode(message: WebexAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.spaceId !== "") {
      writer.uint32(10).string(message.spaceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebexAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebexAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spaceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebexAccount {
    return { spaceId: isSet(object.spaceId) ? globalThis.String(object.spaceId) : "" };
  },

  toJSON(message: WebexAccount): unknown {
    const obj: any = {};
    if (message.spaceId !== "") {
      obj.spaceId = message.spaceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebexAccount>, I>>(base?: I): WebexAccount {
    return WebexAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WebexAccount>, I>>(object: I): WebexAccount {
    const message = createBaseWebexAccount();
    message.spaceId = object.spaceId ?? "";
    return message;
  },
};

function createBaseSlackAccount(): SlackAccount {
  return { workspaceId: "", workspaceName: "", channelId: "", channelName: "" };
}

export const SlackAccount = {
  encode(message: SlackAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.workspaceId !== "") {
      writer.uint32(10).string(message.workspaceId);
    }
    if (message.workspaceName !== "") {
      writer.uint32(18).string(message.workspaceName);
    }
    if (message.channelId !== "") {
      writer.uint32(26).string(message.channelId);
    }
    if (message.channelName !== "") {
      writer.uint32(34).string(message.channelName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SlackAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSlackAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.workspaceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.workspaceName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.channelId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.channelName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SlackAccount {
    return {
      workspaceId: isSet(object.workspaceId) ? globalThis.String(object.workspaceId) : "",
      workspaceName: isSet(object.workspaceName) ? globalThis.String(object.workspaceName) : "",
      channelId: isSet(object.channelId) ? globalThis.String(object.channelId) : "",
      channelName: isSet(object.channelName) ? globalThis.String(object.channelName) : "",
    };
  },

  toJSON(message: SlackAccount): unknown {
    const obj: any = {};
    if (message.workspaceId !== "") {
      obj.workspaceId = message.workspaceId;
    }
    if (message.workspaceName !== "") {
      obj.workspaceName = message.workspaceName;
    }
    if (message.channelId !== "") {
      obj.channelId = message.channelId;
    }
    if (message.channelName !== "") {
      obj.channelName = message.channelName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SlackAccount>, I>>(base?: I): SlackAccount {
    return SlackAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SlackAccount>, I>>(object: I): SlackAccount {
    const message = createBaseSlackAccount();
    message.workspaceId = object.workspaceId ?? "";
    message.workspaceName = object.workspaceName ?? "";
    message.channelId = object.channelId ?? "";
    message.channelName = object.channelName ?? "";
    return message;
  },
};

function createBaseInvitation(): Invitation {
  return { chatPlatform: 0, entry: "" };
}

export const Invitation = {
  encode(message: Invitation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chatPlatform !== 0) {
      writer.uint32(8).int32(message.chatPlatform);
    }
    if (message.entry !== "") {
      writer.uint32(18).string(message.entry);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Invitation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvitation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.chatPlatform = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.entry = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Invitation {
    return {
      chatPlatform: isSet(object.chatPlatform) ? chatPlatformFromJSON(object.chatPlatform) : 0,
      entry: isSet(object.entry) ? globalThis.String(object.entry) : "",
    };
  },

  toJSON(message: Invitation): unknown {
    const obj: any = {};
    if (message.chatPlatform !== 0) {
      obj.chatPlatform = chatPlatformToJSON(message.chatPlatform);
    }
    if (message.entry !== "") {
      obj.entry = message.entry;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Invitation>, I>>(base?: I): Invitation {
    return Invitation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Invitation>, I>>(object: I): Invitation {
    const message = createBaseInvitation();
    message.chatPlatform = object.chatPlatform ?? 0;
    message.entry = object.entry ?? "";
    return message;
  },
};

function createBaseGetInvitationsRequest(): GetInvitationsRequest {
  return {};
}

export const GetInvitationsRequest = {
  encode(_: GetInvitationsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetInvitationsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetInvitationsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetInvitationsRequest {
    return {};
  },

  toJSON(_: GetInvitationsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetInvitationsRequest>, I>>(base?: I): GetInvitationsRequest {
    return GetInvitationsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetInvitationsRequest>, I>>(_: I): GetInvitationsRequest {
    const message = createBaseGetInvitationsRequest();
    return message;
  },
};

function createBaseGetInvitationsResponse(): GetInvitationsResponse {
  return { invitations: [] };
}

export const GetInvitationsResponse = {
  encode(message: GetInvitationsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.invitations) {
      Invitation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetInvitationsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetInvitationsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.invitations.push(Invitation.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetInvitationsResponse {
    return {
      invitations: globalThis.Array.isArray(object?.invitations)
        ? object.invitations.map((e: any) => Invitation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetInvitationsResponse): unknown {
    const obj: any = {};
    if (message.invitations?.length) {
      obj.invitations = message.invitations.map((e) => Invitation.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetInvitationsResponse>, I>>(base?: I): GetInvitationsResponse {
    return GetInvitationsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetInvitationsResponse>, I>>(object: I): GetInvitationsResponse {
    const message = createBaseGetInvitationsResponse();
    message.invitations = object.invitations?.map((e) => Invitation.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCliCommandParams(): CliCommandParams {
  return { cliParam: 0, value: "" };
}

export const CliCommandParams = {
  encode(message: CliCommandParams, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cliParam !== 0) {
      writer.uint32(8).int32(message.cliParam);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CliCommandParams {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCliCommandParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.cliParam = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CliCommandParams {
    return {
      cliParam: isSet(object.cliParam) ? cliParamFromJSON(object.cliParam) : 0,
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: CliCommandParams): unknown {
    const obj: any = {};
    if (message.cliParam !== 0) {
      obj.cliParam = cliParamToJSON(message.cliParam);
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CliCommandParams>, I>>(base?: I): CliCommandParams {
    return CliCommandParams.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CliCommandParams>, I>>(object: I): CliCommandParams {
    const message = createBaseCliCommandParams();
    message.cliParam = object.cliParam ?? 0;
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseCliCommandRequest(): CliCommandRequest {
  return { command: "", action: 0, fabricId: "", resultFormat: 0, cursorPos: 0, cmdParams: [] };
}

export const CliCommandRequest = {
  encode(message: CliCommandRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.command !== "") {
      writer.uint32(10).string(message.command);
    }
    if (message.action !== 0) {
      writer.uint32(16).int32(message.action);
    }
    if (message.fabricId !== "") {
      writer.uint32(26).string(message.fabricId);
    }
    if (message.resultFormat !== 0) {
      writer.uint32(32).int32(message.resultFormat);
    }
    if (message.cursorPos !== 0) {
      writer.uint32(40).int32(message.cursorPos);
    }
    for (const v of message.cmdParams) {
      CliCommandParams.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CliCommandRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCliCommandRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.command = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.action = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.resultFormat = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.cursorPos = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.cmdParams.push(CliCommandParams.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CliCommandRequest {
    return {
      command: isSet(object.command) ? globalThis.String(object.command) : "",
      action: isSet(object.action) ? commandActionFromJSON(object.action) : 0,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      resultFormat: isSet(object.resultFormat) ? resultFormatFromJSON(object.resultFormat) : 0,
      cursorPos: isSet(object.cursorPos) ? globalThis.Number(object.cursorPos) : 0,
      cmdParams: globalThis.Array.isArray(object?.cmdParams)
        ? object.cmdParams.map((e: any) => CliCommandParams.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CliCommandRequest): unknown {
    const obj: any = {};
    if (message.command !== "") {
      obj.command = message.command;
    }
    if (message.action !== 0) {
      obj.action = commandActionToJSON(message.action);
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.resultFormat !== 0) {
      obj.resultFormat = resultFormatToJSON(message.resultFormat);
    }
    if (message.cursorPos !== 0) {
      obj.cursorPos = Math.round(message.cursorPos);
    }
    if (message.cmdParams?.length) {
      obj.cmdParams = message.cmdParams.map((e) => CliCommandParams.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CliCommandRequest>, I>>(base?: I): CliCommandRequest {
    return CliCommandRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CliCommandRequest>, I>>(object: I): CliCommandRequest {
    const message = createBaseCliCommandRequest();
    message.command = object.command ?? "";
    message.action = object.action ?? 0;
    message.fabricId = object.fabricId ?? "";
    message.resultFormat = object.resultFormat ?? 0;
    message.cursorPos = object.cursorPos ?? 0;
    message.cmdParams = object.cmdParams?.map((e) => CliCommandParams.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCliCommandCompletion(): CliCommandCompletion {
  return { keyword: "", helpString: "" };
}

export const CliCommandCompletion = {
  encode(message: CliCommandCompletion, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.keyword !== "") {
      writer.uint32(10).string(message.keyword);
    }
    if (message.helpString !== "") {
      writer.uint32(18).string(message.helpString);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CliCommandCompletion {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCliCommandCompletion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keyword = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.helpString = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CliCommandCompletion {
    return {
      keyword: isSet(object.keyword) ? globalThis.String(object.keyword) : "",
      helpString: isSet(object.helpString) ? globalThis.String(object.helpString) : "",
    };
  },

  toJSON(message: CliCommandCompletion): unknown {
    const obj: any = {};
    if (message.keyword !== "") {
      obj.keyword = message.keyword;
    }
    if (message.helpString !== "") {
      obj.helpString = message.helpString;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CliCommandCompletion>, I>>(base?: I): CliCommandCompletion {
    return CliCommandCompletion.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CliCommandCompletion>, I>>(object: I): CliCommandCompletion {
    const message = createBaseCliCommandCompletion();
    message.keyword = object.keyword ?? "";
    message.helpString = object.helpString ?? "";
    return message;
  },
};

function createBaseCliCommandResponse(): CliCommandResponse {
  return {
    cmdAction: 0,
    command: "",
    executionResults: "",
    commandCompletions: [],
    resultFormat: 0,
    cmdParams: [],
    cursorPos: 0,
    validCommand: false,
    longestCommonSubstring: "",
  };
}

export const CliCommandResponse = {
  encode(message: CliCommandResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cmdAction !== 0) {
      writer.uint32(8).int32(message.cmdAction);
    }
    if (message.command !== "") {
      writer.uint32(18).string(message.command);
    }
    if (message.executionResults !== "") {
      writer.uint32(26).string(message.executionResults);
    }
    for (const v of message.commandCompletions) {
      CliCommandCompletion.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.resultFormat !== 0) {
      writer.uint32(40).int32(message.resultFormat);
    }
    for (const v of message.cmdParams) {
      CliCommandParams.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.cursorPos !== 0) {
      writer.uint32(56).int32(message.cursorPos);
    }
    if (message.validCommand !== false) {
      writer.uint32(64).bool(message.validCommand);
    }
    if (message.longestCommonSubstring !== "") {
      writer.uint32(74).string(message.longestCommonSubstring);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CliCommandResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCliCommandResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.cmdAction = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.command = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.executionResults = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.commandCompletions.push(CliCommandCompletion.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.resultFormat = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.cmdParams.push(CliCommandParams.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.cursorPos = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.validCommand = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.longestCommonSubstring = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CliCommandResponse {
    return {
      cmdAction: isSet(object.cmdAction) ? commandActionFromJSON(object.cmdAction) : 0,
      command: isSet(object.command) ? globalThis.String(object.command) : "",
      executionResults: isSet(object.executionResults) ? globalThis.String(object.executionResults) : "",
      commandCompletions: globalThis.Array.isArray(object?.commandCompletions)
        ? object.commandCompletions.map((e: any) => CliCommandCompletion.fromJSON(e))
        : [],
      resultFormat: isSet(object.resultFormat) ? resultFormatFromJSON(object.resultFormat) : 0,
      cmdParams: globalThis.Array.isArray(object?.cmdParams)
        ? object.cmdParams.map((e: any) => CliCommandParams.fromJSON(e))
        : [],
      cursorPos: isSet(object.cursorPos) ? globalThis.Number(object.cursorPos) : 0,
      validCommand: isSet(object.validCommand) ? globalThis.Boolean(object.validCommand) : false,
      longestCommonSubstring: isSet(object.longestCommonSubstring)
        ? globalThis.String(object.longestCommonSubstring)
        : "",
    };
  },

  toJSON(message: CliCommandResponse): unknown {
    const obj: any = {};
    if (message.cmdAction !== 0) {
      obj.cmdAction = commandActionToJSON(message.cmdAction);
    }
    if (message.command !== "") {
      obj.command = message.command;
    }
    if (message.executionResults !== "") {
      obj.executionResults = message.executionResults;
    }
    if (message.commandCompletions?.length) {
      obj.commandCompletions = message.commandCompletions.map((e) => CliCommandCompletion.toJSON(e));
    }
    if (message.resultFormat !== 0) {
      obj.resultFormat = resultFormatToJSON(message.resultFormat);
    }
    if (message.cmdParams?.length) {
      obj.cmdParams = message.cmdParams.map((e) => CliCommandParams.toJSON(e));
    }
    if (message.cursorPos !== 0) {
      obj.cursorPos = Math.round(message.cursorPos);
    }
    if (message.validCommand !== false) {
      obj.validCommand = message.validCommand;
    }
    if (message.longestCommonSubstring !== "") {
      obj.longestCommonSubstring = message.longestCommonSubstring;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CliCommandResponse>, I>>(base?: I): CliCommandResponse {
    return CliCommandResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CliCommandResponse>, I>>(object: I): CliCommandResponse {
    const message = createBaseCliCommandResponse();
    message.cmdAction = object.cmdAction ?? 0;
    message.command = object.command ?? "";
    message.executionResults = object.executionResults ?? "";
    message.commandCompletions = object.commandCompletions?.map((e) => CliCommandCompletion.fromPartial(e)) || [];
    message.resultFormat = object.resultFormat ?? 0;
    message.cmdParams = object.cmdParams?.map((e) => CliCommandParams.fromPartial(e)) || [];
    message.cursorPos = object.cursorPos ?? 0;
    message.validCommand = object.validCommand ?? false;
    message.longestCommonSubstring = object.longestCommonSubstring ?? "";
    return message;
  },
};

function createBaseCablingRemotePort(): CablingRemotePort {
  return { nodeId: "", port: undefined };
}

export const CablingRemotePort = {
  encode(message: CablingRemotePort, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeId !== "") {
      writer.uint32(10).string(message.nodeId);
    }
    if (message.port !== undefined) {
      CablingPort.encode(message.port, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CablingRemotePort {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCablingRemotePort();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.port = CablingPort.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CablingRemotePort {
    return {
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      port: isSet(object.port) ? CablingPort.fromJSON(object.port) : undefined,
    };
  },

  toJSON(message: CablingRemotePort): unknown {
    const obj: any = {};
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.port !== undefined) {
      obj.port = CablingPort.toJSON(message.port);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CablingRemotePort>, I>>(base?: I): CablingRemotePort {
    return CablingRemotePort.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CablingRemotePort>, I>>(object: I): CablingRemotePort {
    const message = createBaseCablingRemotePort();
    message.nodeId = object.nodeId ?? "";
    message.port = (object.port !== undefined && object.port !== null)
      ? CablingPort.fromPartial(object.port)
      : undefined;
    return message;
  },
};

function createBaseCablingPort(): CablingPort {
  return {
    lineCard: 0,
    port: 0,
    breakout: 0,
    portSpeed: 0,
    portMaxSpeed: 0,
    expectedCableType: 0,
    cableSpeed: 0,
    cableType: 0,
  };
}

export const CablingPort = {
  encode(message: CablingPort, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lineCard !== 0) {
      writer.uint32(16).uint32(message.lineCard);
    }
    if (message.port !== 0) {
      writer.uint32(24).uint32(message.port);
    }
    if (message.breakout !== 0) {
      writer.uint32(32).uint32(message.breakout);
    }
    if (message.portSpeed !== 0) {
      writer.uint32(40).uint32(message.portSpeed);
    }
    if (message.portMaxSpeed !== 0) {
      writer.uint32(48).uint32(message.portMaxSpeed);
    }
    if (message.expectedCableType !== 0) {
      writer.uint32(56).int32(message.expectedCableType);
    }
    if (message.cableSpeed !== 0) {
      writer.uint32(64).uint32(message.cableSpeed);
    }
    if (message.cableType !== 0) {
      writer.uint32(72).uint32(message.cableType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CablingPort {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCablingPort();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.lineCard = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.port = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.breakout = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.portSpeed = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.portMaxSpeed = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.expectedCableType = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.cableSpeed = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.cableType = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CablingPort {
    return {
      lineCard: isSet(object.lineCard) ? globalThis.Number(object.lineCard) : 0,
      port: isSet(object.port) ? globalThis.Number(object.port) : 0,
      breakout: isSet(object.breakout) ? globalThis.Number(object.breakout) : 0,
      portSpeed: isSet(object.portSpeed) ? globalThis.Number(object.portSpeed) : 0,
      portMaxSpeed: isSet(object.portMaxSpeed) ? globalThis.Number(object.portMaxSpeed) : 0,
      expectedCableType: isSet(object.expectedCableType) ? cableTypeFromJSON(object.expectedCableType) : 0,
      cableSpeed: isSet(object.cableSpeed) ? globalThis.Number(object.cableSpeed) : 0,
      cableType: isSet(object.cableType) ? globalThis.Number(object.cableType) : 0,
    };
  },

  toJSON(message: CablingPort): unknown {
    const obj: any = {};
    if (message.lineCard !== 0) {
      obj.lineCard = Math.round(message.lineCard);
    }
    if (message.port !== 0) {
      obj.port = Math.round(message.port);
    }
    if (message.breakout !== 0) {
      obj.breakout = Math.round(message.breakout);
    }
    if (message.portSpeed !== 0) {
      obj.portSpeed = Math.round(message.portSpeed);
    }
    if (message.portMaxSpeed !== 0) {
      obj.portMaxSpeed = Math.round(message.portMaxSpeed);
    }
    if (message.expectedCableType !== 0) {
      obj.expectedCableType = cableTypeToJSON(message.expectedCableType);
    }
    if (message.cableSpeed !== 0) {
      obj.cableSpeed = Math.round(message.cableSpeed);
    }
    if (message.cableType !== 0) {
      obj.cableType = Math.round(message.cableType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CablingPort>, I>>(base?: I): CablingPort {
    return CablingPort.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CablingPort>, I>>(object: I): CablingPort {
    const message = createBaseCablingPort();
    message.lineCard = object.lineCard ?? 0;
    message.port = object.port ?? 0;
    message.breakout = object.breakout ?? 0;
    message.portSpeed = object.portSpeed ?? 0;
    message.portMaxSpeed = object.portMaxSpeed ?? 0;
    message.expectedCableType = object.expectedCableType ?? 0;
    message.cableSpeed = object.cableSpeed ?? 0;
    message.cableType = object.cableType ?? 0;
    return message;
  },
};

function createBaseCablingConnection(): CablingConnection {
  return { from: undefined, to: undefined };
}

export const CablingConnection = {
  encode(message: CablingConnection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.from !== undefined) {
      CablingPort.encode(message.from, writer.uint32(10).fork()).ldelim();
    }
    if (message.to !== undefined) {
      CablingRemotePort.encode(message.to, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CablingConnection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCablingConnection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.from = CablingPort.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.to = CablingRemotePort.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CablingConnection {
    return {
      from: isSet(object.from) ? CablingPort.fromJSON(object.from) : undefined,
      to: isSet(object.to) ? CablingRemotePort.fromJSON(object.to) : undefined,
    };
  },

  toJSON(message: CablingConnection): unknown {
    const obj: any = {};
    if (message.from !== undefined) {
      obj.from = CablingPort.toJSON(message.from);
    }
    if (message.to !== undefined) {
      obj.to = CablingRemotePort.toJSON(message.to);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CablingConnection>, I>>(base?: I): CablingConnection {
    return CablingConnection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CablingConnection>, I>>(object: I): CablingConnection {
    const message = createBaseCablingConnection();
    message.from = (object.from !== undefined && object.from !== null)
      ? CablingPort.fromPartial(object.from)
      : undefined;
    message.to = (object.to !== undefined && object.to !== null) ? CablingRemotePort.fromPartial(object.to) : undefined;
    return message;
  },
};

function createBaseCablingDevice(): CablingDevice {
  return { deviceType: 0, nodeId: "", FabricPorts: [], AdditionalPorts: [], connections: [], deviceId: "" };
}

export const CablingDevice = {
  encode(message: CablingDevice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceType !== 0) {
      writer.uint32(8).int32(message.deviceType);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    for (const v of message.FabricPorts) {
      CablingPort.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.AdditionalPorts) {
      CablingPort.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.connections) {
      CablingConnection.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.deviceId !== "") {
      writer.uint32(50).string(message.deviceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CablingDevice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCablingDevice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deviceType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.FabricPorts.push(CablingPort.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.AdditionalPorts.push(CablingPort.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.connections.push(CablingConnection.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.deviceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CablingDevice {
    return {
      deviceType: isSet(object.deviceType) ? cablingDeviceTypeFromJSON(object.deviceType) : 0,
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      FabricPorts: globalThis.Array.isArray(object?.FabricPorts)
        ? object.FabricPorts.map((e: any) => CablingPort.fromJSON(e))
        : [],
      AdditionalPorts: globalThis.Array.isArray(object?.AdditionalPorts)
        ? object.AdditionalPorts.map((e: any) => CablingPort.fromJSON(e))
        : [],
      connections: globalThis.Array.isArray(object?.connections)
        ? object.connections.map((e: any) => CablingConnection.fromJSON(e))
        : [],
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
    };
  },

  toJSON(message: CablingDevice): unknown {
    const obj: any = {};
    if (message.deviceType !== 0) {
      obj.deviceType = cablingDeviceTypeToJSON(message.deviceType);
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.FabricPorts?.length) {
      obj.FabricPorts = message.FabricPorts.map((e) => CablingPort.toJSON(e));
    }
    if (message.AdditionalPorts?.length) {
      obj.AdditionalPorts = message.AdditionalPorts.map((e) => CablingPort.toJSON(e));
    }
    if (message.connections?.length) {
      obj.connections = message.connections.map((e) => CablingConnection.toJSON(e));
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CablingDevice>, I>>(base?: I): CablingDevice {
    return CablingDevice.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CablingDevice>, I>>(object: I): CablingDevice {
    const message = createBaseCablingDevice();
    message.deviceType = object.deviceType ?? 0;
    message.nodeId = object.nodeId ?? "";
    message.FabricPorts = object.FabricPorts?.map((e) => CablingPort.fromPartial(e)) || [];
    message.AdditionalPorts = object.AdditionalPorts?.map((e) => CablingPort.fromPartial(e)) || [];
    message.connections = object.connections?.map((e) => CablingConnection.fromPartial(e)) || [];
    message.deviceId = object.deviceId ?? "";
    return message;
  },
};

function createBaseCablingRequest(): CablingRequest {
  return { fabricId: "", leafs: [], spines: [], order: 0 };
}

export const CablingRequest = {
  encode(message: CablingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.leafs) {
      CablingDevice.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.spines) {
      CablingDevice.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.order !== 0) {
      writer.uint32(32).uint32(message.order);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CablingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCablingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.leafs.push(CablingDevice.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.spines.push(CablingDevice.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.order = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CablingRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      leafs: globalThis.Array.isArray(object?.leafs) ? object.leafs.map((e: any) => CablingDevice.fromJSON(e)) : [],
      spines: globalThis.Array.isArray(object?.spines) ? object.spines.map((e: any) => CablingDevice.fromJSON(e)) : [],
      order: isSet(object.order) ? globalThis.Number(object.order) : 0,
    };
  },

  toJSON(message: CablingRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.leafs?.length) {
      obj.leafs = message.leafs.map((e) => CablingDevice.toJSON(e));
    }
    if (message.spines?.length) {
      obj.spines = message.spines.map((e) => CablingDevice.toJSON(e));
    }
    if (message.order !== 0) {
      obj.order = Math.round(message.order);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CablingRequest>, I>>(base?: I): CablingRequest {
    return CablingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CablingRequest>, I>>(object: I): CablingRequest {
    const message = createBaseCablingRequest();
    message.fabricId = object.fabricId ?? "";
    message.leafs = object.leafs?.map((e) => CablingDevice.fromPartial(e)) || [];
    message.spines = object.spines?.map((e) => CablingDevice.fromPartial(e)) || [];
    message.order = object.order ?? 0;
    return message;
  },
};

function createBaseCablingResponse(): CablingResponse {
  return { leafs: [], spines: [] };
}

export const CablingResponse = {
  encode(message: CablingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.leafs) {
      CablingDevice.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.spines) {
      CablingDevice.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CablingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCablingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.leafs.push(CablingDevice.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.spines.push(CablingDevice.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CablingResponse {
    return {
      leafs: globalThis.Array.isArray(object?.leafs) ? object.leafs.map((e: any) => CablingDevice.fromJSON(e)) : [],
      spines: globalThis.Array.isArray(object?.spines) ? object.spines.map((e: any) => CablingDevice.fromJSON(e)) : [],
    };
  },

  toJSON(message: CablingResponse): unknown {
    const obj: any = {};
    if (message.leafs?.length) {
      obj.leafs = message.leafs.map((e) => CablingDevice.toJSON(e));
    }
    if (message.spines?.length) {
      obj.spines = message.spines.map((e) => CablingDevice.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CablingResponse>, I>>(base?: I): CablingResponse {
    return CablingResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CablingResponse>, I>>(object: I): CablingResponse {
    const message = createBaseCablingResponse();
    message.leafs = object.leafs?.map((e) => CablingDevice.fromPartial(e)) || [];
    message.spines = object.spines?.map((e) => CablingDevice.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetImageTargetsRequest(): GetImageTargetsRequest {
  return { lookbackDays: 0, releaseTypes: [], sortField: 0 };
}

export const GetImageTargetsRequest = {
  encode(message: GetImageTargetsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lookbackDays !== 0) {
      writer.uint32(8).uint32(message.lookbackDays);
    }
    for (const v of message.releaseTypes) {
      writer.uint32(18).string(v!);
    }
    if (message.sortField !== 0) {
      writer.uint32(24).int32(message.sortField);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetImageTargetsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetImageTargetsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.lookbackDays = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.releaseTypes.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.sortField = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetImageTargetsRequest {
    return {
      lookbackDays: isSet(object.lookbackDays) ? globalThis.Number(object.lookbackDays) : 0,
      releaseTypes: globalThis.Array.isArray(object?.releaseTypes)
        ? object.releaseTypes.map((e: any) => globalThis.String(e))
        : [],
      sortField: isSet(object.sortField) ? fabricImageSortFieldFromJSON(object.sortField) : 0,
    };
  },

  toJSON(message: GetImageTargetsRequest): unknown {
    const obj: any = {};
    if (message.lookbackDays !== 0) {
      obj.lookbackDays = Math.round(message.lookbackDays);
    }
    if (message.releaseTypes?.length) {
      obj.releaseTypes = message.releaseTypes;
    }
    if (message.sortField !== 0) {
      obj.sortField = fabricImageSortFieldToJSON(message.sortField);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetImageTargetsRequest>, I>>(base?: I): GetImageTargetsRequest {
    return GetImageTargetsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetImageTargetsRequest>, I>>(object: I): GetImageTargetsRequest {
    const message = createBaseGetImageTargetsRequest();
    message.lookbackDays = object.lookbackDays ?? 0;
    message.releaseTypes = object.releaseTypes?.map((e) => e) || [];
    message.sortField = object.sortField ?? 0;
    return message;
  },
};

function createBaseGetImageTargetsResponse(): GetImageTargetsResponse {
  return { latest: undefined, fabricImages: [], otherImages: [] };
}

export const GetImageTargetsResponse = {
  encode(message: GetImageTargetsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.latest !== undefined) {
      ReleaseImageInfo.encode(message.latest, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.fabricImages) {
      FabricImageInfo.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.otherImages) {
      ReleaseImageInfo.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetImageTargetsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetImageTargetsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.latest = ReleaseImageInfo.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricImages.push(FabricImageInfo.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.otherImages.push(ReleaseImageInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetImageTargetsResponse {
    return {
      latest: isSet(object.latest) ? ReleaseImageInfo.fromJSON(object.latest) : undefined,
      fabricImages: globalThis.Array.isArray(object?.fabricImages)
        ? object.fabricImages.map((e: any) => FabricImageInfo.fromJSON(e))
        : [],
      otherImages: globalThis.Array.isArray(object?.otherImages)
        ? object.otherImages.map((e: any) => ReleaseImageInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetImageTargetsResponse): unknown {
    const obj: any = {};
    if (message.latest !== undefined) {
      obj.latest = ReleaseImageInfo.toJSON(message.latest);
    }
    if (message.fabricImages?.length) {
      obj.fabricImages = message.fabricImages.map((e) => FabricImageInfo.toJSON(e));
    }
    if (message.otherImages?.length) {
      obj.otherImages = message.otherImages.map((e) => ReleaseImageInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetImageTargetsResponse>, I>>(base?: I): GetImageTargetsResponse {
    return GetImageTargetsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetImageTargetsResponse>, I>>(object: I): GetImageTargetsResponse {
    const message = createBaseGetImageTargetsResponse();
    message.latest = (object.latest !== undefined && object.latest !== null)
      ? ReleaseImageInfo.fromPartial(object.latest)
      : undefined;
    message.fabricImages = object.fabricImages?.map((e) => FabricImageInfo.fromPartial(e)) || [];
    message.otherImages = object.otherImages?.map((e) => ReleaseImageInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFabricImageInfo(): FabricImageInfo {
  return { fabricId: "", fabricName: "", tag: "", releaseAt: undefined, expireAt: undefined, docLink: "" };
}

export const FabricImageInfo = {
  encode(message: FabricImageInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.fabricName !== "") {
      writer.uint32(18).string(message.fabricName);
    }
    if (message.tag !== "") {
      writer.uint32(26).string(message.tag);
    }
    if (message.releaseAt !== undefined) {
      Timestamp.encode(toTimestamp(message.releaseAt), writer.uint32(34).fork()).ldelim();
    }
    if (message.expireAt !== undefined) {
      Timestamp.encode(toTimestamp(message.expireAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.docLink !== "") {
      writer.uint32(50).string(message.docLink);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricImageInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricImageInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tag = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.releaseAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.expireAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.docLink = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricImageInfo {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      fabricName: isSet(object.fabricName) ? globalThis.String(object.fabricName) : "",
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
      releaseAt: isSet(object.releaseAt) ? fromJsonTimestamp(object.releaseAt) : undefined,
      expireAt: isSet(object.expireAt) ? fromJsonTimestamp(object.expireAt) : undefined,
      docLink: isSet(object.docLink) ? globalThis.String(object.docLink) : "",
    };
  },

  toJSON(message: FabricImageInfo): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.fabricName !== "") {
      obj.fabricName = message.fabricName;
    }
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    if (message.releaseAt !== undefined) {
      obj.releaseAt = message.releaseAt.toISOString();
    }
    if (message.expireAt !== undefined) {
      obj.expireAt = message.expireAt.toISOString();
    }
    if (message.docLink !== "") {
      obj.docLink = message.docLink;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricImageInfo>, I>>(base?: I): FabricImageInfo {
    return FabricImageInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricImageInfo>, I>>(object: I): FabricImageInfo {
    const message = createBaseFabricImageInfo();
    message.fabricId = object.fabricId ?? "";
    message.fabricName = object.fabricName ?? "";
    message.tag = object.tag ?? "";
    message.releaseAt = object.releaseAt ?? undefined;
    message.expireAt = object.expireAt ?? undefined;
    message.docLink = object.docLink ?? "";
    return message;
  },
};

function createBaseReleaseImageInfo(): ReleaseImageInfo {
  return { tag: "", releaseAt: undefined, expireAt: undefined, docLink: "" };
}

export const ReleaseImageInfo = {
  encode(message: ReleaseImageInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== "") {
      writer.uint32(10).string(message.tag);
    }
    if (message.releaseAt !== undefined) {
      Timestamp.encode(toTimestamp(message.releaseAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.expireAt !== undefined) {
      Timestamp.encode(toTimestamp(message.expireAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.docLink !== "") {
      writer.uint32(34).string(message.docLink);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReleaseImageInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReleaseImageInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tag = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.releaseAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.expireAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.docLink = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReleaseImageInfo {
    return {
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
      releaseAt: isSet(object.releaseAt) ? fromJsonTimestamp(object.releaseAt) : undefined,
      expireAt: isSet(object.expireAt) ? fromJsonTimestamp(object.expireAt) : undefined,
      docLink: isSet(object.docLink) ? globalThis.String(object.docLink) : "",
    };
  },

  toJSON(message: ReleaseImageInfo): unknown {
    const obj: any = {};
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    if (message.releaseAt !== undefined) {
      obj.releaseAt = message.releaseAt.toISOString();
    }
    if (message.expireAt !== undefined) {
      obj.expireAt = message.expireAt.toISOString();
    }
    if (message.docLink !== "") {
      obj.docLink = message.docLink;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReleaseImageInfo>, I>>(base?: I): ReleaseImageInfo {
    return ReleaseImageInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReleaseImageInfo>, I>>(object: I): ReleaseImageInfo {
    const message = createBaseReleaseImageInfo();
    message.tag = object.tag ?? "";
    message.releaseAt = object.releaseAt ?? undefined;
    message.expireAt = object.expireAt ?? undefined;
    message.docLink = object.docLink ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
