import { grpc } from "@improbable-eng/grpc-web";
import { FredClientImpl } from "src/gen/schema/fred/service";
import {
  GetDeviceDetailsRequest,
  GetDeviceDetailsResponse,
  GetUserContextsRequest,
  GetUserContextsResponse,
  GetUserPreferencesRequest
} from "src/gen/schema/fred/fapi";
import { UserPreferences } from "src/gen/schema/models/user";
import { GrpcDebugableWebImpl } from "./grpcDebugableWebImpl";
import { xCsrfTransportFactory } from "./xCsrfTransportFactory";

const transport: grpc.TransportFactory = xCsrfTransportFactory();

const rpc = new GrpcDebugableWebImpl("", { debug: false, transport });
const fredClient = new FredClientImpl(rpc);

export const Fred = {
  getUserContexts: (
    req: GetUserContextsRequest = GetUserContextsRequest.create()
  ): Promise<GetUserContextsResponse> => {
    return fredClient.GetUserContexts(req);
  },

  getUserPreferences: (
    req: GetUserPreferencesRequest = GetUserPreferencesRequest.create()
  ): Promise<UserPreferences> => {
    return fredClient.GetUserPreferences(req);
  },

  /**
   * [TORT-4916] Switch details ( in addition metadata about device and node, the response includes the bind and claim states)
   */
  getDeviceDetails: (
    req: GetDeviceDetailsRequest = GetDeviceDetailsRequest.create()
  ): Promise<GetDeviceDetailsResponse> => {
    return fredClient.GetDeviceDetails(req);
  }
};
