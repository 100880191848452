import { Icon, Nav } from "@magnetic/nav";
import React, { FormEvent, useContext, useState, useCallback } from "react";
import "./navbar.scss";
import { LoginContext } from "./loginContext";
import { UserRoleType } from "../dataTypes/userRoleType";
import { UserRole } from "../gen/schema/models/auth";
import { UserContext } from "src/gen/schema/fred/fapi";
import "./orgSwitcher.scss";
import { Button } from "@magnetic/button";
import { Plus } from "@magnetic/icons";
import { Search } from "components/search";

interface TenantProps {
  readonly context: UserContext;
}

/**
 * Used to map the UserContext to
 * key by Org ID. this way the tenants
 * can be grouped in the drawer by
 * org.
 */
const mapUserContextToOrgs = (
  contexts: UserContext[]
): Map<string, UserContext[]> => {
  return contexts.reduce((out, context: UserContext) => {
    const tmp = out.get(context.orgId);
    if (tmp) {
      return out.set(context.orgId, [...tmp, context]);
    } else {
      return out.set(context.orgId, [context]);
    }
  }, new Map<string, UserContext[]>());
};

const Tenants = ({ context }: TenantProps) => {
  const { session, switchTenant } = useContext(LoginContext);
  if (session) {
    const { tenantId, tenantName, role } = context;
    const contextRole = new UserRoleType(
      role || UserRole.USER_ROLE_UNSPECIFIED
    );
    return (
      <Nav.Drawer.Item
        className="org-tenant"
        onClick={() => {
          switchTenant(tenantId);
        }}
        selected={session?.tenantId === tenantId}
      >
        {tenantName}
        {contextRole.render()}
      </Nav.Drawer.Item>
    );
  }
  return null;
};

const sortAlphabetically = (a: string = "", b: string = "") => {
  const nameA = a?.toUpperCase?.(); // ignore upper and lowercase
  const nameB = b?.toUpperCase?.(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

interface OrgsSwitcherDrawerProps {
  readonly openOrgModal: () => void;
}

export const OrgsSwitcherDrawer = ({
  openOrgModal
}: OrgsSwitcherDrawerProps) => {
  const { session, userContexts } = useContext(LoginContext);
  const [orgSearch, setOrgSearch] = useState<string>("");

  const handleSearchCriteriaChange = useCallback(
    (e: FormEvent<HTMLInputElement>): void => {
      const { value } = e.currentTarget;
      setOrgSearch(value);
    },
    []
  );

  if (userContexts?.contexts && session) {
    const contexts = mapUserContextToOrgs(userContexts.contexts);

    const items = Array.from(contexts.entries())
      .sort((a, b) =>
        sortAlphabetically(a?.[1]?.[0]?.orgName, b?.[1]?.[0]?.orgName)
      )
      .map(([key, context]) => {
        const filteredContext = context.filter((ctx) =>
          ctx.tenantName.toLowerCase().includes(orgSearch.toLowerCase())
        );

        if (filteredContext.length === 0 && orgSearch !== "") {
          return null;
        }

        return (
          <Nav.Drawer.ItemGroup className="org-drawer" key={key} label="">
            {filteredContext
              .sort((a, b) => sortAlphabetically(a?.tenantName, b?.tenantName))
              .map((c) => (
                <Tenants key={c.tenantId} context={c} />
              ))}
          </Nav.Drawer.ItemGroup>
        );
      });

    return (
      <Nav.Drawer.Content
        heading="Select an organization"
        id="organization"
        key="org"
      >
        <Search
          label=""
          className="org-search-bar"
          onChange={handleSearchCriteriaChange}
          options={[]}
          placeholder="Search organization name"
        />
        <Button
          icon={<Plus size={14} weight="bold" />}
          name="create-org"
          className="org-create-button"
          kind="tertiary"
          onClick={openOrgModal}
          size="md"
        >
          Create new organization
        </Button>
        {items}
      </Nav.Drawer.Content>
    );
  }
  return null;
};

export const OrgSwitcher = () => {
  const { session } = useContext(LoginContext);
  return (
    <Nav.Item
      drawerContentId="organization"
      heading="Organization"
      icon={<Icon kind="organization-switcher" />}
      label={session?.tenantName}
      switcher={true}
    />
  );
};
