import { grpc } from "@improbable-eng/grpc-web";

/**
 * Creates a TransportFactory that sets xCsrfToken in AppState.
 *
 * @param baseFactory - The original factory you want to wrap.
 *   Defaults to grpc.CrossBrowserHttpTransport({}), because that is the default of @improbable-eng/grpc-web
 *   https://github.com/improbable-eng/grpc-web/blob/master/client/grpc-web/docs/transport.md
 */
export function xCsrfTransportFactory(
  baseFactory: grpc.TransportFactory = grpc.CrossBrowserHttpTransport({})
): grpc.TransportFactory {
  return (options: grpc.TransportOptions): grpc.Transport => {
    const baseTransport = baseFactory(options);

    return {
      sendMessage: (msgBytes: Uint8Array) => {
        baseTransport.sendMessage(msgBytes);
      },
      finishSend: () => {
        baseTransport.finishSend();
      },
      cancel: () => {
        baseTransport.cancel();
      },
      // Override the 'start' method to intercept headers.
      start: (metadata: grpc.Metadata) => {
        // Save original onHeaders callback
        const originalOnHeaders = options.onHeaders;

        // Wrap the original onHeaders so we can set xCsrfToken, then call the original
        options.onHeaders = (headers: grpc.Metadata, status: number) => {
          const xCsrfToken: string | undefined = headers.get("X-CSRF-Token")[0];
          if (xCsrfToken) {
            window.cnc.xCsrfToken = xCsrfToken;
          }
          originalOnHeaders(headers, status);
        };

        // Call the base transport’s start method.
        baseTransport.start(metadata);
      }
    };
  };
}
